import { useState } from 'react'
import type { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Calendar, Button } from 'antd'

type CalendarProps = {
  defaultValue?: Dayjs
  onSubmit: (selection: Dayjs) => void
}

const DateSelect = ({ onSubmit, defaultValue }: CalendarProps) => {
  const { t } = useTranslation()
  const [selection, setSelection] = useState(defaultValue)

  return (
    <>
      <Calendar
        fullscreen={false}
        defaultValue={selection}
        style={{
          width: '300px',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        onSelect={(nextValue) => {
          setSelection(nextValue)
        }}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '6px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          block
          type="primary"
          onClick={() => {
            onSubmit(selection!)
          }}
        >
          {t('new-task-form.due-date.save')}
        </Button>
      </div>
    </>
  )
}

export default DateSelect
