import { FCProps } from 'types/FC'
import { Cascader, Button, Tooltip } from 'antd'

export type DropdownProps = FCProps & {
  title?: string
  label?: string
  isOpen?: boolean
  icon?: JSX.Element
  outlined?: boolean
  onChange: (value: boolean) => void
  isReadonly?: boolean
}

const Dropdown = ({
  icon,
  label,
  title,
  isOpen,
  onChange,
  outlined,
  children,
  isReadonly,
}: DropdownProps) => (
  <Cascader
    open={isOpen}
    dropdownRender={() => children as JSX.Element}
    onDropdownVisibleChange={(value) => onChange(value)}
    disabled={isReadonly}
  >
    <span>
      <Tooltip title={title}>
        <Button
          type={outlined ? 'default' : 'text'}
          icon={icon}
          disabled={isReadonly}
        >
          {label}
        </Button>
      </Tooltip>
    </span>
  </Cascader>
)

export default Dropdown
