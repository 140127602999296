import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from "react-router-dom";
import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Col, List, Typography, Pagination } from 'antd'
import { useTranslation } from 'react-i18next'
import useStore from 'hooks/useStore'
import { Notification } from 'services/Notification.slice'
import styles from './NotificationList.module.scss'

dayjs.extend(relativeTime)
const pageSize = 5

type NotificationListProps = {
  notifications: Notification[]
  filter?: string
  setItemRead: (id: string) => void
  itemsRead: string[]
  closeModal: (val: boolean) => void
}
const icons = {
  'due.today': <ExclamationCircleOutlined />,
  calendar: <CalendarOutlined />,
  delegated: <UserOutlined />,
  'group.invitation': <UsergroupAddOutlined />,
}

const NotificationList = ({
  notifications,
  filter,
  setItemRead,
  itemsRead,
  closeModal,
}: NotificationListProps) => {
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(1)
  const { t } = useTranslation()
  const [filteredList, setFilteredList] = useState<Notification[]>([])
  const patchReadAt = useStore((state) => state.patchReadAt)
  const navigate = useNavigate();

  useEffect(() => {
    const totalList = notifications.filter((notification) => {
      return filter === 'unread'
        ? notification.readAt === null
        : notification.readAt !== null
    })
    setTotal(totalList.length)
    const nextFilteredList = totalList.slice(
      pageSize * (current - 1),
      pageSize * current,
    )
    setFilteredList(nextFilteredList)
  }, [filter, notifications, current])

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={filteredList}
        renderItem={(item, index) => (
          <List.Item
            className={styles.item}
            style={{ cursor: 'pointer' }}
            key={`${index}-${item.id}`}
            onClick={() => filter === 'unread' && patchReadAt([item.id])}
            actions={[
              <Typography>{dayjs().to(dayjs(item.createdAt))}</Typography>,
              <Checkbox
                checked={itemsRead.some((read) => item.id === read)}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setItemRead(item.id)
                }}
              />,
            ].filter((_, i) => filter !== 'read' || i !== 1)}
          >
            <List.Item.Meta
              avatar={icons[item.templateName]}
              title={t(
                `notifications.${item.templateName}-title`,
                item.variables || {},
              )}
              description={t(
                `notifications.${item.templateName}-body`,
                item.variables || {},
              )}
            />
            {item.actions && (
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '16px',
                }}
              >
                {item.templateName === "delegated" ? 
                  <Button
                    key={"viewTask"}
                    style={{marginRight: '16px'}}
                    onClick={(e) => {
                      // By not stopping propagation, the notification will be considered read
                      navigate(`/task/${item.variables?.taskId}`)
                      closeModal(false)
                    }}
                  >
                    View
                  </Button> :
                  Object.keys(item.actions).map((action, i) => (
                    <Button
                      key={action}
                      style={{
                        marginRight:
                          item.actions &&
                          Object.keys(item.actions).length > 1 &&
                          i !== Object.keys(item.actions).length - 1
                            ? '16px'
                            : '0px',
                      }}
                      onClick={(e) => {
                        // By not stopping propagation, the notification will be considered read
                        item.actions &&
                          (window.location.href = item.actions[action])
                      }}
                    >
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </Button>
                ))}
              </Col>
            )}
          </List.Item>
        )}
      />
      {total > pageSize && (
        <Pagination
          onChange={setCurrent}
          current={current}
          total={total}
          pageSize={pageSize}
        />
      )}
    </>
  )
}

export default NotificationList
