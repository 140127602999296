import { AutoComplete, Select, Tooltip, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardFooterSelectItem from './CardFooterSelectItem'
import { ManagingRoleId } from 'utils/templates'
import { RoleMappingForTaskNode } from 'types/Tasks'
import { UserTemplateRole } from 'types/Templates'

const { Text } = Typography

export type CardFooterProps = {
  isDraft: boolean
  priorityGroup?: number
  onPriorityGroupChange?: (value: number) => void
  roleMapping?: RoleMappingForTaskNode | null
  onRoleMappingChange?: (value: RoleMappingForTaskNode) => void
  templateRoles?: Partial<UserTemplateRole>[]
}

const CardFooter = (props: CardFooterProps) => {
  const { t } = useTranslation()
  const {
    isDraft,
    priorityGroup,
    onPriorityGroupChange,
    roleMapping,
    onRoleMappingChange,
    templateRoles,
  } = props
  const taskPriorityGroups = useStore((state) => state.priorityGroups)
  const [isPriorityGroupOpen, setIsPriorityGroupOpen] = useState(false)
  const [isProjectManagerOpen, setIsProjectManagerOpen] = useState(false)

  const projectManagerList = ManagingRoleId.map((role) => ({
    label: role.role,
    value: role.id,
  }))
  const roleList = templateRoles?.map((role) => ({
    label: role.title,
    value: role.title,
  }))
  const assigningRoleMatchWithExistent = !!templateRoles?.find(
    (role) => role.title === roleMapping?.assigningRole,
  )

  if (isDraft) {
    if (!roleMapping?.assigningRole) {
      return null
    }
    return (
      <CardFooterSelectItem label={t('templates.assignee-role')}>
        {assigningRoleMatchWithExistent ? (
          <Text>{roleMapping?.assigningRole}</Text>
        ) : (
          <Text strong={true}>{roleMapping?.assigningRole}</Text>
        )}
      </CardFooterSelectItem>
    )
  }

  return (
    <>
      <CardFooterSelectItem label={t('new-task-form.priority-group')}>
        <Select
          style={{ width: 150 }}
          placeholder={t('new-task-form.priority-group')}
          onChange={onPriorityGroupChange}
          onClick={() => setIsPriorityGroupOpen(!isPriorityGroupOpen)}
          open={isPriorityGroupOpen}
          onDropdownVisibleChange={(visible) => setIsPriorityGroupOpen(visible)}
          options={taskPriorityGroups
            ?.filter((pg) => pg.id < 4)
            .map((priorityGroup) => ({
              value: priorityGroup.id || 0,
              label: priorityGroup.title,
            }))}
          value={priorityGroup || 0}
        />
      </CardFooterSelectItem>
      <CardFooterSelectItem label={t('templates.project-manager-role')}>
        <Tooltip title={t('templates.tooltip.manager-role-description')}>
          <Select
            style={{ width: 150 }}
            placeholder={t('templates.project-manager-role')}
            onChange={(e) =>
              onRoleMappingChange?.({ ...roleMapping, managingRole: e })
            }
            onClick={() => setIsProjectManagerOpen(!isProjectManagerOpen)}
            open={isProjectManagerOpen}
            onDropdownVisibleChange={(visible) =>
              setIsProjectManagerOpen(visible)
            }
            options={projectManagerList}
            value={roleMapping?.managingRole || 0}
          />
        </Tooltip>
      </CardFooterSelectItem>
      <CardFooterSelectItem label={t('templates.assignee-role')}>
        <Tooltip title={t('templates.tooltip.assignee-role-description')}>
          <AutoComplete
            value={roleMapping?.assigningRole}
            onChange={(e) => {
              onRoleMappingChange?.({ ...roleMapping, assigningRole: e })
            }}
            options={roleList}
            style={{ width: 150 }}
            filterOption={(inputValue, option) =>
              (option!.value || '')
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            placeholder={t('templates.tooltip.assignee-role-placeholder')}
          />
        </Tooltip>
      </CardFooterSelectItem>
    </>
  )
}

export default CardFooter
