import { Modal } from 'antd'
import { t } from 'i18next'
import GroupForm from './GroupForm'

type AddGroupModalProps = {
  isOpen: boolean
  handleClose: () => void
}

const AddGroupModal = ({ isOpen, handleClose }: AddGroupModalProps) => {
  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      title={t('group.title')}
      footer={null}
    >
      <GroupForm handleClose={handleClose} />
    </Modal>
  )
}

export default AddGroupModal
