import clsx from 'clsx'
import { motion } from 'framer-motion'
import React from 'react'
import 'reactflow/dist/style.css'
import styles from '../../TaskFlowViewer.module.scss'

type IconContainerProps = {
  children: React.JSX.Element
  isAvatarContainer?: boolean
}

const IconContainer = ({ children, isAvatarContainer }: IconContainerProps) => (
  <motion.div
    className={clsx(
      styles.iconContainer,
      isAvatarContainer && styles.iconAvatar,
    )}
    whileHover={{ scale: 1.2 }}
    whileTap={{ scale: 0.8 }}
  >
    {children}
  </motion.div>
)

export default IconContainer
