import { DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TaskItem } from 'types/Tasks'
import DeleteTasksDialog from './DeleteTasksDialog'

type DeleteTaskButtonProps = {
  disabled?: boolean
  isCustomDialog?: boolean
  isMainTemplateTask?: boolean
  isIcon?: boolean
  onExecute?: () => void
  task?: Partial<TaskItem> | undefined
}

const DeleteTaskButton = ({
  disabled = false,
  isCustomDialog = false,
  isMainTemplateTask: isDeletingTemplate = false,
  isIcon = false,
  onExecute = () => {},
  task,
}: DeleteTaskButtonProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const openDialog = () => {
    setIsOpen(true)
  }

  const button = isIcon ? (
    <Button
      type="text"
      icon={<DeleteOutlined />}
      onClick={openDialog}
      disabled={disabled}
    />
  ) : (
    <Button
      danger
      style={{ marginLeft: 'auto' }}
      onClick={openDialog}
      disabled={disabled}
    >
      {t('my-today-page.delete-modal.delete', {
        ns: 'common',
      })}
      <DeleteOutlined />
    </Button>
  )

  return (
    <DeleteTasksDialog
      children={button}
      isDeletingTemplate={isDeletingTemplate}
      onExecute={onExecute}
      setShowDialog={setIsOpen}
      showDialog={isOpen}
      taskIds={task?.id ? [task.id] : []}
      taskHasChildren={isCustomDialog}
    />
  )
}

export default DeleteTaskButton
