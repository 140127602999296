import {
  Filter,
  Grouping,
  Sorting,
  SummaryItem,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid'
import type { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import {
  GridView,
  GridViewResponse,
  GridViewListItem,
  GridViewParams,
} from 'types/GridView'

const removePaginationQuery = '?page=1&offset=0&limit=20'
const defaultPageSize = 15
const defaultColumns = [
  'owner',
  'group',
  'status',
  'title',
  'priorityGroup',
  'description',
  'assignedTo',
  'mode',
  'level',
  'recurring',
  'urgent',
  'parent',
  'taskAttachmentsCount',
  'dueAt',
  'createdAt',
  'updatedAt',
  'completionAt',
  'activatedAt',
]
const defaultTableColumnExtensions = [
  { columnName: 'owner', width: '200px', wordWrapEnabled: true },
  { columnName: 'group', width: '150px', wordWrapEnabled: true },
  { columnName: 'status', width: '150px', wordWrapEnabled: true },
  { columnName: 'title', width: '250px', wordWrapEnabled: true },
  { columnName: 'priorityGroup', width: '150px', wordWrapEnabled: true },
  { columnName: 'description', width: '250px', wordWrapEnabled: false },
  { columnName: 'assignedTo', width: '200px', wordWrapEnabled: true },
  { columnName: 'mode', width: '150px', wordWrapEnabled: true },
  { columnName: 'level', width: '150px', wordWrapEnabled: true },
  { columnName: 'recurring', width: '150px', wordWrapEnabled: true },
  { columnName: 'urgent', width: '100px', wordWrapEnabled: true },
  { columnName: 'parent', width: '150px', wordWrapEnabled: true },
  { columnName: 'taskAttachmentsCount', width: '150px', wordWrapEnabled: true },
  { columnName: 'dueAt', width: '250px', wordWrapEnabled: true },
  { columnName: 'createdAt', width: '250px', wordWrapEnabled: true },
  { columnName: 'updatedAt', width: '250px', wordWrapEnabled: true },
  { columnName: 'completionAt', width: '250px', wordWrapEnabled: true },
  { columnName: 'activatedAt', width: '250px', wordWrapEnabled: true },
]

const defaultSorting = [
  { columnName: 'createdAt', direction: 'desc' },
] as Sorting[]

const defaultGridView = {
  id: -1,
  title: 'Default',
  gridData: {
    pageSize: defaultPageSize,
    grouping: [],
    filters: [],
    sorting: defaultSorting,
    columnOrder: defaultColumns,
    hiddenColumnNames: [],
    tableColumnExtensions: defaultTableColumnExtensions,
  },
}

export interface AllTasksState extends ApiState {
  isGridViewChanged: boolean
  columnOrder: string[]
  filters: Filter[]
  grouping: Grouping[]
  hiddenColumnNames: string[]
  pageSize: number
  sorting: Sorting[]
  currentPage: number
  summaryItems: SummaryItem[]
  tableColumnExtensions: TableColumnWidthInfo[]
  setColumnOrder: (value: string[]) => void
  setFilters: (value: Filter[]) => void
  setGrouping: (value: Grouping[]) => void
  setHiddenColumnNames: (value: string[]) => void
  setPageSize: (value: number) => void
  setSorting: (value: Sorting[]) => void
  setCurrentPage: (value: number) => void
  setSummaryItems: (value: SummaryItem[]) => void
  setTableColumnExtensions: (value: TableColumnWidthInfo[]) => void
  selectedGridView: GridViewListItem
  setGridView: (id: number) => Promise<void>
  setGridViewData: (data: GridView) => void
  listGridViews: () => Promise<GridViewListItem[]>
  saveGridView: (data: GridViewParams) => Promise<void>
  deleteGridView: (id: number) => Promise<void>
  editGridView: (data: GridViewParams) => Promise<void>
  shouldUpdateGridViews: boolean
  setShouldUpdateGridViews: (newShouldUpdateGridViews: boolean) => void
}

export const createAllTasksSlice: StateCreator<
  AllTasksState,
  [['zustand/immer', never]]
> = (set, get) => ({
  isGridViewChanged: false,
  columnOrder: defaultColumns,
  filters: [],
  grouping: [],
  hiddenColumnNames: [],
  pageSize: defaultPageSize,
  sorting: defaultSorting,
  currentPage: 0,
  summaryItems: [],
  tableColumnExtensions: defaultTableColumnExtensions,
  setColumnOrder: (values) => {
    set({
      columnOrder: values,
      isGridViewChanged: true,
    })
  },
  setFilters: (values) => {
    set({
      filters: values,
      isGridViewChanged: true,
    })
  },
  setGrouping: (values) => {
    if (values.length === 0) {
      get().setSummaryItems([])
    } else {
      const lastGroup = values.slice(-1)
      get().setSummaryItems([
        {
          columnName: lastGroup[0].columnName,
          type: 'count',
          showInGroupFooter: false,
        } as SummaryItem,
      ])
    }
    set({
      grouping: values,
      isGridViewChanged: true,
    })
  },
  setHiddenColumnNames: (values) => {
    set({
      hiddenColumnNames: values,
      isGridViewChanged: true,
    })
  },
  setPageSize: (values) => {
    set({
      pageSize: values,
      isGridViewChanged: true,
    })
  },
  setSorting: (values) => {
    set({
      sorting: values,
      isGridViewChanged: true,
    })
  },
  setCurrentPage: (values) => {
    set({
      currentPage: values,
    })
  },
  setSummaryItems: (values) => {
    set({
      summaryItems: values,
      isGridViewChanged: true,
    })
  },
  setTableColumnExtensions: (values) => {
    set({
      tableColumnExtensions: values,
      isGridViewChanged: true,
    })
  },
  setGridView: async (id) => {
    if (id === -1) {
      set({
        selectedGridView: { title: 'Default', id: -1 },
      })
      get().setGridViewData(defaultGridView.gridData)
      return
    }
    const response = await get().api!('GET', `task/grid/${id}`)
    set({
      selectedGridView: { title: response.data.title, id: response.data.id },
    })
    get().setGridViewData(response.data.gridData)
  },
  setGridViewData: ({
    columnOrder,
    filters,
    grouping,
    hiddenColumnNames,
    pageSize,
    sorting,
    tableColumnExtensions,
  }: GridView) => {
    get().setColumnOrder(columnOrder)
    get().setFilters(filters)
    get().setGrouping(grouping)
    get().setHiddenColumnNames(hiddenColumnNames)
    get().setPageSize(pageSize)
    get().setSorting(sorting)
    get().setTableColumnExtensions(tableColumnExtensions)
    set({ isGridViewChanged: false })
  },

  listGridViews: async () => {
    const response = await get().api!(
      'GET',
      `task/grid${removePaginationQuery}`,
    )
    if (response.status === 200) {
      const gridViewTitles = response.data.results.map(
        (view: GridViewResponse) => {
          return { title: view.title, id: view.id }
        },
      )
      return gridViewTitles
    }
    return []
  },
  saveGridView: async (data) => {
    const response = await get().api!('POST', 'task/grid', data)
    if (response.status === 201) {
      get().setGridViewData(response.data.gridData)
      set((draft) => {
        draft.shouldUpdateGridViews = true
        draft.selectedGridView = {
          title: response.data.title,
          id: response.data.id,
        }
      })
    }
  },
  deleteGridView: async (id) => {
    if (id === -1) {
      return
    }
    const response = await get().api!('DELETE', `task/grid/${id}`)
    if (response.status === 200) {
      get().setGridView(-1)
      set((draft) => {
        draft.shouldUpdateGridViews = true
      })
    }
  },
  editGridView: async (data) => {
    if (data.id === -1) {
      return
    }
    const response = await get().api!('PATCH', `task/grid/${data.id}`, data)
    if (response.status === 200) {
      set((draft) => {
        draft.selectedGridView = {
          title: response.data.title,
          id: response.data.id,
        }
        draft.shouldUpdateGridViews = true
      })
      get().setGridViewData(response.data.gridData)
    }
  },
  selectedGridView: {
    title: 'Default',
    id: -1,
  },
  shouldUpdateGridViews: true,
  setShouldUpdateGridViews: (newShouldUpdateGridViews) => {
    set((draft) => {
      draft.shouldUpdateGridViews = newShouldUpdateGridViews
    })
  },
})
