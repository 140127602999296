import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow'
import {
  LayoutDirection,
  hasBeenModified,
  hasBeenModifiedLevelOrParent,
  isUpdatableNode,
} from 'sections/TaskFlowViewer/TaskFlowHelper'
import { TaskEvents, TaskNodeEvent } from 'sections/TaskFlowViewer/FlowUtils'
import { TaskItem, TaskNodeData, TaskPriorityGroup } from 'types/Tasks'
import { type StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import { getTimeZone } from 'utils/IntlUtlis'

const initialNodes: Node[] = []
const initialEdges: Edge[] = []

type DescendantsStructure = {
  tasks: Pick<TaskNodeData, 'id' | 'level' | 'parent'>[]
}

export interface TaskFlowState extends ApiState {
  nodes: Node<TaskNodeData>[]
  edges: Edge<TaskNodeData>[]
  layoutDirection: LayoutDirection
  lastModifiedTasks: Partial<TaskItem>[]
  setLastModifiedTasks: (tasks: Partial<TaskItem>[]) => void
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect
  setLayoutDirection: (direction: LayoutDirection) => void
  updateNode: (nodeId: string, text: string) => void
  addNode: (node: Node<TaskNodeData>) => void
  setInitialData: (nodes: Node<TaskNodeData>[], edges: Edge[]) => void
  childNodeEvent: TaskNodeEvent
  setChildNodeEvent: (event: TaskNodeEvent) => void
  saveChanges: () => Promise<TaskItem[]>
  deleteNewTasks: () => Promise<any>
  updateTaskFlow: (task: TaskNodeData) => Promise<TaskItem[]>
  deleteScheduledTaskFlow: (id: number) => Promise<void>
  deleteTaskFlow: (task: TaskNodeData) => Promise<any>
  updateDescendantsStructure: (data: DescendantsStructure) => Promise<void>
}

function compareByLevel(a: TaskNodeData, b: TaskNodeData) {
  if (a.level < b.level) {
    return -1
  }
  if (a.level > b.level) {
    return 1
  }
  return 0
}

export const createTaskFlowSlice: StateCreator<
  TaskFlowState,
  [['zustand/immer', never]]
> = (set, get) => ({
  childNodeEvent: {
    event: TaskEvents.NONE,
    node: {
      id: '',
      position: { x: 0, y: 0 },
      data: { id: '', title: '', level: 0 },
    },
  },
  nodes: initialNodes,
  edges: initialEdges,
  layoutDirection: LayoutDirection.TOP_TO_BOTTOM,
  lastModifiedTasks: [],
  setLastModifiedTasks: (tasks) => {
    set({
      lastModifiedTasks: tasks,
    })
  },
  updateTaskFlow: async (task: TaskNodeData) => {
    const {
      id,
      title,
      priorityGroup,
      mode,
      scheduledTask,
      dueAt,
      taskDetails,
      originalDueAt,
      originalMode,
      originalPriorityGroup,
      originalScheduledTask,
      originalTaskDetails,
      originalTitle,
      taskRole,
    } = task

    const isRoutineDefinition =
      scheduledTask?.recurRule && !scheduledTask?.initialDate

    if (scheduledTask) {
      delete scheduledTask['initialDate']
    }

    const details = { flag: taskDetails?.flag?.id }
    const updates: { [key: string]: any } = {}

    if (title !== originalTitle) {
      updates.title = title
    }
    if (mode !== originalMode) {
      updates.mode = mode
    }
    if (dueAt !== originalDueAt) {
      updates.dueAt = dueAt
    }
    if (scheduledTask !== originalScheduledTask) {
      updates.scheduledTask = scheduledTask
    }
    if (taskDetails?.flag?.id !== originalTaskDetails?.flag?.id) {
      updates.details = details
    }
    if (isRoutineDefinition) {
      updates.priorityGroup = TaskPriorityGroup.RoutineDefinition
    } else if (priorityGroup !== originalPriorityGroup) {
      updates.priorityGroup = priorityGroup
    }
    updates.userTimezone = getTimeZone().value
    if (taskRole) {
      updates.taskRole = taskRole
    }
    const response = await get().api!('PATCH', `/task/${id}`, updates)
    return response.data
  },
  deleteScheduledTaskFlow: async (id: number) => {
    await get().api!('DELETE', `/task/schedule/${id}`)
  },
  deleteTaskFlow: async (task: TaskNodeData) => {
    const { id } = task
    const response = await get().api!('DELETE', `/task/${id}`)
    return response
  },
  onNodesChange: (changes: NodeChange[]) => {
    if (changes.find((change) => change.type === 'remove')) {
      return
    }
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    })
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    if (changes.find((change) => change.type === 'remove')) {
      return
    }
    set({
      edges: applyEdgeChanges(changes, get().edges),
    })
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    })
  },
  setLayoutDirection: (direction: LayoutDirection) => {
    set({
      layoutDirection: direction,
    })
  },
  updateNode(nodeId, text) {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, text } }
        }
        return node
      }),
    })
  },
  addNode(node: Node<TaskNodeData>) {
    set({
      nodes: [...get().nodes, node],
    })
  },
  setInitialData: (nodes: Node<TaskNodeData>[], edges: Edge[]) => {
    set({
      nodes: [...nodes],
      edges: [...edges],
    })
  },
  setChildNodeEvent: (event: TaskNodeEvent) => {
    set({
      childNodeEvent: event,
    })
  },
  saveChanges: async () => {
    set({
      lastModifiedTasks: [],
    })
    let modifiedTasks: TaskItem[] = []
    const tasksWithModifiedLevel = get()
      .nodes.filter(isUpdatableNode)
      .filter(hasBeenModifiedLevelOrParent)
      .map((node) => node.data)

    if (tasksWithModifiedLevel.length) {
      const tasks = tasksWithModifiedLevel.map((task) => ({
        id: task.id,
        level: task.level,
        parent: task.parent,
      }))
      await get().updateDescendantsStructure({ tasks })
    }

    const dataChanged = get()
      .nodes.filter(isUpdatableNode)
      .filter(hasBeenModified)
      .map((node) => node.data)
    dataChanged.sort(compareByLevel)
    for (let index = 0; index < dataChanged.length; index++) {
      const updated = await get().updateTaskFlow(dataChanged[index])
      modifiedTasks = modifiedTasks.concat(updated)
    }
    return modifiedTasks
  },
  deleteNewTasks: async () => {
    const newTasks = get()
      .nodes.filter(isUpdatableNode)
      .filter((node) => node.data.isNew === true)
      .map((node) => node.data)

    for (let index = 0; index < newTasks.length; index++) {
      await get().deleteTaskFlow(newTasks[index])
    }
    return newTasks
  },
  updateDescendantsStructure: async (data) => {
    try {
      await get().api!('PATCH', `/task/descendants/new-structure`, data)
    } catch (error) {
      throw error
    }
  },
})
