import dagre from 'dagre'
import { Edge, Node, Position, getConnectedEdges, getOutgoers } from 'reactflow'
import { TaskMode } from 'services/Tasks.slice'
import { Task, TaskGroup, TaskItem, TaskLevel, TaskNodeData } from 'types/Tasks'
import {
  getGroupNameWithOwnerIfNeeded,
  isRoutineInstanceSchedule,
} from 'utils/taskUtils'

export enum LayoutDirection {
  TOP_TO_BOTTOM = 'TB',
  LEFT_TO_RIGHT = 'LR',
}

const taskNode = 'taskNode'
const groupNode = 'groupNode'
const editNode = 'taskEditNode'
export const defaultNode = 'default'

export const nodeWidth = 300
export const nodeHeight = 240
export const MAX_LEVEL = 8
const nodeWidthSeparation = 50
const nodeHeightSeparation = 50
const horizontalSpacePerLevel = nodeWidth + nodeWidthSeparation
const verticalSpacePerLevel = nodeHeight + nodeHeightSeparation

const checkTarget = (edge: Edge[], id: string) => {
  const edges = edge.filter((ed: Edge) => {
    return ed.target !== id
  })
  return edges
}

export const getTargetNode = (
  nodes: Node[],
  centerX: number,
  centerY: number,
  taskDropped?: Task,
) => {
  const nWidth = 250
  const nHeight = 80

  return nodes.find(
    (n) =>
      centerX > n.position.x &&
      centerX < n.position.x + nWidth &&
      centerY > n.position.y &&
      centerY < n.position.y + nHeight &&
      n.id !== taskDropped?.id,
  )
}

const parseTaskToNodeData = (task: Task | TaskItem) => {
  const taskRole = 'taskRole' in task ? task.taskRole : undefined
  return {
    id: task.id,
    parent: task.parent?.id || task.parent,
    originalParent: task.parent?.id || task.parent,
    status: task.status,
    title: task.title,
    originalTitle: task.title,
    level: task.level?.id,
    originalLevel: task.level?.id,
    priorityGroup: task.priorityGroup?.id,
    originalPriorityGroup: task.priorityGroup?.id,
    priority: task.taskDetails?.flag?.id,
    originalPriority: task.taskDetails?.flag?.id,
    dueDate: task.dueAt,
    originalDueDate: task.dueAt,
    mode: task.mode,
    originalMode: task.mode,
    group: task.group,
    taskAssignees: task.taskAssignees,
    scheduledTask: task.scheduledTask,
    originalScheduledTask: task.scheduledTask,
    pendingAssignees: task.pendingAssignees,
    hasChildren: (task.children || []).length > 0,
    roles: task.roles,
    taskRole,
  } as TaskNodeData
}

export const getNodeFromTaskNodeData = (
  taskNodeData: TaskNodeData,
  isSelected: boolean = false,
) => {
  return {
    id: taskNodeData.id,
    type: taskNode,
    data: taskNodeData,
    position: { x: 0, y: 0 },
    selected: isSelected,
  } as Node<TaskNodeData>
}

export const getNodeFromTask = (task: Task | TaskItem) => {
  const taskNodeData = parseTaskToNodeData(task)
  return getNodeFromTaskNodeData(taskNodeData)
}

export const getNodesFromTaskList = (tasks: Task[] | TaskItem[]) => {
  let results: Node<TaskNodeData>[] = []
  for (const task of tasks || []) {
    const child = getNodeFromTask(task)
    results = results.concat(child)
  }
  return results
}

export const getEdge = (sourceId: string, targetId: string) => {
  return {
    id: `${sourceId}-${targetId}`,
    source: sourceId,
    target: targetId,
    type: defaultNode,
    animated: false,
  } as Edge
}

export const collapseExpand = (
  node: Node<TaskNodeData>,
  hidden: boolean,
  nodes: Node<TaskNodeData>[],
  edges: Edge[],
) => {
  const outgoers: Node<TaskNodeData>[] = []
  const connectedEdges: Edge[] = []
  const stack: Node<TaskNodeData>[] = []

  const currentNodeID = node.id
  stack.push(node)
  while (stack.length > 0) {
    const lastNode = stack.pop()!
    const childNode = getOutgoers(lastNode, nodes, edges)
    const childEdge = checkTarget(
      getConnectedEdges([lastNode], edges),
      currentNodeID,
    )

    childNode.forEach((goer) => {
      stack.push(goer)
      outgoers.push(goer)
    })
    childEdge.forEach((edge) => {
      connectedEdges.push(edge)
    })
  }

  const childNodeID = outgoers.map((node) => {
    return node.id
  })
  const childEdgeID = connectedEdges.map((edge) => {
    return edge.id
  })

  return {
    nodes: nodes.map((node) => {
      if (childEdgeID.includes(node.id) || childNodeID.includes(node.id))
        node.hidden = hidden
      if (node.id.length === 1) node.hidden = false
      return node
    }),
    edges: edges.map((edge) => {
      if (childEdgeID.includes(edge.id) || childNodeID.includes(edge.id))
        edge.hidden = hidden
      return edge
    }),
  }
}

export const getInitialNodesAndEdges = (
  initialTask: TaskItem[],
  taskId: string,
  forceToShowIcons: boolean,
) => {
  const initialNodes = convertTaskTreeToTaskNodeArray(
    initialTask,
    taskId,
    forceToShowIcons,
  )

  const initialEdges = initialNodes
    .filter((item) => item.data.parent !== '')
    .map((item) => {
      return getEdge(item.data.parent!, item.id)
    })

  return { initialNodes, initialEdges }
}

function convertTaskTreeToTaskNodeArray(
  tasks: TaskItem[],
  selectedId: string,
  forceToShowIcons: boolean,
): Node<TaskNodeData>[] {
  return tasks.map((task) =>
    getNodeFromTaskNodeData(
      { ...parseTaskToNodeData(task), forceToShowIcons },
      task.id === selectedId,
    ),
  )
}

export const groupByGroups = (tasks: TaskItem[], groups?: TaskGroup[]) => {
  const groupedTasks: { [k: string]: any } = {}
  const sortedTasks = [...tasks].sort((a, b) => a.title.localeCompare(b.title))
  for (const task of sortedTasks) {
    if (task.mode === TaskMode.COMPLETED) {
      continue
    }
    const group = getGroupNameWithOwnerIfNeeded(task, groups)
    if (!groupedTasks[group]) {
      groupedTasks[group] = []
    }
    groupedTasks[group].push(task)
  }
  return groupedTasks
}

export const isTaskOrEditNode = (node: Node) => {
  return node.type === taskNode || node.type === editNode
}

export const isUpdatableNode = (node: Node) => {
  return (
    node.type === taskNode ||
    node.type === editNode ||
    node.type === defaultNode
  )
}

export const hasBeenModified = (node: Node<TaskNodeData>) => {
  return (
    hasBeenModifiedLevelOrParent(node) ||
    hasBeenModifiedWithoutLevelOrParent(node)
  )
}

const isEqualValue = (value1?: number | string, value2?: number | string) => {
  const v1HasAValue = !!value1
  const v2HasAValue = !!value2
  if (!v1HasAValue && !v2HasAValue) {
    return true
  }
  return value1 === value2
}

export const hasBeenModifiedWithoutLevelOrParent = (
  node: Node<TaskNodeData>,
) => {
  const role = node.data.taskRole ? node.data.taskRole : null
  const origRole = node.data.originalTaskRole
    ? node.data.originalTaskRole
    : null
  return (
    node.data.priorityGroup !== node.data.originalPriorityGroup ||
    node.data.mode !== node.data.originalMode ||
    JSON.stringify(node.data.taskDetails) !==
      JSON.stringify(node.data.originalTaskDetails) ||
    JSON.stringify(node.data.scheduledTask) !==
      JSON.stringify(node.data.originalScheduledTask) ||
    !isEqualValue(role?.assignedEmail, origRole?.assignedEmail) ||
    !isEqualValue(role?.assigningRole, origRole?.assigningRole) ||
    !isEqualValue(role?.email, origRole?.email) ||
    !isEqualValue(role?.managingRole, origRole?.managingRole) ||
    node.data.dueAt !== node.data.originalDueAt ||
    node.data.title !== node.data.originalTitle
  )
}

export const hasBeenModifiedLevelOrParent = (node: Node<TaskNodeData>) => {
  return (
    node.data.level !== node.data.originalLevel ||
    node.data.parent !== node.data.originalParent
  )
}

const getMainParent = (
  tasks: Node<TaskNodeData>[],
): Node<TaskNodeData> | undefined => {
  const parentMap = new Map<string, Node<TaskNodeData>>()
  for (const task of tasks) {
    parentMap.set(task.id, task)
  }
  const mainTasks: Node<TaskNodeData>[] = []
  for (const task of tasks) {
    const parentTask = parentMap.get(task.data.parent || '')

    if (!parentTask) {
      mainTasks.push(task)
    }
  }

  return mainTasks?.[0]
}

export const getLayoutedElements = (
  nodes: Node<TaskNodeData>[],
  edges: Edge[],
  taskLevels: TaskLevel[],
  direction = LayoutDirection.TOP_TO_BOTTOM,
  selectedTask: string = '',
  baseLevel: number = 0,
  setLevel: boolean = false,
) => {
  const isHorizontal = direction === LayoutDirection.LEFT_TO_RIGHT
  const dagreGraph = new dagre.graphlib.Graph<TaskNodeData>()
  const levels: { [key: number]: number } = {}
  const centerAt = { x: 0, y: 0 }
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({ rankdir: direction })

  const defaultNodes = nodes.filter((node) => node.type === defaultNode)
  nodes = nodes.filter(isTaskOrEditNode)
  nodes = assignLevels(nodes, setLevel ? baseLevel : -1)

  const mainParent = selectedTask
    ? selectedTask
    : getMainParent(nodes)?.id || selectedTask

  nodes.forEach((node: Node) => {
    if (node.hidden) {
      dagreGraph.setNode(node.id, { width: 0, height: 0 })
    } else {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
    }
  })
  edges.forEach((edge: Edge) => {
    if (edge.hidden === undefined || edge.hidden === false) {
      dagreGraph.setEdge(edge.source, edge.target)
    }
  })
  dagre.layout(dagreGraph, { align: 'DL', ranker: 'tight-tree' })

  nodes.forEach((node: Node<TaskNodeData>) => {
    const nodeWithPosition = dagreGraph.node(node.id)

    node.targetPosition = isHorizontal ? Position.Left : Position.Top
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }
    node.selected = node.id === selectedTask

    if (node.id === selectedTask || node.id === mainParent) {
      centerAt.x = node.position.x + nodeWidth / 2
      centerAt.y = node.position.y + nodeHeight / 2
    }

    if (!node.hidden) {
      if (!levels[node.data.level]) {
        levels[node.data.level] = 0
      } else {
        levels[node.data.level]++
      }
    }
    return node
  })
  return {
    nodes: [
      ...nodes,
      ...defaultNodes,
      ...groupNodes(taskLevels, levels, isHorizontal),
    ],
    edges,
    centerAt,
  }
}

export const filterTasksNotDisplayed = (
  tasks: TaskItem[],
  nodes: Node[],
  showElementsWithAncestors: boolean,
  showElementsWithChildren: boolean,
  filterString: string,
) => {
  const filteredTasks = tasks
    .filter((task) => {
      return !showElementsWithAncestors
        ? (task.parent?.id || '').length === 0
        : true
    })
    .filter((task) => {
      return !showElementsWithChildren
        ? (task.children || []).length === 0
        : true
    })
    .filter((task) => {
      return !nodes.some((node) => {
        return task.id === node.id
      })
    })
    .filter((task) => {
      return !isRoutineInstanceSchedule(task)
    })
  if (!filterString) {
    return filteredTasks
  }
  return filteredTasks.filter((task) =>
    task.title.toLowerCase().includes(filterString.toLowerCase()),
  )
}

export const groupNodes = (
  taskLevels: TaskLevel[],
  levels: { [key: number]: number },
  isHorizontal: boolean,
) => {
  const nodes = Object.keys(levels).map((level, idx) => {
    const id = level
    const taskLevel = taskLevels.find((lv) => lv.id === parseInt(level))
    // TODO: Allow the user to customize this name
    const title = taskLevel ? taskLevel.title : 'Base tasks'
    return {
      id: id,
      type: groupNode,
      draggable: false,
      position: isHorizontal
        ? {
            x: horizontalSpacePerLevel * idx + nodeWidthSeparation,
            y: verticalSpacePerLevel * -1,
          }
        : {
            x: horizontalSpacePerLevel * -1,
            y: verticalSpacePerLevel * idx - 25,
          },
      data: {
        id,
        title,
        level: id,
        isHorizontal,
      },
    } as Node
  })
  return nodes
}

export const getChildrenNodesFromTaskList = (
  task: Task,
  taskList: Task[],
  nodes: Node[],
) => {
  let results: Node<TaskNodeData>[] = []
  const children =
    !nodes.some((nd) => nd.id === task.id) &&
    taskList.find((ts) => ts.id === task.id)?.children
  for (const element of children || []) {
    const childTask =
      !nodes.some((nd) => nd.id === element.id) &&
      taskList.find((ch) => ch.id === element.id)
    if (childTask) {
      const child = getNodeFromTask(childTask)
      const grandChildren = getChildrenNodesFromTaskList(
        childTask,
        taskList,
        nodes,
      )
      results = results.concat(child)
      results = results.concat(grandChildren)
    }
  }
  return results
}

export const isOriginAnAncestor = (
  nodeOrigin: Node<TaskNodeData>,
  nodeTarget: Node<TaskNodeData>,
  nodes: Node<TaskNodeData>[],
): boolean => {
  if (nodeOrigin.id === nodeTarget.id) return false

  if (!nodeTarget.data.parent) return false

  if (nodeTarget.data.parent === nodeOrigin.id) {
    return true
  }

  const targetAncestor = nodes.find((nd) => nd.id === nodeTarget.data.parent)
  if (!targetAncestor) return false

  return isOriginAnAncestor(nodeOrigin, targetAncestor, nodes)
}

const getParentNode = (
  nodeOrigin: Node<TaskNodeData>,
  nodes: Node<TaskNodeData>[],
): Node<TaskNodeData> | undefined => {
  const parent = nodes.find((nd) => nd.id === nodeOrigin.data.parent)
  return parent
}

export const countExistingLevelsUp = (
  nodeOrigin: Node<TaskNodeData>,
  nodes: Node<TaskNodeData>[],
): number => {
  let i = 1
  let parent = getParentNode(nodeOrigin, nodes)

  // I'm using a random number just to prevent an endless loop
  while (parent !== undefined && i < 20) {
    i++
    parent = getParentNode(parent, nodes)
  }

  return i
}

export const countExistingLevelsDown = (
  nodeOrigin: Node<TaskNodeData>,
  nodes: Node<TaskNodeData>[],
) => {
  let generations = 0
  let queue = [nodeOrigin]
  while (queue.length > 0) {
    generations++
    const newQueue = []
    for (const n of queue) {
      const children = nodes.filter((obj) => obj.data.parent === n.id)
      newQueue.push(...children)
    }
    queue = newQueue
  }
  return generations
}

export const getMinMaxLevel = (nodes: Node<TaskNodeData>[]) => {
  let minLevel = Infinity
  let maxLevel = -Infinity
  for (const node of nodes) {
    if (node.data.level < minLevel) {
      minLevel = node.data.level
    }
    if (node.data.level > maxLevel) {
      maxLevel = node.data.level
    }
  }
  return { minLevel, maxLevel }
}

const assignLevels = (
  nodes: Node<TaskNodeData>[],
  newBaseLevel: number = -1,
) => {
  const mainNode = getMainParent(nodes)
  if (!mainNode) {
    return [...nodes]
  }
  const level = newBaseLevel !== -1 ? newBaseLevel + 1 : mainNode.data.level
  let nodesNumberOfChildren: { [k: string]: any } = {}
  let nodesLevel: { [k: string]: any } = {}
  nodesLevel[mainNode.id] = level
  const queue: Node<TaskNodeData, string | undefined>[] = []
  queue.push(mainNode)
  while (queue.length > 0) {
    const currentNode = queue.shift()
    nodesNumberOfChildren[currentNode?.id!] = 0
    nodes.forEach((node) => {
      if (node.data.parent === currentNode?.id) {
        queue.push(node)
        nodesNumberOfChildren[currentNode?.id!]++
        nodesLevel[node.id] = nodesLevel[currentNode?.id!] + 1
      }
      return node
    })
  }

  return nodes.map((node) => {
    // node.data.level = nodesLevel[node.id]
    // node.data.hasChildren = nodesNumberOfChildren[node.id] > 0
    return {
      ...node,
      data: {
        ...node.data,
        level: nodesLevel[node.id],
        hasChildren: nodesNumberOfChildren[node.id] > 0,
      },
    }
  })
}

export const getChildrenNodesFromNodes = (
  node: Node<TaskNodeData>,
  nodes: Node[],
) => {
  const returnNodes: Node<TaskNodeData, string | undefined>[] = []
  const queue: Node<TaskNodeData, string | undefined>[] = []
  queue.push(node)
  while (queue.length > 0) {
    const currentNode = queue.shift()
    returnNodes.push(currentNode!)
    for (const node of nodes) {
      if (node.data.parent === currentNode?.id) {
        queue.push(node)
      }
    }
  }
  return returnNodes
}

export const removeMiddleNode = (
  deleted: Node<TaskNodeData>[],
  nodes: Node<TaskNodeData>[],
  edges: Edge<TaskNodeData>[],
) => {
  if (deleted.length === 0) {
    return { nodes, edges }
  }
  const deletedTask = deleted[0]
  const newDeleted = deleted.map((node) => ({
    ...node,
    hidden: true,
    type: defaultNode,
    data: {
      ...node.data,
      parent: '',
    },
  }))

  const parentTask = deletedTask.data.parent
  const childrenTasks = nodes.filter(
    (node) => node.data.parent === deletedTask.id,
  )

  const remainingNodes = nodes.filter(
    (node) => node.data.parent !== deletedTask.id && node.id !== deletedTask.id,
  )

  const remainingEdges = edges.filter(
    (edge) =>
      edge.source !== null &&
      edge.source !== deletedTask.id &&
      edge.target !== deletedTask.id,
  )

  if (parentTask) {
    const createdEdges = childrenTasks.flatMap(
      (childNode) =>
        ({
          id: `${parentTask}->${childNode.id}`,
          source: parentTask,
          target: childNode.id,
        } as Edge<TaskNodeData>),
    )

    const updatedNodes = childrenTasks.map((node) => ({
      ...node,
      data: {
        ...node.data,
        parent: parentTask,
      },
    }))

    return {
      nodes: [...remainingNodes, ...updatedNodes, ...newDeleted],
      edges: [...remainingEdges, ...createdEdges],
      parentTask,
    }
  } else {
    return {
      nodes: [...remainingNodes, ...newDeleted],
      edges: [...remainingEdges],
      parentTask,
    }
  }
}

export const removeChildrenAndGrandchildren = (
  deleted: Node<TaskNodeData>[],
  nodes: Node<TaskNodeData>[],
  edges: Edge<TaskNodeData>[],
) => {
  if (deleted.length === 0) {
    return { nodes, edges }
  }
  const deletedTask = deleted[0]
  const newDeleted = deleted.map((node) => ({
    ...node,
    hidden: true,
    type: defaultNode,
    data: {
      ...node.data,
      parent: '',
    },
  }))

  const childrenTasks = getChildrenNodesFromNodes(deletedTask, nodes)
  const childrenTaskIds = childrenTasks.flatMap((node) => node.id)
  const parentTask = deletedTask.data.parent

  const remainingNodes = nodes.filter(
    (node) => !childrenTaskIds.includes(node.id),
  )

  const remainingEdges = edges.filter(
    (edge) =>
      edge.source !== null &&
      !childrenTaskIds.includes(edge.source) &&
      !childrenTaskIds.includes(edge.target),
  )

  return {
    nodes: [...remainingNodes, ...newDeleted],
    edges: [...remainingEdges],
    parentTask,
  }
}

export const mostFrequentString = (array: string[]): string => {
  let frequency: { [key: string]: number } = {}
  let maxString: string = ''
  let maxCount: number = 0
  for (let str of array) {
    if (frequency[str]) {
      frequency[str]++
    } else {
      frequency[str] = 1
    }
    if (frequency[str] > maxCount) {
      maxString = str
      maxCount = frequency[str]
    }
  }
  return maxString
}

export const updateNodeAndEdge = (
  nodes: Node<TaskNodeData>[],
  edges: Edge[],
  taskItem: Partial<TaskItem>,
  addIfNotExists: boolean,
) => {
  let updatedNodes = [...nodes]
  let updatedEdges = [...edges]
  if (!addIfNotExists && !nodes.find((item) => item.id === taskItem.id)) {
    return { updatedNodes, updatedEdges }
  }

  const editedNode = getNodeFromTask(taskItem as Task)
  updatedNodes = updatedNodes
    .filter((item) => item.id !== editedNode.id)
    .concat(editedNode)

  if (
    taskItem.parent?.id &&
    updatedNodes.find((item) => item.id === taskItem.parent?.id)
  ) {
    const newEdge = {
      id: `${taskItem.parent?.id}->${taskItem.id}`,
      source: taskItem.parent?.id,
      target: taskItem.id,
    } as Edge<TaskNodeData>

    updatedEdges = updatedEdges
      .filter((item) => item.target !== taskItem.id)
      .concat(newEdge)
  }

  return { updatedNodes, updatedEdges }
}
