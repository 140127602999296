import HomeIcon from '@mui/icons-material/HomeOutlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined'
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SellIcon from '@mui/icons-material/Sell';
import MoneyIcon from '@mui/icons-material/Money';
import CelebrationIcon from '@mui/icons-material/Celebration';
import BookIcon from '@mui/icons-material/Book';
import LockClockIcon from '@mui/icons-material/LockClock';
import UpdateIcon from '@mui/icons-material/Update';
import SecurityIcon from '@mui/icons-material/Security';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SailingIcon from '@mui/icons-material/Sailing';
import SportsIcon from '@mui/icons-material/Sports';
import TimerIcon from '@mui/icons-material/Timer';
import BedIcon from '@mui/icons-material/Bed';
import SpaIcon from '@mui/icons-material/Spa';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import TapasIcon from '@mui/icons-material/Tapas';
import SleddingIcon from '@mui/icons-material/Sledding';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import CameraIcon from '@mui/icons-material/Camera';
import TsunamiIcon from '@mui/icons-material/Tsunami';
import WaterIcon from '@mui/icons-material/Water';
import PoolIcon from '@mui/icons-material/Pool';
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import LandscapeIcon from '@mui/icons-material/Landscape';
import AdjustIcon from '@mui/icons-material/Adjust';
import PortraitIcon from '@mui/icons-material/Portrait';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import TelegramIcon from '@mui/icons-material/Telegram';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import MicIcon from '@mui/icons-material/Mic';
import PhoneIcon from '@mui/icons-material/Phone';
import DevicesIcon from '@mui/icons-material/Devices';
import DialpadIcon from '@mui/icons-material/Dialpad';
import HardwareIcon from '@mui/icons-material/Hardware';
import ForwardIcon from '@mui/icons-material/Forward';
import FactoryIcon from '@mui/icons-material/Factory';
import StarIcon from '@mui/icons-material/Star';
import StarsIcon from '@mui/icons-material/Stars';
import StartIcon from '@mui/icons-material/Start';
import PasswordIcon from '@mui/icons-material/Password';
import VerifiedIcon from '@mui/icons-material/Verified';
import MasksIcon from '@mui/icons-material/Masks';
import HealingIcon from '@mui/icons-material/Healing';
import MedicationIcon from '@mui/icons-material/Medication';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import StormIcon from '@mui/icons-material/Storm';
import CloudIcon from '@mui/icons-material/Cloud';
import FunctionsIcon from '@mui/icons-material/Functions';
import CalculateIcon from '@mui/icons-material/Calculate';
import PercentIcon from '@mui/icons-material/Percent';
import MoodIcon from '@mui/icons-material/Mood';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import InterestsIcon from '@mui/icons-material/Interests';
import EjectIcon from '@mui/icons-material/Eject';
import DetailsIcon from '@mui/icons-material/Details';
import { Row, Tag } from 'antd'
import { SvgIcon, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

const iconStyle = { height: '16px', width: '16px', marginRight: '4px' }
export const iconsTaskGroup: {[key: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">>} = {
  home: HomeIcon,
  public: PublicOutlinedIcon,
  cottage: CottageOutlinedIcon,
  family: FamilyRestroomOutlinedIcon,
  work: WorkOutlineOutlinedIcon,
  apartment: ApartmentOutlinedIcon,
  person: PersonOutlineOutlinedIcon,
  diversity: Diversity3OutlinedIcon,
  group: GroupOutlinedIcon,
  store: StoreOutlinedIcon,
  homework: HomeWorkOutlinedIcon,
  boy: FaceOutlinedIcon,
  girl: Face3OutlinedIcon,
  sell: SellIcon,
  money: MoneyIcon,
  celebration: CelebrationIcon,
  book: BookIcon,
  lockClock: LockClockIcon,
  update: UpdateIcon,
  security: SecurityIcon,
  fastfood: FastfoodIcon,
  sailing: SailingIcon,
  sports: SportsIcon,
  timer: TimerIcon,
  bed: BedIcon,
  spa: SpaIcon,
  restaurant: RestaurantIcon,
  tapas: TapasIcon,
  sledding: SleddingIcon,
  fireplace: FireplaceIcon,
  whatshot: WhatshotIcon,
  screenshot: ScreenshotIcon,
  camera: CameraIcon,
  tsunami: TsunamiIcon,
  water: WaterIcon,
  pool: PoolIcon,
  umbrella: UmbrellaIcon,
  landscape: LandscapeIcon,
  adjust: AdjustIcon,
  portrait: PortraitIcon,
  voicemail: VoicemailIcon,
  telegram: TelegramIcon,
  receipt: ReceiptIcon,
  description: DescriptionIcon,
  mic: MicIcon,
  phone: PhoneIcon,
  devices: DevicesIcon,
  dialpad: DialpadIcon,
  hardware: HardwareIcon,
  forward: ForwardIcon,
  factory: FactoryIcon,
  star: StarIcon,
  stars: StarsIcon,
  start: StartIcon,
  password: PasswordIcon,
  verified: VerifiedIcon,
  masks: MasksIcon,
  healing: HealingIcon,
  medication: MedicationIcon,
  thermostat: ThermostatIcon,
  storm: StormIcon,
  cloud: CloudIcon,
  functions: FunctionsIcon,
  calculate: CalculateIcon,
  percent: PercentIcon,
  mood: MoodIcon,
  circle: CircleIcon,
  square: SquareIcon,
  widgets: WidgetsIcon,
  dashboard: DashboardIcon,
  category: CategoryIcon,
  interests: InterestsIcon,
  eject: EjectIcon,
  details: DetailsIcon,
}

export const getIconByGroup = (taskGroup?: string, iconClassName?: string) => {
  const conditionalIconStyle = iconClassName ? {} : iconStyle
  if (!taskGroup) return (
    <SvgIcon
      component={iconsTaskGroup['home']}
      style={conditionalIconStyle}
      className={iconClassName}
    />
  )
  const icon =
    iconsTaskGroup[taskGroup as keyof typeof iconsTaskGroup] ||
    iconsTaskGroup[
      Object.keys(iconsTaskGroup)[0] as keyof typeof iconsTaskGroup
    ]
  return (
    <SvgIcon
      component={icon}
      style={conditionalIconStyle}
      className={iconClassName}
    />
  )
}

type CheckboxIconTagProps = {
  id: number
  label: string
  icon?: string
  color?: string
  outlined?: boolean
  checked: boolean
  onChange?: (id: number) => void
}

function CheckboxIconTag({
  id,
  label,
  icon,
  color,
  outlined,
  checked,
  onChange,
}: CheckboxIconTagProps) {
  const checkableTagstyle = {
    borderRadius: '16px',
    margin: '4px',
  }
  const checkedStyle = checked
    ? { backgroundColor: color ?? 'var(--completed-color)' }
    : { backgroundColor: 'var(--not-started-color)' }
  const outlinedStyle = {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#fff',
    outlineStyle: 'solid',
    outlineWidth: '2px',
    outlineColor: color,
  }

  return (
    <Tag.CheckableTag
      checked={checked}
      onClick={() => onChange?.(id)}
      style={{
        ...checkableTagstyle,
        ...checkedStyle,
        ...(outlined && outlinedStyle),
      }}
    >
      <Row align="middle">
        {getIconByGroup(icon)}
        {label}
        {checked ? (
          <CloseOutlinedIcon
            style={{ height: '12px', width: '12px', marginLeft: '4px' }}
          />
        ) : null}
      </Row>
    </Tag.CheckableTag>
  )
}

export default CheckboxIconTag
