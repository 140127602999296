// https://github.com/facebook/lexical/blob/main/packages/lexical-list/src/formatList.ts
import {
  $createListItemNode,
  $createListNode,
  $isListItemNode,
  $isListNode,
  ListItemNode,
  ListNode,
  ListType,
} from '@lexical/list'
import { ElementNode, LexicalNode, NodeKey } from 'lexical'

export function $isSelectingEmptyListItem(
  anchorNode: ListItemNode | LexicalNode,
  nodes: Array<LexicalNode>,
): boolean {
  return (
    $isListItemNode(anchorNode) &&
    (nodes.length === 0 ||
      (nodes.length === 1 &&
        anchorNode.is(nodes[0]) &&
        anchorNode.getChildrenSize() === 0))
  )
}

export function append(node: ElementNode, nodesToAppend: Array<LexicalNode>) {
  node.splice(node.getChildrenSize(), 0, nodesToAppend)
}

export function createListOrMerge(
  node: ElementNode,
  listType: ListType,
  indent?: number,
): ListNode {
  if ($isListNode(node)) {
    return node
  }

  const previousSibling = node.getPreviousSibling()
  const nextSibling = node.getNextSibling()
  const listItem = $createListItemNode()
  listItem.setFormat(node.getFormatType())
  listItem.setIndent(node.getIndent())
  append(listItem, node.getChildren())

  if (
    $isListNode(previousSibling) &&
    listType === previousSibling.getListType()
  ) {
    previousSibling.append(listItem)
    node.remove()
    // if the same type of list is on both sides, merge them.

    if ($isListNode(nextSibling) && listType === nextSibling.getListType()) {
      append(previousSibling, nextSibling.getChildren())
      nextSibling.remove()
    }
    if (indent === 1) {
      $handleIndent(listItem)
    }
    if (indent === 2) {
      $handleIndent(listItem)
      $handleIndent(listItem)
    }
    return previousSibling
  } else if (
    $isListNode(nextSibling) &&
    listType === nextSibling.getListType()
  ) {
    nextSibling.getFirstChildOrThrow().insertBefore(listItem)
    node.remove()
    return nextSibling
  } else {
    const list = $createListNode(listType)
    list.append(listItem)
    node.replace(list)
    return list
  }
}

/**
 * Adds an empty ListNode/ListItemNode chain at listItemNode, so as to
 * create an indent effect. Won't indent ListItemNodes that have a ListNode as
 * a child, but does merge sibling ListItemNodes if one has a nested ListNode.
 * @param listItemNode - The ListItemNode to be indented.
 */
export function $handleIndent(listItemNode: ListItemNode): void {
  // go through each node and decide where to move it.
  const removed = new Set<NodeKey>()

  if (isNestedListNode(listItemNode) || removed.has(listItemNode.getKey())) {
    return
  }

  const parent = listItemNode.getParent()

  // We can cast both of the below `isNestedListNode` only returns a boolean type instead of a user-defined type guards
  const nextSibling =
    listItemNode.getNextSibling<ListItemNode>() as ListItemNode
  const previousSibling =
    listItemNode.getPreviousSibling<ListItemNode>() as ListItemNode
  // if there are nested lists on either side, merge them all together.

  if (isNestedListNode(nextSibling) && isNestedListNode(previousSibling)) {
    const innerList = previousSibling.getFirstChild()

    if ($isListNode(innerList)) {
      innerList.append(listItemNode)
      const nextInnerList = nextSibling.getFirstChild()

      if ($isListNode(nextInnerList)) {
        const children = nextInnerList.getChildren()
        append(innerList, children)
        nextSibling.remove()
        removed.add(nextSibling.getKey())
      }
    }
  } else if (isNestedListNode(nextSibling)) {
    // if the ListItemNode is next to a nested ListNode, merge them
    const innerList = nextSibling.getFirstChild()

    if ($isListNode(innerList)) {
      const firstChild = innerList.getFirstChild()

      if (firstChild !== null) {
        firstChild.insertBefore(listItemNode)
      }
    }
  } else if (isNestedListNode(previousSibling)) {
    const innerList = previousSibling.getFirstChild()

    if ($isListNode(innerList)) {
      innerList.append(listItemNode)
    }
  } else {
    // otherwise, we need to create a new nested ListNode

    if ($isListNode(parent)) {
      const newListItem = $createListItemNode()
      const newList = $createListNode(parent.getListType())
      newListItem.append(newList)
      newList.append(listItemNode)

      if (previousSibling) {
        previousSibling.insertAfter(newListItem)
      } else if (nextSibling) {
        nextSibling.insertBefore(newListItem)
      } else {
        parent.append(newListItem)
      }
    }
  }
}

/**
 * Checks to see if the passed node is a ListItemNode and has a ListNode as a child.
 * @param node - The node to be checked.
 * @returns true if the node is a ListItemNode and has a ListNode child, false otherwise.
 */
export function isNestedListNode(
  node: LexicalNode | null | undefined,
): boolean {
  return $isListItemNode(node) && $isListNode(node.getFirstChild())
}

/**
 * Removes an indent by removing an empty ListNode/ListItemNode chain. An indented ListItemNode
 * has a great grandparent node of type ListNode, which is where the ListItemNode will reside
 * within as a child.
 * @param listItemNode - The ListItemNode to remove the indent (outdent).
 */
export function $handleOutdent(listItemNode: ListItemNode): void {
  // go through each node and decide where to move it.

  if (isNestedListNode(listItemNode)) {
    return
  }
  const parentList = listItemNode.getParent()
  const grandparentListItem = parentList ? parentList.getParent() : undefined
  const greatGrandparentList = grandparentListItem
    ? grandparentListItem.getParent()
    : undefined
  // If it doesn't have these ancestors, it's not indented.

  if (
    $isListNode(greatGrandparentList) &&
    $isListItemNode(grandparentListItem) &&
    $isListNode(parentList)
  ) {
    // if it's the first child in it's parent list, insert it into the
    // great grandparent list before the grandparent
    const firstChild = parentList ? parentList.getFirstChild() : undefined
    const lastChild = parentList ? parentList.getLastChild() : undefined

    if (listItemNode.is(firstChild)) {
      grandparentListItem.insertBefore(listItemNode)

      if (parentList.isEmpty()) {
        grandparentListItem.remove()
      }
      // if it's the last child in it's parent list, insert it into the
      // great grandparent list after the grandparent.
    } else if (listItemNode.is(lastChild)) {
      grandparentListItem.insertAfter(listItemNode)

      if (parentList.isEmpty()) {
        grandparentListItem.remove()
      }
    } else {
      // otherwise, we need to split the siblings into two new nested lists
      const listType = parentList.getListType()
      const previousSiblingsListItem = $createListItemNode()
      const previousSiblingsList = $createListNode(listType)
      previousSiblingsListItem.append(previousSiblingsList)
      listItemNode
        .getPreviousSiblings()
        .forEach((sibling) => previousSiblingsList.append(sibling))
      const nextSiblingsListItem = $createListItemNode()
      const nextSiblingsList = $createListNode(listType)
      nextSiblingsListItem.append(nextSiblingsList)
      append(nextSiblingsList, listItemNode.getNextSiblings())
      // put the sibling nested lists on either side of the grandparent list item in the great grandparent.
      grandparentListItem.insertBefore(previousSiblingsListItem)
      grandparentListItem.insertAfter(nextSiblingsListItem)
      // replace the grandparent list item (now between the siblings) with the outdented list item.
      grandparentListItem.replace(listItemNode)
    }
  }
}
