import { CloseOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

type CheckboxTagProps = {
  label: string
  checked: boolean
  onChange?: (nextState: boolean) => void
}

function CheckboxTag({ label, checked, onChange }: CheckboxTagProps) {
  const checkableTagstyle = {
    borderRadius: '16px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
  }
  const checkedStyle = checked
    ? { backgroundColor: 'var(--completed-color)' }
    : { backgroundColor: 'var(--not-started-color)' }

  return (
    <Tag.CheckableTag
      checked={checked}
      onClick={() => onChange?.(!checked)}
      style={{ ...checkableTagstyle, ...checkedStyle }}
    >
      {label} {checked ? <CloseOutlined /> : null}
    </Tag.CheckableTag>
  )
}

export default CheckboxTag
