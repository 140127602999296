import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
} from 'antd'
import useStore from 'hooks/useStore'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type AddInviteModalProps = {
  isOpen: boolean
  onCancel: () => void
  closeModal: () => void
  taskId?: string
}

enum ValidateStatus {
  DEFAULT = '',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  VALIDATING = 'validating',
}

enum Role {
  owner = 1,
  admin = 2,
  member = 3,
}

const AddInviteModal = ({
  isOpen,
  onCancel,
  taskId,
  closeModal,
}: AddInviteModalProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [status, setStatus] = useState<ValidateStatus>()
  const [role, setRole] = useState<Role>(3)
  const addCollaboratorInvite = useStore((state) => state.addCollaboratorInvite)
  const { selectedEmails, updateSelectedEmails } = useTaskEditorStore(
    (state) => state,
  )
  const validateMessages = {
    required: t('email.required', { ns: 'validation' }),
    types: {
      email: t('email.invalid', { ns: 'validation' }),
    },
  }

  const onFinish = ({ email }: { email: string }) => {
    setStatus(ValidateStatus.VALIDATING)
    addCollaboratorInvite(email.toLowerCase(), { id: '' }, '')
    const newEmailList = selectedEmails.filter((value) => value !== email)
    updateSelectedEmails([...newEmailList, email.toLowerCase()])
    closeModal()
  }

  const onFinishFailed = () => {
    setStatus(ValidateStatus.ERROR)
  }

  const onRoleChange = (e: RadioChangeEvent) => {
    setRole(e.target.value)
  }

  return (
    <Modal
      zIndex={1010}
      title={t('invite-modal.invite-other-user')}
      open={isOpen}
      onCancel={onCancel}
      centered
      width={400}
      footer={null}
      afterClose={() => {
        form.resetFields()
        setStatus(ValidateStatus.DEFAULT)
      }}
    >
      <Form
        form={form}
        name="inviteForm"
        layout="vertical"
        style={{ maxWidth: 'calc(100% - 16px)' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        validateMessages={validateMessages}
      >
        <Form.Item
          label={t('invite-modal.email-label')}
          name="email"
          hasFeedback
          validateStatus={status}
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input
            placeholder={t('invite-modal.email-input-placeholder') || ''}
            style={{ textTransform: 'lowercase' }}
          />
        </Form.Item>
        <Form.Item label={t('invite-modal.role-label')} hidden>
          <Radio.Group onChange={onRoleChange} value={role}>
            <Radio value={1}>Owner</Radio>
            <Radio value={2}>Admin</Radio>
            <Radio value={3}>Member</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item style={{ alignItems: 'flex-end', paddingTop: 16 }}>
          <Space align="baseline">
            <Button onClick={onCancel}>{t('invite-modal.cancel-text')}</Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: 'var(--completed-color)' }}
            >
              {t('invite-modal.create-invitation')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddInviteModal
