import React from 'react'
import { setAutoFreeze, enablePatches } from 'immer'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './utils/reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'utils/i18n'
import './styles/main.scss'
import 'antd/dist/reset.css'

setAutoFreeze(false)
enablePatches()

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
