import Fuse from 'fuse.js'
import { MenuItem, MenuItemChild } from 'types/Tasks'

const options = {
  shouldSort: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
}

export const searchContacts = (
  query: string,
  list: MenuItemChild[],
  keys: string[],
) => {
  const fuse = new Fuse(list, { ...options, keys })
  return fuse.search(query)
}

export const getKeysByEmail = (email: string, menuItems: MenuItem[]) => {
  const keys: string[] = []
  menuItems.forEach((menuItem) => {
    menuItem.children
      .filter((child) => child.email === email)
      .forEach((child) => {
        keys.push(child.key as string)
      })
  })
  return keys
}

export const filterUniqueEmailsMenuItemChild = (
  assigneeList: MenuItemChild[],
): MenuItemChild[] => {
  const cleanedList: { [key: string]: MenuItemChild } = {}
  for (const record of assigneeList) {
    const { email, name, image } = record
    if (email) {
      if (!cleanedList[email]) {
        cleanedList[email] = record
      } else {
        if (!cleanedList[email].image && image) {
          cleanedList[email] = record
        } else if (!cleanedList[email].name && name) {
          cleanedList[email] = record
        }
      }
    }
  }
  return Object.values(cleanedList)
}
