import React, { useState } from 'react'

import { Button, Checkbox, Col, Divider, Popover, Row } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { useTranslation } from 'react-i18next'
import style from './CheckboxMenu.module.scss'

type OptionType = {
  id: number
  title: string
}

type CommonType = {
  options: OptionType[] | string[] | number[]
  value?: CheckboxValueType[]
  onChange: (checkedValue: CheckboxValueType[]) => void
}

type CheckboxType = CommonType & {
  onClose: () => void
  onSave: () => void
}

export type CheckboxMenuProps = CommonType & {
  label?: string
  isOpen?: boolean
  icon?: React.JSX.Element
  outlined?: boolean
}

const CheckboxRender = ({
  value,
  options,
  onChange,
  onClose,
  onSave,
}: CheckboxType) => {
  const { t } = useTranslation()
  return (
    <Checkbox.Group onChange={onChange} value={value} style={{ width: '100%' }}>
      <Row>
        <Col span={24} className={style.checkboxContainer}>
          {options.map((option, i) => (
            <React.Fragment
              key={typeof option === 'object' ? option.id : option}
            >
              <Checkbox
                key={typeof option === 'object' ? option.id : option}
                value={typeof option === 'object' ? option.id : option}
                style={{ display: 'block', margin: '0', whiteSpace: 'nowrap' }}
              >
                {typeof option === 'object' ? option.title : option}
              </Checkbox>
              {(typeof option === 'object' ? option.id : option) === 0 && (
                <Divider
                  dashed
                  style={{
                    marginTop: '8px',
                    marginBottom: '8px',
                    borderColor: 'var(--text-color)',
                  }}
                />
              )}
            </React.Fragment>
          ))}
          <div className={style.buttonContainer}>
            <Button size="small" onClick={onClose}>
              {t('actions.cancel')}
            </Button>{' '}
            <Button size="small" type="primary" onClick={onSave}>
              {t('actions.done')}
            </Button>
          </div>
        </Col>
      </Row>
    </Checkbox.Group>
  )
}

const CheckboxMenu = ({
  icon,
  label,
  isOpen,
  options,
  onChange,
  outlined,
  value,
  ...props
}: CheckboxMenuProps) => {
  const [clicked, setClicked] = useState(false)
  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>(
    value || [],
  )

  const hide = () => {
    setClicked(false)
  }

  const onLocalChange = (selection: CheckboxValueType[]) => {
    setSelectedItems([...selection])
  }

  const handleClickChange = (open: boolean) => {
    if (open) {
      setSelectedItems(value || [])
    } else {
      setSelectedItems([])
    }
    setClicked(open)
  }

  const onSave = () => {
    onChange(selectedItems)
    hide()
  }

  return (
    <Popover
      content={
        <CheckboxRender
          options={options}
          value={selectedItems}
          onChange={onLocalChange}
          onClose={hide}
          onSave={onSave}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={clicked}
      onOpenChange={handleClickChange}
    >
      <Button
        type={outlined ? 'default' : 'text'}
        icon={icon}
        className={style.buttonLabel}
        {...props}
      >
        {label}
      </Button>
    </Popover>
  )
}

export default CheckboxMenu
