import useStore from 'hooks/useStore'
import type { FCProps } from 'types/FC'
import { Navigate } from 'react-router-dom'
import { Userpilot } from 'userpilot'

export enum RouteProtectionRule {
  AUTHENTICATED,
  NOT_AUTHENTICATED,
}

type ProtectedRouteProps = FCProps & {
  rule: RouteProtectionRule
  redirectURI: string
}

// Initialize Userpilot
Userpilot.initialize(process.env.REACT_APP_USER_PILOT_APP_TOKEN || '')

const ProtectedRoute = ({
  rule,
  redirectURI,
  children,
}: ProtectedRouteProps) => {
  const isAuthenticated = useStore((state) => !!state.user)

  if (rule === RouteProtectionRule.AUTHENTICATED && isAuthenticated) {
    return children as JSX.Element
  }

  if (rule === RouteProtectionRule.NOT_AUTHENTICATED && !isAuthenticated) {
    return children as JSX.Element
  }

  return <Navigate replace to={redirectURI} />
}

export default ProtectedRoute
