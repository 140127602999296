import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import { Button, Dropdown, MenuProps, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { TemplateTypes } from 'types/Templates'
import { EditingElementType, ElementEnum } from '../TemplatesUtils'

type AddButtonsProps = {
  parentKey: string
  onNewElement: Function
  onDelete?: Function
}

const AddButtons = ({ parentKey, onNewElement, onDelete }: AddButtonsProps) => {
  const { t } = useTranslation()
  const items: MenuProps['items'] = [
    {
      label: t('templates.type.task'),
      key: TemplateTypes.TASK,
    },
    {
      label: t('templates.type.custom-field'),
      key: TemplateTypes.CUSTOM_FIELD,
    },
  ]

  return (
    <Space>
      <Dropdown
        menu={{
          items,
          onClick: (event) => {
            onNewElement({
              type: ElementEnum.NEW_TEMPLATE,
              templateType: event.key,
              key: parentKey,
            } as EditingElementType)
          },
        }}
        trigger={['hover']}
      >
        <Button icon={<NoteAddOutlinedIcon />} type="text" size="small" />
      </Dropdown>
      <Button
        icon={<CreateNewFolderOutlinedIcon />}
        type="text"
        size="small"
        onClick={() =>
          onNewElement({
            type: ElementEnum.NEW_FOLDER,
            key: parentKey,
          } as EditingElementType)
        }
      />
      {onDelete && (
        <Button
          icon={<DeleteOutlinedIcon />}
          type="text"
          size="small"
          style={{ color: 'var(--warning-color)' }}
          onClick={() =>
            onDelete({
              type: ElementEnum.DELETE_FOLDER,
              key: parentKey,
            } as EditingElementType)
          }
        />
      )}
    </Space>
  )
}

export default AddButtons
