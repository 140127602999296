import clsx from 'clsx'
import { useState } from 'react'
import { Node } from 'reactflow'
import styles from './TaskGroupNode.module.css'
import { getLevelForColor } from '../FlowUtils'

export type TaskGroupData = {
  id: string
  parent?: string
  title: string
  level: number
  isHorizontal: boolean
}

function TaskGroupNode(groupNode: Node<TaskGroupData>) {
  const [active, setActive] = useState(false)
  const direction = groupNode.data.isHorizontal ? 'horizontal' : 'vertical'

  const makeGroupActive = () => {
    setActive(true)
  }

  const makeGroupInactive = () => {
    setActive(false)
  }

  const levelForColor = getLevelForColor(groupNode.data.level)

  const cardNodeClassName = clsx(
    styles.taskGroupNode,
    styles['level' + levelForColor],
    styles[direction + 'Style'],
    active ? styles[direction + 'MouseOver'] : styles[direction + 'MouseOut'],
  )

  return (
    <div
      onMouseOver={makeGroupActive}
      onMouseOut={makeGroupInactive}
      className={cardNodeClassName}
    >
      <div style={{ backgroundColor: 'transparent' }}>
        {active && groupNode.data.title}
      </div>
    </div>
  )
}

export default TaskGroupNode
