import { App, Drawer, Space, Input } from 'antd'
import { SearchProps } from 'antd/es/input'
import CheckboxTag from 'components/CheckboxTag'
import LoadingProgress from 'components/LoadingProgress'
import NoTaskPlaceholder from 'components/NoTaskPlaceholder'
import useStore from 'hooks/useStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Node } from 'reactflow'
import 'reactflow/dist/style.css'
import { TaskMode } from 'services/Tasks.slice'
import { Task, TaskItem, TaskNodeData, TaskPriorityGroup } from 'types/Tasks'
import { filterTasksNotDisplayed, groupByGroups } from '../TaskFlowHelper'
import CategoryContainer from './CategoryContainer'
import styles from './SidePanel.module.css'

const { Search } = Input

type SidePanelProps = {
  isSidePanelOpen: boolean
  closeSidePanel: () => void
  isTaskFlowViewerVisible?: boolean
  nodes?: Node<TaskNodeData>[]
  // For compatibility
  existingTasks?: Task[]
  loading?: boolean
  showElementsWithAncestors?: boolean
  setShowElementsWithAncestors?: (value: boolean) => void
  showElementsWithChildren?: boolean
  setShowElementsWithChildren?: (value: boolean) => void
}

const SidePanel = ({
  isSidePanelOpen,
  closeSidePanel,
  isTaskFlowViewerVisible,
  nodes,
}: SidePanelProps) => {
  const app = App.useApp()
  const { t } = useTranslation()
  const { getAllTasks, selectedGroups, includeTasksAssignedToMe } = useStore(
    (state) => state,
  )
  const { groups } = useStore((state) => state.tasks)
  const [groupedTasks, setGroupedTasks] = useState<{ [k: string]: any }>({})
  const [existingTasks, setExistingTasks] = useState<Array<TaskItem>>([])
  const [loadingLeftPanelTasks, setLoadingLeftPanelTasks] = useState(false)
  const [showWithAncestors, setShowWithAncestors] = useState(false)
  const [showWithChildren, setShowWithChildren] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const onChangeTasksWithAncestors = (value: boolean) => {
    setShowWithAncestors(value)
  }

  const onChangeTasksWithChildren = (value: boolean) => {
    setShowWithChildren(value)
  }

  const onSearch: SearchProps['onSearch'] = (value, _, info) => {
    setSearchValue(value)
  }

  useEffect(() => {
    if (isSidePanelOpen) {
      const tasksToShow = filterTasksNotDisplayed(
        existingTasks,
        nodes || [],
        showWithAncestors,
        showWithChildren,
        searchValue,
      )
      setGroupedTasks(groupByGroups(tasksToShow, groups))
    }
  }, [
    isSidePanelOpen,
    existingTasks,
    nodes,
    showWithAncestors,
    showWithChildren,
    searchValue,
    groups,
  ])

  useEffect(() => {
    if (isTaskFlowViewerVisible) {
      if (isSidePanelOpen && existingTasks.length === 0) {
        setLoadingLeftPanelTasks(true)
        getAllTasks({
          groups: selectedGroups,
          modes: [TaskMode.ACTIVE, TaskMode.DRAFT],
          priorityGroups: [
            TaskPriorityGroup.Now,
            TaskPriorityGroup.Next,
            TaskPriorityGroup.Later,
            TaskPriorityGroup.Future,
            TaskPriorityGroup.Scheduled,
          ],
          delegatedToMe: includeTasksAssignedToMe,
        })
          .then((data) => {
            setExistingTasks(data as TaskItem[])
          })
          .catch((error) => {
            setExistingTasks([])
            const message =
              error.response.data.fallback_message || error.message
            app.notification.error({
              message,
            })
          })
          .finally(() => {
            setLoadingLeftPanelTasks(false)
          })
      }
    } else {
      setExistingTasks([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTaskFlowViewerVisible,
    isSidePanelOpen,
    selectedGroups,
    includeTasksAssignedToMe,
  ])

  return (
    <Drawer
      title={t('task-node.add-existing-task')}
      placement="left"
      closable={true}
      mask={false}
      onClose={closeSidePanel}
      destroyOnClose={true}
      open={isSidePanelOpen}
    >
      <div className={styles.siderContent}>
        <Search
          placeholder="Search"
          style={{ width: '100%', marginBottom: '8px' }}
          onSearch={onSearch}
          enterButton
          allowClear
        />
        <Space size={[0, 8]} wrap style={{ marginBottom: '8px' }}>
          <CheckboxTag
            label={t('task-plan-viewer.show-tasks-with-ancestors')}
            checked={showWithAncestors}
            onChange={onChangeTasksWithAncestors}
          />

          <CheckboxTag
            label={t('task-plan-viewer.show-tasks-with-children')}
            checked={showWithChildren}
            onChange={onChangeTasksWithChildren}
          />
        </Space>

        {!loadingLeftPanelTasks && Object.keys(groupedTasks).length > 0 && (
          <CategoryContainer groupedTasks={groupedTasks} />
        )}
        {loadingLeftPanelTasks && <LoadingProgress />}
        {!loadingLeftPanelTasks && Object.keys(groupedTasks).length === 0 && (
          <NoTaskPlaceholder />
        )}
      </div>
    </Drawer>
  )
}

export default SidePanel
