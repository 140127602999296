import { CloseCircleOutlined, SendOutlined } from '@ant-design/icons'
import Switch from '@mui/material/Switch'
import type { InputRef } from 'antd'
import {
  App,
  Button,
  Col,
  Empty,
  Form,
  Input,
  Layout,
  List,
  Menu,
  MenuProps,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { SearchProps } from 'antd/es/input'
import { MenuItemGroupType } from 'antd/es/menu/hooks/useItems'
import EmailListItem from 'components/EmailSelector/EmailListItem'
import { shouldShowResendButton } from 'components/EmailSelector/EmailSelectorHelper'
import { motion } from 'framer-motion'
import useStore from 'hooks/useStore'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import { nanoid } from 'nanoid'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, MenuItemChild, PendingAssignee } from 'types/Tasks'
import { useTaskEditor } from '../useTaskEditor'
import ActionButtons from './ActionButtons'
import styles from './DelegationDrawer.module.scss'
import {
  filterUniqueEmailsMenuItemChild,
  getKeysByEmail,
  searchContacts,
} from './DelegationDrawerHelper'
import SelectTag from './SelectTag'
import FloatingContainer from '../FloatingContainer'

type DelegationDrawerProps = {
  assigneeHasEditorPermissions: boolean
  initialValues: PendingAssignee[]
  isOpen: boolean
  isDocked?: boolean
  onCancel: () => void
  onInvite: () => void
  setAssigneeHasEditorPermissions: Dispatch<SetStateAction<boolean>>
}

const { Text, Title } = Typography
const { Search } = Input
const { Footer } = Layout

type MenuItemChildProps = {
  name: string
  email: string
  avatar?: string
  key: string
  status: string
  groupId: string
  user: string
}

const DelegationDrawer = ({
  assigneeHasEditorPermissions,
  initialValues,
  isOpen,
  isDocked,
  onCancel,
  onInvite,
  setAssigneeHasEditorPermissions,
}: DelegationDrawerProps) => {
  const { t } = useTranslation()
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const user = useStore((state) => state.user?.data)
  const { pendingInvites, allGroups, contacts, resendDelegationInvitation } =
    useStore((state) => state)
  const {
    isFloatButtonVisible,
    isFloatButtonTemporarilyHidden,
    selectedEmails,
    updateSelectedEmails,
  } = useTaskEditorStore((state) => state)
  const { initialValues: taskInitialValues } = useTaskEditor()
  const thumbnailAvatar = useStore((state) => state.thumbnailAvatar)
  const inputRef = useRef<InputRef>(null)
  const [groupMenuItems, setGroupMenuItems] = useState<MenuItem[] | []>([])
  const [contactMenuItems, setContactMenuItems] = useState<MenuItem | null>()
  const [inviteMenuItems, setInviteMenuItems] = useState<MenuItem | null>()
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [groupList, setGroupList] = useState<MenuItemChild[]>([])
  const [inviteList, setInviteList] = useState<MenuItemChild[]>([])
  const [contactList, setContactList] = useState<MenuItemChild[]>([])
  const [searchList, setSearchList] = useState<MenuItemChild[]>([])
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [isSearching, setIsSearching] = useState(false)

  const fullName = `${user?.firstName} ${user?.lastName}`.trim()
  const userEmail = user?.email

  const handleResend = useCallback(
    (email: string) => {
      if (!taskInitialValues?.id) {
        return
      }
      resendDelegationInvitation(email, taskInitialValues.id)
        .then(() => {
          message.success(t('invite.resent'))
        })
        .catch((error) => {
          if (error.response) {
            message.error(error.response.data.fallback_message)
          } else {
            message.error(error.message)
          }
        })
    },
    [message, resendDelegationInvitation, t, taskInitialValues?.id],
  )

  const menuItemChild = useCallback(
    ({
      name,
      email,
      avatar,
      key,
      status,
      groupId,
      user,
    }: MenuItemChildProps) => {
      return {
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: 8,
            }}
          >
            <SelectTag
              label={name}
              secondaryLabel={email}
              image={avatar}
              status={status}
            />
            {shouldShowResendButton(status) && (
              <Space>
                <Tooltip title={t('group.resend')}>
                  <Button
                    icon={<SendOutlined />}
                    disabled={!shouldShowResendButton(status)}
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleResend(email)
                    }}
                  />
                </Tooltip>
              </Space>
            )}
          </div>
        ),
        key: key,
        email: email,
        disabled: false,
        status: status,
        user: user,
        group: groupId,
        name: name.trim(),
        image: avatar,
      }
    },
    [handleResend, t],
  )

  useEffect(() => {
    const privateGroup = allGroups.find(
      (item) => item.isDefault && item.groupMembers?.length,
    )
    const privateMenuItem: MenuItemGroupType[] = []
    if (privateGroup) {
      const {
        name = '',
        email = '',
        status = '',
        user = '',
        avatar = '',
      } = privateGroup.groupMembers![0]
      const key = `${email}-${user}-${nanoid(5)}`
      const privateList = [
        menuItemChild({
          name,
          email,
          avatar,
          key,
          status,
          groupId: privateGroup.id.toString(),
          user,
        }),
      ]
      setGroupList((prev) => [...prev, ...privateList])
      privateMenuItem.push({
        label: privateGroup.title,
        key: `${privateGroup.title.toLowerCase()}-${nanoid(5)}`,
        type: 'group',
        children: privateList,
      })
    }
    const groupMenuItem = allGroups.map((item) => {
      const { groupMembers = [], id } = item
      const childList = groupMembers
        .map((child) => {
          const { name = '', email = '', user = '', avatar = '' } = child
          const key = `${email}-${user}-${nanoid(5)}`
          const selected = initialValues.find((iv) => iv.email === email)
          return menuItemChild({
            name,
            email,
            avatar: avatar || selected?.image,
            key,
            status: selected?.status || '',
            groupId: id.toString(),
            user,
          })
        })
        .filter((item) => {
          return item?.email?.trim() !== userEmail && item?.email?.trim()
        })

      if (childList.length > 0) {
        setGroupList((prev) => [...prev, ...childList])
        return {
          id: item.id.toString(),
          label: item.title,
          key: `${item.title.toLowerCase()}-${nanoid(5)}`,
          children: childList,
        }
      }
      setGroupList([])
      return {}
    })

    const filterEmptyGroups = [...privateMenuItem, ...groupMenuItem].filter(
      (groupMenuItem) => Object.keys(groupMenuItem).length > 0,
    )

    if (filterEmptyGroups && filterEmptyGroups.length > 0) {
      setGroupMenuItems(filterEmptyGroups as MenuItem[])
    }
  }, [allGroups, initialValues, menuItemChild, userEmail])

  useEffect(() => {
    const newContactsList = contacts
      .map((contact) => {
        const { contactEmail = [], firstName = '', lastName = '', id } = contact
        const email = contactEmail?.[0]?.email || ''
        const name = `${firstName || ''} ${lastName || ''}`
        const key = `${email}-${id}`
        const selected = initialValues.find((iv) => iv.email === email)
        return menuItemChild({
          name,
          email,
          avatar: '',
          key,
          status: selected?.status || '',
          groupId: id.toString(),
          user: '',
        })
      })
      .filter((item) => {
        return item.email !== userEmail && item.email
      })
    if (newContactsList.length > 0) {
      setContactList(newContactsList)
      const contactsMenuItem = {
        id: nanoid(5),
        label: t('delegation-drawer.menu-contacts'),
        key: 'contacts',
        children: newContactsList,
      }

      setContactMenuItems(contactsMenuItem)
    } else {
      setContactList([])
      setContactMenuItems(null)
    }
  }, [contacts, initialValues, menuItemChild, t, userEmail])

  useEffect(() => {
    if (pendingInvites && pendingInvites.length > 0) {
      const pendingInvitesList = pendingInvites
        .map((invite) => {
          const { email = '' } = invite
          const key = `${email}-others`
          const selected = initialValues.find((iv) => iv.email === email)
          return menuItemChild({
            name: '',
            email,
            avatar: selected?.image,
            key,
            status: selected?.status || '',
            groupId: '',
            user: '',
          })
        })
        .filter((item) => {
          return item.email !== user?.email
        })

      if (pendingInvitesList.length > 0) {
        setInviteList(pendingInvitesList)
      }

      const pendingInvitesMenuItem = {
        id: nanoid(5),
        label: t('delegation-drawer.menu-others'),
        key: 'others',
        children: pendingInvitesList,
      }

      setInviteMenuItems(pendingInvitesMenuItem)
    } else {
      setInviteList([])
      setInviteMenuItems(null)
    }
  }, [initialValues, menuItemChild, pendingInvites, t, user?.email])

  useEffect(() => {
    let menuItems: MenuItem[] = []

    if (groupMenuItems && groupMenuItems.length > 0) {
      menuItems = [...menuItems, ...groupMenuItems]
    }

    if (contactMenuItems) {
      menuItems = [...menuItems, contactMenuItems]
    }

    if (inviteMenuItems) {
      menuItems = [...menuItems, inviteMenuItems]
    }
    setMenuItems(menuItems)
  }, [contactMenuItems, groupMenuItems, inviteMenuItems, user])

  useEffect(() => {
    const selectedKeys = selectedEmails.flatMap((email) =>
      getKeysByEmail(email, menuItems),
    )
    setSelectedKeys(selectedKeys)
  }, [menuItems, selectedEmails])

  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys)
  }

  const onSelect: MenuProps['onSelect'] = (event) => {
    const email = event.key.split('-')[0]
    const keys = getKeysByEmail(email, menuItems)
    const additionalKeys = keys.filter((key) => key !== event.key)
    setSelectedKeys([...event.selectedKeys, ...additionalKeys])
    updateSelectedEmails([...selectedEmails, email])
  }

  const onDeselect: MenuProps['onSelect'] = (event) => {
    const email = event.key.split('-')[0]
    const keys = getKeysByEmail(email, menuItems)
    const newList = event.selectedKeys.filter((key) => keys.indexOf(key) === -1)
    setSelectedKeys(newList)
    updateSelectedEmails(selectedEmails.filter((value) => value !== email))
  }

  const onSearch: SearchProps['onSearch'] = (value, _, info) => {
    if (info?.source === 'input') {
      const usersList: MenuItemChild[] = [
        ...groupList,
        ...inviteList,
        ...contactList,
      ]
      const result = searchContacts(value, usersList, ['email', 'name'])

      const newResultList = result.map((result) => {
        return result?.item
      })

      const uniqueResultList = filterUniqueEmailsMenuItemChild(newResultList)

      setIsSearching(true)
      if (uniqueResultList.length === 0) {
        setSearchList([{ email: 'no results' }])
      } else {
        setSearchList(uniqueResultList)
      }
    } else {
      setIsSearching(false)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isOpen ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      className={styles.drawer}
    >
      <Space
        wrap
        direction="vertical"
        size="middle"
        style={{ width: 'calc(100% - 16px)', rowGap: '0px' }}
      >
        <Row>
          <Title level={5}>{t('delegation-drawer.task-owner')}</Title>
        </Row>
        <Row>
          <Col>
            <EmailListItem
              email={userEmail || ''}
              image={thumbnailAvatar || ''}
              name={fullName}
              style={{ padding: 0 }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form form={form}>
              <Form.Item name="search">
                <Search
                  ref={inputRef}
                  placeholder="Search"
                  style={{ width: '100%', marginTop: 8 }}
                  onSearch={onSearch}
                  enterButton
                  allowClear
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {isSearching && searchList.length !== 0 ? (
          <div style={{ maxHeight: 506, overflow: 'auto', paddingBottom: 32 }}>
            <List
              dataSource={searchList}
              bordered={false}
              renderItem={(item) => {
                if (item?.email === 'no results') {
                  return (
                    <List.Item style={{ paddingInline: 16 }}>
                      <Text
                        ellipsis
                        style={{
                          backgroundColor: 'transparent',
                          verticalAlign: 'middle',
                          paddingTop: 6,
                          border: 'none',
                        }}
                      >
                        {t('delegation-drawer.zero-results')}
                      </Text>
                      <CloseCircleOutlined
                        onClick={() => {
                          setSearchList([])
                          setIsSearching(false)
                          form.resetFields()
                        }}
                      />
                    </List.Item>
                  )
                }
                return (
                  <List.Item
                    style={{
                      paddingInline: 0,
                      border: 'none',
                      backgroundColor:
                        selectedEmails.indexOf(item.email!) === -1
                          ? 'var(--offset-background-color)'
                          : '#e6f4ff',
                      marginBottom: 4,
                    }}
                    onClick={() => {
                      if (selectedEmails.indexOf(item.email!) === -1) {
                        updateSelectedEmails([...selectedEmails, item.email!])
                      } else {
                        updateSelectedEmails(
                          selectedEmails.filter(
                            (email) => email !== item.email,
                          ),
                        )
                      }
                    }}
                  >
                    <SelectTag
                      label={item?.name || ''}
                      secondaryLabel={item?.email}
                      image={item?.image}
                      status={item?.status}
                      border={false}
                    />
                  </List.Item>
                )
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {!isSearching ? (
          <div style={{ maxHeight: 506, overflow: 'auto', paddingBottom: 32 }}>
            {menuItems && menuItems.length > 0 ? (
              <Menu
                mode="inline"
                theme="light"
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                items={menuItems}
                onSelect={onSelect}
                onDeselect={onDeselect}
                onOpenChange={onOpenChange}
                multiple={true}
              />
            ) : (
              <Empty description={t('delegation-drawer.empty-text')} />
            )}
          </div>
        ) : (
          <></>
        )}
        <Footer
          style={{
            width: 'calc(100% - 16px)',
          }}
        >
          <Row>
            <Col span={24}>
              <Text>{t('delegation-drawer.grant-editor-permissions')}</Text>
              <Switch
                checked={assigneeHasEditorPermissions}
                onChange={(evt) =>
                  setAssigneeHasEditorPermissions(evt.target.checked)
                }
              />
            </Col>
          </Row>
          {!isFloatButtonVisible && (
            <ActionButtons onCancel={onCancel} onInvite={onInvite} />
          )}
        </Footer>
        {isOpen &&
          isFloatButtonVisible &&
          !isFloatButtonTemporarilyHidden &&
          !isDocked && (
            <FloatingContainer
              children={
                <ActionButtons onCancel={onCancel} onInvite={onInvite} />
              }
              position="left"
            />
          )}
      </Space>
    </motion.div>
  )
}

export default DelegationDrawer
