import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $generateNodesFromDOM } from '@lexical/html'
import { $getRoot, $insertNodes } from 'lexical'
import { decode } from 'html-entities'

type UpdateHtmlPluginProps = {
  initialContent: string | null
}

const UpdateHtmlPlugin = ({ initialContent }: UpdateHtmlPluginProps) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (initialContent) {
      editor.update(() => {
        const content = decode(initialContent)
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser()
        const dom = parser.parseFromString(content, 'text/html')

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom)

        const root = $getRoot()
        root.clear()

        // Select the root
        $getRoot().select()
        // // Insert them at a selection.
        $insertNodes(nodes)
      })
    }
  }, [editor, initialContent])

  return null
}

export default UpdateHtmlPlugin
