import { Tabs, TabsProps } from 'antd'
import useStore from 'hooks/useStore'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomField } from 'types/CustomFields'
import RoutineReport from './RoutineReport'
import SentDataReport from './SentDataReport'
import TrendAnalysisReport from './TrendAnalysisReport'

type CompletionReportProps = {
  taskId: string
  isRoutine: boolean
}

const CompletionReport = ({ taskId, isRoutine }: CompletionReportProps) => {
  const { t } = useTranslation()
  const [customFields, setCustomFields] = useState<
    CustomField | null | undefined
  >()
  const getCustomFieldByTaskId = useStore(
    (state) => state.getCustomFieldByTaskId,
  )

  const getItems = useCallback(() => {
    const items: TabsProps['items'] = []
    if (isRoutine) {
      items.push({
        key: 'routineCompletion',
        label: t('reports.routine.title'),
        children: <RoutineReport taskId={taskId} />,
      })
    }
    if (customFields?.customField) {
      items.push({
        key: 'trendAnalysis',
        label: t('reports.trend.title'),
        children: (
          <TrendAnalysisReport taskId={taskId} customFields={customFields} />
        ),
      })
      items.push({
        key: 'sentDataReport',
        label: t('reports.data.title'),
        children: (
          <SentDataReport taskId={taskId} customFields={customFields} />
        ),
      })
    }
    return items
  }, [customFields, isRoutine, t, taskId])

  const items = getItems()

  useEffect(() => {
    getCustomFieldByTaskId(taskId).then((result) => {
      setCustomFields(result)
    })
  }, [getCustomFieldByTaskId, taskId])

  return <Tabs defaultActiveKey={items[0]?.key} items={items} />
}

export default CompletionReport
