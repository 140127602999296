import { Button, Col, Form, Row, Typography } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { RoleMappingShared } from 'types/Templates'
import CellComponent from './CellComponent'
import styles from './RoleMapping.module.scss'
import RowComponent from './RowComponent'
import RowTitleComponent from './RowTitleComponent'

const { Title } = Typography

type ProjectRolesDetailProps = {
  title?: string
  column1: string
  column2: string
  roles: RoleMappingShared[]
  showButtons?: boolean
  editingRole?: Partial<RoleMappingShared> | null
  setEditingRole?: Dispatch<SetStateAction<Partial<RoleMappingShared> | null>>
  onSave?: () => void
  onEdit?: (role: Partial<RoleMappingShared> | null) => void
  onDelete?: (role: Partial<RoleMappingShared>) => void
}

const ProjectRolesDetail = ({
  title,
  column1,
  column2,
  roles,
  showButtons,
  editingRole,
  setEditingRole,
  onSave,
  onEdit,
  onDelete,
}: ProjectRolesDetailProps) => {
  const newElement = { id: 0, role: '', email: '' } as RoleMappingShared
  return (
    <Row gutter={16} style={{ padding: 16 }} justify="start">
      <Col span={24}>
        {title && (
          <Row className={styles.rowGrid} gutter={[24, 24]}>
            <CellComponent span={24}>
              <Title level={5}>{title}</Title>
              {showButtons && (
                <Button
                  type="primary"
                  style={{ marginLeft: 8 }}
                  size="small"
                  onClick={() => setEditingRole?.(newElement)}
                >
                  +
                </Button>
              )}
            </CellComponent>
          </Row>
        )}
        <RowTitleComponent column1={column1} column2={column2} />
        <Form>
          {roles?.map((field) => (
            <Form.Item
              key={field.id}
              name={field.id}
              style={{ marginBottom: 0 }}
            >
              <RowComponent
                field={field}
                showButtons={showButtons}
                editingRole={editingRole}
                setEditingRole={setEditingRole}
                onEdit={onEdit}
                onDelete={onDelete}
                onSave={onSave}
              />
            </Form.Item>
          ))}
          {editingRole && !editingRole.id && (
            <Form.Item name="new-item" style={{ marginBottom: 0 }}>
              <RowComponent
                field={newElement}
                showButtons={showButtons}
                editingRole={editingRole}
                setEditingRole={setEditingRole}
                onEdit={onEdit}
                onDelete={onDelete}
                onSave={onSave}
              />
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  )
}

export default ProjectRolesDetail
