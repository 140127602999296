import { DefaultOptionType } from 'antd/es/select'
import { PendingAssignee } from 'types/Tasks'
import { AssigneeInvitationStatus } from 'utils/taskUtils'

export const filterOption = (
  input: string,
  option: DefaultOptionType | undefined,
) =>
  ((option?.value as string) ?? '')
    .toLowerCase()
    .includes(input.toLowerCase()) ||
  ((option?.name as string) ?? '').toLowerCase().includes(input.toLowerCase())

export const encodeOptionLabel = (contact: PendingAssignee) => {
  const { name, email, image, status, createdAt } = contact
  const displayName = name?.replaceAll('|', '') || email!
  return `${displayName}|${status || ''}|${createdAt || ''}|${image || ''}`
}

export const decodeOptionLabel = (value: string) => {
  const values = value.split('|')
  return {
    name: values[0],
    status: values[1],
    createdAt: values[2],
    image: values[3],
  } as PendingAssignee
}

export const shouldShowResendButton = (status: string) => {
  return (
    status === AssigneeInvitationStatus.PENDING ||
    status === AssigneeInvitationStatus.EXPIRED
  )
}
