import dayjs from 'dayjs'
import { CustomField } from 'types/CustomFields'
import { CompletionReportType, TaskStatus, TaskStatusId } from 'types/Tasks'
import { colorList } from 'components/AccountGroupFilter/GroupForm'

export const statusColorScale = {
  [TaskStatusId.NOT_STARTED]: 'var(--not-started-color)',
  [TaskStatusId.ACTIVE]: 'var(--in-progress-active-color)',
  [TaskStatusId.PAUSED]: 'var(--in-progress-paused-color)',
  [TaskStatusId.DONE]: 'var(--completed-color)',
}

export type RechartDataType = {
  name: string
  [key: string]: string
}

export type RechartBarType = {
  key: number | string
  name: string
  fill: string
}

export type SummaryDetailType = {
  status: number | string
  value: number
  label: string
  color: string
}

export type SummaryType = {
  total: number
  data: SummaryDetailType[]
}

export const GroupByOptions = [
  { value: 'week', label: 'Week', dateFormat: 'YYYY/MM/DD' },
  { value: 'month', label: 'Month', dateFormat: 'MM/YYYY' },
  { value: 'quarter', label: 'Quarter', dateFormat: 'MM/YYYY' },
  { value: 'year', label: 'Year', dateFormat: 'YYYY' },
  { value: 'day', label: 'Day', dateFormat: 'YYYY/MM/DD' },
  { value: 'second', label: 'Individual Records', dateFormat: 'YYYY/MM/DD' },
]

export const fixedColumns = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
]

export const getDateFormat = (type: string, userConfigDateFormat: string) => {
  if (type === 'week' && userConfigDateFormat) {
    return userConfigDateFormat
  }
  return (
    GroupByOptions.find((option) => option.value === type)?.dateFormat ||
    userConfigDateFormat
  )
}

export const transformToRechartFormat = (
  original: CompletionReportType[],
  format: string,
): RechartDataType[] => {
  const result = []
  const groupedByName: { [key: string]: { [key: string]: string | number } } =
    {}
  original.forEach(({ name: range, key: status, value }) => {
    if (!groupedByName[range]) {
      groupedByName[range] = { name: range }
    }
    groupedByName[range][status] = value?.toFixed(2) || 0
  })

  for (const name in groupedByName) {
    result.push(groupedByName[name])
  }

  result.sort((a, b) => {
    const nameA = a.name
    const nameB = b.name
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return result.map((item) => ({
    ...item,
    name: dayjs(item.name).format(format),
  }))
}

export const statusDetailsRecharts = (
  statusList?: TaskStatus[],
): RechartBarType[] => {
  const statuses = taskStatusArrayToObject(statusList || [])
  return [
    {
      key: TaskStatusId.NOT_STARTED,
      name: statuses[TaskStatusId.NOT_STARTED],
      fill: 'var(--not-started-color)',
    },
    {
      key: TaskStatusId.ACTIVE,
      name: statuses[TaskStatusId.ACTIVE],
      fill: 'var(--in-progress-active-color)',
    },
    {
      key: TaskStatusId.PAUSED,
      name: statuses[TaskStatusId.PAUSED],
      fill: 'var(--in-progress-paused-color)',
    },
    {
      key: TaskStatusId.DONE,
      name: statuses[TaskStatusId.DONE],
      fill: 'var(--completed-color)',
    },
  ]
}

export const customFieldBarsRecharts = (
  customFields: CustomField,
): RechartBarType[] => {
  return customFields.customField
    .filter((item) => item.type === 'number')
    .map((item, idx) => ({
      key: item.id,
      name: item.label,
      fill: colorList[idx % colorList.length],
    }))
}

export function summarizeDataByCustomField(
  original: CompletionReportType[],
  customFields: CustomField,
): SummaryType {
  const summaryByStatus = {
    total: 0,
    data: {} as { [key: string]: number },
  }
  const groups: { [key: string]: number } = {}

  original.forEach(({ key, value }) => {
    if (!groups[key]) {
      groups[key] = 1
    } else {
      groups[key] += 1
    }
    if (!summaryByStatus.data[key]) {
      summaryByStatus.data[key] = value
    } else {
      summaryByStatus.data[key] += value
    }
  })

  const formattedData = Object.keys(summaryByStatus.data)
    .map((key) => {
      return {
        status: key,
        value: groups[key]
          ? Math.round(summaryByStatus.data[key] / groups[key])
          : 0,
        label:
          customFields.customField?.find((item) => item.id === key)?.label ||
          '',
        color: '', //statusColorScale[status],
      }
    })
    .sort((a, b) => {
      const nameA = a.label
      const nameB = b.label
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

  return {
    total: summaryByStatus.total,
    data: formattedData,
  }
}

export function summarizeData(
  original: CompletionReportType[],
  statusList?: TaskStatus[],
): SummaryType {
  const summaryByStatus = {
    total: 0,
    data: {} as { [key: number]: number },
  }

  original.forEach(({ key: status, value }) => {
    summaryByStatus.total += value

    if (!summaryByStatus.data[status]) {
      summaryByStatus.data[status] = value
    } else {
      summaryByStatus.data[status] += value
    }
  })

  const formattedData = Object.keys(summaryByStatus.data).map((status) => {
    const statusInt = parseInt(status, 10)
    return {
      status: statusInt,
      value: summaryByStatus.data[statusInt],
      label: statusList?.find((item) => item.id === statusInt)?.title || '',
      color: statusColorScale[statusInt as TaskStatusId],
    }
  })

  return {
    total: summaryByStatus.total,
    data: formattedData,
  }
}

export const getCompletedPercentage = (summary: SummaryType) => {
  if (!summary.total) {
    return 0
  }
  const completed = summary.data.find(
    (item) => item.status === TaskStatusId.DONE,
  )
  if (!completed) {
    return 0
  }
  const value = (completed.value / summary.total) * 100
  return Math.round((value + Number.EPSILON) * 100) / 100
}

const taskStatusArrayToObject = (arr: TaskStatus[]) => {
  const result: { [key: number]: string } = {}
  arr.forEach((item) => {
    result[item.id] = item.title
  })
  return result
}
