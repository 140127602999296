import { Col, Row, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useTranslation } from 'react-i18next'
import { TaskLists } from 'services/Tasks.slice'
import PriorityItem from './PriorityItem'
import styles from './PriorityPanel.module.scss'
import { getFilteredPriorityGroupBySelectables } from 'utils/taskUtils'

const { Text } = Typography

type PriorityPanelProps = {
  tasks: TaskLists
}

const PriorityPanel = ({ tasks }: PriorityPanelProps) => {
  const { t } = useTranslation()
  const priorityGroups = getFilteredPriorityGroupBySelectables(
    useStore((state) => state.priorityGroups || []),
  )

  return (
    <div className={styles.priorityRow}>
      <Text className={styles.title}>
        {t('my-today-page.priority-group', { ns: 'common' })}
      </Text>
      <Row gutter={12}>
        {priorityGroups.map((priorityGroup) => {
          return (
            <Col key={priorityGroup.id} xs={6} lg={24}>
              <PriorityItem
                data={priorityGroup}
                tasks={tasks}
                dropId={'count-' + priorityGroup.id.toString()}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default PriorityPanel
