import jwt_decode, { JwtPayload } from 'jwt-decode'

export type TokenType = {
  access: string
  refresh: string
}

const buffer = 1 * 60 // Buffer of one minutes in seconds
export const checkToken = (token: TokenType) => {
  // Decode the token
  const decodedToken = jwt_decode<JwtPayload>(token.access)

  // Check expiration
  if (decodedToken && decodedToken.exp) {
    const expirationTimestamp = decodedToken.exp
    const currentTimestamp = Math.floor(Date.now() / 1000) // Current time in seconds

    return expirationTimestamp > currentTimestamp + buffer // Make sure it isn't expiring in the next few minutes
  } else {
    // Unable to decode token or missing expiration claim
    return false
  }
}
