import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  List,
  Flex,
  Typography,
  Input,
  Button,
  Layout,
  Form,
  Tooltip,
  Space,
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { SendOutlined } from '@ant-design/icons'
import useStore from 'hooks/useStore'
import { Chat, Message } from 'services/Chat.slice'
import { WebSocketContext, WebSocketContextType } from 'utils/WebSocketProvider'
import ChatMessage from './ChatMessage'
import UserAvatar from 'components/UserAvatar'
import { Size } from 'components/UserAvatar/UserAvatar'

type ChatWindowProps = {
  selectedChat: Chat
}

const { Header, Footer, Content } = Layout
const { Title } = Typography

const ChatWindow = ({ selectedChat }: ChatWindowProps) => {
  const listRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const {
    getChatMessages,
    postChatMessage,
    patchChatMessage,
    deleteChatMessage,
    setChatHasUnread,
    addMessage,
    patchMessage,
    deleteMessage,
    user,
    messages,
    dateFormatOption,
    timeFormat,
  } = useStore((state) => state)

  const [newMessage, setNewMessage] = useState<string>('')
  const [editingMessageId, setEditingMessageId] = useState<string>('')
  const { lastMessage } = useContext(WebSocketContext) as WebSocketContextType
  const navigate = useNavigate()

  useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data)
      if (message.data && message.data.template_name === 'new_message') {
        addMessage(message.data.chat_id, {
          id: message.data.id,
          user: message.data.author,
          userName: message.data.author,
          userAvatar: message.data.author_avatar,
          message: message.data.message,
          createdAt: message.data.created_at,
        })
      } else if (
        message.data &&
        message.data.template_name === 'update_message'
      ) {
        patchMessage(
          message.data.chat_id,
          message.data.id,
          message.data.message,
        )
      } else if (
        message.data &&
        message.data.template_name === 'delete_message'
      ) {
        deleteMessage(message.data.chat_id, message.data.id)
      }
    }
  }, [lastMessage, addMessage, deleteMessage, patchMessage])

  useEffect(() => {
    getChatMessages(selectedChat.id)
  }, [selectedChat.id, getChatMessages])

  useEffect(() => {
    if (listRef.current) {
      listRef.current?.scrollTo(0, listRef.current.scrollHeight)
    }
    if (selectedChat.hasUnread) {
      setChatHasUnread(selectedChat.id, false)
    }
  }, [messages, selectedChat, setChatHasUnread])

  const handleNewMessageChange: ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setNewMessage(e.target.value)
  }

  const handleCreateNewMessage = () => {
    if (editingMessageId && newMessage) {
      patchChatMessage(selectedChat.id, editingMessageId, newMessage)
    } else if (newMessage) {
      postChatMessage(selectedChat.id as string, newMessage)
    }
    setNewMessage('')
    setEditingMessageId('')
  }

  const onEditClick = (messageId: string, message: string) => {
    setNewMessage(message)
    setEditingMessageId(messageId)
    inputRef.current?.focus()
  }

  const onDeleteClick = (messageId: string) => {
    deleteChatMessage(selectedChat.id, messageId)
  }

  return (
    <>
      <Header
        style={{
          height: 64,
          paddingInline: 24,
          paddingTop: 4,
          lineHeight: '64px',
          backgroundColor: 'var(--background-color)',
          borderBottom: '1px solid var(--border-color)',
        }}
      >
        <Flex justify="space-between" align="center">
          <Tooltip
            title={
              selectedChat.attachedObject?.parent
                ? `${selectedChat.attachedObject?.parent.title} -> ${selectedChat.attachedObject.title}`
                : selectedChat.attachedObject?.title
            }
          >
            <Title
              level={5}
              onClick={() =>
                navigate(`/task/${selectedChat.attachedObject?.id}`)
              }
              style={{
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginBottom: 0,
              }}
            >
              {selectedChat.attachedObject?.title}
            </Title>
          </Tooltip>
          <Tooltip
            title={selectedChat.viewers
              .map((viewer) => viewer.fullName)
              .join('\r\n')}
            overlayStyle={{ whiteSpace: 'pre-wrap' }}
            placement="bottomRight"
          >
            <Space size={0}>
              {selectedChat.viewers.slice(0, 4).map((viewer) => (
                <UserAvatar
                  key={viewer.id}
                  label={viewer.fullName}
                  image={viewer.avatar ? viewer.avatar.thumbnailUrl : undefined}
                  size={Size.SMALL}
                  avatarStyles={{
                    marginRight: '-12px',
                    border: '1px solid white',
                  }}
                />
              ))}
              {selectedChat.viewers.length > 4 && (
                <UserAvatar
                  label={`+ ${selectedChat.viewers.length - 4}`}
                  size={Size.SMALL}
                  avatarStyles={{
                    marginRight: '-12px',
                    border: '1px solid white',
                  }}
                />
              )}
            </Space>
          </Tooltip>
        </Flex>
      </Header>
      <Content
        ref={listRef}
        style={{
          textAlign: 'center',
          lineHeight: '120px',
          backgroundColor: 'var(--offset-background-color)',
          paddingLeft: 8,
          paddingTop: 8,
          paddingRight: 8,
          overflow: 'auto',
        }}
      >
        <List
          dataSource={messages[selectedChat.id]}
          renderItem={(item: Message) => (
            <ChatMessage
              message={item.message}
              userName={item.user !== user?.data.id ? item.userName : undefined}
              userAvatar={item.userAvatar}
              createdAt={item.createdAt}
              dateFormatOption={dateFormatOption}
              timeFormat={timeFormat}
              updatedAt={item.updatedAt}
              deletedAt={item.deletedAt}
              onEditClick={() => onEditClick(item.id, item.message)}
              onDeleteClick={() => onDeleteClick(item.id)}
            />
          )}
        />
      </Content>
      <Footer
        style={{
          textAlign: 'center',
          backgroundColor: 'var(--background-color)',
          position: 'sticky',
        }}
      >
        <Form
          onFinish={handleCreateNewMessage}
          style={{ width: '100%', display: 'flex', alignItems: 'end' }}
        >
          <Form.Item style={{ width: '100%' }}>
            <Input.TextArea
              ref={inputRef}
              autoSize={{ minRows: 1, maxRows: 5 }}
              value={newMessage}
              onChange={handleNewMessageChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  e.preventDefault()
                  setNewMessage(newMessage + '\n')
                } else if (e.key === 'Enter') {
                  e.preventDefault()
                  handleCreateNewMessage()
                }
              }}
              style={{ flex: '1 1 auto' }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: 'var(--completed-color)', marginLeft: '16px' }}
            >
              <SendOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Footer>
    </>
  )
}

export default ChatWindow
