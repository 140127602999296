import { Drawer } from 'antd'
import LoadingProgress from 'components/LoadingProgress'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Node } from 'reactflow'
import 'reactflow/dist/style.css'
import { TaskNodeData } from 'types/Tasks'
import { RoleMappingBase } from 'types/Templates'
import MappingDetails from './MappingDetails'
import styles from './RoleMappingPanel.module.scss'

type RoleMappingPanelProps = {
  isRoleMappingPanelOpen: boolean
  closeRoleMappingPanel: () => void
  isTaskFlowViewerVisible?: boolean
  nodes?: Node<TaskNodeData>[]
  templateId: number
  roleMapping: RoleMappingBase[]
  setRoleMapping: Dispatch<SetStateAction<RoleMappingBase[]>>
}

const RoleMappingPanel = ({
  isRoleMappingPanelOpen,
  closeRoleMappingPanel,
  isTaskFlowViewerVisible,
  nodes,
  templateId,
  roleMapping,
  setRoleMapping,
}: RoleMappingPanelProps) => {
  const { t } = useTranslation()
  const [loadingLeftPanelTasks] = useState(false)

  return (
    <Drawer
      title={t('templates.role-mapping-tab')}
      placement="right"
      closable={true}
      mask={false}
      onClose={closeRoleMappingPanel}
      destroyOnClose={true}
      open={isRoleMappingPanelOpen}
      size="large"
    >
      <div className={styles.siderContent}>
        {!loadingLeftPanelTasks && (
          <MappingDetails
            templateId={templateId}
            roleMapping={roleMapping}
            setRoleMapping={setRoleMapping}
          />
        )}
        {loadingLeftPanelTasks && <LoadingProgress />}
      </div>
    </Drawer>
  )
}

export default RoleMappingPanel
