import { List } from 'antd'
import { colorList } from 'components/AccountGroupFilter/GroupForm'
import { TaskMode } from 'services/Tasks.slice'
import { Task } from 'types/Tasks'
import styles from './DraggableTaskItem.module.css'

type DraggableTaskItemProps = {
  task: Task
}

const DraggableTaskItem = ({ task }: DraggableTaskItemProps) => (
  <List.Item
    className={styles.taskItem}
    style={{
      borderLeftColor:
        task.group?.metadata?.color?.toLowerCase() || colorList[0],
      backgroundColor: task.mode === TaskMode.DRAFT ? '#c3c3c3' : undefined,
    }}
    draggable
    onDragStart={(event) =>
      event.dataTransfer.setData('application/reactflow', JSON.stringify(task))
    }
  >
    <div className={styles.taskTitle}>{task.title}</div>
  </List.Item>
)

export default DraggableTaskItem
