import {
  DeploymentUnitOutlined,
  EllipsisOutlined,
  FlagFilled,
  LeftOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import clsx from 'clsx'
import AudioPlayer from 'components/AudioPlayer'
import { DeleteTaskButton } from 'components/DeleteTasksDialog'
import DueDateDropdown from 'components/DueDateDropdown'
import RoutineDropdown from 'components/RoutineDropdown'
import SchedulingDropDown from 'components/SchedulingDropdown'
import UserAvatar from 'components/UserAvatar'
import { Size } from 'components/UserAvatar/UserAvatar'
import dayjs, { Dayjs } from 'dayjs'
import { motion } from 'framer-motion'
import useStore from 'hooks/useStore'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { useTaskFlowViewer } from 'sections/TaskFlowViewer'
import { FlexibleTime } from 'types/FlexibleTime'
import {
  ScheduledTask,
  TaskItem,
  TaskPriority,
  TaskPriorityGroup,
} from 'types/Tasks'
import { TaskActions, TaskFields, hasPermission } from 'utils/permissions'
import { recurRuleFromString } from 'utils/recurRule'
import {
  getAssigneeInvitationStatus,
  getDueDateColorIcon,
  isInstanceOfSubtaskOfRoutine,
  isRecurring,
  isRoutineInstanceSchedule,
  isScheduledForToday,
  scheduledDateFormatted,
} from 'utils/taskUtils'
import { MessageOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import styles from '../TaskListItem.module.scss'

type TaskToolbarProps = {
  flagged?: boolean
  task: TaskItem
  isTaskExpanded?: boolean
  isReadonly?: boolean
  showChatIcon?: boolean
  onToggle?: (taskId: string) => void
}

export default function TaskToolbar({
  flagged,
  task,
  isTaskExpanded,
  isReadonly,
  showChatIcon,
  onToggle,
}: TaskToolbarProps) {
  const { t } = useTranslation()
  const user = useStore((state) => state.user?.data)
  const { open, toggleDrawer, isDrawerOpen } = useTaskEditor()
  const {
    taskRolePermissions,
    updateTask,
    setShouldUpdateTasks,
    dateFormat,
    timeFormat,
  } = useStore((state) => state)
  const thumbnailAvatar = useStore((state) => state.thumbnailAvatar)

  const [showHiddenActions, setShowHiddenActions] = useState(false)
  const schedule = task.scheduledTask ? task.scheduledTask : undefined
  const rRule = recurRuleFromString(schedule?.recurRule)
  const [flexibleTime, setFlexibleTime] = useState<FlexibleTime | undefined>(
    task.scheduledTask?.flexibleTime || FlexibleTime.BEGINNING,
  )
  const [routineIsOpen, setRoutineIsOpen] = useState(false)

  const taskDueAt = task?.dueAt
  const shouldShowToolBar = isTaskExpanded || showHiddenActions
  const taskIsScheduledForToday = isScheduledForToday(task)
  const isRecurrent = isRecurring(task)
  const isRoutineInstance = isRoutineInstanceSchedule(task)
  const isSubtaskOfRoutine = isInstanceOfSubtaskOfRoutine(task)
  const toolBarWidth =
    isRoutineInstance || isSubtaskOfRoutine ? '141px' : '205px'
  const recurringTooltip = isRecurrent && rRule.toText()
  const { openTaskFlowViewer } = useTaskFlowViewer()

  const delegated =
    task?.taskAssignees && task?.taskAssignees.length > 0
      ? task.taskAssignees[0]
      : undefined

  const pendingAssignee = task.pendingAssignees?.find(
    (pa) => pa.status !== 'Refused',
  )

  const isAnotherPersonDelegated = delegated
    ? delegated.user !== user?.id
    : false

  const isDelegatedToMe = delegated
    ? delegated.user === user?.id && task.user?.id !== user?.id
    : false

  const canDelete = useMemo(() => {
    return hasPermission(
      TaskActions.DELETE_TASK,
      task?.roles,
      taskRolePermissions,
    )
  }, [task?.roles, taskRolePermissions])
  const canEditFlag = useMemo(() => {
    return hasPermission(TaskFields.FLAG, task?.roles, taskRolePermissions)
  }, [task?.roles, taskRolePermissions])
  const canEditDueAt = useMemo(() => {
    return hasPermission(TaskFields.DUE_AT, task?.roles, taskRolePermissions)
  }, [task?.roles, taskRolePermissions])
  const canEditScheduling = useMemo(() => {
    return hasPermission(
      TaskFields.SCHEDULED_TASK,
      task?.roles,
      taskRolePermissions,
    )
  }, [task?.roles, taskRolePermissions])

  const delegationStatus = getAssigneeInvitationStatus(task)

  const toggleAdditionalActions = () => {
    setShowHiddenActions(!showHiddenActions)
  }

  const isParentTask = task?.children?.length !== 0

  const handleFlagIconClick = () => {
    const updatedDetails = {
      flag: flagged ? TaskPriority.NORMAL : TaskPriority.URGENT,
    }

    updateTask(
      {
        taskId: task!.id,
        data: {
          details: updatedDetails,
        },
      },
      user!.id,
    )
  }

  const handleSchedulingRecurringChange = (
    taskId: string,
    newValue?: Partial<ScheduledTask> | null,
  ) => {
    const scheduledTask = newValue || null
    updateTask({ taskId, data: { scheduledTask } }, user!.id)
  }

  const handleDueDateChange = (date: Dayjs | undefined) => {
    const data = {
      dueAt: date ? date.toISOString() : null,
    }

    updateTask(
      {
        taskId: task!.id,
        data,
      },
      user!.id,
    )
  }

  const openDelegationModal = () => {
    open(task, () => setShouldUpdateTasks(true))
    if (isDrawerOpen === false) {
      toggleDrawer()
    }
  }

  return (
    <div
      className={clsx(
        styles.taskActions,
        isTaskExpanded && styles.taskActionsExpanded,
      )}
      onClick={(evt) => {
        if (!isReadonly) {
          evt.preventDefault()
          evt.stopPropagation()
        }
      }}
    >
      <div className={styles.highlightedActions}>
        <Button
          type="text"
          icon={
            <FlagFilled
              className={clsx(
                flagged ? styles.flagicon : styles.flagIconDisable,
              )}
              onClick={
                isReadonly || !canEditFlag ? () => {} : handleFlagIconClick
              }
            />
          }
        />

        {showChatIcon &&
          task.priorityGroup?.id !== TaskPriorityGroup.RoutineDefinition && (
            <div onClick={(e) => e.stopPropagation()}>
              <Button
                type="text"
                icon={
                  <Badge
                    className="ant-menu-item-icon"
                    dot={false /* TODO: Add the correct logic */}
                  >
                    <MessageOutlined className="ant-menu-item-icon" />
                  </Badge>
                }
                onClick={() => {
                  open({
                    id: task.id,
                    isUpdate: true,
                    showChatAtOpening: true,
                  })
                }}
              />
            </div>
          )}

        {task?.dueAt && (
          <div onClick={(e) => e.stopPropagation()}>
            <DueDateDropdown
              data-testid="due-date-dropdown"
              allowClear={!!taskDueAt}
              onChange={handleDueDateChange}
              iconClassName={clsx(styles.priorityActionIcon)}
              iconStyles={{ color: getDueDateColorIcon(taskDueAt || '') }}
              tooltip={dayjs(taskDueAt).format(dateFormat)}
              isReadonly={isReadonly || !canEditDueAt}
            />
          </div>
        )}

        {isRecurrent && (
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title={recurringTooltip}>
              <RoutineDropdown
                outlined={false}
                title={recurringTooltip || ''}
                isOpen={routineIsOpen}
                setIsOpen={setRoutineIsOpen}
                onChange={(value) =>
                  handleSchedulingRecurringChange(task.id, value)
                }
                iconClassName={clsx(styles.priorityActionIcon)}
                value={schedule || undefined}
                setFlexibleTime={setFlexibleTime}
                flexibleTime={flexibleTime}
                isRoutineInstance={isRoutineInstanceSchedule(task)}
                disabled={isReadonly || !canEditScheduling}
              />
            </Tooltip>
          </div>
        )}

        {taskIsScheduledForToday && (
          <Tooltip
            title={scheduledDateFormatted(
              dateFormat,
              timeFormat,
              task.scheduledTask,
            )}
          >
            <SchedulingDropDown
              outlined={false}
              title={scheduledDateFormatted(
                dateFormat,
                timeFormat,
                task.scheduledTask,
              )}
              setRoutineIsOpen={setRoutineIsOpen}
              onChange={(value) =>
                handleSchedulingRecurringChange(task.id, value)
              }
              value={schedule || undefined}
              setFlexibleTime={setFlexibleTime}
              iconStyles={{
                color: getDueDateColorIcon(
                  (task.scheduledTask?.startDate as string) || '',
                ),
              }}
              disabled={isReadonly || !canEditScheduling}
            />
          </Tooltip>
        )}

        {isDelegatedToMe ? (
          <Tooltip
            title={t('my-today-page.delegated-by', {
              ns: 'common',
              name: task.user?.name,
            })}
          >
            <div>
              <UserAvatar
                label={task.user?.name}
                size={Size.SMALL}
                delegationStatus={delegationStatus}
                color={`var(--delegation-${delegationStatus.toLocaleLowerCase()})`}
                image={task.user?.avatar}
              />
            </div>
          </Tooltip>
        ) : (
          delegated &&
          isAnotherPersonDelegated && (
            <Tooltip
              title={t('my-today-page.delegated-to', {
                ns: 'common',
                name: delegated.assigneeName,
                status: delegationStatus,
              })}
            >
              <div>
                <UserAvatar
                  label={delegated.assigneeName}
                  size={Size.SMALL}
                  delegationStatus={delegationStatus}
                  color={`var(--delegation-${delegationStatus.toLocaleLowerCase()})`}
                  image={delegated.avatar}
                />
              </div>
            </Tooltip>
          )
        )}

        {!delegated && pendingAssignee && (
          <Tooltip
            title={t('my-today-page.delegation-confirmation-pending', {
              ns: 'common',
              date: dayjs(pendingAssignee.createdAt).format(dateFormat),
            })}
          >
            <div>
              <Button
                type="text"
                icon={<UserOutlined style={{ color: 'red' }} />}
              />
            </div>
          </Tooltip>
        )}
        {task.inputType === 'audio' && <AudioPlayer task={task} />}
      </div>

      {!isReadonly && (
        <div className={clsx(styles.hiddenActions)}>
          {!isTaskExpanded && (
            <LeftOutlined
              onClick={toggleAdditionalActions}
              className={clsx(
                styles.triggerIcon,
                showHiddenActions && styles.triggerIconActive,
              )}
            />
          )}

          <motion.div
            initial={{ opacity: 0, width: 0 }}
            animate={{
              opacity: shouldShowToolBar ? 1 : 0,
              width: shouldShowToolBar ? toolBarWidth : 0,
            }}
            transition={{ duration: 0.3 }}
          >
            {shouldShowToolBar && (
              <div className={styles.hiddenActionsItems}>
                {!task?.dueAt && !isRoutineInstance && !isSubtaskOfRoutine && (
                  <Tooltip
                    title={
                      taskDueAt
                        ? dayjs(taskDueAt).format('MM/DD/YYYY')
                        : t('my-today-page.due-date', { ns: 'common' })
                    }
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <DueDateDropdown
                        allowClear={!!taskDueAt}
                        onChange={handleDueDateChange}
                        disabled={isReadonly || !canEditDueAt}
                      />
                    </div>
                  </Tooltip>
                )}

                {!taskIsScheduledForToday &&
                  !isRoutineInstance &&
                  !isSubtaskOfRoutine && (
                    <Tooltip
                      title={t('my-today-page.scheduled', { ns: 'common' })}
                    >
                      <SchedulingDropDown
                        outlined={false}
                        title={t('new-task-form.recurrence.scheduling')!}
                        setRoutineIsOpen={setRoutineIsOpen}
                        onChange={(value) =>
                          handleSchedulingRecurringChange(task.id, value)
                        }
                        value={schedule || undefined}
                        setFlexibleTime={setFlexibleTime}
                        disabled={isReadonly || !canEditScheduling}
                      />
                    </Tooltip>
                  )}
                {(!delegated || !isAnotherPersonDelegated) &&
                  !pendingAssignee &&
                  !isDelegatedToMe && (
                    <Tooltip
                      title={t('my-today-page.delegate-task', { ns: 'common' })}
                    >
                      <Button
                        type="text"
                        style={{ padding: 0 }}
                        onClick={openDelegationModal}
                      >
                        <UserAvatar
                          label={user!.firstName + ' ' + user!.lastName}
                          size={Size.SMALL}
                          image={thumbnailAvatar}
                        />
                      </Button>
                    </Tooltip>
                  )}
                <Tooltip title={t('new-task-form.plan-view')}>
                  <div>
                    <Button
                      icon={<DeploymentUnitOutlined />}
                      type="text"
                      onClick={() => openTaskFlowViewer(task)}
                    />
                  </div>
                </Tooltip>
                {!isRecurrent && (
                  <div onClick={(e) => e.stopPropagation()}>
                    <RoutineDropdown
                      outlined={false}
                      title={t('my-today-page.make-recurring') as string}
                      isOpen={routineIsOpen}
                      setIsOpen={setRoutineIsOpen}
                      onChange={(value) =>
                        handleSchedulingRecurringChange(task.id, value)
                      }
                      value={schedule || undefined}
                      setFlexibleTime={setFlexibleTime}
                      flexibleTime={flexibleTime}
                      isRoutineInstance={isRoutineInstanceSchedule(task)}
                      disabled={isReadonly || !canEditScheduling}
                    />
                  </div>
                )}

                <DeleteTaskButton
                  isCustomDialog={isParentTask}
                  isIcon={true}
                  task={task}
                  disabled={isReadonly || !canDelete}
                />
                {onToggle && (
                  <Button
                    onClick={() => onToggle(task.id)}
                    type="text"
                    icon={<EllipsisOutlined />}
                  />
                )}
              </div>
            )}
          </motion.div>
        </div>
      )}
    </div>
  )
}
