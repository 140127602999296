import { ApiState } from './Auth.slice'
import { StateCreator } from 'zustand'
import { AxiosResponse } from 'axios'

export interface IntegrationState extends ApiState {
  getGoogleLogin: (userId: string) => string
  getMSLogin: (userId: string) => string
  storeCode: (code: string, state: string, provider: string) => Promise<AxiosResponse>
  getIntegrationToken: () => Promise<AxiosResponse>
  createConnection: (integrationId: string, calendar: string, startDate: string, group: string, direction: number) => Promise<AxiosResponse>
  deleteIntegration: (provider: string, integrationId: string) => Promise<AxiosResponse>
  deleteConnection: (integrationId: string, connectionId: string) => Promise<AxiosResponse>
}

export const createIntegrationSlice: StateCreator<
  IntegrationState,
  [['zustand/immer', never]]
> = (_set, get) => ({
  getGoogleLogin: (userId) => {
    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const state = userId
    const APP_URL = window.location.origin
    const provider = 'google'
    const scopes = 'https://www.googleapis.com/auth/calendar.readonly%20https://www.googleapis.com/auth/calendar.events%20https://www.googleapis.com/auth/userinfo.profile'
    const args_str = `response_type=code&prompt=consent&client_id=${client_id}&scope=${scopes}&state=${state}&access_type=offline&redirect_uri=${APP_URL}/callback/${provider}`
    const auth_url = `${process.env.REACT_APP_GOOGLE_AUTH_URL}?${args_str}`
    return auth_url 
  },
  getMSLogin: (userId) => {
    const client_id = 'e2ff516b-68c7-4380-afee-fc437c5243cb'
    // const key = 'f9ce0941-2ff0-4f1a-a6a2-51f191e29106'
    const scope = 'Calendars.ReadWrite.Shared offline_access User.Read Contacts.Read'
    const state = userId
    const APP_URL = window.location.origin
    const redirect_uri = APP_URL + '/callback/azure'
    const args = `response_type=code&client_id=${client_id}&scope=${scope}&state=${state}&redirect_uri=${redirect_uri}&prompt=login`
    const auth_url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${args}`
    return auth_url
  },
  storeCode: async (code, state, provider) => {
    const response = await get().api!('POST', `/integration/initialize/${provider}/`, {code, state})
    return response
  },
  getIntegrationToken: async () => {
    const response = await get().api!('GET', `/integration/initialize/`)
    return response
  },
  createConnection: async (integrationId, calendar, startDate, group, direction) => {
    const response = await get().api!('POST', `/integration/connect/${integrationId}/`, {
      calendar,
      startDate,
      group,
      direction
    })
    return response
  },
  deleteIntegration: async (provider, integrationId) => {
    const response = await get().api!('DELETE', `integration/initialize/${provider}/${integrationId}/`)
    return response
  },
  deleteConnection: async (integrationId, connectionId) => {
    const response = await get().api!('DELETE', `integration/connect/${integrationId}/${connectionId}/`)
    return response
  }
})
