import { Droppable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import { Dispatch, SetStateAction } from 'react'
import { TaskItem } from 'types/Tasks'
import Task from './Task'
import styles from './TasksList.module.scss'

type PanelWrapperProps = {
  id: string
  tasks: TaskItem[]
  isExpanded?: boolean
  individualToggledTaskIDs?: string[]
  setIndividualToggledTaskIDs?: Dispatch<SetStateAction<string[]>>
}

const getIsExpanded = (
  taskId: string,
  isExpanded?: boolean,
  individualToggledTaskIDs?: string[],
) => {
  const isToogled = individualToggledTaskIDs?.includes(taskId)
  return isToogled ? !isExpanded : isExpanded
}

const CustomPanel = ({
  isExpanded,
  tasks,
  id,
  individualToggledTaskIDs,
  setIndividualToggledTaskIDs,
}: PanelWrapperProps) => {
  const handleToogle = (taskId: string) => {
    if (!individualToggledTaskIDs) {
      return
    }
    if (!setIndividualToggledTaskIDs) {
      return
    }
    if (individualToggledTaskIDs.includes(taskId)) {
      setIndividualToggledTaskIDs(
        individualToggledTaskIDs.filter((id) => id !== taskId),
      )
    } else {
      setIndividualToggledTaskIDs([...individualToggledTaskIDs, taskId])
    }
  }
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => {
        const isDraggingOver = snapshot.isDraggingOver
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ cursor: 'pointer' }}
            className={isDraggingOver ? styles.listPanelDraggingOver : ''}
          >
            {tasks.map((item: TaskItem, index: number) => {
              return (
                <Task
                  key={item.id}
                  data={item}
                  index={index}
                  isExpanded={getIsExpanded(
                    item.id,
                    isExpanded,
                    individualToggledTaskIDs,
                  )}
                  onToggle={handleToogle}
                />
              )
            })}
            {provided.placeholder}
          </div>
        )
      }}
    </Droppable>
  )
}

export default CustomPanel
