import { Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { TaskItem } from 'types/Tasks'
import { isTaskDone, isTaskDueToday, isTaskOverdue } from 'utils/taskUtils'

const { Text } = Typography

type DelegatedTaskHeaderSummaryProps = {
  tasks?: TaskItem[]
}

const DelegatedTaskHeaderSummary = ({
  tasks,
}: DelegatedTaskHeaderSummaryProps) => {
  const { t } = useTranslation()
  const style = { marginLeft: 8 }
  const count = tasks?.length || 0
  const done_tasks = tasks?.filter(isTaskDone)?.length || 0
  const due_tasks = tasks?.filter(isTaskDueToday)?.length || 0
  const overdue_tasks = tasks?.filter(isTaskOverdue)?.length || 0

  return (
    <div>
      <Tooltip title={t('my-today-page.completed-tasks')}>
        <span>
          {done_tasks} / {count}
        </span>
      </Tooltip>
      <Tooltip title={t('my-today-page.due-today')}>
        <Text type="warning" style={style}>
          {due_tasks}
        </Text>
      </Tooltip>
      <Tooltip title={t('my-today-page.overdue')}>
        <Text type="danger" style={style}>
          {overdue_tasks}
        </Text>
      </Tooltip>
    </div>
  )
}

export default DelegatedTaskHeaderSummary
