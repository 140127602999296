import { Button, Col, Space } from 'antd'
import { CustomFieldDataFormat } from 'types/CustomFields'
import EditCustomFieldsHeader from './EditCustomFieldsHeader'
import EditCustomFieldsRow from './EditCustomFieldsRow'

type EditCustomFieldsProps = {
  add: () => void
  remove: (id: number | string) => void
  edit: (id: number | string, value: CustomFieldDataFormat) => void
  fields?: CustomFieldDataFormat[]
  isValidating: boolean
}

const EditCustomFields = ({
  add,
  remove,
  edit,
  fields,
  isValidating,
}: EditCustomFieldsProps) => {
  return (
    <>
      <EditCustomFieldsHeader />

      {fields?.map((field) => (
        <EditCustomFieldsRow
          key={field.id}
          id={field.id}
          remove={remove}
          edit={edit}
          data={field}
          isValidating={isValidating}
        />
      ))}

      <Col style={{ marginTop: 8, paddingLeft: 16 }}>
        <Space>
          <Button block onClick={add}>
            +
          </Button>
        </Space>
      </Col>
    </>
  )
}

export default EditCustomFields
