import { Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './CustomFields.module.scss'
import CustomFieldsCellComponent from './CustomFieldsCellComponent'

const { Title } = Typography

const CustomFieldsValuesHeader = () => {
  const { t } = useTranslation()
  return (
    <Row className={styles.rowGrid} gutter={[24, 24]}>
      <CustomFieldsCellComponent span={6}>
        <Title level={5}>{t('custom-field.field-header')}</Title>
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={18}>
        <Title level={5}>{t('custom-field.value-header')}</Title>
      </CustomFieldsCellComponent>
    </Row>
  )
}

export default CustomFieldsValuesHeader
