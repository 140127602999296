import React, { ReactNode } from 'react'
import { Badge } from '@mui/material'

type RippleBadgeProps = {
  invisible: boolean
  color?: string
  children?: ReactNode
}

const RippleBadge = ({
  color = 'var(--audio-task-color)',
  children,
  invisible,
}: RippleBadgeProps) => {
  return (
    <Badge
      invisible={invisible}
      variant="dot"
      sx={{
        '& .MuiBadge-badge': {
          color: color,
          backgroundColor: color,
        },
        '&::after':
          invisible === false
            ? {
                position: 'absolute',
                top: -4,
                right: -4,
                width: 8,
                height: 8,
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: `1px solid ${color}`,
                content: '""',
              }
            : {},
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }}
    >
      {children}
    </Badge>
  )
}

export default RippleBadge
