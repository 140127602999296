import { Col, Flex } from 'antd'
import { PropsWithChildren } from 'react'

type CellComponentProps = {
  span: number
  align?: React.CSSProperties['alignItems']
  justify?: React.CSSProperties['justifyContent']
}

const CellComponent = ({
  children,
  span,
  align,
  justify,
}: PropsWithChildren<CellComponentProps>) => {
  return (
    <Col span={span}>
      <Flex
        style={{ width: '100%', height: 50 }}
        justify={justify || 'center'}
        align={align || 'center'}
      >
        {children}
      </Flex>
    </Col>
  )
}
export default CellComponent
