export enum EntryRequired {
  PER_TASK = 1,
  PER_USER = 2,
}

export enum CustomFieldDataType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  LIST = 'list',
}

export type CustomFieldDataFormat = {
  id: string
  label: string
  description: string
  type: string
  maxChar: number
  options: string
  isRequired: boolean
  isEditable: boolean
}

export type CustomField = {
  id: number
  task: string
  user: string
  dataEntryRequired: EntryRequired
  allowDelegatedToEdit: boolean
  customField: CustomFieldDataFormat[]
}

export type CustomFieldValueDataFormat = {
  [key: string]: string | number
}

export type CustomFieldValue = {
  id: number
  customField: number
  routineTask?: string
  user?: string
  customValue: CustomFieldValueDataFormat
}

export type TemplateCustomField = {
  customFieldId: number
  title: string
  customField: CustomFieldDataFormat[]
}
