import { useState } from 'react'
import { Tag } from 'antd'
import dayjs from 'dayjs'

const { CheckableTag } = Tag

type DayOfWeekProps = {
  value?: number[]
  onChange?: (nextValue: number[]) => void
}

const mapDayJsWithRrule = [1, 2, 3, 4, 5, 6, 0]

const DayOfWeek = ({ value, onChange }: DayOfWeekProps) => {
  const [daysOfWeek] = useState(
    Array.from({ length: 7 }, (_, i) =>
      dayjs().day(mapDayJsWithRrule[i]).format('ddd'),
    ),
  )

  return (
    <>
      {daysOfWeek.map((label, index) => {
        const isChecked = (value || []).includes(index)

        return (
          <CheckableTag
            key={label}
            checked={isChecked}
            onClick={() =>
              onChange?.(
                isChecked
                  ? (value || []).filter((item) => item !== index)
                  : [...(value || []), index],
              )
            }
          >
            {label}
          </CheckableTag>
        )
      })}
    </>
  )
}

export default DayOfWeek
