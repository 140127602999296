import { CheckOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconContainer from '../IconContainer'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'

const { Paragraph } = Typography

export type CardTitleProps = {
  isCompleted: boolean
  isRunning: boolean
  iconColor: string
  iconClassName: string
  title: string
  onDoubleClick: MouseEventHandler<HTMLElement>
}

const CardTitle = (props: CardTitleProps) => {
  const { t } = useTranslation()
  const {
    isCompleted,
    isRunning,
    iconColor,
    iconClassName,
    title,
    onDoubleClick,
  } = props
  const [ellipsis, setEllipsis] = useState(true)

  return (
    <Space>
      {isCompleted && (
        <IconContainer>
          <Tooltip title={t('task-node.completed')}>
            <CheckOutlined
              twoToneColor={iconColor}
              className={iconClassName}
              style={{ fontSize: 32 }}
            />
          </Tooltip>
        </IconContainer>
      )}
      {isRunning && !isCompleted && (
        <IconContainer>
          <Tooltip title={t('task-node.in-progress')}>
            <DirectionsRunIcon
              className={iconClassName}
              style={{ fontSize: 32 }}
            />
          </Tooltip>
        </IconContainer>
      )}
      <Paragraph
        delete={isCompleted}
        onClick={() => setEllipsis(!ellipsis)}
        onDoubleClick={onDoubleClick}
        ellipsis={ellipsis}
        style={{ margin: 0, width: 200, textAlign: 'center' }}
      >
        {title}
      </Paragraph>
    </Space>
  )
}

export default CardTitle
