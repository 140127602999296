import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import Dropdown from 'components/Dropdown'
import { useTranslation } from 'react-i18next'
import { Button, Menu } from 'antd'
import {
  CalendarTwoTone,
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import Calendar from 'components/InlineCalendar'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'

type DueDateDropdownProps = {
  value?: Date
  onChange?: (selection?: dayjs.Dayjs) => void
  outlined?: boolean
  iconClassName?: string
  iconStyles?: React.CSSProperties
  tooltip?: string
  allowClear?: boolean
  isReadonly?: boolean
  disabled?: boolean
}

export const DueDateDropdown = ({
  value,
  onChange,
  outlined,
  iconClassName,
  iconStyles,
  tooltip,
  allowClear = false,
  isReadonly,
  disabled,
}: DueDateDropdownProps) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const [shouldOpenCalendar, setCalendarOpen] = useState(false)
  const submitAndClose = (nextValue: Dayjs) => () => {
    onChange?.(nextValue)
    setCalendarOpen(false)
    setOpen(false)
  }
  const removeAndClose = () => {
    onChange?.(undefined)
    setCalendarOpen(false)
    setOpen(false)
  }

  let menuItems: ItemType<MenuItemType>[] = [
    {
      key: 'today',
      icon: <CalendarTwoTone twoToneColor="#4f4" />,
      onClick: submitAndClose(dayjs()),
      label: t('new-task-form.due-date.today'),
    },
    {
      key: 'tomorrow',
      icon: <CalendarTwoTone />,
      onClick: submitAndClose(dayjs().add(1, 'day')),
      label: t('new-task-form.due-date.tomorrow'),
    },
    {
      key: 'next-week',
      icon: <CalendarTwoTone twoToneColor="#F44" />,
      onClick: submitAndClose(dayjs().add(1, 'week')),
      label: t('new-task-form.due-date.next-week'),
    },
    {
      key: 'pick-a-date',
      icon: <CalendarOutlined />,
      onClick: () => setCalendarOpen(true),
      label: t('new-task-form.due-date.pick-a-date'),
    },
  ]

  if (value || allowClear) {
    menuItems = [
      ...menuItems,
      {
        danger: true,
        key: 'remove-selection',
        icon: <DeleteOutlined />,
        onClick: removeAndClose,
        label: t('new-task-form.due-date.remove-selection'),
      },
    ]
  }

  if (isReadonly) {
    return (
      <Button
        icon={
          <ExclamationCircleOutlined
            className={iconClassName}
            style={iconStyles}
          />
        }
        title={tooltip || t('new-task-form.due-date.title')!}
        type="text"
      />
    )
  }

  return (
    <Dropdown
      isOpen={isOpen}
      outlined={outlined}
      icon={
        <ExclamationCircleOutlined
          className={iconClassName}
          style={iconStyles}
        />
      }
      title={tooltip || t('new-task-form.due-date.title')!}
      onChange={(nextValue) => setOpen(nextValue)}
      label={value ? dayjs(value).format('ddd DD') : ''}
      isReadonly={disabled}
    >
      {shouldOpenCalendar ? (
        <Calendar onSubmit={(nextValue) => submitAndClose(nextValue)()} />
      ) : (
        <Menu
          mode="vertical"
          style={{ width: 250, borderInlineEnd: 'none' }}
          items={menuItems}
        />
      )}
    </Dropdown>
  )
}

export default DueDateDropdown
