import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row, Col, App, message, Tabs, Badge } from 'antd'
import useStore from 'hooks/useStore'
import {
  DragDropContext,
  DropResult,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import TasksList from './TasksList'
import styles from './Home.module.scss'
import PriorityPanel from './PriorityPanel/PriorityPanel'
import {
  sanitizeDestinationId,
  getFilteredTasksByLevels,
  getNewOrderCode,
  getTaskFromTaskList,
  categorizeTaskForMyToday,
} from 'utils/taskUtils'
import Calendar from './Calendar/Calendar'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { UserOutlined, NotificationOutlined } from '@ant-design/icons'
import DelegatedTaskList from './DelegatedTaskList'
import { TaskItem } from 'types/Tasks'
import useTimeTrigger from 'hooks/useTimeTrigger'
import dayjs from 'dayjs'
import { TaskLists } from 'services/Tasks.slice'
import { isAxiosError } from 'axios'
import { WebSocketContext, WebSocketContextType } from 'utils/WebSocketProvider'

const Home = () => {
  const selectedGroups = useStore((state) => state.selectedGroups)
  const setAssignedTasks = useStore((state) => state.setAssignedTasks)
  const isNormalizing = useStore((state) => state.isNormalizing)
  const lastExecutionAssignedTasks = useStore(
    (state) => state.lastExecutionAssignedTasks,
  )
  const lastNormalizationDate = useStore((state) => state.lastNormalizationDate)
  const routineTimes = useStore((state) => state.routineTimes)
  const getUserConfig = useStore((state) => state.getUserConfig)
  const getAvatar = useStore((state) => state.getAvatar)
  const [messageApi, contextHolder] = message.useMessage()
  const moveTaskPositionInMyToday = useStore(
    (state) => state.moveTaskPositionInMyToday,
  )
  const setCurrentGroup = useStore((state) => state.setCurrentGroup)
  const getTasksPriorityGroups = useStore(
    (state) => state.getTasksPriorityGroupGlobal,
  )
  const getTasksStatus = useStore((state) => state.getTasksStatus)
  const getUserGroups = useStore((state) => state.getUserGroups)
  const getNotifications = useStore((state) => state.getNotifications)
  const setInitialTimeZone = useStore((state) => state.setInitialTimeZone)
  const updateTask = useStore((state) => state.updateTask)
  const groups = useStore((state) => state.tasks?.groups || [])
  const todayTaskPriorityGroupId: number = 0
  const user = useStore((state) => state.user)
  const userId = useStore((state) => state.userId)
  const setTaskLevels = useStore((state) => state.setTaskLevels)
  const [delegatedTasks, setDelegatedTasks] = useState<TaskItem[]>([])
  const [issueTasks, setIssueTasks] = useState<TaskItem[]>([])
  const [pendingTasks, setPendingTasks] = useState<TaskItem[]>([])
  const getAllTasks = useStore((state) => state.getAllTasks)
  const { shouldUpdateTasks, setShouldUpdateTasks } = useStore((state) => state)
  const getAllContacts = useStore((state) => state.getAllContacts)
  const dismissTask = useStore((state) => state.dismissTask)
  const getPendingTasks = useStore((state) => state.getPendingTasks)
  const { lastMessage } = useContext(WebSocketContext) as WebSocketContextType

  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const selectedTaskLevels = useStore((state) => state.selectedTaskLevels)
  const includeTasksAssignedToMe = useStore(
    (state) => state.includeTasksAssignedToMe,
  )
  const hasPageBeenRendered = useRef({
    firstLoad: false,
    normalization: false,
    filters: false,
  })

  const app = App.useApp()
  const { t } = useTranslation()

  const tasks = useStore((state) => state.tasks?.list || {})
  const [tasksFiltered, setTasksFiltered] = useState<TaskLists>({})

  const [showIssues, setShowIssues] = useState(false)

  const fetchPending = useCallback(() => {
    getPendingTasks().then((tasks) => {
      const validTasks = tasks.filter(
        (t) => t.pendingAssignees[0].status !== 'Rejected',
      )
      setPendingTasks(validTasks as TaskItem[])
    })
  }, [getPendingTasks, setPendingTasks])

  useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage?.data)

      if (message.data && message.data.template_name === 'delegated') {
        fetchPending()
      }
    }
  }, [lastMessage, fetchPending])

  useEffect(() => {
    const integrationUsers =
      process.env.REACT_APP_INTEGRATION_USERS?.split(',') || []
    user && setShowIssues(integrationUsers.includes(user.data.email) || false)
  }, [user, setShowIssues])

  useTimeTrigger({
    dateTimeToCompare: dayjs(lastExecutionAssignedTasks),
    orderedTimeTriggerList: [
      routineTimes.beginning as string,
      routineTimes.middle as string,
      routineTimes.end as string,
    ],
    onTriggerEvent: () => {
      setAssignedTasks(selectedGroups, includeTasksAssignedToMe)
    },
  })

  useEffect(() => {
    getTasksStatus()
    getNotifications()
    setInitialTimeZone()
    getAllContacts()
    Promise.all([getUserConfig(), getUserGroups()])
      .then((results) => {
        // Do not execute if normalizing or if normalization is going to be executed
        if (!isNormalizing && lastNormalizationDate) {
          setAssignedTasks(
            groups.length ? selectedGroups : undefined,
            includeTasksAssignedToMe,
          )
        }
        hasPageBeenRendered.current.firstLoad = true
      })
      .catch((error) => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTasksStatus,
    getNotifications,
    getUserGroups,
    getUserConfig,
    setInitialTimeZone,
  ])

  useEffect(() => {
    if (userId) {
      getAvatar(userId)
    }
  }, [userId, getAvatar])

  useEffect(() => {
    setTaskLevels()
    getTasksPriorityGroups()
  }, [setTaskLevels, getTasksPriorityGroups])

  useEffect(() => {
    setActiveKeys([todayTaskPriorityGroupId.toString()])
  }, [setActiveKeys, todayTaskPriorityGroupId])

  useEffect(() => {
    if (groups.length) {
      setCurrentGroup(groups[0]?.id)
    }
  }, [groups, setCurrentGroup])

  useEffect(() => {
    const filtered = getFilteredTasksByLevels(tasks, selectedTaskLevels)
    setTasksFiltered(filtered)
  }, [selectedTaskLevels, tasks, user])

  useEffect(() => {
    if (shouldUpdateTasks) {
      setAssignedTasks(selectedGroups, includeTasksAssignedToMe)
    }
  }, [
    shouldUpdateTasks,
    setAssignedTasks,
    selectedGroups,
    includeTasksAssignedToMe,
  ])
  useEffect(() => {
    if (isNormalizing) {
      messageApi.open({
        type: 'loading',
        content: t('my-today.sync-data', {
          ns: 'validation',
        }),
        duration: 12,
      })
    } else {
      messageApi.destroy()
      if (hasPageBeenRendered.current.normalization) {
        setAssignedTasks(
          groups.length ? selectedGroups : undefined,
          includeTasksAssignedToMe,
        )
      }
    }
    hasPageBeenRendered.current.normalization = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNormalizing])

  useEffect(() => {
    if (
      hasPageBeenRendered.current.firstLoad &&
      hasPageBeenRendered.current.filters &&
      groups.length
    ) {
      setAssignedTasks(selectedGroups, includeTasksAssignedToMe)
    }
    hasPageBeenRendered.current.filters = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    routineTimes.beginning,
    routineTimes.middle,
    routineTimes.end,
    selectedGroups.length,
    includeTasksAssignedToMe,
  ])

  useEffect(() => {
    if (user && selectedGroups.length > 0) {
      getAllTasks({
        groups: selectedGroups,
        type: 'delegated',
      }).then((tasks) => {
        setDelegatedTasks(tasks as TaskItem[])
      })
    }
  }, [user, selectedGroups, getAllTasks])

  useEffect(() => {
    fetchPending()
  }, [fetchPending])

  useEffect(() => {
    if (selectedGroups.length > 0 && showIssues) {
      getAllTasks({
        groups: selectedGroups,
        priorityGroups: [8],
      }).then((tasks) => {
        setIssueTasks(tasks as TaskItem[])
      })
    }
  }, [selectedGroups, getAllTasks, showIssues])

  useEffect(() => {
    if (shouldUpdateTasks) {
      getAllTasks({
        groups: selectedGroups,
        type: 'delegated',
      }).then((tasks) => {
        setShouldUpdateTasks(false)
        setDelegatedTasks(tasks as TaskItem[])
      })
      fetchPending()
      if (showIssues) {
        getAllTasks({
          groups: selectedGroups,
          priorityGroups: [8],
        }).then((tasks) => {
          setIssueTasks(tasks as TaskItem[])
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateTasks, showIssues, fetchPending])

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId: taskId } = result
    const sourceDroppableId = parseInt(source.droppableId)
    const destinationDroppableId = sanitizeDestinationId(
      destination?.droppableId || '',
    )

    const draggableIds = tasks?.[sourceDroppableId].map((task) => task.id)
    const isSourceAndDestinationSame =
      sourceDroppableId === destinationDroppableId

    const currentTask = getTaskFromTaskList(taskId, tasks)

    if (!currentTask) {
      return
    }

    if (!destination) {
      return
    }

    if (isSourceAndDestinationSame && source.index === destination.index) {
      return
    }

    if (!draggableIds.includes(taskId)) {
      return
    }

    if (!isSourceAndDestinationSame) {
      const { reasonIsPinnedToToday } = categorizeTaskForMyToday(currentTask)
      if (reasonIsPinnedToToday) {
        app.notification.warning({
          message: t('my-today.pinned.' + reasonIsPinnedToToday, {
            ns: 'validation',
          }),
        })
        return
      }
    }

    const code = getNewOrderCode(
      tasksFiltered,
      destinationDroppableId,
      destination.index,
      sourceDroppableId,
      source.index,
    )

    if (!code) {
      return
    }

    moveTaskPositionInMyToday(currentTask, destinationDroppableId, code)

    if (!activeKeys.includes(destinationDroppableId.toString())) {
      setActiveKeys([...activeKeys, destinationDroppableId.toString()])
    }

    updateTask(
      {
        taskId,
        data: {
          priorityGroup: destinationDroppableId,
          taskPosition: 'custom',
          customSortCode: code,
        },
      },
      user!.data.id,
    ).catch((error) => {
      if (isAxiosError(error)) {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
        setShouldUpdateTasks(true)
      }
    })
  }

  const onDragUpdate = (update: { destination: any }) => {
    const destination = update.destination
    if (destination) {
    }
  }

  const handleDismissTask = (task: TaskItem) => {
    dismissTask(task.id)
      .then(() => {
        setDelegatedTasks(delegatedTasks.filter((dt) => dt.id !== task.id))
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          const message =
            error.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
        }
      })
  }

  const items = [
    {
      key: 'myTasks',
      label: (
        <span>
          <span role="img" aria-label="user" className="anticon anticon-user">
            <Sun height="16px" width="16px" style={{ marginRight: 8 }} />
            {'  '}
            {t('my-today-page.my-tasks-tab-title')}
          </span>
        </span>
      ),
      children: (
        <TasksList
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          tasks={tasksFiltered}
        />
      ),
      closable: false,
    },
    {
      key: 'pendingTasks',
      label: (
        <span>
          <Badge
            className="ant-menu-item-icon"
            dot={pendingTasks.length > 0}
            style={{ marginRight: 8 }}
          >
            <NotificationOutlined height="18px" style={{ marginRight: 8 }} />
          </Badge>
          {t('my-today-page.pending-tab-title')}
        </span>
      ),
      children: <DelegatedTaskList tasks={pendingTasks} pending={true} />,
      closable: false,
    },
    {
      key: 'delegatedTasks',
      label: (
        <span>
          <UserOutlined height="18px" style={{ marginRight: 8 }} />
          {t('my-today-page.delegated-tab-title')}
        </span>
      ),
      children: (
        <DelegatedTaskList
          tasks={delegatedTasks}
          onDismiss={handleDismissTask}
        />
      ),
      closable: false,
    },
  ]

  if (showIssues) {
    items.push({
      key: 'issues',
      label: (
        <span>
          <NotificationOutlined height="18px" style={{ marginRight: 8 }} />
          {t('my-today-page.issues-tab-title')}
        </span>
      ),
      children: <DelegatedTaskList tasks={issueTasks} />,
      closable: false,
    })
  }

  const onTabClick = (key: string) => {
    if (key === 'delegatedTasks') {
      getAllTasks({
        groups: selectedGroups,
        type: 'delegated',
      }).then((tasks) => {
        setShouldUpdateTasks(false)
        setDelegatedTasks(tasks as TaskItem[])
      })
    }
  }

  return (
    <div className={styles.home}>
      {contextHolder}
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <Row gutter={12}>
          <Col className={styles.tasksFiltersColumn} xs={24} lg={{ span: 15 }}>
            <Tabs
              defaultActiveKey="1"
              items={items}
              type="editable-card"
              hideAdd={true}
              onTabClick={onTabClick}
            />
          </Col>
          <Col xs={0} lg={2}>
            <section className={styles.dragDropSection}>
              <PriorityPanel tasks={tasksFiltered} />
            </section>
          </Col>
          <Col xs={24} lg={7} className={styles.calendarContainer}>
            <div>
              <Calendar selectedGroups={selectedGroups} />
            </div>
          </Col>
        </Row>
      </DragDropContext>
    </div>
  )
}

export default Home
