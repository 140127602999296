import { App, Button, Col, Input, Row, Space, Spin, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Task } from 'types/Tasks'
import SelectTag from '../DelegationDrawer/SelectTag'
import SearchUser, { UserSearch } from './SearchUser'
import { useTaskEditor } from '../useTaskEditor'
import { TaskFields } from 'utils/permissions'

const { Text, Title } = Typography

type TaskProjectManagerProps = {
  taskId: string
  refreshMembersList: () => void
}

const TaskProjectManager = ({
  taskId,
  refreshMembersList,
}: TaskProjectManagerProps) => {
  const { t } = useTranslation()
  const app = App.useApp()
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSubmitingForm, setIsSubmitingForm] = useState<boolean>(false)
  const [currentTask, setCurrentTask] = useState<Task | undefined>(undefined)
  const { getTaskById, updateTask } = useStore((state) => state)
  const user = useStore((state) => state.user?.data)
  const [selectedUser, setSelectedUser] = useState<UserSearch | undefined>(
    undefined,
  )
  const { hasUpdatePermissionOnTask } = useTaskEditor()
  const hasPermissionToChangeManager = useMemo(
    () => hasUpdatePermissionOnTask(TaskFields.TASK_MANAGER),
    [hasUpdatePermissionOnTask],
  )

  const fetchTask = useCallback(() => {
    if (taskId) {
      setLoading(true)
      getTaskById(taskId)
        .then((task) => {
          setCurrentTask(task)
          setIsEditing(false)
        })
        .catch((error) => {
          const message =
            error.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [app.notification, getTaskById, taskId])

  useEffect(() => {
    fetchTask()
  }, [fetchTask])

  const validateAndSubmit = () => {
    setIsSubmitingForm(true)
    if (!taskId || !selectedUser?.id) {
      return
    }

    setLoading(true)
    updateTask(
      { taskId: taskId, data: { manager: selectedUser?.id } },
      user!.id,
    )
      .then((roles) => {
        fetchTask()
        refreshMembersList()
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
      .finally(() => {
        setLoading(false)
      })

    setIsSubmitingForm(false)
  }

  const handleEditTaskManager = () => {
    if (currentTask?.manager) {
      setSelectedUser({ ...currentTask.manager, email: '' })
    } else {
      setSelectedUser(undefined)
    }
    setIsEditing(true)
  }

  const renderSearchView = () => (
    <Row gutter={16} style={{ marginBottom: 16 }}>
      <Col span={12}>
        <SearchUser selectedUserChanged={(user) => setSelectedUser(user)} />
      </Col>
      <Col span={12}>
        <Row>
          <Col span={24} style={{ paddingBottom: 8 }}>
            <Title level={5}>{t('task-members.project-manager')}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ paddingBottom: 8 }}>
            <Space direction="horizontal">
              <Text>{t('task-members.user')}</Text>
              <Input
                type="text"
                style={{ width: 200 }}
                readOnly={true}
                value={selectedUser?.name}
                status={
                  isSubmitingForm && !selectedUser?.id ? 'error' : undefined
                }
              />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="horizontal">
              <Button block onClick={() => setIsEditing(!isEditing)}>
                {t('settings.user-form.cancel')}
              </Button>
              <Button type="primary" block onClick={validateAndSubmit}>
                {t('task-members.assign-project-manager')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <Spin spinning={loading} size="large">
      <Row>
        <Col span={24}>
          <Space
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '8px',
            }}
          >
            <Title level={4}>{t('task-members.project-manager')}</Title>
          </Space>
        </Col>
      </Row>
      {isEditing ? (
        renderSearchView()
      ) : (
        <Row gutter={[16, 16]} align="bottom">
          <Col flex={'none'}>
            <SelectTag
              label={currentTask?.manager?.name}
              border
              image={currentTask?.manager?.avatar}
            />
          </Col>
          <Col flex={'auto'}>
            <Button
              type="primary"
              onClick={handleEditTaskManager}
              disabled={!hasPermissionToChangeManager}
            >
              {t('settings.user-form.edit')}
            </Button>
          </Col>
        </Row>
      )}
    </Spin>
  )
}

export default TaskProjectManager
