import { useEffect, useState } from 'react'
import {
  Modal,
  Input,
  Form,
  Button,
  Space,
  Row,
  Col,
  Select,
  App,
  Collapse,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import useStore from 'hooks/useStore'
import { getCountries } from 'utils/IntlUtlis'
import {
  Contact,
  ContactAddress,
  ContactCompany,
  ContactEmail,
  ContactPhone,
  ContactWeb,
} from 'types/Contacts'

const { TextArea } = Input

type AddContactModalProps = {
  isOpen: boolean
  onCancel: () => void
}

type Options = {
  label: string
  value: number
  key: number
}

enum ValidateStatus {
  DEFAULT = '',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  VALIDATING = 'validating',
}

const AddContactModal = ({ isOpen, onCancel }: AddContactModalProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const { t } = useTranslation()
  const app = App.useApp()

  const [status, setStatus] = useState<ValidateStatus>()
  const [companies, setCompanies] = useState<Options[]>([])

  const createContact = useStore((state) => state.createContact)
  const getCompanies = useStore((state) => state.getCompanies)

  const countries = getCountries()

  const [form] = Form.useForm()

  useEffect(() => {
    getCompanies().then((data) => {
      const companylist = data.map((company) => {
        return { value: company.id, label: company.name, key: company.id }
      })
      setCompanies(companylist)
    })
  }, [getCompanies, form])

  const onFinish = (values: Contact) => {
    const {
      id,
      firstName,
      lastName,
      contactCompany,
      contactPhone = {},
      contactAddress = {},
      contactEmail = {},
      contactWeb = {},
    } = values

    const data: Contact = {
      id,
      firstName,
      lastName,
    }

    const contactCompanyValues = Object.values(
      contactCompany as ContactCompany,
    ).filter(Boolean)

    const contactAddressValues = Object.values(
      contactAddress as ContactAddress[],
    ).filter(Boolean)

    const contactPhoneValues = Object.values(
      contactPhone as ContactPhone[],
    ).filter(Boolean)

    const contactEmailValues = Object.values(
      contactEmail as ContactEmail[],
    ).filter(Boolean)

    const contactWebValues = Object.values(contactWeb as ContactWeb[]).filter(
      Boolean,
    )

    if (contactCompanyValues.length !== 0) {
      data.contactCompany = contactCompany
    }

    if (contactAddressValues.length !== 0) {
      data.contactAddress = [contactAddress] as unknown as ContactAddress[]
    }

    if (contactPhoneValues.length !== 0) {
      data.contactPhone = [contactPhone] as unknown as ContactPhone[]
    }

    if (contactEmailValues.length !== 0) {
      data.contactEmail = [contactEmail] as unknown as ContactEmail[]
    }

    if (contactWebValues.length !== 0) {
      data.contactWeb = [contactWeb] as unknown as ContactWeb[]
    }

    if (data.contactEmail) {
      data.contactEmail[0].email = data.contactEmail[0].email.toLowerCase()
    }

    setStatus(ValidateStatus.VALIDATING)

    createContact(data)
      .then(() => {
        setStatus(ValidateStatus.SUCCESS)
        form.resetFields()
        app.notification.success({
          message: t('add-contact-modal.add-contact-success'),
        })
      })
      .catch((error) => {
        setStatus(ValidateStatus.ERROR)
        const message = error?.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const onFinishFailed = () => {
    setStatus(ValidateStatus.ERROR)
  }

  return (
    <Modal
      zIndex={1010}
      title={t('add-contact-modal.add-contact')}
      open={isOpen}
      onCancel={onCancel}
      centered
      width={isTabletOrMobile ? '60%' : '40%'}
      footer={null}
    >
      <Form
        name="add-company"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('add-contact-modal.first-name')}
              name="firstName"
              hasFeedback
              validateStatus={status}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('add-contact-modal.last-name')}
              name="lastName"
              hasFeedback
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Contact Company">
              <Col span={24}>
                <Form.Item
                  required
                  name={['contactCompany', 'company']}
                  label="Company"
                >
                  <Select options={companies} placeholder="Select A Company" />
                </Form.Item>
              </Col>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={['contactCompany', 'jobTitle']}
                    label="Job Title"
                  >
                    <Input placeholder="Job title" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['contactCompany', 'department']}
                    label="Department"
                  >
                    <Input placeholder="Department" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Collapse
              ghost
              bordered={false}
              items={[
                {
                  key: 'address',
                  label: 'Contact Address',
                  children: (
                    <Form.Item>
                      <Form.Item
                        label={'Title'}
                        name={['contactAddress', 'title']}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t('add-contact-modal.address1')}
                        name={['contactAddress', 'address1']}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t('add-contact-modal.address2')}
                        name={['contactAddress', 'address2']}
                      >
                        <Input />
                      </Form.Item>
                      <Row gutter={12}>
                        <Col span={12}>
                          <Form.Item
                            label={t('add-contact-modal.city')}
                            name={['contactAddress', 'city']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            label={t('add-contact-modal.zip')}
                            name={['contactAddress', 'zip']}
                            rules={[
                              {
                                max: 12,
                                message:
                                  'Value should be less than 12 character',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label={t('add-contact-modal.state')}
                            name={['contactAddress', 'state']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label={t('add-contact-modal.country')}
                        name={['contactAddress', 'country']}
                      >
                        <Select options={countries} />
                      </Form.Item>
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Collapse
              ghost
              bordered={false}
              items={[
                {
                  key: 'phone',
                  label: 'Contact Phone',
                  children: (
                    <Form.Item>
                      <Form.Item
                        label={'Title'}
                        name={['contactPhone', 'title']}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t('add-company-modal.phone')}
                        name={['contactPhone', 'phone']}
                      >
                        <Input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          placeholder="XXX-XXX-XXXX"
                        />
                      </Form.Item>
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Col>
          <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Collapse
              ghost
              bordered={false}
              items={[
                {
                  key: 'email',
                  label: 'Contact Email',
                  children: (
                    <Form.Item label={'Contact Email'}>
                      <Form.Item
                        label={'Title'}
                        name={['contactEmail', 'title']}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t('add-company-modal.email')}
                        name={['contactEmail', 'email']}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          style={{ textTransform: 'lowercase' }}
                        />
                      </Form.Item>
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Col>
          <Col
            span={24}
            style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 16 }}
          >
            <Collapse
              ghost
              bordered={false}
              items={[
                {
                  key: 'web',
                  label: 'Contact Web',
                  children: (
                    <Form.Item>
                      <Form.Item label={'Title'} name={['contactWeb', 'title']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Web Link'}
                        name={['contactWeb', 'webLink']}
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={t('add-company-modal.notes')} name="notes">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={12}>
            <Form.Item
              style={{
                alignItems: 'flex-end',
                paddingTop: 16,
                textAlign: 'right',
              }}
            >
              <Space align="baseline">
                <Button onClick={onCancel}>
                  {t('add-contact-modal.close')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: 'var(--completed-color)' }}
                >
                  {t('add-contact-modal.save')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddContactModal
