import { WeekView } from '@devexpress/dx-react-scheduler-material-ui'
import useStore from 'hooks/useStore'
import dayjs from 'dayjs'
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler'

const TimeScaleLabel = ({
  formatDate,
  ...restProps
}: WeekView.TimeScaleLabelProps) => {
  const { timeFormat } = useStore((state) => state)

  const formatTimeScaleDate = (date: SchedulerDateTime | undefined) =>
    dayjs(date).format(timeFormat)
  return (
    <WeekView.TimeScaleLabel {...restProps} formatDate={formatTimeScaleDate} />
  )
}

export default TimeScaleLabel
