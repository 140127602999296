import { Button, Row } from 'antd'
import { colorList } from 'components/AccountGroupFilter/GroupForm'
import CheckboxIconTag from 'components/CheckboxIconTag/CheckboxIconTag'
import useStore from 'hooks/useStore'
import { useEffect, useRef, useState } from 'react'
import { TaskGroup } from 'types/Tasks'
import { isGroupOwner } from 'utils/taskUtils'

type GroupFilterProps = {
  filters?: TaskGroup[]
  selected?: number[]
  onChange?: (id: number) => void
}

const GroupFilter = ({ filters, selected, onChange }: GroupFilterProps) => {
  const { user } = useStore((state) => state)
  const flexContainerRef = useRef<HTMLDivElement>(null)
  const [showHidden, setShowHidden] = useState(false)
  const [hiddenFilters, setHiddenFilters] = useState<TaskGroup[]>([])
  const [displayedFilters, setDisplayedFilters] = useState<TaskGroup[]>([])

  useEffect(() => {
    const calculateTotalWidth = () => {
      const containerWidth = flexContainerRef.current?.offsetWidth || 0
      const buttonWidth = 93 // account for the missing group button
      const hiddenFilters: any = []
      let totalWidth = 0

      const displayedFilters: any = filters?.filter((filter, i) => {
        // Use a temporary element to measure the width
        const tempElement = document.createElement('div')
        tempElement.innerHTML = filter.alternativeTitle || filter.title
        tempElement.style.display = 'inline-block'
        document.body.appendChild(tempElement)

        // Measure the width
        totalWidth += tempElement.offsetWidth + 50 // account for margin and icons
        // Remove the temporary element
        document.body.removeChild(tempElement)

        if (
          (totalWidth >= containerWidth && i === filters.length - 1) ||
          (totalWidth >= containerWidth - buttonWidth &&
            i !== filters.length - 1)
        ) {
          hiddenFilters.push(filter)
          return false
        }
        return true
      })
      setHiddenFilters(hiddenFilters)
      setDisplayedFilters(displayedFilters)
    }

    // Calculate the total width initially and on window resize
    calculateTotalWidth()
    window.addEventListener('resize', calculateTotalWidth)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', calculateTotalWidth)
    }
  }, [filters])

  return (
    <Row
      align="middle"
      gutter={16}
      justify="start"
      style={{ marginLeft: '0px', width: '100%' }}
      ref={flexContainerRef}
    >
      {filters && selected && (
        <>
          {(showHidden ? filters : displayedFilters).map((filter) => (
            <CheckboxIconTag
              key={filter.id}
              id={filter.id}
              label={filter.alternativeTitle || filter.title}
              icon={filter.metadata?.icon}
              color={filter.metadata?.color || colorList[0]}
              outlined={isGroupOwner(filter, user?.data?.id)}
              checked={selected?.includes(filter.id) as boolean}
              onChange={onChange}
            />
          ))}
          {hiddenFilters.length > 0 && (
            <Button onClick={() => setShowHidden(!showHidden)}>
              {showHidden ? 'collapse' : `+ ${hiddenFilters.length} more`}
            </Button>
          )}
        </>
      )}
    </Row>
  )
}

export default GroupFilter
