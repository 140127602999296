import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './NoTaskPlaceholder.module.scss'

const { Title } = Typography

const NoTaskPlaceholder = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <Title level={4}>{t('planning.no-tasks-to-display')}</Title>
    </div>
  )
}

export default NoTaskPlaceholder
