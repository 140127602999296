import { Button, Select, Space } from 'antd'
import useStore from 'hooks/useStore'
import { useTranslation } from 'react-i18next'
import { TaskStatusId } from 'types/Tasks'
import styles from './StatusDropdown.module.scss'
import { useMemo } from 'react'

const { Option } = Select

interface StatusDropdownProps {
  className?: string
  style?: React.CSSProperties
  value?: TaskStatusId
  onChange?: (value: TaskStatusId) => void
  isReadonly?: boolean
}

const statusStyles = {
  [TaskStatusId.NOT_STARTED]: {
    className: styles.optionNotStarted,
    selectorClassName: styles.pickedNotStarted,
  },
  [TaskStatusId.ACTIVE]: {
    className: styles.optionInProgress,
    selectorClassName: styles.pickedInProgress,
  },
  [TaskStatusId.PAUSED]: {
    className: styles.optionInProgressPaused,
    selectorClassName: styles.pickedInProgressPaused,
  },
  [TaskStatusId.DONE]: {
    className: styles.optionCompleted,
    selectorClassName: styles.pickedCompleted,
  },
}

export default function StatusDropdown({
  className = '',
  style = {},
  value = TaskStatusId.NOT_STARTED,
  onChange,
  isReadonly,
}: StatusDropdownProps) {
  const { t } = useTranslation()
  const { statusList } = useStore((state) => state.tasks)
  const items = useMemo(
    () =>
      statusList?.map((status) => ({
        value: status.id,
        label: status.title,
        ...(statusStyles[status.id as TaskStatusId] || {}),
      })) || [],
    [statusList],
  )
  const selectedTitle = useMemo(
    () => statusList?.find((status) => status.id === value)?.title,
    [value, statusList],
  )

  const selectorClass = () => {
    const option = items.find((opt) => opt.value === value)
    return option ? option.selectorClassName : ''
  }

  const handleStatusChange = (value: TaskStatusId) => {
    onChange?.(value)
  }

  if (isReadonly) {
    return (
      <Space>
        <div
          className={`${className} ${styles.statusSelect} ${selectorClass()}`}
        >
          <Button
            style={{
              ...style,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            value={value}
          >
            {selectedTitle}
          </Button>
        </div>
      </Space>
    )
  }

  return (
    <Space>
      <Select
        placeholder={t('my-today-page.select-status')}
        style={style}
        className={`${className} ${styles.statusSelect} ${selectorClass()}`}
        suffixIcon={false}
        value={value}
        onChange={handleStatusChange}
        popupClassName={styles.statusDropdown}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {statusList?.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.title}
          </Option>
        ))}
      </Select>
    </Space>
  )
}
