import moment from 'moment-timezone'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { countryList } from './geoData'
import { UserConfigRoutineTimes } from 'types/Settings'
import { DateArray } from 'ics'

export const getTimeZones = () => {
  const timezones = moment.tz.names()
  return timezones.map((timezone) => ({
    value: timezone,
    label: timezone.replace(/_/g, ' '),
  }))
}

export const getTimeZone = () => {
  dayjs.extend(timezone)
  const userZone = dayjs.tz.guess()
  return { value: userZone, label: userZone.replace(/_/g, ' ') }
}

export const getCountries = () => {
  return countryList
}
export const formatTo12HourFormat = (
  times: Partial<UserConfigRoutineTimes>,
) => {
  const convertedTimes: UserConfigRoutineTimes = {
    beginning: '',
    middle: '',
    end: '',
  }
  for (const [key, value] of Object.entries(times)) {
    if (value === undefined) continue
    let timeValue
    if (typeof value === 'string') {
      timeValue = dayjs(value, 'HH:mm')
    } else if (dayjs.isDayjs(value)) {
      timeValue = value
    } else {
      throw new Error('Invalid time format')
    }

    convertedTimes[key as keyof UserConfigRoutineTimes] =
      timeValue.format('hh:mm a')
  }
  return convertedTimes
}
export const formatTo24HourFormat = (
  times: Partial<UserConfigRoutineTimes>,
) => {
  const convertedTimes: UserConfigRoutineTimes = {
    beginning: '',
    middle: '',
    end: '',
  }
  for (const [key, value] of Object.entries(times)) {
    if (value === undefined) continue
    let timeValue
    if (typeof value === 'string') {
      timeValue = dayjs(value, 'hh:mm a')
    } else if (dayjs.isDayjs(value)) {
      timeValue = value
    } else {
      throw new Error('Invalid time format')
    }
    convertedTimes[key as keyof UserConfigRoutineTimes] =
      timeValue.format('HH:mm')
  }
  return convertedTimes
}

export const getCalendarTime = (utcDate: string): DateArray => {
  const eventYear = dayjs(utcDate).format('YYYY')
  const eventMonth = dayjs(utcDate).format('MM')
  const eventDay = dayjs(utcDate).format('DD')
  const eventHour = dayjs(utcDate).format('HH')
  const eventMinute = dayjs(utcDate).format('mm')
  return [
    parseFloat(eventYear),
    parseFloat(eventMonth),
    parseFloat(eventDay),
    parseFloat(eventHour),
    parseFloat(eventMinute),
  ]
}

export const getCalendarDuration = (startTime: string, endTime: string) => {
  const end = dayjs(endTime)
  const start = dayjs(startTime)
  const difference = end.diff(start, 'minute')
  return difference
}
