import { useContext, useEffect } from 'react'
import { MessageOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import useStore from 'hooks/useStore'
import { WebSocketContext, WebSocketContextType } from 'utils/WebSocketProvider'

const ChatIcon = () => {
  const { getChats, setHasUnreadChats, hasUnreadChats } = useStore(
    (state) => state,
  )
  const { lastMessage } = useContext(WebSocketContext) as WebSocketContextType

  useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data)
      if (message.data && message.data.template_name === 'new_message') {
        setHasUnreadChats(true)
      }
    }
  }, [lastMessage, setHasUnreadChats])

  useEffect(() => {
    getChats()
  }, [getChats])

  return (
    <Badge className="ant-menu-item-icon" dot={hasUnreadChats}>
      <MessageOutlined className="ant-menu-item-icon" />
    </Badge>
  )
}

export default ChatIcon
