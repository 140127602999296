import { Card, Input } from 'antd'
import clsx from 'clsx'
import useStore from 'hooks/useStore'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, Node, Position } from 'reactflow'
import 'reactflow/dist/style.css'
import { TaskNodeData } from 'types/Tasks'
import styles from './TaskEditNode.module.css'
import { getLevelForColor, TaskEvents } from '../FlowUtils'

const useMutationObserver = (
  ref: any,
  callback: MutationCallback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback)
      observer.observe(ref.current, options)
      return () => observer.disconnect()
    }
  }, [ref, callback, options])
}

function TaskEditNode(taskNode: Node<TaskNodeData>) {
  const data = taskNode.data

  const { t } = useTranslation()
  const [title, setTitle] = useState(data.title)
  const { setChildNodeEvent } = useStore((state) => ({
    setChildNodeEvent: state.setChildNodeEvent,
  }))
  const inputRef = React.useRef<HTMLDivElement | null>(null)

  const onFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  useMutationObserver(inputRef, onFocusInput)

  const levelForColor = getLevelForColor(data.level)
  const cardContainerClassName = clsx(
    styles.cardContainer,
    taskNode.selected && styles['shadowLevel' + levelForColor],
  )
  const cardNodeClassName = clsx(
    styles.cardNode,
    styles['level' + levelForColor],
  )

  const handleKeyDown = (
    event: React.KeyboardEvent,
    params: Node<TaskNodeData>,
  ) => {
    if (event.key === 'Enter') {
      saveChanges(params)
    }
  }

  const handleInputBlur = (
    event: React.FocusEvent,
    params: Node<TaskNodeData>,
  ) => {
    saveChanges(params)
  }

  const saveChanges = (node: Node<TaskNodeData>) => {
    node.data.title = title
    setChildNodeEvent({ event: TaskEvents.COMMIT_NODE, node: node })
  }

  return (
    <>
      {data.parent && (
        <Handle
          type="target"
          position={taskNode.targetPosition ?? Position.Top}
        />
      )}
      <div className={cardContainerClassName}>
        <Card
          className={cardNodeClassName}
          onDragStart={(e) => e.preventDefault()}
          ref={inputRef}
        >
          <Input
            placeholder={t('task-node.edit-title') ?? ''}
            defaultValue={data.title}
            onBlur={(e) => handleInputBlur(e, taskNode)}
            onChange={(e) => setTitle(e.target.value)}
            onDragStart={(e) => e.preventDefault()}
            onKeyDown={(e) => handleKeyDown(e, taskNode)}
            autoFocus={true}
          />
        </Card>
      </div>
      <Handle
        type="source"
        position={taskNode.sourcePosition ?? Position.Bottom}
      />
    </>
  )
}

export default TaskEditNode
