import dayjs from 'dayjs'
import i18n from 'utils/i18n'
import useStore from 'hooks/useStore'
import { AppTheme } from 'types/Theme'
import type { FCProps } from 'types/FC'
import { useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { App, ConfigProvider, theme } from 'antd'
import { Provider as WrapProvider } from 'react-wrap-balancer'
import type { Locale } from 'antd/lib/locale'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

import 'utils/dayjsPlugins'
import { WebSocketProvider } from 'utils/WebSocketProvider'
import { Notifications } from 'utils/Notifications'

const msalConfig = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: 'e2ff516b-68c7-4380-afee-fc437c5243cb',
    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority:
      'https://login.microsoftonline.com/f9ce0941-2ff0-4f1a-a6a2-51f191e29106',
    // Full redirect URL, in form of http://localhost:3000
    redirectUri: `https://${window.location.origin}/callback/azure`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

const msalInstance = new PublicClientApplication(msalConfig)

const AppProvider = ({ children }: FCProps) => {
  const isLightTheme = useStore((state) => state.theme === AppTheme.LIGHT)
  const [antLocale, setAntLocale] = useState<Locale>()

  useEffect(() => {
    const defaultLanguage = 'en_US'
    async function fileExists(url: string) {
      try {
        const response = await fetch(url, { method: 'HEAD' })
        return response.ok
      } catch (error) {
        return false
      }
    }
    i18n.on('languageChanged', (language) => {
      if (language === 'en') {
        language = defaultLanguage
      }
      const twoDigitLocale = language.split(/(_|-)/g)[0]
      const dayjsUrl = `dayjs/locale/${twoDigitLocale}.js`
      fileExists(dayjsUrl).then((exists) => {
        if (exists) {
          import(`dayjs/locale/${twoDigitLocale}.js`)
            .then(() => {
              dayjs.locale(twoDigitLocale)
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.log(error)
            })
        }
      })

      const antdLibraryName = language.replace('-', '_')
      const antdUrl = `antd/locale/${antdLibraryName}.js`
      fileExists(antdUrl).then((exists) => {
        if (exists) {
          import(`antd/locale/${antdLibraryName}.js`)
            .then((value) => {
              setAntLocale(value.default)
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.log(error)
            })
        }
      })
    })
  }, [])

  return (
    <ConfigProvider
      locale={antLocale}
      theme={{
        algorithm: isLightTheme ? theme.defaultAlgorithm : theme.darkAlgorithm,
      }}
    >
      <I18nextProvider i18n={i18n}>
        <App notification={{ placement: 'topRight', top: 60 }}>
          <WrapProvider>
            <WebSocketProvider
              url={
                `${process.env.REACT_APP_WS}/ws/notifications/` ||
                'ws://0.0.0.0:8000/ws/notifications/'
              }
            >
              <MsalProvider instance={msalInstance}>
                <Notifications>
                  <BrowserRouter>{children}</BrowserRouter>
                </Notifications>
              </MsalProvider>
            </WebSocketProvider>
          </WrapProvider>
        </App>
      </I18nextProvider>
    </ConfigProvider>
  )
}

export default AppProvider
