import { Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './CustomFields.module.scss'
import CustomFieldsCellComponent from './CustomFieldsCellComponent'

const { Title } = Typography

const EditCustomFieldsHeader = () => {
  const { t } = useTranslation()
  return (
    <Row className={styles.rowGrid} gutter={[24, 24]}>
      <CustomFieldsCellComponent span={4}>
        <Title level={5}>{t('custom-field.type-header')}</Title>
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={6}>
        <Title level={5}>{t('custom-field.label-header')}</Title>
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={6}>
        <Title level={5}>{t('custom-field.description-header')}</Title>
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={3}>
        <Title level={5}>{t('custom-field.maxchar-header')}</Title>
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={3}>
        <Title level={5}>{t('custom-field.required-header')}</Title>
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={2} />
    </Row>
  )
}

export default EditCustomFieldsHeader
