import TaskListItem from 'components/TaskListItem'
import { Draggable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import { TaskItem } from 'types/Tasks'

type TaskProps = {
  data: TaskItem
  index: number
  isExpanded?: boolean
  isReadonly?: boolean
  onToggle?: (taskId: string) => void
}

export default function Task({
  data,
  index,
  isExpanded = false,
  isReadonly = false,
  onToggle,
}: TaskProps) {
  return (
    <Draggable key={data?.id} draggableId={data?.id} index={index}>
      {(provided, snapshot) => {
        const isDragging = snapshot.isDragging

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TaskListItem
              data={data}
              index={index}
              isExpanded={isExpanded}
              isDragging={isDragging}
              isReadonly={isReadonly}
              onToggle={onToggle}
            />
          </div>
        )
      }}
    </Draggable>
  )
}
