import { Button, Card, Space } from 'antd'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NotificationList from './NotificationList'
import './NotificationPane.css'
import useStore from 'hooks/useStore'
import dayjs from 'dayjs'

type NotificationPaneProps = {
  isCollapsed: boolean
  closeModal: (val: boolean) => void
}

const tabList = [
  {
    key: 'unread',
    tab: 'Unread',
  },
  {
    key: 'read',
    tab: 'Read',
  },
]

const useOutsideClick = (
  ref: RefObject<HTMLDivElement>,
  action: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        action()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, action])
}

const NotificationPane = ({
  isCollapsed,
  closeModal,
}: NotificationPaneProps) => {
  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  const notificationList = useStore((state) => state.notifications)

  const getNotifications = useStore((state) => state.getNotifications)
  const patchReadAt = useStore((state) => state.patchReadAt)
  const [activeTab, setActiveTab] = useState<string>('unread')
  const [itemsRead, setItemsRead] = useState<string[]>([])

  useOutsideClick(wrapperRef, () => closeModal(false))

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  const markAsRead = () => {
    patchReadAt(itemsRead)
  }

  const addItemRead = (id: string) => {
    const newItems = itemsRead.some((item) => item === id)
      ? itemsRead.filter((item) => item !== id)
      : itemsRead.concat(id)
    setItemsRead(newItems)
  }

  const addAllItemsRead = () => {
    const allIds = notificationList.map((n) => n.id)
    setItemsRead(allIds)
  }

  const sortedNotificationList = notificationList.sort((a, b) =>
    dayjs(b.createdAt).diff(dayjs(a.createdAt)),
  )

  return (
    <div className="mask">
      <Card
        title={t('notifications.title')}
        className={`notification-pane ${isCollapsed && 'is-collapsed'}`}
        tabList={tabList}
        onTabChange={(key: string) => setActiveTab(key)}
        ref={wrapperRef}
      >
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {activeTab === 'unread' && (
            <Space size={20}>
              <Button onClick={markAsRead} disabled={itemsRead.length === 0}>
                {t('notifications.mark-read')}
              </Button>
              <Button onClick={() => addAllItemsRead()}>
                {t('my-today-page.select-all')}
              </Button>
            </Space>
          )}
        </div>
        <NotificationList
          closeModal={closeModal}
          notifications={sortedNotificationList}
          filter={activeTab}
          setItemRead={addItemRead}
          itemsRead={itemsRead}
        />
      </Card>
    </div>
  )
}

export default NotificationPane
