import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(isToday)
dayjs.extend(utcPlugin)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(LocalizedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
