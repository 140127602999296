import { Select } from 'antd'
import { useState } from 'react'
import Row from 'components/Row'

type DayOfMonthProps = {
  value?: number
  onChange?: (value: number) => void
}

const DayofMonth = ({ value, onChange }: DayOfMonthProps) => {
  const [daysOfMonth] = useState(
    Array.from({ length: 31 }).map((_, i) => ({
      label: `${i + 1}`,
      value: i + 1,
    })),
  )

  return (
    <Row style={{ justifyContent: 'flex-end' }}>
      <span>On: </span>
      <Select<number>
        size="small"
        options={daysOfMonth}
        defaultValue={value}
        style={{ width: '80px' }}
        onChange={(nextValue) => {
          onChange?.(nextValue)
        }}
      />
    </Row>
  )
}

export default DayofMonth
