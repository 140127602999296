import { Segmented } from 'antd'
import { FlexibleTime } from 'types/FlexibleTime'
import { useTranslation } from 'react-i18next'

type FuzzyTimeProps = {
  value?: FlexibleTime
  onChange?: (value: FlexibleTime) => void
}

const FuzzyTimeSelector = ({ value, onChange }: FuzzyTimeProps) => {
  const { t } = useTranslation()

  const fuzzyTimeOptions = [
    {
      label: t(`new-task-form.recurrence.flexible-time.beginning`),
      value: FlexibleTime.BEGINNING,
    },
    {
      label: t(`new-task-form.recurrence.flexible-time.middle`),
      value: FlexibleTime.MIDDLE,
    },
    {
      label: t(`new-task-form.recurrence.flexible-time.end`),
      value: FlexibleTime.END,
    },
  ]

  return (
    <Segmented
      size="small"
      value={value}
      options={fuzzyTimeOptions}
      style={{ cursor: 'pointer' }}
      onChange={(option) => {
        onChange?.(option as FlexibleTime)
      }}
    />
  )
}

export default FuzzyTimeSelector
