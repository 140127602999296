import { LinearProgress } from '@mui/material'
import styles from './LoadingProgress.module.scss'

const LoadingProgress = () => {
  return (
    <div className={styles.container}>
      <LinearProgress />
    </div>
  )
}

export default LoadingProgress
