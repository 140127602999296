import { Chip } from '@mui/material'
import { Popover, SelectProps } from 'antd'
import UserAvatar from 'components/UserAvatar'
import React from 'react'
import { EmailPopover } from './EmailPopover'
import { decodeOptionLabel } from './EmailSelectorHelper'

type TagRender = SelectProps['tagRender']

const avatarStyle = {
  marginLeft: '4px',
  marginRight: '-6px',
  fontSize: '0.75rem',
}

export const EmailTagRender: TagRender = (props) => {
  const { label, value, onClose } = props
  const { name, image, status } = decodeOptionLabel(label as string)
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Popover
      content={<EmailPopover data={props} />}
      trigger="hover"
      arrow={false}
    >
      <Chip
        key={value}
        label={name}
        onDelete={onClose}
        variant="outlined"
        avatar={
          <UserAvatar
            label={name}
            size="small"
            image={image}
            delegationStatus={status}
            avatarStyles={avatarStyle}
          />
        }
        onMouseDown={onPreventMouseDown}
        style={{ marginInlineEnd: 4 }}
      />
    </Popover>
  )
}
