import { Typography, List } from 'antd'
import useStore from 'hooks/useStore'
import { useEffect, useState } from 'react'
import { useTaskEditor } from '../useTaskEditor'
import { EventLog } from 'services/Notification.slice'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

const InviteStatus = () => {
  const [notifications, setNotifications] = useState<EventLog[]>([])
  const { getTaskNotifications, dateFormatOption, timeFormat } = useStore()
  const { t } = useTranslation()
  const { initialValues = {} } = useTaskEditor()

  useEffect(() => {
    if (initialValues.id) {
      getTaskNotifications(initialValues.id).then((notifications) =>
        setNotifications(notifications),
      )
    }
  }, [initialValues, getTaskNotifications, setNotifications])

  const dateTimeFormatter = () => {
    const dateFormat = dateFormatOption === 'month_first' ? 'MM/DD' : 'DD/MM'
    return `${dateFormat} ${timeFormat}`
  }

  const items = notifications.map((notification) => ({
    key: notification.id,
    label: t(`notifications.${notification.action}`, {
      name: notification.user?.fullName,
    }),
    date: dayjs(notification.timestamp).format(dateTimeFormatter()),
  }))

  return (
    <div style={{ paddingTop: 32 }}>
      {items.length ? (
        <List
          bordered
          dataSource={items}
          renderItem={(item) => (
            <List.Item key={item.key}>
              <Typography.Text>{item.label}</Typography.Text>
              <Typography.Text>{item.date}</Typography.Text>
            </List.Item>
          )}
        />
      ) : (
        <Typography.Text>No Notifications have been record.</Typography.Text>
      )}
    </div>
  )
}

export default InviteStatus
