import { Input } from 'antd'
import { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditingElementType, ElementEnum } from '../TemplatesUtils'

type CustomTemplateInputProps = {
  onSave: Function
  isValidTitle: Function
  style?: CSSProperties
  placeholder?: string
  editingElement?: EditingElementType
}

const CustomTemplateInput = ({
  onSave,
  isValidTitle,
  style,
  editingElement,
}: CustomTemplateInputProps) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (isValidTitle(title, editingElement)) {
        onSave(title)
      }
    }
  }

  const handleInputBlur = (event: React.FocusEvent) => {
    if (isValidTitle(title, editingElement)) {
      onSave(title)
    }
  }

  const placeholderKey =
    editingElement?.type === ElementEnum.NEW_FOLDER
      ? 'templates.new-folder-placeholder'
      : editingElement?.type === ElementEnum.NEW_TEMPLATE
      ? 'templates.new-template-placeholder'
      : ''

  const defaultValue =
    editingElement?.type === ElementEnum.EDIT_FOLDER ||
    editingElement?.type === ElementEnum.EDIT_TEMPLATE
      ? editingElement.title
      : ''

  return (
    <Input
      placeholder={t(placeholderKey) ?? ''}
      onBlur={(e) => handleInputBlur(e)}
      onChange={(e) => setTitle(e.target.value)}
      onDragStart={(e) => e.preventDefault()}
      onKeyDown={(e) => handleKeyDown(e)}
      defaultValue={defaultValue}
      style={{ ...style }}
      autoFocus={true}
    />
  )
}

export default CustomTemplateInput
