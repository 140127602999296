import { FlagOutlined, FlagFilled } from '@ant-design/icons'

type FlagCheckboxProps = {
  size?: number
  value?: boolean
  onChange?: (nextState: boolean) => void
}

const FlagCheckbox = ({ size, value, onChange }: FlagCheckboxProps) =>
  value ? (
    <FlagFilled
      size={size}
      style={{ color: '#F44' }}
      onClick={() => onChange?.(false)}
    />
  ) : (
    <FlagOutlined size={size} onClick={() => onChange?.(true)} />
  )

export default FlagCheckbox
