import React, { useEffect, useMemo, useState } from 'react'
import { App, Button, Col, List, Row, Typography, Space, Divider } from 'antd'
import { useDropzone } from 'react-dropzone'
import useStore from 'hooks/useStore'
import { UploadedFile } from 'services/Tasks.slice'
import LensIcon from '@mui/icons-material/Lens'
import { useTranslation } from 'react-i18next'
import { TaskItem } from 'types/Tasks'
import { List as MUIList } from '@mui/material'
import FileItem from './FileItem'

const { Text, Title } = Typography

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'var(--border-color)',
  borderStyle: 'dashed',
  backgroundColor: 'var(--offset-background-color)',
  color: 'var(--text-color)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: 'var(--delegation-viewed)',
}

const acceptStyle = {
  borderColor: 'var(--delegation-accepted)',
}

const rejectStyle = {
  borderColor: 'var(--delegation-declined)',
}

type AttachmentsProps = {
  taskId: string
  task?: Partial<TaskItem>
  attachments?: UploadedFile[]
}

const Attachments = ({ taskId, attachments, task }: AttachmentsProps) => {
  const app = App.useApp()
  const { t } = useTranslation()

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const [pendingFiles, setPendingFiles] = useState<File[]>([])

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ multiple: false })

  const { postTaskFiles, deleteTaskFile, subscriptionEvents } = useStore(
    (state) => state,
  )

  const { taskAttachments, storage } = subscriptionEvents

  useEffect(() => {
    if (attachments) {
      setUploadedFiles(attachments)
    }
  }, [attachments, setUploadedFiles])

  const clearAcceptedFiles = () => {
    setPendingFiles([])
    acceptedFiles.splice(0, 1)
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setPendingFiles(acceptedFiles)
    }
  }, [acceptedFiles])

  const uploadedFileItems = uploadedFiles.map((file: UploadedFile) => {
    return (
      <FileItem
        key={file.id}
        file={file}
        task={task}
        handleDelete={() => handleDelete(file.id)}
      />
    )
  })

  const handleUpload = () => {
    // Append the uploaded file(s) to the FormData object
    acceptedFiles.forEach((file) => {
      // Create a new FormData object to send the file to the server
      const formData = new FormData()
      formData.append('task', taskId)
      formData.append('document', file)
      // Replace 'your_upload_endpoint' with the actual endpoint to which you want to send the file(s)
      postTaskFiles(formData)
        .then((data) => {
          setUploadedFiles((prev) => prev.concat(data))
          acceptedFiles.splice(0, acceptedFiles.length)
        })
        .catch((error) => {
          // Handle any errors that occurred during the upload
          app.notification.error({
            message: error.response.data.fallback_message,
          })
        })
    })
  }

  const handleDelete = (fileId: number) => {
    // Create a new FormData object to send the file to the server
    const formData = new FormData()

    // Append the uploaded file(s) to the FormData object
    acceptedFiles.forEach((file) => {
      formData.append('file', file)
    })

    // Replace 'your_upload_endpoint' with the actual endpoint to which you want to send the file(s)
    deleteTaskFile(fileId, taskId)
      .then(() => {
        const index = uploadedFiles.findIndex((uf) => uf.id === fileId)
        setUploadedFiles(
          uploadedFiles.slice(0, index).concat(uploadedFiles.slice(index + 1)),
        )
      })
      .catch((error) => {
        // Handle any errors that occurred during the upload
        console.error('Upload error:', error)
      })
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  return (
    <div className="container">
      <Row justify="center" style={{ marginTop: 24 }}>
        <Col span={22} style={{ margin: '16px 0' }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div> {t('attachments-tab.upload-file')}</div>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col offset={20} style={{ paddingRight: 74 }}>
          <Space>
            <Button onClick={clearAcceptedFiles}>Clear</Button>
            <Button onClick={handleUpload}>
              {t('attachments-tab.upload')}
            </Button>
          </Space>
        </Col>
        <Col span={24} style={{ marginLeft: 80 }}>
          <List>
            <Title level={5}>{t('attachments-tab.files')}:</Title>
            {pendingFiles.map((file: File) => (
              <li key={file.name}>
                <Space>
                  <Text strong>{file.name}</Text> -{' '}
                  <Text type="secondary">{file.size} bytes</Text>
                </Space>
              </li>
            ))}
          </List>
        </Col>
      </Row>
      <Divider />
      {uploadedFileItems.length > 0 && (
        <Row justify="center" style={{ marginBottom: 48, marginTop: 24 }}>
          <Col span={22}>
            <Title level={4} style={{ marginBottom: 28 }}>
              {t('attachments-tab.file-attachments')}
            </Title>
            <MUIList>{uploadedFileItems}</MUIList>
          </Col>
        </Row>
      )}
      {taskAttachments.title && (
        <Row>
          <Col flex="350px" style={{ paddingLeft: 38, marginBottom: 12 }}>
            <Space>
              <Title style={{ marginBottom: '0' }} level={5}>
                {taskAttachments.title}:
              </Title>
              <Text>{`${taskAttachments.currentCount} of ${taskAttachments.limit}`}</Text>
            </Space>
          </Col>
          <Col flex="auto">
            <LensIcon
              style={{
                color: `var(--licensing-${taskAttachments.status}-color)`,
              }}
            />
          </Col>
        </Row>
      )}
      {storage.title && (
        <Row>
          <Col flex="350px" style={{ paddingLeft: 38, marginBottom: 12 }}>
            <Space>
              <Title style={{ marginBottom: '0' }} level={5}>
                {storage.title}:
              </Title>
              <Text>{`${storage.currentCount} B of ${storage.limit}`} B</Text>
            </Space>
          </Col>
          <Col flex="auto">
            <LensIcon
              style={{
                color: `var(--licensing-${storage.status}-color)`,
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default Attachments
