import { Typography } from 'antd'
import { Droppable } from "@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration"
import { TaskLists } from 'services/Tasks.slice'
import { TaskPriorityGroupGlobal } from 'types/Tasks'
import styles from './PriorityPanel.module.scss'

interface PriorityItemProps {
  dropId: string
  tasks: TaskLists
  data: TaskPriorityGroupGlobal
}

const { Text } = Typography

export default function PriorityItem({
  dropId,
  data,
  tasks,
}: PriorityItemProps) {
  const label = data.title

  return (
    <Droppable droppableId={dropId}>
      {(provided, snapshot) => {
        const isDraggingOver = snapshot.isDraggingOver

        return (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div
              className={
                isDraggingOver
                  ? styles.priorityItemDraggingOver
                  : styles.priorityItem
              }
            >
              <Text className={styles.title}> {label}</Text>
              <Text className={styles.title}>
                {tasks?.[data.id]?.length || 0}
              </Text>

              {provided.placeholder}
            </div>
          </div>
        )
      }}
    </Droppable>
  )
}
