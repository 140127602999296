import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

type TabCloseButtonProps = {
  onClick: () => void
  visible?: boolean
}

const TabCloseButton = ({ onClick, visible }: TabCloseButtonProps) => {
  if (visible) {
    return <Button type="text" icon={<CloseOutlined />} onClick={onClick} />
  }
  return null
}

export default TabCloseButton
