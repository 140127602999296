import { Layout, Spin } from 'antd'
import Container from '../Container'

const PageLoader = () => (
  <Layout>
    <Container>
      <Spin size="large" />
    </Container>
  </Layout>
)

export default PageLoader
