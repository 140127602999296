import {
  TaskRole,
  TaskRolePermission,
  TaskRolePermissionBase,
  TaskRolePermissions,
} from 'types/Permission'
import { parseRoleList, parseTaskRolePermissions } from 'utils/permissions'
import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'

export interface PermissionState extends ApiState {
  roleList: TaskRole[]
  taskRolePermissions?: TaskRolePermissions
  groupRolePermissions?: TaskRolePermissions
  createUserTaskRole: (
    userTaskRole: Partial<TaskRolePermissionBase>,
  ) => Promise<TaskRolePermissionBase>
  updateUserTaskRole: (
    id: number,
    UserTaskRole: Partial<TaskRolePermissionBase>,
  ) => Promise<TaskRolePermissionBase>
  deleteUserTaskRole: (id: number) => Promise<void>
  getAllUserTaskRole: () => Promise<TaskRolePermissionBase[]>
  getUserTaskRoleById: (id: number) => Promise<TaskRolePermissionBase>
  getUserTaskRoleByTaskId: (taskId: string) => Promise<TaskRolePermission[]>

  getAllTaskRolePermission: () => Promise<TaskRolePermissions>
}

export const createPermissionSlice: StateCreator<PermissionState> = (
  set,
  get,
) => ({
  roleList: [],
  taskRolePermissions: {},
  groupRolePermissions: {},
  createUserTaskRole: async (userTaskRole) => {
    const response = await get().api!('POST', '/user/task/role', userTaskRole)
    return response.data
  },
  updateUserTaskRole: async (id, userTaskRole) => {
    const response = await get().api!(
      'PATCH',
      `/user/task/role/${id}`,
      userTaskRole,
    )
    return response.data
  },
  deleteUserTaskRole: async (id) => {
    await get().api!('DELETE', `/user/task/role/${id}`)
  },
  getAllUserTaskRole: async () => {
    const response = await get().api!('GET', `/user/task/role`)
    return response.data
  },
  getUserTaskRoleById: async (id) => {
    const response = await get().api!('GET', `/user/task/role/${id}`)
    return response.data
  },
  getUserTaskRoleByTaskId: async (taskId) => {
    const response = await get().api!('GET', `/user/task/role/${taskId}`)
    return response.data
  },

  getAllTaskRolePermission: async () => {
    const response = await get().api!('GET', `/task/role/permission`)
    const roleList = parseRoleList(response.data)
    const taskPermissions = parseTaskRolePermissions(response.data, 'task')
    const groupPermissions = parseTaskRolePermissions(response.data, 'group')
    set(() => {
      return {
        roleList,
        taskRolePermissions: taskPermissions,
        groupRolePermissions: groupPermissions,
      }
    })
    return response.data
  },
})
