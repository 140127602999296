import { useContext, useEffect } from 'react'
import { BellOutlined } from '@ant-design/icons'
import { Badge } from 'antd'
import useStore from 'hooks/useStore'
import { WebSocketContext, WebSocketContextType } from 'utils/WebSocketProvider'

const NotificationIcon = () => {
  const { setHasUnread, getNotifications, hasUnreadNotifications } = useStore(
    (state) => state,
  )
  const { lastMessage } = useContext(WebSocketContext) as WebSocketContextType

  useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data)
      if (message.data && message.data.has_unread !== undefined) {
        setHasUnread(message.data.has_unread)
      }
    }
  }, [lastMessage, setHasUnread])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  return (
    <Badge className="ant-menu-item-icon" dot={hasUnreadNotifications}>
      <BellOutlined className="ant-menu-item-icon" />
    </Badge>
  )
}

export default NotificationIcon
