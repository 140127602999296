import { DeploymentUnitOutlined, EditOutlined } from '@ant-design/icons'
import { TaskItem } from 'types/Tasks'

type PlanningToolbarProps = {
  task: TaskItem
  onViewTaskEditor: (task: TaskItem) => void
  onViewFlowViewer: (task: TaskItem) => void
}

const PlanningToolbar = ({
  task,
  onViewTaskEditor,
  onViewFlowViewer,
}: PlanningToolbarProps) => {
  return (
    <div
      style={{
        flexGrow: '0',
        flexShrink: '0',
        flexBasis: '38px',
      }}
      onClick={(evt) => {
        evt.preventDefault()
        evt.stopPropagation()
      }}
    >
      <DeploymentUnitOutlined onClick={() => onViewFlowViewer(task)} />{' '}
      <EditOutlined onClick={() => onViewTaskEditor(task)} />
    </div>
  )
}

export default PlanningToolbar
