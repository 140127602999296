import React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { UploadedFile } from 'services/Tasks.slice'
import VolumeMuteIcon from '@mui/icons-material/VolumeMute'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

type PlayListProps = {
  tracks: UploadedFile[]
  handleListItemClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => void
  selectedIndex: number
  isActive: boolean
}

const PlayList = ({
  tracks,
  handleListItemClick,
  selectedIndex,
  isActive,
}: PlayListProps) => {
  return (
    <List component="nav" aria-label="audio tasks">
      {tracks.length > 0 &&
        tracks.map((track, index) => {
          return (
            <ListItemButton
              key={track.id}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, 0)}
              sx={{ pl: 1.5 }}
            >
              <ListItemIcon sx={{ minWidth: 32 }}>
                {isActive ? <VolumeUpIcon /> : <VolumeMuteIcon />}
              </ListItemIcon>
              <ListItemText
                primary={track.filename}
                primaryTypographyProps={{
                  variant: 'subtitle2',
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            </ListItemButton>
          )
        })}
    </List>
  )
}

export default PlayList
