import { SendOutlined } from '@ant-design/icons'
import {
  App,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import clsx from 'clsx'
import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import type { DisplayValueType } from 'rc-select/lib/BaseSelect'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { TaskFields } from 'utils/permissions'
import { EmailOptionRender } from './EmailOptionRender'
import styles from './EmailSelector.module.scss'
import {
  decodeOptionLabel,
  shouldShowResendButton,
} from './EmailSelectorHelper'

const { Text } = Typography
const transparentStyle = { color: 'rgba(0, 0, 0, 0.6)' }

type EmailOmmitedValuesPopoverProps = {
  omittedValues: DisplayValueType[]
}

export const EmailOmmitedValuesPopover = ({
  omittedValues,
}: EmailOmmitedValuesPopoverProps) => {
  const { t } = useTranslation()
  const { message } = App.useApp()
  const { dateFormat, resendDelegationInvitation } = useStore((state) => state)
  const { initialValues } = useTaskEditor()
  const { hasUpdatePermissionOnTask } = useTaskEditor()
  const isReadonly = !hasUpdatePermissionOnTask(TaskFields.TASK_ASSIGNEES)

  const handleResend = (email: string) => {
    if (!initialValues?.id) {
      return
    }
    resendDelegationInvitation(email, initialValues.id)
      .then(() => {
        message.success(t('invite.resent'))
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.fallback_message)
        } else {
          message.error(error.message)
        }
      })
  }

  return (
    <div
      style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}
      onMouseDown={(evt) => {
        evt.preventDefault()
        evt.stopPropagation()
      }}
    >
      <Space direction="vertical" size={'middle'}>
        {omittedValues.map((value) => {
          const { status, createdAt } = decodeOptionLabel(value.label as string)
          const listItem = EmailOptionRender(value as DefaultOptionType, {
            index: 0,
          })
          const statusClassName = clsx(
            styles[
              status ? status.replaceAll(' ', '').toLowerCase() : 'onhold'
            ],
          )
          const dateString = status
            ? dayjs(createdAt).isValid()
              ? dayjs(createdAt).format(dateFormat)
              : t('email-selector.no-date')
            : t('email-selector.not-sent')
          return (
            <React.Fragment key={value.value}>
              <Row key={value.value}>
                <Col span={22}>{listItem}</Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Space
                    style={{
                      paddingLeft: 20,
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Space>
                      <Text strong style={transparentStyle}>
                        {t('email-selector.status')}:
                      </Text>
                      <Text className={statusClassName}>
                        {status || 'Not Sent'}
                      </Text>
                      <Text strong style={transparentStyle}>
                        {t('email-selector.date')}:
                      </Text>
                      <Text>{dateString}</Text>
                    </Space>

                    <Tooltip title={t('group.resend')}>
                      <Button
                        icon={<SendOutlined />}
                        disabled={!shouldShowResendButton(status) || isReadonly}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          handleResend(value.value as string)
                        }}
                        style={{ marginRight: 8 }}
                      />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            </React.Fragment>
          )
        })}
      </Space>
    </div>
  )
}
