import { Layout } from 'antd'
import useStore from 'hooks/useStore'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import LicensingBanner from 'components/LicensingBanner'
import FlowViewer from 'sections/TaskFlowViewer/FlowViewer/FlowViewer'
import { Outlet } from 'react-router-dom'
import NewTaskActionButton from 'sections/TaskEditorModal'
import { Userpilot } from 'userpilot'

const PageLayout = () => {
  const isAuthenticated = useStore((state) => !!state.user)
  const user = useStore((state) => state.user)

  if (isAuthenticated) {
    const fullName = `${user?.data?.firstName} ${user?.data?.lastName}`
    Userpilot.identify(user?.data?.id || '', {
      name: fullName?.trim() || '',
      email: user?.data?.email,
      typeId: user?.data?.typeId,
    })
  }

  return (
    <Layout hasSider>
      {isAuthenticated && <Sidebar />}
      <Layout>
        <Header />
        {isAuthenticated && <LicensingBanner />}
        <Outlet />
      </Layout>
      {isAuthenticated && <FlowViewer />}
      {isAuthenticated && <NewTaskActionButton />}
    </Layout>
  )
}

export default PageLayout
