import Routes from './Routes'
import Providers from './AppProvider'

const App = () => {
  return (
    <Providers>
      <Routes />
    </Providers>
  )
}

export default App
