import { SerializedLexicalNode } from 'lexical'
import { decode } from 'html-entities'

export type SerializedLexicalNodeWithExtras = SerializedLexicalNode & {
  listType?: string
  children?: SerializedLexicalNodeWithExtras[]
  checked?: boolean
}

export const getCheckListItemsCount = (json: string) => {
  try {
    const content = JSON.parse(json)
    const listChildren = content.root.children.filter(
      (child: SerializedLexicalNode) => {
        return child.type === 'list'
      },
    )
    const checkListChildren = listChildren.filter(
      (child: SerializedLexicalNodeWithExtras) => {
        return child.listType === 'check'
      },
    )

    const checkListLength = checkListChildren
      .map((child: any) => {
        return child.children
      })
      .flat()

    const checkedItems = checkListLength.filter(
      (item: SerializedLexicalNodeWithExtras) => {
        return item?.checked === true
      },
    )

    if (checkedItems.length === 0) {
      return null
    }
    return { done: checkedItems.length, count: checkListLength.length }
  } catch (e) {
    return null
  }
}

type ChecklistItem = {
  id: number
  text: string
  checked: string | boolean | null
}

export function extractChecklistsWithCheckedBoxes(
  htmlString: string,
): ChecklistItem[] {
  const parser = new DOMParser()
  const doc = parser.parseFromString(decode(htmlString), 'text/html')

  const checklistItems: ChecklistItem[] = []
  const listElements = doc.querySelectorAll('[__lexicallisttype="check"]')

  listElements.forEach((listElement, index) => {
    listElement
      .querySelectorAll('.editor-listitem')
      .forEach((item, itemIndex) => {
        const text = item.textContent?.trim() || ''

        const checked = item.ariaChecked

        checklistItems.push({
          id: itemIndex + 1,
          text,
          checked,
        })
      })
  })

  return checklistItems
}

export function decodeHtmlEntities(html: string) {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = html || ''
  return textArea.value
}
