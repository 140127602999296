import { Space, Typography } from 'antd'
import UserAvatar from 'components/UserAvatar'
import { useEffect, useState } from 'react'

const { Text } = Typography

type SelectTagProps = {
  label?: string
  secondaryLabel?: string
  onClick?: () => void
  border?: boolean
  disabled?: boolean
  image?: string
  status?: string
}

const SelectTag = ({
  label,
  secondaryLabel,
  onClick,
  border = false,
  disabled = false,
  image = '',
  status = '',
}: SelectTagProps) => {
  const [hasBorder, setHasBorder] = useState(false)

  useEffect(() => {
    setHasBorder(border)
  }, [border])

  return (
    <Space
      style={{
        border: hasBorder ? '1px solid var(--border-color)' : 'none',
        borderRadius: 4,
        lineHeight: 2,
        paddingLeft: 8,
        paddingBottom: 4,
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: disabled ? '#0000000a' : 'transparent',
      }}
      size={2}
      onClick={onClick}
    >
      <div style={{ lineHeight: 3 }}>
        <UserAvatar
          label={label?.trim() || secondaryLabel}
          size={32}
          image={image}
          delegationStatus={status}
        />
      </div>
      <div
        style={{
          paddingRight: 8,
          paddingLeft: 8,
          minWidth: 200,
          maxWidth: 300,
        }}
      >
        <Space
          direction="vertical"
          size={0}
          style={{ lineHeight: 0, maxWidth: 210 }}
        >
          {label?.trim() && (
            <Text
              strong
              style={{
                backgroundColor: 'transparent',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                width: '100%',
                color: disabled ? '#00000040' : 'var(--text-color)',
              }}
            >
              {label}
            </Text>
          )}
          <Text
            style={{
              color:
                label?.trim() && disabled
                  ? '#00000040'
                  : label?.trim()
                  ? 'var(--secondary-text-color)'
                  : 'var(--text-color)',
              backgroundColor: 'transparent',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          >
            {secondaryLabel}
          </Text>
        </Space>
      </div>
    </Space>
  )
}

export default SelectTag
