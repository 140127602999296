import { create } from 'zustand'

type State = {
  selectedEmails: string[]
  isIndividualModeSelected: boolean
  isFloatButtonVisible: boolean
  isFloatButtonTemporarilyHidden: boolean
  leftPositionDelegationButtons: number
  leftPositionTaskButtons: number
}

type Action = {
  updateSelectedEmails: (value: State['selectedEmails']) => void
  updateIsIndividualModeSelected: (value: boolean) => void
  updateIsFloatButtonVisible: (value: boolean) => void
  updateIsFloatButtonTemporarilyHidden: (value: boolean) => void
  updateLeftPositionFloatingButtons: (delegation: number, task: number) => void
}

const useTaskEditorStore = create<State & Action>((set) => ({
  leftPositionDelegationButtons: 0,
  leftPositionTaskButtons: 0,
  selectedEmails: [],
  isIndividualModeSelected: false,
  isFloatButtonVisible: false,
  isFloatButtonTemporarilyHidden: false,
  updateLeftPositionFloatingButtons: (delegation, task) =>
    set(() => ({
      leftPositionDelegationButtons: delegation,
      leftPositionTaskButtons: task,
    })),
  updateSelectedEmails: (value) =>
    set(() => ({
      selectedEmails: value,
      isIndividualModeSelected: value.length > 1,
    })),
  updateIsIndividualModeSelected: (value) =>
    set(() => ({ isIndividualModeSelected: value })),
  updateIsFloatButtonVisible: (value) =>
    set(() => ({ isFloatButtonVisible: value })),
  updateIsFloatButtonTemporarilyHidden: (value) =>
    set(() => ({ isFloatButtonTemporarilyHidden: value })),
}))

export default useTaskEditorStore
