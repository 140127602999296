import React from 'react'
import { motion } from 'framer-motion'

const AnimatedContainer = ({
  animate = false,
  children,
}: {
  animate: boolean
  children: boolean | JSX.Element | JSX.Element[] | null | undefined
}) => {
  if (animate) {
    return (
      <motion.div
        initial={false}
        animate={{
          height: 'auto',
          opacity: 1,
          transition: {
            height: {
              duration: 0.4,
            },
            opacity: {
              duration: 0.25,
              delay: 0.15,
            },
          },
        }}
      >
        {children}
      </motion.div>
    )
  }
  return <div>{children}</div>
}

export default AnimatedContainer
