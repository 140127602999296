import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { TaskItem } from 'types/Tasks'

type CompletedTaskToolbarProps = {
  task: TaskItem
  onDismiss: (task: TaskItem) => void
}

const CompletedTaskToolbar = ({
  task,
  onDismiss,
}: CompletedTaskToolbarProps) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t('my-today-page.dismiss')}>
      <Button
        onClick={(evt) => {
          evt.preventDefault()
          evt.stopPropagation()
          onDismiss(task)
        }}
        icon={<PlaylistRemoveIcon />}
        danger
        shape="circle"
        type="text"
      />
    </Tooltip>
  )
}

export default CompletedTaskToolbar
