import { DefaultOptionType } from 'antd/es/select'
import EmailListItem from './EmailListItem'
import { decodeOptionLabel } from './EmailSelectorHelper'

export const EmailOptionRender = (
  oriOption: DefaultOptionType,
  info?: {
    index: number
  },
) => {
  const { label, value } = oriOption
  const { name, image, status } = decodeOptionLabel(label as string)

  return (
    <EmailListItem
      email={(value as string)!}
      image={image || ''}
      name={name}
      status={status}
    />
  )
}
