import { StateCreator } from 'zustand'
import { produce } from 'immer'
import { ApiState } from './Auth.slice'
import { TaskItem } from 'types/Tasks'

// Only fetch chats from the last 2 months
const chatTimeframe = 1440

export type Message = {
  id: string
  user: string
  userName: string
  userAvatar?: string
  message: string
  createdAt: string
  updatedAt?: string
  deletedAt?: string
}

export type Chat = {
  id: string
  name: string
  createdAt: string
  hasUnread: boolean
  attachedObject?: TaskItem
  groupMetadata?: {
    icon: string
    color: string
  }
  viewers: {
    id: string
    fullName: string
    avatar?: {
      thumbnailUrl: string
    }
  }[]
}

export interface ChatState extends ApiState {
  chats: Chat[]
  hasUnreadChats: boolean
  messages: { [key: string]: Message[] }
  getChats: () => Promise<void>
  getChatByTaskID: (taskID: string) => Promise<Chat>
  getChatMessages: (chatID: string) => Promise<void>
  postChatMessage: (chatID: string, message: string) => Promise<void>
  patchChatMessage: (
    chatID: string,
    messageID: string,
    message: string,
  ) => Promise<void>
  deleteChatMessage: (chatID: string, messageID: string) => Promise<void>
  setHasUnreadChats: (hasUnread: boolean) => void
  setChatHasUnread: (chatID: string, hasUnread: boolean) => void
  addMessage: (chatID: string, message: Message) => void
  patchMessage: (chatID: string, messageID: string, message: string) => void
  deleteMessage: (chatID: string, messageID: string) => void
}

export const createChatSlice: StateCreator<
  ChatState,
  [['zustand/immer', never]]
> = (set, get) => ({
  chats: [],
  hasUnreadChats: false,
  messages: {},
  getChats: async () => {
    try {
      const response = await get().api!(
        'GET',
        `/chat?recent_messages=${chatTimeframe}`,
      )
      const hasUnread = response.data.some((chat: Chat) => chat.hasUnread)

      set(
        produce((draft) => {
          draft.chats = response.data
          draft.hasUnreadChats = hasUnread
        }),
      )
    } catch (ex) {
      return
    }
  },
  getChatByTaskID: async (taskID) => {
    try {
      const response = await get().api!('GET', `/chat/task/${taskID}`)

      set(
        produce((draft) => {
          draft.chats.push(response.data)
          draft.hasUnreadChats = response.data.hasUnread
        }),
      )

      return response.data
    } catch (ex) {
      return
    }
  },
  getChatMessages: async (chatID: string) => {
    const response = await get().api!('GET', `/chat/${chatID}/message`)

    set(
      produce((draft) => {
        const currentChatIndex = draft.chats.findIndex(
          (chat: Chat) => chat.id === chatID,
        )
        const currentChat = draft.chats[currentChatIndex]
        draft.messages[chatID] = response.data
        draft.messages[chatID].sort(
          (a: Message, b: Message) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )
        currentChat.hasUnread = false
        draft.hasUnreadChats = draft.chats.some((chat: Chat) => chat.id !== chatID && chat.hasUnread)
      }),
    )
  },
  postChatMessage: async (chatID: string, message: string) => {
    const response = await get().api!('POST', `/chat/${chatID}/message`, {
      message,
    })

    set(
      produce((draft) => {
        draft.messages[chatID]
          ? draft.messages[chatID].push(response.data)
          : (draft.messages[chatID] = [response.data])
      }),
    )
  },
  patchChatMessage: async (
    chatID: string,
    messageID: string,
    message: string,
  ) => {
    await get().api!('PATCH', `/chat/${chatID}/message/${messageID}`, {
      message,
    })
    get().patchMessage(chatID, messageID, message)
  },
  patchMessage: (chatID: string, messageID: string, message: string) => {
    set(
      produce((draft) => {
        const index = draft.messages[chatID].findIndex(
          (message: Message) => message.id === messageID,
        )
        if (index !== -1) {
          draft.messages[chatID][index].message = message
          draft.messages[chatID][index].updatedAt = Date.now()
        }
      }),
    )
  },
  deleteChatMessage: async (chatID: string, messageID: string) => {
    await get().api!('DELETE', `/chat/${chatID}/message/${messageID}`)

    get().deleteMessage(chatID, messageID)
  },
  deleteMessage: (chatID: string, messageID: string) => {
    set(
      produce((draft) => {
        const index = draft.messages[chatID].findIndex(
          (message: Message) => message.id === messageID,
        )
        if (index !== -1) {
          draft.messages[chatID][index].message = ''
          draft.messages[chatID][index].deletedAt = Date.now()
        }
      }),
    )
  },
  setHasUnreadChats: (hasUnread: boolean) => {
    set((draft) => {
      draft.hasUnreadChats = hasUnread
    })
  },
  setChatHasUnread: (chatID, hasUnread) => {
    set((draft) => {
      const currentChatIndex = draft.chats.findIndex(
        (chat) => chat.id === chatID,
      )
      const currentChat = draft.chats[currentChatIndex]
      if (currentChat) {
        currentChat.hasUnread = hasUnread
        const customSort = (a: Chat, b: Chat) => {
          if (a.hasUnread && !b.hasUnread) return -1
          if (!a.hasUnread && b.hasUnread) return 1

          if (a.id === chatID) return -1
          if (b.id === chatID) return 1

          return 0
        }
        if (hasUnread) draft.chats = draft.chats.sort(customSort)
      }
      draft.hasUnreadChats = draft.chats.some((chat) => chat.hasUnread)
    })
  },
  addMessage: async (chatID, message) => {
    if (!get().messages[chatID]) {
      await get().getChats()
      return
    }
    set(
      produce((draft) => {
        const uniqueIds = new Set(
          (draft.messages[chatID] || []).map((message: Message) => message.id),
        )
        if (!uniqueIds.has(message.id)) {
          draft.messages[chatID].push(message)
          draft.messages[chatID].sort(
            (a: Message, b: Message) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
        }
      }),
    )
  },
})
