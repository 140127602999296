import { Drawer, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'reactflow/dist/style.css'
import { getWindowSize } from '../FlowUtils'
import { useTaskFlowViewer } from '../useTaskFlowViewer'
import Header from './Header'

export type FlowDrawerProps = {
  flowViewer: React.ReactNode
  numberTasksCreated: number
  numberOfTasksModified: number
  loading: boolean
  isDeleteChildrenModalOpen: boolean
  removeChildrenNodesAndLayout: () => void
  removeMiddleNodeAndLayout: () => void
  discard: () => void
  saveAndClose: () => void
  close: () => void
  markAllAsActive: () => void
  convertToDraft: () => void
  convertToTemplate: () => void
}

const FlowDrawer = (props: FlowDrawerProps) => {
  const {
    flowViewer,
    numberTasksCreated,
    numberOfTasksModified,
    loading,
    discard,
    saveAndClose,
    close,
    markAllAsActive,
    convertToDraft,
    convertToTemplate,
    isDeleteChildrenModalOpen,
    removeChildrenNodesAndLayout,
    removeMiddleNodeAndLayout,
  } = props

  const { t } = useTranslation()
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const { isCalledFromPlanning, isTaskFlowViewerVisible, isTemplateMode } =
    useTaskFlowViewer()

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <>
      <Drawer
        title={
          isTemplateMode
            ? t('templates.template-tab')
            : t('task-plan-viewer.title')
        }
        placement="right"
        closable={false}
        onClose={close}
        open={isTaskFlowViewerVisible}
        width={windowSize.innerWidth - (isCalledFromPlanning ? 376 : 0)}
        destroyOnClose={true}
        mask={false}
        extra={
          <Header
            numberTasksCreated={numberTasksCreated}
            numberOfTasksModified={numberOfTasksModified}
            loading={loading}
            discard={discard}
            saveAndClose={saveAndClose}
            convertToDraft={convertToDraft}
            close={close}
            markAllAsActive={markAllAsActive}
            convertToTemplate={convertToTemplate}
          />
        }
      >
        {flowViewer}
      </Drawer>
      <Modal
        title={t('planning.delete-children-modal-title')}
        open={isDeleteChildrenModalOpen}
        onOk={removeChildrenNodesAndLayout}
        onCancel={removeMiddleNodeAndLayout}
        okText={t('actions.yes')}
        cancelText={t('actions.no')}
        zIndex={1100}
      >
        {t('planning.delete-children-modal-content')}
      </Modal>
    </>
  )
}

export default FlowDrawer
