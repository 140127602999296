import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  AutoComplete,
  Button,
  Input,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
} from 'antd'
import clsx from 'clsx'
import useStore from 'hooks/useStore'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RoleMappingShared, UserTemplateRole } from 'types/Templates'
import { isValidEmail } from 'utils/taskUtils'
import CellComponent from './CellComponent'
import styles from './RoleMapping.module.scss'

const { Text } = Typography

type RowComponentProps = {
  field: RoleMappingShared
  editingRole?: Partial<RoleMappingShared> | null
  setEditingRole?: Dispatch<SetStateAction<Partial<RoleMappingShared> | null>>
  showButtons?: boolean
  onEdit?: (role: Partial<UserTemplateRole> | null) => void
  onDelete?: (role: Partial<UserTemplateRole>) => void
  onSave?: () => void
  isForRoleMappingTree?: boolean
  showHorizontalBorders?: boolean
}

const RowComponent = ({
  field,
  showButtons,
  editingRole,
  setEditingRole,
  onEdit,
  onDelete,
  onSave,
  isForRoleMappingTree,
  showHorizontalBorders,
}: RowComponentProps) => {
  const { t } = useTranslation()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { userTemplateRole } = useStore((state) => state)
  const options = (userTemplateRole || []).map((role) => ({
    value: role.title!,
  }))

  const isEditing = field.id === editingRole?.id
  const isValidMappedRole =
    !editingRole?.email ||
    !!userTemplateRole?.find((role) => role.title === editingRole?.email) ||
    isValidEmail(editingRole?.email)
  const isValidRoleEmail =
    !editingRole?.email || isValidEmail(editingRole?.email)

  const editEmailInput = isForRoleMappingTree ? (
    <Tooltip title={t('templates.tooltip.select-role')}>
      <AutoComplete
        value={editingRole?.email}
        onChange={(e) => {
          setEditingRole?.({ ...editingRole, email: e })
        }}
        options={options}
        style={{ width: 200 }}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        status={isValidMappedRole ? undefined : 'error'}
        placeholder="john.doe@tepintasks.com"
      />
    </Tooltip>
  ) : (
    <Input
      value={editingRole?.email}
      onChange={(e) => {
        setEditingRole?.({ ...editingRole, email: e.target.value })
      }}
      status={isValidRoleEmail ? undefined : 'error'}
    />
  )

  return (
    <Row
      className={clsx(
        showHorizontalBorders ? styles.rowRoleMappingTree : styles.rowGrid,
      )}
      gutter={[24, 24]}
    >
      {showButtons &&
        (isEditing ? (
          <CellComponent span={3}>
            <Button
              icon={<SaveOutlined />}
              type="text"
              size="small"
              onClick={() => {
                isForRoleMappingTree
                  ? isValidMappedRole && onSave?.()
                  : isValidRoleEmail && onSave?.()
              }}
              title={t('actions.save')}
            />
            <Button
              icon={<CloseOutlined />}
              type="text"
              size="small"
              onClick={() => onEdit?.(null)}
              title={t('actions.cancel')}
            />
          </CellComponent>
        ) : (
          <CellComponent span={3}>
            <Button
              icon={<EditOutlined />}
              type="text"
              size="small"
              onClick={() => onEdit?.(field)}
              title={t('actions.edit')}
            />
            {onDelete && (
              <Popconfirm
                title={t('templates.discard-prompt.title')}
                description={t('templates.discard-prompt.description')}
                open={isConfirmOpen}
                onConfirm={() => {
                  setIsConfirmOpen(false)
                  onDelete?.(field)
                }}
                onCancel={() => {
                  setIsConfirmOpen(false)
                }}
                icon={null}
                okText={t('templates.discard-prompt.ok-text')}
                cancelText={t('templates.discard-prompt.cancel-text')}
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  size="small"
                  style={{ color: 'var(--warning-color)' }}
                  onClick={() => setIsConfirmOpen(true)}
                  title={t('actions.remove')}
                />
              </Popconfirm>
            )}
          </CellComponent>
        ))}
      <CellComponent
        span={showButtons ? 9 : 12}
        justify={isForRoleMappingTree ? 'left' : undefined}
      >
        {isEditing && !isForRoleMappingTree ? (
          <Input
            value={editingRole.role}
            onChange={(e) => {
              setEditingRole?.({ ...editingRole, role: e.target.value })
            }}
          />
        ) : (
          <>
            {isForRoleMappingTree &&
              !field.email &&
              (field.roleMatches ? (
                <Tooltip title={t('templates.tooltip.role-matches')}>
                  <Button
                    icon={<CheckCircleOutlined />}
                    type="text"
                    size="small"
                    style={{
                      borderRadius: '16px',
                      backgroundColor: 'var(--licensing-ok-color)',
                      color: 'white',
                      marginRight: '4px',
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={t('templates.tooltip.role-does-not-matches')}>
                  <Button
                    icon={<WarningOutlined />}
                    type="text"
                    size="small"
                    style={{
                      borderRadius: '16px',
                      backgroundColor: 'var(--warning-color)',
                      color: 'white',
                      marginRight: '4px',
                    }}
                    onClick={() => onEdit?.(field)}
                  />
                </Tooltip>
              ))}
            <Text>{field.role}</Text>
          </>
        )}
      </CellComponent>
      <CellComponent span={12} justify="left">
        {isEditing ? editEmailInput : <Text>{field.email}</Text>}
      </CellComponent>
    </Row>
  )
}

export default RowComponent
