import {
  CalendarOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  NumberOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import {
  Button,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { CustomFieldDataFormat, CustomFieldDataType } from 'types/CustomFields'
import styles from './CustomFields.module.scss'
import CustomFieldsCellComponent from './CustomFieldsCellComponent'

const { Text } = Typography

type EditCustomFieldsRowProps = {
  id: number | string
  data: CustomFieldDataFormat
  remove: (id: number | string) => void
  edit: (id: number | string, value: CustomFieldDataFormat) => void
  isValidating: boolean
}

const iconStyle = { marginRight: '8px' }
const listSeparator = '|'

const defaultMaxCharPerType = (type: string) => {
  if (type === CustomFieldDataType.TEXT) {
    return 120
  }
  if (type === CustomFieldDataType.NUMBER) {
    return 6
  }
  return 10
}

const EditCustomFieldsRow = ({
  data,
  id,
  remove,
  edit,
  isValidating,
}: EditCustomFieldsRowProps) => {
  const { t } = useTranslation()
  const editData = (
    id: number | string,
    field: string,
    value?: string | number | boolean,
  ) => {
    const newValue = { ...data, [field]: value }
    edit(id, newValue)
  }

  const editType = (id: number | string, value: string) => {
    const newValue = {
      ...data,
      type: value,
      maxChar: defaultMaxCharPerType(value),
    }
    edit(id, newValue)
  }

  const options = [
    {
      value: CustomFieldDataType.TEXT,
      label: (
        <>
          <FileTextOutlined style={iconStyle} /> {t('custom-field.text-type')}
        </>
      ),
    },
    {
      value: CustomFieldDataType.NUMBER,
      label: (
        <>
          <NumberOutlined style={iconStyle} /> {t('custom-field.number-type')}
        </>
      ),
    },
    {
      value: CustomFieldDataType.DATE,
      label: (
        <>
          <CalendarOutlined style={iconStyle} /> {t('custom-field.date-type')}
        </>
      ),
    },
    {
      value: CustomFieldDataType.TIME,
      label: (
        <>
          <FieldTimeOutlined style={iconStyle} /> {t('custom-field.time-type')}
        </>
      ),
    },
    {
      value: CustomFieldDataType.LIST,
      label: (
        <>
          <UnorderedListOutlined style={iconStyle} />{' '}
          {t('custom-field.list-type')}
        </>
      ),
    },
  ]

  return (
    <>
      <Row className={styles.rowGrid} gutter={[24, 24]}>
        <CustomFieldsCellComponent span={4}>
          <Select
            value={data.type}
            style={{ width: 130 }}
            options={options}
            onChange={(value: string) => editType(id, value)}
          />
        </CustomFieldsCellComponent>
        <CustomFieldsCellComponent span={6}>
          <Input
            placeholder={t('custom-field.label-header')}
            value={data.label}
            onChange={(e) => editData(id, 'label', e.target.value)}
            className={
              isValidating && !data.label ? 'ant-input-status-error' : undefined
            }
          />
        </CustomFieldsCellComponent>
        <CustomFieldsCellComponent span={6}>
          <Input
            placeholder={t('custom-field.description-header')}
            value={data.description}
            onChange={(e) => editData(id, 'description', e.target.value)}
          />
        </CustomFieldsCellComponent>
        <CustomFieldsCellComponent span={3}>
          <InputNumber
            min={1}
            max={999}
            value={data.maxChar || 120}
            onChange={(e) => editData(id, 'maxChar', e || 0)}
          />
        </CustomFieldsCellComponent>
        <CustomFieldsCellComponent span={3}>
          <Switch
            defaultChecked={
              data.isRequired === undefined ? true : data.isRequired
            }
            onChange={(e) => editData(id, 'isRequired', e)}
          />
        </CustomFieldsCellComponent>
        <CustomFieldsCellComponent span={2}>
          <Button
            icon={<DeleteOutlined onClick={() => remove(id)} />}
            type="text"
          ></Button>
        </CustomFieldsCellComponent>
      </Row>
      {data.type === CustomFieldDataType.LIST && (
        <Row className={styles.rowGrid} gutter={[24, 24]}>
          <CustomFieldsCellComponent span={4}>
            <Text>{t('custom-field.list-values')}</Text>
          </CustomFieldsCellComponent>
          <CustomFieldsCellComponent span={20}>
            <Select
              mode="tags"
              allowClear
              style={{ width: '100%' }}
              placeholder={t('custom-field.list-values')}
              defaultValue={data.options?.split(listSeparator)}
              onChange={(values: string[]) =>
                editData(id, 'options', values.join(listSeparator))
              }
            />
          </CustomFieldsCellComponent>
        </Row>
      )}
    </>
  )
}

export default EditCustomFieldsRow
