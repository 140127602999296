import { Button, Col, Row, Select, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CustomField } from 'types/CustomFields'
import { CompletionReportType } from 'types/Tasks'
import {
  GroupByOptions,
  RechartBarType,
  RechartDataType,
  SummaryType,
  customFieldBarsRecharts,
  getDateFormat,
  summarizeDataByCustomField,
  transformToRechartFormat,
} from './CompletionReportUtils'

const { Title } = Typography

type TrendAnalysisReportProps = {
  taskId: string
  customFields: CustomField
}

const TrendAnalysisReport = ({
  taskId,
  customFields,
}: TrendAnalysisReportProps) => {
  const { t } = useTranslation()
  const { getTaskReportTrend, dateFormat } = useStore((state) => state)
  const [reportData, setReportData] = useState<CompletionReportType[]>([])
  const [rechartData, setRechartData] = useState<RechartDataType[]>([])
  const [rechartBars, setRechartBars] = useState<RechartBarType[]>([])
  const [activeDataKeys, setActiveDataKeys] = useState<string[]>([])
  const [summary, setSummary] = useState<SummaryType | null>(null)
  const [selectedType, setSelectedType] = useState<string>(
    GroupByOptions[0].value,
  )

  useEffect(() => {
    getTaskReportTrend(taskId, selectedType).then((result) => {
      setReportData(result)
    })
  }, [getTaskReportTrend, taskId, selectedType])

  useEffect(() => {
    const currentDateFormat = getDateFormat(selectedType, dateFormat!)
    setRechartData(transformToRechartFormat(reportData, currentDateFormat))
    setSummary(summarizeDataByCustomField(reportData, customFields))
  }, [customFields, dateFormat, reportData, selectedType])

  useEffect(() => {
    const bars = customFieldBarsRecharts(customFields)

    const barKeys = bars.map((bar) => bar.key as string)
    if (barKeys.length) {
      setActiveDataKeys(barKeys)
    }

    bars.sort((a, b) => {
      const nameA = a.name
      const nameB = b.name
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    setRechartBars(bars)
  }, [customFields])

  return (
    <Row gutter={16} style={{ padding: 16 }} justify="start">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={24}>
            <Title level={2}>{t('reports.trend.title')}</Title>
          </Col>
        </Row>
        <Row style={{ marginBottom: 24 }}>
          <Col span={12}>
            <Row style={{ marginBottom: 4 }}>
              <Col span={14}>
                <Title level={4}>{t('reports.trend.summary')}</Title>
              </Col>
              <Col span={10}>
                <Title level={4}>{t('reports.avg')}</Title>
              </Col>
            </Row>
            {summary?.data.map((row, index) => {
              return (
                <Row key={row.status} gutter={8} style={{ marginBottom: 4 }}>
                  <Col span={14}>
                    <Button
                      ghost
                      type="text"
                      style={{
                        color: activeDataKeys.includes(
                          rechartBars[index].key as string,
                        )
                          ? '#000'
                          : 'var(--secondary-text-color)',
                        backgroundColor: activeDataKeys.includes(
                          rechartBars[index].key as string,
                        )
                          ? rechartBars[index].fill
                          : 'var(--default-category-color)',
                        minWidth: 120,
                        marginRight: 8,
                        fontWeight: 800,
                        alignItems: 'flex-start',
                      }}
                      onClick={() => {
                        if (
                          !activeDataKeys.includes(
                            rechartBars[index].key as string,
                          )
                        ) {
                          setActiveDataKeys((prev) => [
                            ...prev,
                            rechartBars[index].key as string,
                          ])
                          return
                        }
                        setActiveDataKeys([rechartBars[index].key as string])
                      }}
                    >
                      {row.label}
                    </Button>
                  </Col>
                  <Col span={10}>{row.value || 0}</Col>
                </Row>
              )
            })}
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={14}>
                <Title level={5}>{t('reports.type')}</Title>
              </Col>
              <Col span={10}>
                <Select
                  options={GroupByOptions}
                  style={{ width: 150 }}
                  value={selectedType}
                  onChange={(value) => setSelectedType(value)}
                ></Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ height: 500 }}>
            <ResponsiveContainer width="100%" height={500}>
              <LineChart
                width={500}
                height={300}
                data={rechartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name">
                  <Label
                    value={t('reports.date')}
                    position="bottom"
                    offset={-8}
                  />
                </XAxis>
                <YAxis />
                <Tooltip
                  labelStyle={{
                    color: '#000',
                  }}
                />
                <Legend />
                {rechartBars.map((item) => (
                  <Line
                    key={item.key}
                    type="monotone"
                    dataKey={item.key}
                    stroke={item.fill}
                    name={item.name}
                    hide={!activeDataKeys.includes(item.key as string)}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TrendAnalysisReport
