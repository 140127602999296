import useStore from 'hooks/useStore'
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion'
import { AppTheme } from 'types/Theme'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ThemeToggle from 'components/ThemeToggle'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Row, Menu, Typography } from 'antd'
import type { SiderTheme } from 'antd/es/layout/Sider'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { ReactComponent as Logo } from 'assets/logo.svg'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import {
  SettingOutlined,
  RetweetOutlined,
  CalendarOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  OneToOneOutlined,
  ApiOutlined,
} from '@ant-design/icons'
import NotificationPane from './NotificationPane'
import NotificationIcon from './NotificationIcon'
import ChatIcon from './ChatIcon'
import styles from './Sidebar.module.scss'

const { Sider } = Layout
const { Title } = Typography
const show = {
  opacity: 1,
  width: 'auto',
  display: 'block',
}

const hide = {
  opacity: 0,
  width: '0px',
  transitionEnd: {
    display: 'none',
  },
}

type LinkLabelProps = {
  to: string
  label: string
}

const LinkLabel = ({ label, to }: LinkLabelProps) => (
  <>
    {label}
    <Link to={to} />
  </>
)

const AppSidebar = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const isTabletOrBelow = useMediaQuery({ query: '(max-width: 1024px)' })
  const { theme, user } = useStore((state) => state)
  const [isCollapsed, setCollapsed] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [activeKey, setActiveKey] = useState('today')
  const [showCurriculum, setShowCurriculum] = useState(false)
  const currentTheme = AppTheme[theme].toLowerCase() as SiderTheme

  useEffect(() => {
    const integrationUsers =
      process.env.REACT_APP_INTEGRATION_USERS?.split(',') || []
    user &&
      setShowCurriculum(integrationUsers.includes(user.data.email) || false)
  }, [user, setShowCurriculum])

  useEffect(() => {
    const nextKey =
      {
        '/': 'today',
        '/calendar': 'calendar',
        '/settings': 'settings',
        '/planning': 'planning',
        '/templates': 'templates',
        '/curriculum': 'curriculum',
        '/timesheets': 'timesheets',
        '/integrations': 'integrations',
        '/routines': 'routines',
        '/routines/all': 'routines-all',
        '/routines/daily': 'routines-daily',
        '/routines/weekly': 'routines-weekly',
        '/tasks': 'tasks',
        '/chat': 'chat',
        '/notifications': 'notifications',
      }[location.pathname] || 'today'

    setActiveKey(nextKey)
  }, [location])

  useEffect(() => {
    setCollapsed(isTabletOrBelow)
  }, [isTabletOrBelow])

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 'notifications',
        icon: <NotificationIcon />,
        label: t('sidebar.notifications'),
      },
      {
        key: 'chat',
        icon: <ChatIcon />,
        label: <LinkLabel label={t('sidebar.chat')} to="/chat" />,
      },
      {
        key: 'today',
        icon: <Sun height="16px" width="16px" />,
        label: <LinkLabel label={t('sidebar.today')} to="/" />,
      },
      {
        key: 'calendar',
        icon: <CalendarOutlined />,
        label: <LinkLabel label={t('sidebar.calendar')} to="/calendar" />,
      },
      {
        key: 'tasks',
        icon: <OrderedListOutlined />,
        label: <LinkLabel label={t('sidebar.tasks')} to="/tasks" />,
      },
      {
        key: 'routines',
        icon: <RetweetOutlined />,
        label: <LinkLabel label={t('sidebar.routines')} to="/routines" />,
      },
      {
        key: 'planning',
        icon: <ScheduleOutlined />,
        label: <LinkLabel label={t('sidebar.planning')} to="/planning" />,
      },
      {
        key: 'templates',
        icon: <OneToOneOutlined />,
        label: <LinkLabel label={t('sidebar.templates')} to="/templates" />,
      },
      {
        key: 'integrations',
        icon: <ApiOutlined />,
        label: <LinkLabel label="Integrations" to="/integrations" />,
      },
      {
        key: 'settings',
        icon: <SettingOutlined />,
        label: <LinkLabel label={t('sidebar.settings')} to="/settings" />,
      },
    ]
    if (showCurriculum) {
      items.push({
        key: 'curriculum',
        icon: <SchoolOutlinedIcon />,
        label: <LinkLabel label={t('sidebar.curriculum')} to="/curriculum" />,
      })
    }
    return items
  }, [showCurriculum, t])

  return (
    <Sider
      collapsible={!isTabletOrBelow}
      theme={currentTheme}
      collapsed={isCollapsed}
      onCollapse={() => setCollapsed((prevState) => !prevState)}
      className={styles.sidebar}
    >
      <Row>
        <Link
          to="/"
          style={{
            display: 'flex',
            margin: '10px auto',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Logo height="32px" width="32px" />
          <motion.span animate={isCollapsed ? hide : show}>
            <Title level={3} ellipsis={{ rows: 1 }} style={{ margin: '0px' }}>
              TepinTasks
            </Title>
          </motion.span>
        </Link>
      </Row>
      <Menu
        mode="inline"
        theme={currentTheme}
        selectedKeys={[activeKey]}
        onClick={({ key }) => {
          if (key === 'notifications') setShowNotifications(!showNotifications)
        }}
        className={styles.menu}
        items={menuItems}
      />
      <Row
        style={{
          display: 'flex',
          margin: '10px auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ThemeToggle />
      </Row>
      {showNotifications && (
        <NotificationPane
          isCollapsed={isCollapsed}
          closeModal={setShowNotifications}
        />
      )}
    </Sider>
  )
}

export default AppSidebar
