import './index.css'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  $isListItemNode,
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import { InsertInlineImageDialog } from './InlineImagePlugin'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { ReactComponent as Redo } from '../../../assets/wysiwyg/arrow-clockwise.svg'
import { ReactComponent as Undo } from '../../../assets/wysiwyg/arrow-counterclockwise.svg'
import { ReactComponent as Indent } from '../../../assets/wysiwyg/indent.svg'
import { ReactComponent as Link } from '../../../assets/wysiwyg/link.svg'
import { ReactComponent as NumberedList } from '../../../assets/wysiwyg/list-ol.svg'
import { ReactComponent as BulletList } from '../../../assets/wysiwyg/list-ul.svg'
import { ReactComponent as Outdent } from '../../../assets/wysiwyg/outdent.svg'
import { ReactComponent as CheckList } from '../../../assets/wysiwyg/square-check.svg'
import { ReactComponent as Bold } from '../../../assets/wysiwyg/type-bold.svg'
import { ReactComponent as H1 } from '../../../assets/wysiwyg/type-h1.svg'
import { ReactComponent as H2 } from '../../../assets/wysiwyg/type-h2.svg'
import { ReactComponent as H3 } from '../../../assets/wysiwyg/type-h3.svg'
import { ReactComponent as Italic } from '../../../assets/wysiwyg/type-italic.svg'
import { ReactComponent as Strikethrough } from '../../../assets/wysiwyg/type-strikethrough.svg'
import { ReactComponent as Underline } from '../../../assets/wysiwyg/type-underline.svg'
import { ReactComponent as FileImage } from '../../../assets/wysiwyg/file-image.svg'
import { ReactComponent as TextCenter } from '../../../assets/wysiwyg/text-center.svg'
import { ReactComponent as TextLeft } from '../../../assets/wysiwyg/text-left.svg'
import { ReactComponent as TextRight } from '../../../assets/wysiwyg/text-right.svg'
import { ReactComponent as TextJustify } from '../../../assets/wysiwyg/justify.svg'
import { ReactComponent as PicLeft } from '../../../assets/wysiwyg/pic-left.svg'
import { $handleIndent, $handleOutdent } from '../utils/formatListUtils'
import { getSelectedNode } from '../utils/getSelectedNode'
import { sanitizeUrl } from '../utils/url'
import useModal from '../hooks/useModal'
import { InsertImageDialog } from './ImagesPlugin'

const LowPriority = 1

function Divider() {
  return <div className="divider" />
}

function positionEditorElement(editor: HTMLElement, rect: DOMRect | null) {
  if (rect === null) {
    editor.style.opacity = '0'
    editor.style.top = '-1000px'
    editor.style.left = '-1000px'
  } else {
    editor.style.opacity = '1'
    editor.style.top = `${rect.top + rect.height + window.scrollY + 10}px`
    editor.style.left = `${
      rect.left + window.scrollX - editor.offsetWidth / 2 + rect.width / 2
    }px`
  }
}

function FloatingLinkEditor({ editor }: { editor: LexicalEditor }) {
  const editorRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const mouseDownRef = useRef(false)
  const [linkUrl, setLinkUrl] = useState('')
  const [isEditMode, setEditMode] = useState(false)
  const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null)

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
      } else {
        setLinkUrl('')
      }
    }
    const editorElem = editorRef.current
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (editorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0)
      let rect: DOMRect | undefined
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement
        }
        rect = inner.getBoundingClientRect()
      } else {
        rect = domRange.getBoundingClientRect()
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect)
      }
      setLastSelection(selection)
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null)
      setLastSelection(null)
      setEditMode(false)
      setLinkUrl('')
    }

    return true
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor()
          return true
        },
        LowPriority,
      ),
    )
  }, [editor, updateLinkEditor])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor()
    })
  }, [editor, updateLinkEditor])

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl)
                }
                setEditMode(false)
              }
            } else if (event.key === 'Escape') {
              event.preventDefault()
              setEditMode(false)
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext()
  const [modal, showModal] = useModal()

  const [activeEditor, setActiveEditor] = useState(editor)
  const [headingType, setHeadingType] = useState<string>('')
  const [isLargeHeading, setIsLargeHeading] = useState(false)
  const [isMediumHeading, setIsMediumHeading] = useState(false)
  const [isSmallHeading, setIsSmallHeading] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isBulletList, setIsBulletList] = useState(false)
  const [isNumberedList, setIsNumberedList] = useState(false)
  const [isCheckList, setIsCheckList] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      if (elementDOM !== null) {
        // Update list format
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()

          setIsBulletList(
            parentList?.getListType() === 'bullet' && type === 'ul',
          )
          setIsNumberedList(
            parentList?.getListType() === 'number' && type === 'ol',
          )
          setIsCheckList(parentList?.getListType() === 'check' && type === 'ul')
        } else {
          // Update heading format
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()

          setHeadingType(type)
          setIsLargeHeading(
            $isHeadingNode(element) && element.getTag() === 'h1',
          )
          setIsMediumHeading(
            $isHeadingNode(element) && element.getTag() === 'h2',
          )
          setIsSmallHeading(
            $isHeadingNode(element) && element.getTag() === 'h3',
          )
          setIsBulletList(false)
          setIsNumberedList(false)
          setIsCheckList(false)
        }
      }

      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [activeEditor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar()
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      activeEditor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        LowPriority,
      ),
      activeEditor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        LowPriority,
      ),
    )
  }, [activeEditor, editor, $updateToolbar])

  const formatParagraph = () => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatLargeHeading = () => {
    activeEditor.update(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection) && headingType === 'h1') {
        formatParagraph()
        return
      }
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode('h1'))
      }
    })
  }

  const formatMediumHeading = () => {
    activeEditor.update(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection) && headingType === 'h2') {
        formatParagraph()
        return
      }
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode('h2'))
      }
    })
  }

  const formatSmallHeading = () => {
    activeEditor.update(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection) && headingType === 'h3') {
        formatParagraph()
        return
      }
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode('h3'))
      }
    })
  }

  const formatBulletList = () => {
    activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
  }

  const formatNumberedList = () => {
    activeEditor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
  }

  const formatCheckList = () => {
    editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
  }

  const indentSelection = () => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if (!selection) {
        return
      }
      const nodes = selection.getNodes()
      for (const node of nodes) {
        const parent = node.getParent()
        if ($isListItemNode(parent)) {
          $handleIndent(parent)
        }
      }
    })
  }

  const outdentSelection = () => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if (!selection) {
        return
      }
      const nodes = selection.getNodes()
      for (const node of nodes) {
        const parent = node.getParent()
        if ($isListItemNode(parent)) {
          $handleOutdent(parent)
        }
      }
    })
  }

  const insertLink = useCallback(() => {
    if (!isLink) {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [activeEditor, isLink])

  return (
    <div className="toolbar">
      <button
        disabled={!canUndo}
        className="toolbar-item spaced"
        aria-label="Undo"
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
        }}
        type="button"
      >
        <i className="format undo">
          <Undo fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        disabled={!canRedo}
        className="toolbar-item spaced"
        aria-label="Redo"
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined)
        }}
        type="button"
      >
        <i className="format redo">
          <Redo fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <Divider />
      <button
        onClick={formatLargeHeading}
        className={'toolbar-item spaced ' + (isLargeHeading ? 'active' : '')}
        aria-label="Format h1"
        type="button"
      >
        <i className="format h1">
          <H1 fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={formatMediumHeading}
        className={'toolbar-item spaced ' + (isMediumHeading ? 'active' : '')}
        aria-label="Format h2"
        type="button"
      >
        <i className="format h2">
          <H2 fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={formatSmallHeading}
        className={'toolbar-item spaced ' + (isSmallHeading ? 'active' : '')}
        aria-label="Format h3"
        type="button"
      >
        <i className="format h3">
          <H3 fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <Divider />
      <button
        onClick={(e) => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label="Format Bold"
        type="button"
      >
        <i style={{ color: '#fff' }} className="format bold">
          <Bold fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={(e) => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        aria-label="Format Italics"
        type="button"
      >
        <i className="format italic">
          <Italic fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={(e) => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        aria-label="Format Underline"
        type="button"
      >
        <i className="format underline">
          <Underline fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={(e) => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
        aria-label="Format Strikethrough"
        type="button"
      >
        <i className="format strikethrough">
          <Strikethrough fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <Divider />
      <button
        onClick={formatBulletList}
        className={'toolbar-item spaced ' + (isBulletList ? 'active' : '')}
        aria-label="Format Bullet List"
        type="button"
      >
        <i className="format bullet-list">
          <BulletList fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={formatNumberedList}
        className={'toolbar-item spaced ' + (isNumberedList ? 'active' : '')}
        aria-label="Format Number List"
        type="button"
      >
        <i className="format number-list">
          <NumberedList fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={formatCheckList}
        className={'toolbar-item spaced ' + (isCheckList ? 'active' : '')}
        aria-label="Format Check List"
        type="button"
      >
        <i className="format check-list">
          <CheckList fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
        className={'toolbar-item spaced '}
        aria-label="Text Left"
        type="button"
      >
        <i className="format text-left">
          <TextLeft fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
        className={'toolbar-item spaced '}
        aria-label="Text Center"
        type="button"
      >
        <i className="format text-center">
          <TextCenter fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
        className={'toolbar-item spaced '}
        aria-label="Text Right"
        type="button"
      >
        <i className="format text-right">
          <TextRight fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
        className={'toolbar-item spaced '}
        aria-label="Justify"
        type="button"
      >
        <i className="format text-justify">
          <TextJustify fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <Divider />
      <button
        onClick={indentSelection}
        className={'toolbar-item spaced '}
        aria-label="Indent"
        type="button"
      >
        <i className="format indent">
          <Indent fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={outdentSelection}
        className={'toolbar-item spaced '}
        aria-label="Outdent"
        type="button"
      >
        <i className="format outdent">
          <Outdent fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <Divider />
      <button
        onClick={insertLink}
        className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
        aria-label="Format Link"
        type="button"
      >
        <i className="format link">
          <Link fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      {isLink &&
        createPortal(
          <FloatingLinkEditor editor={activeEditor} />,
          document.body,
        )}
      <button
        onClick={() => {
          showModal('Insert Image', (onClose) => (
            <InsertImageDialog activeEditor={activeEditor} onClose={onClose} />
          ))
        }}
        className={'toolbar-item image'}
        aria-label="Image"
        type="button"
        disabled={!isEditable}
      >
        <i className="format image">
          <FileImage fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      <button
        onClick={() => {
          showModal('Insert Inline Image', (onClose) => (
            <InsertInlineImageDialog
              activeEditor={activeEditor}
              onClose={onClose}
            />
          ))
        }}
        className={'toolbar-item inline'}
        aria-label="Inline Image"
        type="button"
        disabled={!isEditable}
      >
        <i className="format inline">
          <PicLeft fill="var(--text-color)" width="18" height="18" />
        </i>
      </button>
      {modal}
    </div>
  )
}
