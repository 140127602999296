import type { StateCreator } from 'zustand'
import { AppTheme } from '../@types/Theme'

export type ThemeState = {
  theme: AppTheme
  toggleTheme: () => void
}

export const createThemeSlice: StateCreator<ThemeState> = (set) => ({
  theme: AppTheme.LIGHT,
  toggleTheme: () =>
    set((state) => {
      const nextTheme =
        state.theme === AppTheme.DARK ? AppTheme.LIGHT : AppTheme.DARK

      return {
        theme: nextTheme,
      }
    }),
})
