import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import {
  Curriculum,
  CurriculumTaskItem,
  CurriculumFilterOptions,
  UpdateCurriculumProps,
  CurriculumStatusId,
} from 'types/Curriculum'
import { TaskItem } from 'types/Tasks'
import {
  calculateCompletedCurriculumTaskPercentage,
  buildFilterQuery,
} from 'utils/taskUtils'

export interface CurriculumsState extends ApiState {
  selectedCurriculum: string | undefined
  curriculums: Curriculum[]
  myCurriculums: Curriculum[]
  delegatedCurriculums: Curriculum[]
  libraryCurriculums: Curriculum[]
  setSelectedCurriculum: (id: string | undefined) => void
  getCurriculums: (groups?: number[]) => Promise<void>
  getCurriculum: (id: string) => Promise<Curriculum>
  createCurriculum: (data: {
    title: string
    group: number
  }) => Promise<Curriculum>
  updateCurriculum: (
    id: string,
    data: Partial<UpdateCurriculumProps>,
  ) => Promise<Curriculum>
  deleteCurriculum: (id: string) => Promise<boolean>
  moveTaskPositionInCurriculum: (
    taskId: string,
    previousSortingCode?: string,
    afterSortingCode?: string,
  ) => Promise<CurriculumTaskItem>
  duplicateCurriculum: (id: string) => Promise<boolean>
  shouldUpdateCurriculums: boolean
  setShouldUpdateCurriculums: Function
  shouldUpdateCurriculumView: boolean
  setShouldUpdateCurriculumView: Function
  shouldAddNextTaskToCurriculum: boolean
  setShouldAddNextTaskToCurriculum: Function
  addTaskToCurriculum: (newTasks: TaskItem) => Promise<void>
  selectedCurriculumTab: CurriculumFilterOptions | undefined
  setSelectedCurriculumTab: (id: CurriculumFilterOptions) => void
  shouldAddNextRoutineToCurriculum: boolean
  setShouldAddNextRoutineToCurriculum: (
    newShouldAddNextRoutineToCurriculum: boolean,
  ) => void
  generateNextLesson: (id: string) => Promise<boolean>
  updateCurriculumLesson: (
    id: number,
    data: Partial<CurriculumTaskItem>,
  ) => Promise<void>
  updateCurriculumProgressRatio: (id: number) => Promise<void>
  preserveSelectedCurriculumOnUpdate: boolean
  isLibraryViewEditing: boolean
  setIsLibraryViewEditing: (newEditMode: boolean) => void
  deleteCurriculumTask: (taskId: number) => Promise<void>
}

export const createCurriculumsSlice: StateCreator<
  CurriculumsState,
  [['zustand/immer', never]]
> = (set, get) => ({
  selectedCurriculum: undefined,
  curriculums: [],
  myCurriculums: [],
  delegatedCurriculums: [],
  libraryCurriculums: [],
  setSelectedCurriculum: (id) => {
    set((draft) => {
      draft.selectedCurriculum = id
    })
  },
  getCurriculums: async (groups) => {
    const filters = new Map<string, number[]>()
    if (groups) {
      if (groups.length === 0) {
        // Send a value in group parameter when used deselect all groups
        filters.set('group', [0])
      } else {
        filters.set('group', groups)
      }
    }
    const filterQuery = buildFilterQuery(filters)
    try {
      const response = await Promise.all([
        get().api!('GET', `/curriculum?type=own&${filterQuery}`),
        get().api!('GET', `/curriculum?type=delegated&${filterQuery}`),
        get().api!('GET', `/curriculum?type=template&${filterQuery}`),
      ])
      const [myCurriculums, delegatedCurriculums, libraryCurriculums] =
        response.map((res) => res.data)
      set((draft) => {
        draft.curriculums = [
          ...myCurriculums,
          ...delegatedCurriculums,
          ...libraryCurriculums,
        ]
        draft.myCurriculums = myCurriculums
        draft.delegatedCurriculums = delegatedCurriculums
        draft.libraryCurriculums = libraryCurriculums
        if (!draft.preserveSelectedCurriculumOnUpdate) {
          draft.selectedCurriculum = undefined
        }
        draft.preserveSelectedCurriculumOnUpdate = false
      })
    } catch (error) {
      console.error('Error getting curriculums', error)
      throw error
    }
  },
  getCurriculum: async (id) => {
    try {
      const [curriculumData, curriculumTaskList] = await Promise.all([
        get().api!('GET', `/curriculum/${id}`),
        get().api!('GET', `/curriculum/task/list/${id}`),
      ])
      return {
        ...curriculumData.data,
        tasks: curriculumTaskList.data,
      }
    } catch (error) {
      console.error('Error getting curriculum', error)
      throw error
    }
  },
  createCurriculum: async (data) => {
    const response = await get().api!('POST', '/curriculum/', {
      title: data.title,
      group: data.group,
      mode: 'template',
    })
    const newCurriculum = response.data
    if (newCurriculum) {
      set((draft) => {
        draft.preserveSelectedCurriculumOnUpdate = true
        draft.selectedCurriculum = newCurriculum.id
        draft.isLibraryViewEditing = true
      })
    }
    return newCurriculum
  },
  updateCurriculum: async (id, data) => {
    const response = await get().api!('PATCH', `/curriculum/${id}`, {
      ...data,
    })
    if (response.status === 200) {
      set((draft) => {
        draft.preserveSelectedCurriculumOnUpdate = true
        if (data.group) {
          draft.shouldUpdateCurriculums = true
        } else {
          draft.shouldUpdateCurriculumView = true
        }
      })
      return response.data
    }
  },
  deleteCurriculum: async (id) => {
    const response = await get().api!('DELETE', `curriculum/${id}`)
    if (response.status === 200) {
      get().setSelectedCurriculum(undefined)
      return true
    }
    return false
  },
  moveTaskPositionInCurriculum: async (
    taskId,
    previousSortingCode,
    afterSortingCode,
  ) => {
    const response = await get().api!('PATCH', `/curriculum/task/${taskId}`, {
      sortCodeBefore: previousSortingCode,
      sortCodeAfter: afterSortingCode,
    })
    if (response.status === 200) {
      return response.data
    }
  },
  duplicateCurriculum: async (id) => {
    const response = await get().api!('POST', `curriculum/clone/${id}`)
    if (response.status === 200) {
      get().setSelectedCurriculum(response.data.id)
      get().preserveSelectedCurriculumOnUpdate = true
      get().setShouldUpdateCurriculums(true)
      get().setIsLibraryViewEditing(true)
      return true
    }
    return false
  },
  shouldUpdateCurriculums: true,
  setShouldUpdateCurriculums: (newShouldUpdateCurriculums: boolean) => {
    set((draft) => {
      draft.shouldUpdateCurriculums = newShouldUpdateCurriculums
    })
  },
  shouldUpdateCurriculumView: true,
  setShouldUpdateCurriculumView: (newShouldUpdateCurriculumView: boolean) => {
    set((draft) => {
      draft.shouldUpdateCurriculumView = newShouldUpdateCurriculumView
    })
  },
  shouldAddNextTaskToCurriculum: false,
  setShouldAddNextTaskToCurriculum: (
    newShouldAddNextTaskToCurriculum: boolean,
  ) => {
    set((draft) => {
      draft.shouldAddNextTaskToCurriculum = newShouldAddNextTaskToCurriculum
    })
  },
  addTaskToCurriculum: async (newTask: TaskItem) => {
    await get().api!('POST', '/curriculum/task', {
      curriculum: get().selectedCurriculum,
      template_task: newTask.id,
    })
  },
  selectedCurriculumTab: undefined,
  setSelectedCurriculumTab: (id) => {
    set((draft) => {
      draft.selectedCurriculumTab = id
    })
  },
  shouldAddNextRoutineToCurriculum: false,
  setShouldAddNextRoutineToCurriculum: (
    newShouldAddNextRoutineToCurriculum,
  ) => {
    set((draft) => {
      draft.shouldAddNextRoutineToCurriculum =
        newShouldAddNextRoutineToCurriculum
    })
  },
  generateNextLesson: async (id) => {
    const response = await get().api!('POST', '/curriculum/task/create', {
      curriculum: get().selectedCurriculum,
      templateTask: id,
    })
    if (response.status === 201) {
      return true
    }
    return false
  },
  updateCurriculumLesson: async (id, data) => {
    const response = await get().api!('PATCH', `/curriculum/task/${id}`, data)
    // Update the curriculum progress ratio if updating a curriculum task to completed
    if (
      response.status === 200 &&
      data.status &&
      data.status === CurriculumStatusId.COMPLETED
    ) {
      get().updateCurriculumProgressRatio(response.data.curriculum)
    }

    return response.data
  },
  updateCurriculumProgressRatio: async (id) => {
    const { status, data: tasks } = await get().api!(
      'GET',
      `/curriculum/task/list/${id}`,
    )
    if (status === 200) {
      const progressRatio = calculateCompletedCurriculumTaskPercentage(tasks)
      await get().api!('PATCH', `/curriculum/${id}`, {
        progressRatio: progressRatio,
      })
    }
    get().setShouldUpdateCurriculumView(true)
  },
  preserveSelectedCurriculumOnUpdate: false,
  isLibraryViewEditing: false,
  setIsLibraryViewEditing: (newEditMode) => {
    set((draft) => {
      draft.isLibraryViewEditing = newEditMode
    })
  },
  deleteCurriculumTask: async (taskId) => {
    await get().api!('DELETE', `/curriculum/task/${taskId}`)
    get().setShouldUpdateCurriculumView(true)
  },
})
