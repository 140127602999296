import { Button, Col, Row, Select, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CompletionReportType } from 'types/Tasks'
import {
  GroupByOptions,
  RechartBarType,
  RechartDataType,
  SummaryType,
  getCompletedPercentage,
  getDateFormat,
  statusDetailsRecharts,
  summarizeData,
  transformToRechartFormat,
} from './CompletionReportUtils'

const { Text, Title } = Typography

type RoutineReportProps = {
  taskId: string
}

const RoutineReport = ({ taskId }: RoutineReportProps) => {
  const { t } = useTranslation()
  const statusList = useStore((state) => state.tasks?.statusList)
  const { getTaskReportRoutine, dateFormat } = useStore((state) => state)
  const [reportData, setReportData] = useState<CompletionReportType[]>([])
  const [rechartData, setRechartData] = useState<RechartDataType[]>([])
  const [rechartBars, setRechartBars] = useState<RechartBarType[]>([])
  const [summary, setSummary] = useState<SummaryType | null>(null)
  const [completedPercentage, setCompletedPercentage] = useState<number>(0)
  const [selectedType, setSelectedType] = useState<string>(
    GroupByOptions[0].value,
  )

  useEffect(() => {
    getTaskReportRoutine(taskId, selectedType).then((result) => {
      setReportData(result)
    })
  }, [getTaskReportRoutine, taskId, selectedType])

  useEffect(() => {
    const currentDateFormat = getDateFormat(selectedType, dateFormat!)
    setRechartData(transformToRechartFormat(reportData, currentDateFormat))
    setSummary(summarizeData(reportData, statusList))
  }, [reportData, statusList, selectedType, dateFormat])

  useEffect(() => {
    setRechartBars(statusDetailsRecharts(statusList))
  }, [statusList])

  useEffect(() => {
    if (summary) {
      setCompletedPercentage(getCompletedPercentage(summary))
    }
  }, [summary])

  return (
    <Row gutter={16} style={{ padding: 16 }} justify="start">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={24}>
            <Title level={2}>{t('reports.routine.title')}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={14}>
                <Title level={5}>
                  {t('reports.routine.completion-percentage')}
                </Title>
              </Col>
              <Col span={10}>
                <Text strong>{completedPercentage}%</Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title level={5}>{t('reports.routine.summary')}</Title>
              </Col>
            </Row>
            {summary?.data.map((row) => (
              <Row key={row.status} gutter={8} style={{ marginBottom: 4 }}>
                <Col span={14}>
                  <Button
                    type="text"
                    style={{
                      backgroundColor: row.color,
                      width: 120,
                      marginRight: 8,
                    }}
                  >
                    {row.label}
                  </Button>
                </Col>
                <Col span={10}>
                  {row.value} / {summary.total}
                </Col>
              </Row>
            ))}
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={14}>
                <Title level={5}>{t('reports.type')}</Title>
              </Col>
              <Col span={10}>
                <Select
                  options={GroupByOptions}
                  style={{ width: 150 }}
                  value={selectedType}
                  onChange={(value) => setSelectedType(value)}
                ></Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ height: 500 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={rechartData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name">
                  <Label
                    value={t('reports.date')}
                    position="bottom"
                    offset={-8}
                  />
                </XAxis>
                <YAxis />
                <Tooltip
                  itemStyle={{
                    color: '#000',
                  }}
                  labelStyle={{
                    color: '#000',
                  }}
                />
                <Legend />
                {rechartBars.map((item) => (
                  <Bar
                    key={item.key}
                    dataKey={item.key}
                    name={item.name}
                    stackId="a"
                    fill={item.fill}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default RoutineReport
