import { Button, Popconfirm, Space, Spin } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTaskFlowViewer } from 'sections/TaskFlowViewer'

export type HeaderProps = {
  numberTasksCreated: number
  numberOfTasksModified: number
  loading: boolean
  discard: () => void
  saveAndClose: () => void
  close: () => void
  markAllAsActive: () => void
  convertToDraft: () => void
  convertToTemplate: () => void
}

function Header(props: Readonly<HeaderProps>) {
  const { t } = useTranslation()
  const {
    numberTasksCreated,
    numberOfTasksModified,
    loading,
    discard,
    saveAndClose,
    convertToDraft,
    close,
    markAllAsActive,
    convertToTemplate,
  } = props
  const [isKeepNewOnesOpen, setIsKeepNewOnesOpen] = useState(false)
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false)
  const { isTemplateMode } = useTaskFlowViewer()

  const discardAndDelete = () => {
    if (numberTasksCreated > 0) {
      discard()
    } else {
      closeModal()
    }
  }

  const closeModal = () => {
    setIsKeepNewOnesOpen(false)
    setIsCancelConfirmOpen(false)
    close()
  }

  const doNotDiscardAndDelete = () => {
    if (numberTasksCreated > 0) {
      setIsKeepNewOnesOpen(true)
    }
    setIsCancelConfirmOpen(false)
  }

  const doNotDiscardChanges = () => {
    setIsKeepNewOnesOpen(false)
  }

  const handleCancelClick = (newOpen: boolean) => {
    if (!newOpen) {
      setIsCancelConfirmOpen(newOpen)
      return
    }

    if (numberOfTasksModified === 0 && numberTasksCreated === 0) {
      closeModal()
    } else {
      setIsCancelConfirmOpen(newOpen)
    }
  }

  return (
    <Space>
      {isTemplateMode ? (
        <Button onClick={convertToDraft}>
          {t('planning.convert-to-draft')}
        </Button>
      ) : (
        <>
          <Button onClick={markAllAsActive}>
            {t('planning.mark-all-as-active')}
          </Button>
          <Button onClick={convertToTemplate}>
            {t('planning.convert-to-template')}
          </Button>
        </>
      )}

      <Popconfirm
        title={t('planning.keep-new-title')}
        description={t('planning.keep-and-discard')}
        open={isKeepNewOnesOpen}
        onConfirm={closeModal}
        onCancel={doNotDiscardChanges}
        okText={t('actions.yes')}
        cancelText={t('actions.no')}
      />
      <Popconfirm
        title={t('planning.discard-changes')}
        description={
          numberTasksCreated === 0
            ? t('planning.sure-want-to-discard')
            : t('planning.discard-and-delete', {
                ns: 'common',
                number: numberTasksCreated,
              })
        }
        open={isCancelConfirmOpen}
        onOpenChange={handleCancelClick}
        onConfirm={discardAndDelete}
        onCancel={doNotDiscardAndDelete}
        okText={t('actions.yes')}
        cancelText={t('actions.no')}
      >
        <Button>{t('actions.cancel')}</Button>
      </Popconfirm>
      <Button onClick={saveAndClose} type="primary" disabled={loading}>
        {t('planning.save-and-close')}
      </Button>
      {loading && (
        <div className="loader">
          <Spin spinning={loading} delay={500} />
        </div>
      )}
    </Space>
  )
}

export default Header
