import { createTheme } from '@mui/material/styles'
import { AppTheme } from 'types/Theme'

const getDesignTokens = (mode: any, isLightTheme: boolean) => ({
  palette: {
    mode,
    ...(!isLightTheme && {
      background: {
        default: '#1f1f1f',
        paper: '#1f1f1f',
      },
      text: {
        primary: '#ffffffd8',
        secondary: '#ffffffd8',
      },
    }),
  },
})

export const muiTheme = (currentTheme: any) => {
  const theme = AppTheme[currentTheme].toLowerCase()
  return createTheme(getDesignTokens(theme, currentTheme === AppTheme.LIGHT))
}
