import { PicCenterOutlined } from '@ant-design/icons'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Button, Col, Collapse, Input, Row, Tooltip, Typography } from 'antd'
import { SearchProps } from 'antd/es/input'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import CheckboxMenu from 'components/CheckboxMenu'
import Switch from 'components/Switch'
import useStore from 'hooks/useStore'
import { produce } from 'immer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TaskLists } from 'services/Tasks.slice'
import styles from './TasksList.module.scss'
import { useTasksList } from './useTasksList'

const { Title } = Typography
const { Search } = Input

function filterTasks(tasks: TaskLists, filterString: string): TaskLists {
  if (!filterString) {
    return tasks
  }
  const taskList: TaskLists = {}
  for (const key in tasks) {
    taskList[key] = tasks[key].filter((task) =>
      task.title.toLowerCase().includes(filterString.toLowerCase()),
    )
  }
  return taskList
}

interface TasksListProps {
  activeKeys: string[]
  setActiveKeys: (keys: string[]) => void
  tasks: TaskLists
}

export default function TasksList({
  activeKeys,
  setActiveKeys,
  tasks,
}: Readonly<TasksListProps>) {
  const { t } = useTranslation()
  const isNewLogin = useStore((state) => state.isNewLogin)
  const [taskList, setTaskList] = useState<TaskLists>({})
  const [searchValue, setSearchValue] = useState('')
  const [isExpanded, setIsExpanded] = useState(isNewLogin ? true : false)
  const [individualToggledTaskIDs, setIndividualToggledTaskIDs] = useState<
    string[]
  >([])
  const taskLevels = useStore((state) => state.taskLevels)
  const selectedTaskLevels = useStore((state) => state.selectedTaskLevels)
  const setSelectedTaskLevels = useStore((state) => state.setSelectedTaskLevels)
  const { includeTasksAssignedToMe, setIncludeTasksAssignedToMe } = useStore(
    (state) => state,
  )

  const {
    baseTasksFilter,
    completedTasks,
    dueTodayTasks,
    filterText,
    getItems,
    priorityGroups,
    totalTodayTasks,
  } = useTasksList(tasks)

  const onChange = (key: string | string[]) => {
    const newKeys = Array.isArray(key) ? key : [key]
    setActiveKeys(newKeys)
  }

  const onFilterChange = (checkedValue: CheckboxValueType[]) => {
    const parsedCheckedValue = checkedValue.flatMap((value) => value as number)
    setSelectedTaskLevels(parsedCheckedValue)
  }

  const handleToggleExpand = () => {
    setIsExpanded((prevState) => !prevState)
    setIndividualToggledTaskIDs([])
    useStore.setState(
      produce((state) => {
        state.isNewLogin = !state.isNewLogin
      }),
    )
  }

  const onSearch: SearchProps['onSearch'] = (value, _, info) => {
    setSearchValue(value)
  }

  useEffect(() => {
    setTaskList(filterTasks(tasks, searchValue))
  }, [searchValue, tasks])

  return (
    <div className={styles.tasksListWrapper}>
      <Row className={styles.tasksListHeader} align="middle" justify="end">
        <Col flex="auto">
          <Row gutter={[16, 16]} justify="start">
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {totalTodayTasks ? `${completedTasks}/${totalTodayTasks}` : 0}{' '}
                {t('my-today-page.completed-tasks', { ns: 'common' })}
              </Title>
            </Col>
            <Col>
              <Title level={5} style={{ margin: '0' }}>
                {dueTodayTasks} {t('my-today-page.due-today', { ns: 'common' })}
              </Title>
            </Col>
          </Row>
        </Col>
        <Col flex="160px" style={{ paddingTop: '8px' }}>
          <Tooltip title={t('my-today-page.include-delegated-to-me-tooltip')}>
            <Switch
              initialValue={includeTasksAssignedToMe}
              onChange={(value) => setIncludeTasksAssignedToMe(value)}
              size="small"
              label={t('my-today-page.include-delegated-to-me')}
            />
          </Tooltip>
        </Col>
        <Col flex="30px">
          <Tooltip title={filterText}>
            <CheckboxMenu
              options={[...taskLevels, baseTasksFilter]}
              value={selectedTaskLevels}
              onChange={onFilterChange}
              icon={<FilterAltIcon />}
            />
          </Tooltip>
        </Col>
        <Col flex="30px">
          <Tooltip
            title={
              isExpanded
                ? t('my-today-page.collapse')
                : t('my-today-page.expand')
            }
          >
            <Button
              type="text"
              onClick={handleToggleExpand}
              className={styles.expandToggler}
              icon={<PicCenterOutlined style={{ fontSize: 16 }} />}
            />
          </Tooltip>
        </Col>
      </Row>

      <Search
        placeholder="Search"
        style={{ width: '100%', marginBottom: '8px' }}
        onSearch={onSearch}
        enterButton
        allowClear
      />

      <Collapse
        activeKey={activeKeys}
        onChange={onChange}
        className={styles.taskPanel}
        items={getItems(
          taskList,
          activeKeys,
          isExpanded,
          priorityGroups,
          individualToggledTaskIDs,
          setIndividualToggledTaskIDs,
        )}
      />
    </div>
  )
}
