import { Popover } from 'antd'
import type { DisplayValueType } from 'rc-select/lib/BaseSelect'
import { useTranslation } from 'react-i18next'
import { EmailOmmitedValuesPopover } from './EmailOmmitedValuesPopover'

export const EmailOmmitedValuesRender = (omittedValues: DisplayValueType[]) => {
  const { t } = useTranslation()
  return (
    <Popover
      content={<EmailOmmitedValuesPopover omittedValues={omittedValues} />}
      trigger="hover"
      arrow={false}
    >
      <span>
        {t('email-selector.n-more', {
          ns: 'common',
          number: omittedValues.length,
        })}
      </span>
    </Popover>
  )
}
