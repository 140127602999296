import { EditRecurrenceMenu } from '@devexpress/dx-react-scheduler-material-ui'
import { useTranslation } from 'react-i18next'

const CalendarEditRoutineMenu = ({
  availableOperations,
  ...restProps
}: EditRecurrenceMenu.LayoutProps) => {
  const { t } = useTranslation()
  const allowedOperations = [
    { value: 'current', title: t('calendar.change-current-instance') },
  ]

  return (
    <EditRecurrenceMenu.Layout
      availableOperations={allowedOperations}
      {...restProps}
    />
  )
}

export default CalendarEditRoutineMenu
