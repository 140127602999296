import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Select, Space, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchUser, { UserSearch } from './SearchUser'

const { Text, Title } = Typography

type AddTaskMemberProps = {
  addTaskMember: (userId: string, roleId: number) => void
  cancelEditing: () => void
}

const AddTaskMember = ({
  addTaskMember,
  cancelEditing,
}: AddTaskMemberProps) => {
  const { t } = useTranslation()
  const [isSubmitingForm, setIsSubmitingForm] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserSearch | undefined>(
    undefined,
  )
  const [selectedRole, setSelectedRole] = useState<number | undefined>(
    undefined,
  )
  const { roleList } = useStore((state) => state)

  const roleOptions = useMemo(
    () =>
      roleList.map((role) => ({
        label: role.title,
        value: role.id,
      })),
    [roleList],
  )

  const validateAndSubmit = () => {
    setIsSubmitingForm(true)
    if (!selectedUser?.id) {
      return
    }
    if (!selectedRole) {
      return
    }
    addTaskMember(selectedUser?.id, selectedRole)
    setIsSubmitingForm(false)
  }

  return (
    <Row gutter={16} style={{ marginBottom: 16 }}>
      <Col span={10}>
        <SearchUser selectedUserChanged={(user) => setSelectedUser(user)} />
      </Col>
      <Col
        span={14}
        style={{
          borderColor: 'var(--border-color)',
          borderWidth: 1,
          paddingLeft: 24,
        }}
      >
        <Row>
          <Col span={24} style={{ paddingBottom: 8 }}>
            <Title level={5}>{t('task-members.add-member')}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ paddingBottom: 8 }}>
            <Space direction="horizontal">
              <Text>{t('task-members.user')}</Text>
              <Input
                type="text"
                style={{ width: 200 }}
                readOnly={true}
                value={selectedUser?.name}
                status={
                  isSubmitingForm && !selectedUser?.id ? 'error' : undefined
                }
                placeholder={t('task-members.select-user')}
              />
            </Space>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 28 }}>
          <Col span={24} style={{ paddingBottom: 8 }}>
            <Space direction="horizontal">
              <Text>{t('task-members.role')}</Text>
              <Select
                allowClear
                options={roleOptions}
                style={{ width: 200 }}
                onChange={(value) => setSelectedRole(value)}
                value={selectedRole}
                status={isSubmitingForm && !selectedRole ? 'error' : undefined}
                placeholder={t('task-members.select-role')}
              />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="horizontal">
              <Button block onClick={cancelEditing}>
                {t('settings.user-form.cancel')}
              </Button>
              <Button
                type="primary"
                block
                icon={<PlusOutlined />}
                onClick={validateAndSubmit}
              >
                {t('actions.add')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AddTaskMember
