import { App, Button, Col, Form, Row, Select, Space, Typography } from 'antd'
import useStore from 'hooks/useStore'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  CustomField,
  CustomFieldDataFormat,
  CustomFieldValue,
  CustomFieldValueDataFormat,
  EntryRequired,
  TemplateCustomField,
} from 'types/CustomFields'
import { CustomFieldForwardRefType, FormTaskType } from 'types/Tasks'
import { TaskActions } from 'utils/permissions'
import { v4 as uuid } from 'uuid'
import { useTaskEditor } from '../useTaskEditor'
import CustomFieldsValuesHeader from './CustomFieldsValuesHeader'
import CustomFieldsValuesRow from './CustomFieldsValuesRow'
import EditCustomFields from './EditCustomFields'
import dayjs from 'dayjs'

const { Title } = Typography

type CustomFieldsProps = {
  customFields: CustomField | null | undefined
  customData: CustomFieldValue | null | undefined
  setCustomFields: React.Dispatch<
    React.SetStateAction<CustomField | null | undefined>
  >
  setCustomData: React.Dispatch<
    React.SetStateAction<CustomFieldValue | null | undefined>
  >
  isUpdate: boolean
  isTemplate: boolean
  taskId?: string
  preventEditFields: boolean
  preventEditDataValues: boolean
  returnToMainTab: () => void
  saveAndCompleteTask?: (defaultValues: Partial<FormTaskType>) => void
  completeTask: () => void
}

const CustomFields = forwardRef<CustomFieldForwardRefType, CustomFieldsProps>(
  (props, ref) => {
    const {
      customFields,
      customData,
      setCustomFields,
      setCustomData,
      isUpdate,
      isTemplate,
      taskId,
      preventEditDataValues,
      returnToMainTab,
      saveAndCompleteTask,
      completeTask,
    } = props

    useImperativeHandle(ref, () => ({
      isNotDirty() {
        if (!isUpdate) {
          // Allow to close tab if it's a new record
          return true
        }
        if (isEditingCustomFields) {
          if (customFields?.dataEntryRequired !== selectedRequiredOption) {
            return false
          }
          if (customFields?.allowDelegatedToEdit !== allowDelegatedToEdit) {
            return false
          }
          return (
            JSON.stringify(customFields?.customField) ===
            JSON.stringify(localCustomFields)
          )
        } else {
          return (
            JSON.stringify(form.getFieldsValue()) ===
            JSON.stringify(valuesBeforeSaving)
          )
        }
      },
      saveData(taskId: string) {
        if (localCustomFields.length) {
          const fieldValues = form.getFieldsValue()
          saveCustomDataValuesForm(fieldValues, taskId)
        }
      },
      async isValidForm() {
        if (!localCustomFields.length) {
          return true
        }
        try {
          await form.validateFields()
          return true
        } catch (error) {
          return false
        }
      },
      cancelChanges() {
        setIsEditingCustomFields(false)
        setLocalCustomFields(customFields?.customField || [])
        setIsValidatingCustomFields(false)
        form.resetFields()
      },
    }))

    const app = App.useApp()
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const [isEditingCustomFields, setIsEditingCustomFields] = useState(false)
    const [isValidatingCustomFields, setIsValidatingCustomFields] =
      useState(false)
    const [selectedRequiredOption, setSelectedRequiredOption] = useState(
      EntryRequired.PER_TASK,
    )
    const [allowDelegatedToEdit, setAllowDelegatedToEdit] =
      useState<boolean>(true)
    const [localCustomFields, setLocalCustomFields] = useState<
      CustomFieldDataFormat[]
    >(customFields?.customField || [])
    const [customFieldTemplates, setCustomFieldTemplates] = useState<
      TemplateCustomField[]
    >([])
    const [selectedTemplate, setSelectedTemplate] = useState<
      number | undefined
    >(undefined)
    const templatesData = useMemo(() => {
      return customFieldTemplates.map((customField) => ({
        value: customField.customFieldId,
        label: customField.title,
      }))
    }, [customFieldTemplates])
    const {
      createOrUpdateCustomField,
      createOrUpdateCustomValue,
      getTaskById,
      getTemplateCustomField,
      dateFormat,
      timeFormat,
      convertTaskToTemplate,
    } = useStore((state) => state)
    const { hasUpdatePermissionOnTask } = useTaskEditor()
    const hasPermissionToEditFields = useMemo(
      () => hasUpdatePermissionOnTask(TaskActions.CUSTOM_FIELDS),
      [hasUpdatePermissionOnTask],
    )
    const hasPermissionToEditValues = useMemo(
      () => hasUpdatePermissionOnTask(TaskActions.CUSTOM_VALUES),
      [hasUpdatePermissionOnTask],
    )

    const requiredOptions = [
      {
        value: EntryRequired.PER_TASK,
        label: t('custom-field.required-per-task'),
      },
      {
        value: EntryRequired.PER_USER,
        label: t('custom-field.required-per-user'),
      },
    ]

    const customFieldsBelongsToAnotherTask =
      customFields?.task && taskId !== customFields?.task
    const lockDataEditing = isTemplate || preventEditDataValues

    const updateStateCustomFields = (value: CustomFieldDataFormat[]) => {
      setLocalCustomFields(value)
    }

    const addNewField = () => {
      updateStateCustomFields([
        ...(localCustomFields || []),
        {
          id: uuid().replace(/-/g, ''),
          type: 'text',
          label: '',
          description: '',
          maxChar: 120,
          isRequired: true,
        } as CustomFieldDataFormat,
      ])
    }

    const removeField = (id: number | string) => {
      if (!localCustomFields) {
        return
      }
      const arrCopy = Array.from(localCustomFields)
      const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id)
      arrCopy.splice(objWithIdIndex, 1)
      updateStateCustomFields(arrCopy)
    }

    const editField = (id: number | string, value: CustomFieldDataFormat) => {
      if (!localCustomFields) {
        return
      }
      const arrCopy = Array.from(localCustomFields)
      const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id)
      arrCopy[objWithIdIndex] = value
      updateStateCustomFields(arrCopy)
    }

    const isValidCustomFields = () => {
      if (
        localCustomFields.find((customField) => customField.label.trim() === '')
      ) {
        return false
      }
      return true
    }

    const saveCustomFields = () => {
      if (!isValidCustomFields()) {
        setIsValidatingCustomFields(true)
        return
      }
      setIsValidatingCustomFields(false)
      const newCustomField = {
        ...customFields,
        dataEntryRequired: selectedRequiredOption,
        allowDelegatedToEdit: allowDelegatedToEdit,
        customField: localCustomFields,
      } as CustomField
      setIsEditingCustomFields(false)
      setCustomFields(newCustomField)
      if (!isUpdate) {
        return
      }
      if (!newCustomField.task && taskId) {
        newCustomField.task = taskId
      }
      createOrUpdateCustomField(newCustomField)
        .then((createdCustomField) => {
          setCustomFields(createdCustomField)
          setCustomData({
            customField: createdCustomField.id,
          } as CustomFieldValue)
          getTaskById(taskId!)
          app.notification.success({
            message: t('custom-field.save-success'),
          })
        })
        .catch((error) => {
          app.notification.error({
            message: error.message,
          })
        })
    }

    const cancelCustomFieldsChanges = () => {
      setIsEditingCustomFields(false)
      setLocalCustomFields(customFields?.customField || [])
      setIsValidatingCustomFields(false)
    }

    const saveCustomDataValuesForm = (
      values: CustomFieldValueDataFormat,
      newTaskId?: string,
    ) => {
      const newCustomData = {
        ...(selectedTemplate
          ? {
              customField: selectedTemplate,
              routineTask: newTaskId || taskId,
            }
          : customData),
        customValue: values,
      } as CustomFieldValue
      if (!newCustomData.customField) {
        return
      }
      setCustomData(newCustomData)
      createOrUpdateCustomValue(newCustomData)
        .then(() => {
          if (!newTaskId) {
            getTaskById(taskId!)
            app.notification.success({
              message: t('custom-data.save-success'),
            })
            returnToMainTab()
          }
        })
        .catch((error) => {
          app.notification.error({
            message: error.message,
          })
        })
    }

    const sendCustomDataAndComplete = (sendComplete: boolean) => {
      form
        .validateFields()
        .then(() => {
          form.submit()
          if (sendComplete) {
            completeTask()
          }
        })
        .catch((error) => {
          // Allow Ant Design to display the validation error to the user.
        })
    }

    const saveLocallyAndReturnMainTab = () => {
      const customValue = form.getFieldsValue()
      setCustomData({ customValue } as CustomFieldValue)
      returnToMainTab()
    }

    const cancelCustomDataChanges = () => {
      setSelectedTemplate(undefined)
      form.resetFields()
      form.setFieldsValue(valuesBeforeSaving)
      returnToMainTab()
    }

    const saveNewTaskAndComplete = () => {
      if (!saveAndCompleteTask) {
        return
      }
      form
        .validateFields()
        .then(() => {
          returnToMainTab()
          const selectedTemplateTitle = templatesData.find(
            (template) => template.value === selectedTemplate,
          )?.label
          const defaultTitle = selectedTemplateTitle
            ? selectedTemplateTitle +
              ' - ' +
              dayjs().format(dateFormat + ' ' + timeFormat)
            : undefined
          if (!selectedTemplate) {
            const customValue = form.getFieldsValue()
            setCustomData({ customValue } as CustomFieldValue)
          }
          saveAndCompleteTask({ title: defaultTitle })
        })
        .catch((error) => {
          // Allow Ant Design to display the validation error to the user.
        })
    }

    const convertToTemplate = () => {
      if (!taskId) {
        return
      }
      convertTaskToTemplate(taskId, 'custom-field')
        .then((template) => {
          app.notification.success({
            message: t('custom-field.task-convert-success'),
          })
        })
        .catch((error) => {
          const message =
            error?.response?.data?.detail ||
            error?.response?.data?.fallback_message ||
            error.message
          app.notification.error({
            message,
          })
        })
    }

    const valuesBeforeSaving = useMemo(() => {
      const arrayCustomData =
        preventEditDataValues || isTemplate
          ? []
          : localCustomFields?.map((field) => {
              return {
                [field.id]: customData?.customValue?.[field.id],
              }
            })
      return Object.assign({}, ...arrayCustomData)
    }, [
      customData?.customValue,
      isTemplate,
      localCustomFields,
      preventEditDataValues,
    ])

    useEffect(() => {
      setLocalCustomFields(customFields?.customField || [])
      setAllowDelegatedToEdit(
        customFields ? customFields.allowDelegatedToEdit : true,
      )
      setSelectedRequiredOption(
        customFields?.dataEntryRequired || EntryRequired.PER_TASK,
      )
    }, [customFields])

    useEffect(() => {
      if (!isTemplate) {
        getTemplateCustomField().then((templates) => {
          setCustomFieldTemplates(templates)
        })
      }
    }, [getTemplateCustomField, isTemplate])

    useEffect(() => {
      if (
        isTemplate &&
        !customFields?.customField?.length &&
        hasPermissionToEditFields
      ) {
        setIsEditingCustomFields(true)
      }
    }, [
      customFields?.customField?.length,
      hasPermissionToEditFields,
      isTemplate,
    ])

    useEffect(() => {
      if (selectedTemplate) {
        const customField = customFieldTemplates.find(
          (item) => item.customFieldId === selectedTemplate,
        )
        if (customField) {
          setLocalCustomFields(customField.customField)
        }
      } else {
        setLocalCustomFields(customFields?.customField || [])
      }
    }, [customFieldTemplates, customFields?.customField, selectedTemplate])

    useEffect(() => {
      form.setFieldsValue(valuesBeforeSaving)
    }, [
      form,
      isEditingCustomFields,
      localCustomFields,
      preventEditDataValues,
      valuesBeforeSaving,
    ])

    return (
      <>
        <Row gutter={16} style={{ padding: 16 }} justify="start">
          <Col span={24}>
            <Space style={{ alignItems: 'center', justifyContent: 'center' }}>
              {isEditingCustomFields ? (
                <>
                  <Title level={4}>{t('custom-field.title')}</Title>
                  <Button block onClick={cancelCustomFieldsChanges}>
                    {t('settings.user-form.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    block
                    onClick={saveCustomFields}
                    disabled={!customFields?.id && !localCustomFields?.length}
                  >
                    {t('settings.user-form.save')}
                  </Button>
                </>
              ) : (
                <Row style={{ marginBottom: 8 }}>
                  <Col span={24}>
                    <Title level={4}>{t('custom-data.title')}</Title>
                  </Col>
                  <Col span={24}>
                    <Space style={{ marginTop: '8px' }}>
                      {!isTemplate && !customFields?.customField && (
                        <>
                          <Title level={5}>
                            {t('custom-field.select-template')}
                          </Title>
                          <Select
                            allowClear
                            onChange={(selected) =>
                              setSelectedTemplate(selected)
                            }
                            options={templatesData}
                            style={{ width: 200 }}
                            value={selectedTemplate}
                            disabled={isUpdate && !hasPermissionToEditFields}
                          />
                          {!selectedTemplate &&
                            !customFieldsBelongsToAnotherTask && (
                              <Title
                                level={4}
                                style={{ marginLeft: 16, marginRight: 16 }}
                              >
                                {t('custom-field.or')}
                              </Title>
                            )}
                        </>
                      )}
                      {!selectedTemplate &&
                        !customFieldsBelongsToAnotherTask && (
                          <>
                            {isUpdate &&
                              !isTemplate &&
                              customFields?.customField && (
                                <Button onClick={convertToTemplate}>
                                  {t('custom-field.convert-to-template')}
                                </Button>
                              )}
                            <Button
                              type="primary"
                              onClick={() =>
                                setIsEditingCustomFields((prev) => !prev)
                              }
                              disabled={isUpdate && !hasPermissionToEditFields}
                            >
                              {t('custom-field.edit-custom-fields')}
                            </Button>
                          </>
                        )}
                    </Space>
                  </Col>
                </Row>
              )}
            </Space>
          </Col>
        </Row>

        {isEditingCustomFields ? (
          <>
            <Row>
              <Col>
                <Space
                  style={{ marginTop: 8, marginBottom: 8, paddingLeft: 16 }}
                >
                  <Title level={5}>
                    {t('custom-field.data-required-label')}
                  </Title>
                  <Select
                    options={requiredOptions}
                    style={{ width: 120 }}
                    value={selectedRequiredOption}
                    onChange={(value) => setSelectedRequiredOption(value)}
                  />
                </Space>
              </Col>
            </Row>
            <EditCustomFields
              add={addNewField}
              remove={removeField}
              edit={editField}
              fields={localCustomFields}
              isValidating={isValidatingCustomFields}
            />
          </>
        ) : (
          <Form form={form} onFinish={saveCustomDataValuesForm}>
            <CustomFieldsValuesHeader />
            {localCustomFields?.map((field) => (
              <Form.Item
                key={field.id}
                name={field.id}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: field.isRequired,
                    message: t('task-form.custom-field-required', {
                      ns: 'validation',
                      field: field.label,
                    }),
                  },
                ]}
              >
                <CustomFieldsValuesRow
                  field={field}
                  disabled={lockDataEditing}
                />
              </Form.Item>
            ))}
            {localCustomFields?.length === 0 && (
              <CustomFieldsValuesRow
                field={
                  { label: t('custom-data.no-fields') } as CustomFieldDataFormat
                }
              />
            )}
            <Row>
              <Col>
                <Space style={{ marginTop: '8px' }}>
                  <Button block onClick={cancelCustomDataChanges}>
                    {preventEditDataValues
                      ? 'Back'
                      : t('settings.user-form.cancel')}
                  </Button>
                  {!isTemplate && !preventEditDataValues && (
                    <>
                      {isUpdate ? (
                        <>
                          <Button
                            block
                            onClick={() => sendCustomDataAndComplete(true)}
                            disabled={
                              !localCustomFields?.length ||
                              (isUpdate && !hasPermissionToEditValues)
                            }
                          >
                            {t('custom-data.submit-and-complete')}
                          </Button>
                          <Button
                            type="primary"
                            block
                            htmlType="submit"
                            disabled={
                              !localCustomFields?.length ||
                              (isUpdate && !hasPermissionToEditValues)
                            }
                          >
                            {t('settings.user-form.save')}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            block
                            onClick={saveNewTaskAndComplete}
                            disabled={
                              !localCustomFields?.length ||
                              (isUpdate && !hasPermissionToEditValues)
                            }
                          >
                            {t('custom-data.submit-and-complete')}
                          </Button>
                          <Button
                            type="primary"
                            block
                            onClick={saveLocallyAndReturnMainTab}
                            disabled={
                              !localCustomFields?.length ||
                              (isUpdate && !hasPermissionToEditValues)
                            }
                          >
                            {t('settings.user-form.save')}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </Form>
        )}

        {isEditingCustomFields && (
          <Row gutter={16} style={{ padding: 16 }} justify="start">
            <Col span={24}>
              <Space style={{ alignItems: 'center', justifyContent: 'center' }}>
                <>
                  <Button block onClick={cancelCustomFieldsChanges}>
                    {t('settings.user-form.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    block
                    onClick={saveCustomFields}
                    disabled={!customFields?.id && !localCustomFields?.length}
                  >
                    {t('settings.user-form.save')}
                  </Button>
                </>
              </Space>
            </Col>
          </Row>
        )}
      </>
    )
  },
)

export default CustomFields
