import { produce } from 'immer'
import { camelizeKeys, decamelizeKeys } from 'humps'

import axios from './axiosBase'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}`,
})

// transform response body to camelCase
api.interceptors.response.use((response) => {
  const newResponse = produce(response, (draft) => {
    draft.data = camelizeKeys(response.data)
  })

  return newResponse
})

// transform camelCase data to snake_case
api.interceptors.request.use((config) => {
  if (!(config.data instanceof FormData)) {
    const newConfig = produce(config, (draft) => {
      draft.data = decamelizeKeys(config.data)
    })
    return newConfig
  }
  return config
})

api.interceptors.request.use((config) => {
  // config.headers['Access-Control-Allow-Origin'] = '*'
  return config
})

export default api
