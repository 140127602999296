import { Button, Space } from 'antd'
import useStore from 'hooks/useStore'
import { useTranslation } from 'react-i18next'

type InviteActionsProps = {
  email: string
  taskId: string
  invitedBy: string
  closeModal: () => void
}

const InviteActions = ({
  email,
  taskId,
  invitedBy,
  closeModal,
}: InviteActionsProps) => {
  const { t } = useTranslation()
  const { postInvitation } = useStore((state) => state)
  const handleClick = (action: string) => {
    postInvitation(email, invitedBy, taskId, action)
    closeModal()
  }

  return (
    <Space
      wrap
      style={{
        flex: '1 1 auto',
        justifyContent: 'right',
      }}
    >
      <Button onClick={closeModal}>{t('actions.close')}</Button>
      <Button
        type="primary"
        danger
        style={{
          color: '#FFF',
        }}
        onClick={() => handleClick('0')}
      >
        <Space>{t('notifications.decline')}</Space>
      </Button>
      <Button
        style={{
          background: 'var(--completed-color)',
          color: '#FFF',
        }}
        onClick={() => handleClick('1')}
      >
        <Space>{t('notifications.accept')}</Space>
      </Button>
    </Space>
  )
}

export default InviteActions
