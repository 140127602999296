import dayjs from 'dayjs'
import { RRule } from 'rrule'
import { FlexibleTime } from 'types/FlexibleTime'
import { ScheduledTask, TaskItem } from 'types/Tasks'
import { UserConfigRoutineTimes } from 'types/Settings'

export enum Endings {
  NO_ENDING,
  END_AFTER,
  END_BY,
}

export const flexibleTimeOptions = [
  {
    name: 'beginning',
    value: FlexibleTime.BEGINNING,
  },
  {
    name: 'middle',
    value: FlexibleTime.MIDDLE,
  },
  {
    name: 'end',
    value: FlexibleTime.END,
  },
]

export const getEndingFromRule = (rRule?: RRule): Endings => {
  if (rRule?.options?.until) {
    return Endings.END_BY
  }
  if (rRule?.options?.count) {
    return Endings.END_AFTER
  }
  return Endings.NO_ENDING
}

export const getDefaultByYearDay = (rRule: RRule) => {
  if (rRule?.origOptions?.bymonth && rRule?.origOptions?.bymonthday) {
    const month =
      Array.isArray(rRule?.options?.bymonth) &&
      rRule?.options?.bymonth.length > 0
        ? rRule?.options?.bymonth[0] - 1
        : dayjs().month()
    const day =
      Array.isArray(rRule?.options?.bymonthday) &&
      rRule?.options?.bymonthday.length > 0
        ? rRule?.options?.bymonthday[0]
        : dayjs().date()
    const date = new Date(dayjs().year(), month, day)
    return dayjs(date)
  }
  return dayjs()
}

export const parseScheduleToUpdatable = (schedule: ScheduledTask) => {
  return {
    ...schedule,
    scheduled: undefined,
    timezone: schedule.timezoneId ? schedule.timezoneId : undefined,
    timezoneId: undefined,
  }
}

export const filterTasksByFlexibleTime = (
  tasks: TaskItem[],
  flexibleTimes: UserConfigRoutineTimes,
) => {
  const timeFormat = 'HH:mm'
  const bg = dayjs(flexibleTimes.beginning, timeFormat)
  const mi = dayjs(flexibleTimes.middle, timeFormat)
  const en = dayjs(flexibleTimes.end, timeFormat)
  const dateTimeBegin = dayjs().hour(bg.hour()).minute(bg.minute()).second(0)
  const dateTimeMiddle = dayjs().hour(mi.hour()).minute(mi.minute()).second(0)
  const dateTimeEnd = dayjs().hour(en.hour()).minute(en.minute()).second(0)
  const todayZero = dayjs().hour(0).minute(0).second(0).millisecond(0)
  let flexibleTimeToday = 0

  if (dayjs().isAfter(dateTimeBegin, 'second')) {
    flexibleTimeToday = 1
  }
  if (dayjs().isAfter(dateTimeMiddle, 'second')) {
    flexibleTimeToday = 2
  }
  if (dayjs().isAfter(dateTimeEnd, 'second')) {
    flexibleTimeToday = 3
  }

  return tasks?.filter((task) => {
    if (!task.scheduledTask?.flexibleTime) {
      return true
    }
    if (task.scheduledTask.flexibleTime === FlexibleTime.SCHEDULED) {
      return true
    }
    if (dayjs(task.scheduledTask.startDate).isBefore(todayZero, 'second')) {
      return true
    }
    if (task.scheduledTask.flexibleTime <= flexibleTimeToday) {
      return true
    }
    return false
  })
}
