import { CaretDownFilled } from '@ant-design/icons'
import { Flex, Typography } from 'antd'
import { ReactNode } from 'react'

const { Text } = Typography

interface CustomPaneHeaderProps {
  isActive: boolean
  title: string
  isBold?: boolean
  prefix?: JSX.Element
  suffix?: ReactNode
}

const CustomPanelHeader = ({
  isActive,
  title,
  isBold,
  prefix,
  suffix,
}: CustomPaneHeaderProps) => {
  return (
    <Flex gap="middle" align="start" vertical>
      <Flex style={{ width: '100%' }} justify="space-between" align="center">
        {prefix ? prefix : <div />}
        <Text strong={isBold}>
          {title}
          <CaretDownFilled
            style={{
              fontSize: '16px',
              color: '#999',
              marginRight: '8px',
              transform: isActive ? 'rotate(180deg)' : undefined,
            }}
          />
        </Text>
        {suffix ? suffix : <div />}
      </Flex>
    </Flex>
  )
}

export default CustomPanelHeader
