import { FitViewOptions, Node } from 'reactflow'
import { TaskFlowState } from 'services/TaskFlow.slice'
import { TaskMode } from 'services/Tasks.slice'
import { TaskLevel, TaskNodeData } from 'types/Tasks'
import {
  getEdge,
  getNodeFromTaskNodeData,
  isOriginAnAncestor,
  nodeHeight,
  nodeWidth,
} from './TaskFlowHelper'
import { PermissionRoleIDs } from 'utils/permissions'

export enum TaskEvents {
  ADD = 'TaskAdd',
  CHANGE_PRIORITY = 'TaskChangePriority',
  COLLAPSE = 'TaskCollapse',
  DELEGATE = 'TaskDelegate',
  EDIT = 'TaskEdit',
  EXPAND = 'TaskExpand',
  NONE = 'TaskNone',
  BEGIN_EDIT_NODE = 'TaskBeginEditNode',
  COMMIT_NODE = 'TaskCommitNode',
  DUE_DATE_CHANGED = 'TaskDueDateChanged',
  SCHEDULE_CHANGED = 'TaskScheduleChanged',
  PRIORITY_CHANGED = 'TaskPriorityChanged',
  PRIORITY_GROUP_CHANGED = 'TaskPriorityGroupChanged',
  TASK_ROLE_CHANGED = 'TaskRoleChanged',
}

export type TaskNodeEvent = {
  event: TaskEvents
  node: Node<TaskNodeData>
}

export const borderColors = [
  '#ff5252',
  '#ff31a0',
  '#b352ff',
  '#5552ff',
  '#1ba1e2',
  '#94e93f',
  '#ffd146',
  '#ffa449',
]
export const draftColor = '#d3d3d3'
export const completedColor = '#5a5a5a'

export type IconContainerProps = {
  children: React.JSX.Element
  isAvatarContainer?: boolean
}

export const nodeColor = (node: Node<TaskNodeData>) => {
  return borderColors[node.data.level - 1]
}

export const NUMBER_OF_COLORS_FOR_LEVELS = 8
export const TMP_ID = 'tmpid'
export const NODE_TYPE_TASK = 'taskNode'
export const NODE_TYPE_EDIT = 'taskEditNode'

export const selector = (state: TaskFlowState) => ({
  nodes: state.nodes,
  edges: state.edges,
  layoutDirection: state.layoutDirection,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  setLayoutDirection: state.setLayoutDirection,
  updateNode: state.updateNode,
  addNode: state.addNode,
  setInitialData: state.setInitialData,
  childNodeEvent: state.childNodeEvent,
  setChildNodeEvent: state.setChildNodeEvent,
  saveChanges: state.saveChanges,
  deleteNewTasks: state.deleteNewTasks,
})

export const emptyNode = {
  id: '',
  position: { x: 0, y: 0 },
  data: { id: '', title: '', level: 0 },
}

export const nodeSelectedStyle = { boxShadow: '0 0 40px 10px #FF0' }

export function getWindowSize() {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

export const getHighlightedNodeOnHover = (
  nodes: Node<TaskNodeData>[],
  sourceId?: string,
  targetId?: string,
) => {
  return nodes.map((node) => {
    if (node.id === targetId) {
      node.style = nodeSelectedStyle
    } else if (node.id === sourceId && targetId) {
      node.style = { backgroundColor: '#90EE90' }
    } else {
      node.style = undefined
    }
    return node
  })
}

export const getTargetOnNodeDrag = (
  nodes: Node<TaskNodeData>[],
  node: Node<TaskNodeData>,
) => {
  const centerX = node.position.x + nodeWidth / 2
  const centerY = node.position.y + nodeHeight / 2

  const targetNode = nodes.find(
    (n) =>
      centerX > n.position.x &&
      centerX < n.position.x + nodeWidth &&
      centerY > n.position.y &&
      centerY < n.position.y + nodeHeight &&
      n.id !== node.id,
  )

  if (!targetNode) {
    return null
  }

  const isAncestor = isOriginAnAncestor(node, targetNode, nodes)
  if (isAncestor) {
    return null
  }

  return targetNode
}

export const isValidParentChange = (
  nodes: Node<TaskNodeData>[],
  taskLevels: TaskLevel[],
  draggedNode: Node<TaskNodeData>,
  target: Node<TaskNodeData>,
) => {
  // TODO: Implement new validation to block levels if there is a delegated task that could be promoted
  return true
}

export const taskEventShowInputToAdd = (
  nodes: Node<TaskNodeData>[],
  node: Node<TaskNodeData>,
) => {
  let isValid = true
  const item = node.data

  if (!isValid) {
    return { newNode: undefined, newEdge: undefined }
  }

  const newNode = {
    ...getNodeFromTaskNodeData(
      {
        id: TMP_ID,
        title: '',
        parent: item.id,
        mode: TaskMode.DRAFT,
        level: item.level + 1,
        taskDetails: item.taskDetails,
        taskAssignees: item.taskAssignees,
        group: item.group,
        roles: [PermissionRoleIDs.SUPERADMIN],
      },
      true,
    ),
    type: NODE_TYPE_EDIT,
  }
  const newEdge = getEdge(item.id, newNode.id)

  return { newNode, newEdge }
}

export const getFitViewOptions = (taskId?: string) => {
  return {
    padding: 0.1,
    includeHiddenNodes: false,
    minZoom: 0.5,
    maxZoom: 2,
    duration: 3,
    nodes: [{ id: taskId }],
  } as FitViewOptions
}

export const getLevelForColor = (level: number) => {
  return level % NUMBER_OF_COLORS_FOR_LEVELS === 0
    ? NUMBER_OF_COLORS_FOR_LEVELS
    : level % NUMBER_OF_COLORS_FOR_LEVELS
}
