import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Panel } from 'reactflow'
import 'reactflow/dist/style.css'

type BottomRightPanelProps = {
  setIsSidePanelOpen: (value: boolean) => void
  setIsRoleMappingPanelOpen?: (value: boolean) => void
}

const BottomRightPanel = ({
  setIsSidePanelOpen,
  setIsRoleMappingPanelOpen,
}: BottomRightPanelProps) => {
  const { t } = useTranslation()
  return (
    <Panel position="bottom-right">
      <motion.div
        style={{
          position: 'fixed',
          bottom: 50,
          right: 300,
          zIndex: 10,
          color: '#4894ff',
        }}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
      >
        {setIsRoleMappingPanelOpen && (
          <Button
            type="primary"
            size="large"
            onClick={() => setIsRoleMappingPanelOpen(true)}
            style={{ marginRight: 8 }}
          >
            {t('templates.role-mapping-tab')}
          </Button>
        )}
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setIsSidePanelOpen(true)}
        >
          {t('task-node.add-existing-task')}
        </Button>
      </motion.div>
    </Panel>
  )
}

export default BottomRightPanel
