import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import React from 'react'

type FloatingContainerProps = {
  children: React.ReactElement
  position: 'center' | 'right' | 'left'
}

const FloatingContainer = ({ children, position }: FloatingContainerProps) => {
  const {
    leftPositionDelegationButtons,
    leftPositionTaskButtons,
    updateIsFloatButtonTemporarilyHidden,
  } = useTaskEditorStore((state) => state)
  const additionalStyles =
    position === 'center'
      ? { left: '50%', transform: 'translateX(-50%)' }
      : {
          left:
            position === 'left'
              ? `${leftPositionDelegationButtons}px`
              : `${leftPositionTaskButtons}px`,
        }
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: '8px',
        zIndex: 1010,
        ...additionalStyles,
      }}
    >
      {children}
      <Button
        type="text"
        icon={<CloseOutlined />}
        onClick={() => updateIsFloatButtonTemporarilyHidden(true)}
        style={{ color: 'white' }}
      />
    </div>
  )
}

export default FloatingContainer
