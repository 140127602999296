import TaskListItem from 'components/TaskListItem'
import { TaskItem } from 'types/Tasks'

type DelegatePanelProps = {
  tasks: TaskItem[]
  isExpanded?: boolean
  isReadonly?: boolean
  onDismiss?: (task: TaskItem) => void
}

const DelegatePanel = ({
  isExpanded,
  tasks,
  isReadonly,
  onDismiss,
}: DelegatePanelProps) => (
  <div>
    {tasks.map((item: TaskItem, index: number) => {
      return (
        <TaskListItem
          key={item.id}
          data={item}
          index={index}
          isExpanded={isExpanded}
          isReadonly={isReadonly}
          showChatIcon={true}
          onDismiss={onDismiss}
        />
      )
    })}
  </div>
)

export default DelegatePanel
