import { App, Space, Spin } from 'antd'
import useStore from 'hooks/useStore'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RoleMappingShared,
  UserTemplateRole,
  UserTemplateRoleBase,
} from 'types/Templates'
import ProjectRolesDetail from './ProjectRolesDetail'

const AccountRoles = () => {
  const app = App.useApp()
  const { t } = useTranslation()
  const [editingRole, setEditingRole] =
    useState<Partial<RoleMappingShared> | null>(null)

  const [loading, setLoading] = useState(false)
  const [templateRoles, setTemplateRoles] = useState<UserTemplateRole[]>([])

  const {
    createUserTemplateRole,
    updateUserTemplateRole,
    deleteUserTemplateRole,
    getAllUserTemplateRole,
  } = useStore((state) => state)

  const handleEdit = (role: Partial<RoleMappingShared> | null) => {
    setEditingRole(role)
  }

  const handleDelete = (role: Partial<RoleMappingShared>) => {
    const roleId = role?.id || 0
    if (!roleId) {
      return
    }
    deleteUserTemplateRole(roleId)
      .then(() => {
        setTemplateRoles(
          templateRoles.filter((template) => template.id !== roleId),
        )
        app.notification.info({
          message: t('templates.account-role.delete-success'),
        })
      })
      .catch((error) => {
        const message = error.response.data.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const handleSave = () => {
    const roleId = editingRole?.id || 0
    if (roleId) {
      updateAccountRole(roleId, {
        title: editingRole?.role,
        email: editingRole?.email,
      })
    } else {
      createAccountRole({
        title: editingRole?.role,
        email: editingRole?.email,
      })
    }
    setEditingRole(null)
  }

  const updateAccountRole = (
    id: number,
    data: Partial<UserTemplateRoleBase>,
  ) => {
    updateUserTemplateRole(id, data)
      .then((updated) => {
        setTemplateRoles((current) =>
          current.map((template) =>
            template.id === updated.id ? updated : template,
          ),
        )
        app.notification.info({
          message: t('templates.account-role.update-success'),
        })
      })
      .catch((error) => {
        const message = error.response.data.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const createAccountRole = (data: Partial<UserTemplateRoleBase>) => {
    createUserTemplateRole(data)
      .then((created) => {
        setTemplateRoles((current) => [...current, created])
        app.notification.info({
          message: t('templates.account-role.create-success'),
        })
      })
      .catch((error) => {
        const message = error.response.data.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const fetchTasks = useCallback(async () => {
    setLoading(true)
    try {
      const roles = await getAllUserTemplateRole()
      setTemplateRoles(roles)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const message = error?.response?.data?.fallback_message || error?.message
      app.notification.error({
        message,
      })
    }
  }, [app.notification, getAllUserTemplateRole])

  useEffect(() => {
    fetchTasks()
  }, [fetchTasks])

  return (
    <Space
      style={{
        marginTop: 16,
        padding: 16,
        backgroundColor: 'var(--background-color)',
        width: 'calc(100% - 16px)',
        minHeight: 'calc(50vh - 16px)',
      }}
      direction="vertical"
    >
      <Spin spinning={loading} size="large">
        <ProjectRolesDetail
          title={t('templates.account-roles')}
          column1={t('templates.role')}
          column2={t('templates.user')}
          roles={templateRoles.map((field) => ({
            id: field.id,
            role: field.title,
            email: field.email,
          }))}
          showButtons={true}
          editingRole={editingRole}
          setEditingRole={setEditingRole}
          onEdit={handleEdit}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      </Spin>
    </Space>
  )
}

export default AccountRoles
