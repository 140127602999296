import { Button, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

type ConfirmDialogProps = {
  action?: string
  onCancel: () => void
  onDeleteAllTasks: () => void
  onDeleteParentTask: () => void
  open: boolean
  numberOfDescendants: number
}

type Actions = {
  [key: string]: {
    title: string
    description: string
  }
}

const ConfirmDialog = ({
  action,
  onCancel,
  onDeleteAllTasks,
  onDeleteParentTask,
  open,
  numberOfDescendants,
}: ConfirmDialogProps) => {
  const { t } = useTranslation()

  const actions: Actions = {
    deleteParent: {
      title: t('confirm-delete-task-modal.title.one', {
        ns: 'common',
      }),
      description: t('confirm-delete-task-modal.description.one', {
        count: numberOfDescendants,
        ns: 'common',
      }),
    },
    deleteAll: {
      title: t('confirm-delete-task-modal.title.all', {
        ns: 'common',
      }),
      description: t('confirm-delete-task-modal.description.all', {
        count: numberOfDescendants,
        ns: 'common',
      }),
    },
  }

  if (action) {
    return (
      <Modal
        title={actions[action].title}
        open={open}
        onCancel={onCancel}
        zIndex={1020}
        centered
        maskTransitionName="none"
        footer={[
          <Button
            danger
            key="back"
            onClick={(e) => {
              if (action === 'deleteAll') {
                onDeleteAllTasks()
              }
              if (action === 'deleteParent') {
                onDeleteParentTask()
              }
            }}
            block
          >
            {t('confirm-delete-task-modal.confirm', { ns: 'common' })}
          </Button>,
        ]}
      >
        <Text>{actions[action].description}</Text>
      </Modal>
    )
  }
  return <></>
}

export default ConfirmDialog
