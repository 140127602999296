import { App } from 'antd'
import useStore from 'hooks/useStore'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Edge, Node, getOutgoers, useReactFlow } from 'reactflow'
import 'reactflow/dist/style.css'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { TaskMode, TaskType } from 'services/Tasks.slice'
import { Task, TaskItem, TaskNodeData } from 'types/Tasks'
import { shallow } from 'zustand/shallow'
import FlowDrawer from '../FlowDrawer'
import {
  NODE_TYPE_EDIT,
  NODE_TYPE_TASK,
  TMP_ID,
  TaskEvents,
  emptyNode,
  isValidParentChange,
  selector,
  taskEventShowInputToAdd,
} from '../FlowUtils'
import RoleMappingPanel from '../RoleMappingPanel'
import SidePanel from '../SidePanel'
import {
  collapseExpand,
  defaultNode,
  getEdge,
  getInitialNodesAndEdges,
  getLayoutedElements,
  getMinMaxLevel,
  getNodeFromTask,
  getNodesFromTaskList,
  hasBeenModified,
  isTaskOrEditNode,
  isUpdatableNode,
  removeChildrenAndGrandchildren,
  removeMiddleNode,
  updateNodeAndEdge,
} from '../TaskFlowHelper'
import { useTaskFlowViewer } from '../useTaskFlowViewer'
import CustomReactFlow from './CustomReactFlow'
import { isValidEmail } from 'utils/taskUtils'
import { RoleMappingBase } from 'types/Templates'
import { isAxiosError } from 'axios'

const FlowViewer = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
  const [isRoleMappingPanelOpen, setIsRoleMappingPanelOpen] = useState(false)
  const [templateId, setTemplateId] = useState<number | null>(null)
  const [roleMapping, setRoleMapping] = useState<RoleMappingBase[]>([])
  const [isDeleteChildrenModalOpen, setIsDeleteChildrenModalOpen] =
    useState(false)
  const [numberTasksCreated, setNumberTasksCreated] = useState(0)
  const [numberOfTasksModified, setNumberOfTasksModified] = useState(0)
  const [loading, setLoading] = useState(false)
  const statusList = useStore((state) => state.tasks.statusList)
  const [tasksBeingDeleted, setTasksBeingDeleted] = useState<Node[]>([])
  const setTaskLevels = useStore((state) => state.setTaskLevels)
  const taskLevels = useStore((state) => state.taskLevels)
  const { setShouldUpdateTasks } = useStore((state) => state)
  const user = useStore((state) => state.user?.data)
  const app = App.useApp()
  const { t } = useTranslation()
  const { addNodes, addEdges, deleteElements, getZoom, setCenter, setNodes } =
    useReactFlow()
  const { open } = useTaskEditor()

  const {
    updateTaskState,
    removeFromTaskState,
    createTask,
    getDescendants,
    convertTemplateToDraft,
    convertTaskToTemplate,
    lastModifiedTasks,
    setLastModifiedTasks,
    userTemplateRole,
    getAllUserTemplateRole,
    getTemplateTaskByTaskId,
  } = useStore((state) => state)
  const {
    isTaskFlowViewerVisible,
    isCalledFromPlanning,
    isTemplateMode,
    taskFlowTemplateId,
    initialTaskItemFlow = emptyNode,
    switchToTaskMode,
    switchToTemplateMode,
    closeTaskFlowViewer,
  } = useTaskFlowViewer()
  const taskId = initialTaskItemFlow.id
  const [editingNode, setEditingNode] = useState<
    Node<TaskNodeData> | undefined
  >(undefined)
  const {
    nodes,
    edges,
    layoutDirection,
    setInitialData,
    childNodeEvent,
    setChildNodeEvent,
    saveChanges,
    deleteNewTasks,
  } = useStore(selector, shallow)

  const updateNumberOfTasksModified = (nodeList: Node<TaskNodeData>[]) => {
    const numberOfTasksModified = nodeList
      .filter(isUpdatableNode)
      .filter(hasBeenModified).length
    setNumberOfTasksModified(numberOfTasksModified)
  }

  const changeParent = (
    source?: Node<TaskNodeData>,
    target?: Node<TaskNodeData> | null,
  ) => {
    if (!source) {
      return
    }
    if (!target) {
      return
    }
    const isValid = isValidParentChange(nodes, taskLevels, source, target)
    if (!isValid) {
      return
    }
    const newNode = {
      ...source,
      data: {
        ...source.data,
        parent: target.id,
      },
    }

    const { minLevel } = getMinMaxLevel(nodes)
    const newBaseLevel = minLevel - 1

    deleteElements({
      nodes: [],
      edges: edges.filter((ed) => ed.target === newNode.id),
    })

    const newEdge = getEdge(target.id, newNode.id)
    addEdges(newEdge)

    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      centerAt,
    } = getLayoutedElements(
      nodes.filter((nd) => nd.id !== newNode.id).concat(newNode),
      edges.filter((ed) => ed.target !== newNode.id).concat(newEdge),
      taskLevels,
      layoutDirection,
      newNode.id,
      newBaseLevel,
      true,
    )
    setInitialData(layoutedNodes, layoutedEdges)
    updateNumberOfTasksModified(layoutedNodes)
    setCenter(centerAt.x, centerAt.y, { zoom: getZoom(), duration: 1000 })
  }

  const appendTask = async (
    taskDroppedNode?: Node<TaskNodeData>,
    target?: Node<TaskNodeData> | null,
  ) => {
    if (!taskDroppedNode) {
      return
    }
    if (!target) {
      return
    }

    const isValid = isValidParentChange(
      nodes,
      taskLevels,
      taskDroppedNode,
      target,
    )
    if (!isValid) {
      return
    }

    const newNode = {
      ...taskDroppedNode,
      data: {
        ...taskDroppedNode.data,
        parent: target.id,
      },
    }

    const { minLevel } = getMinMaxLevel(nodes)
    const newBaseLevel = minLevel - 1

    const children = await getDescendants(taskDroppedNode.data.id, false)

    const childrenNodes = getNodesFromTaskList(
      children.filter((nd) => nd.id !== taskDroppedNode.data.id),
    )

    const nodeFound = nodes.some((el) => el.id === newNode.id)
    if (nodeFound) {
      deleteElements({
        nodes: [],
        edges: edges.filter((ed) => ed.target === newNode.id),
      })
    } else {
      addNodes(newNode)
    }
    const newEdge = getEdge(target.id, newNode.id)
    addEdges(newEdge)

    addNodes(childrenNodes)
    const childrenEdges = childrenNodes.map((nd) =>
      getEdge(nd.data.parent!, nd.id),
    )
    addEdges(childrenEdges)

    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      centerAt,
    } = getLayoutedElements(
      nodes
        .filter((nd) => nd.id !== newNode.id)
        .concat(newNode)
        .concat(childrenNodes),
      edges
        .filter((ed) => ed.target !== newNode.id)
        .concat(newEdge)
        .concat(childrenEdges),
      taskLevels,
      layoutDirection,
      newNode.id,
      newBaseLevel,
      true,
    )
    setInitialData(layoutedNodes, layoutedEdges)
    setCenter(centerAt.x, centerAt.y, { zoom: getZoom(), duration: 1000 })
    updateNumberOfTasksModified(layoutedNodes)
  }

  const deleteNode = (deletedNode: Node<TaskNodeData>) => {
    const hasChildrenElements =
      getOutgoers(deletedNode, nodes, edges).length > 0

    setTasksBeingDeleted([deletedNode])
    if (hasChildrenElements) {
      setIsDeleteChildrenModalOpen(true)
    } else {
      removeChildrenNodesAndLayout([deletedNode])
      setTasksBeingDeleted([])
    }
  }

  const discard = () => {
    setLoading(true)
    deleteNewTasks()
      .then((deletedTasks) => {
        removeFromTaskState(deletedTasks)
        closeModal()
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
      .finally(() => {
        setLoading(false)
        setLastModifiedTasks([])
      })
  }

  async function convertToDraft() {
    setLoading(true)
    if (numberOfTasksModified > 0 || numberTasksCreated > 0) {
      await saveChanges()
      setNumberTasksCreated(0)
      setNumberOfTasksModified(0)
    }
    const draftTasks = await convertTemplateToDraft(taskFlowTemplateId)
    switchToTaskMode(draftTasks)
    app.notification.success({
      message: t('planning.convert-to-draft-success'),
    })
    setLoading(false)
    setLastModifiedTasks([])
  }

  async function convertToTemplate() {
    const mainTaskId = nodes.find(
      (node) => node.type === NODE_TYPE_TASK && !node.data.parent,
    )?.id
    if (!mainTaskId) {
      return
    }
    setLoading(true)
    if (numberOfTasksModified > 0 || numberTasksCreated > 0) {
      await saveChanges()
      setNumberTasksCreated(0)
      setNumberOfTasksModified(0)
    }
    const templateTask = await convertTaskToTemplate(mainTaskId, 'task')
    switchToTemplateMode(templateTask)
    app.notification.success({
      message: t('planning.convert-to-template-success'),
    })
    setLoading(false)
    setLastModifiedTasks([])
  }

  const saveAndClose = () => {
    if (numberOfTasksModified === 0 && numberTasksCreated === 0) {
      app.notification.success({
        message: t('planning.no-changes-to-save'),
      })
      closeModal()
    } else {
      setLoading(true)
      saveChanges()
        .then((updatedTasks) => {
          updateTaskState(updatedTasks, true)
          app.notification.success({
            message: t('task-plan-viewer.save-success'),
          })
          setShouldUpdateTasks(true)
          closeModal()
        })
        .catch((error) => {
          const message =
            error.response?.data?.detail?.details ||
            error.response?.data?.fallback_message ||
            error.message
          app.notification.error({
            message,
          })
        })
        .finally(() => {
          setLoading(false)
          setLastModifiedTasks([])
        })
    }
  }

  const closeModal = () => {
    setIsSidePanelOpen(false)
    setNumberTasksCreated(0)
    setNumberOfTasksModified(0)
    closeTaskFlowViewer()
    setLastModifiedTasks([])
  }

  const isValidRoleEmail = (node: Node<TaskNodeData>) => {
    const mappedRoleObject = roleMapping.length
      ? roleMapping.find((role) => role.task === node.data.id)
      : node.data.taskRole
    if (!mappedRoleObject) {
      return true
    }
    if (!mappedRoleObject.assigningRole) {
      return true
    }
    if (mappedRoleObject.email && isValidEmail(mappedRoleObject.email)) {
      return true
    }
    const mappedAssignedRole =
      mappedRoleObject.email || mappedRoleObject.assigningRole
    if (userTemplateRole?.find((role) => role.title === mappedAssignedRole)) {
      return true
    }
    return false
  }

  const markAllAsActive = () => {
    const draftTasks = nodes
      .filter(isTaskOrEditNode)
      .filter((node) => node.data.mode === TaskMode.DRAFT)
    if (draftTasks.length === 0) {
      app.notification.info({
        message: t('planning.all-tasks-already-active', {
          ns: 'common',
        }),
      })
      return
    }
    const validDraftTasks = draftTasks.filter(isValidRoleEmail)
    if (draftTasks.length !== validDraftTasks.length) {
      app.notification.warning({
        message: t('planning.should-map-roles', {
          ns: 'validation',
        }),
      })
      return
    }
    const newNodes = nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          mode:
            node.type === defaultNode || node.data.mode === TaskMode.COMPLETED
              ? node.data.mode
              : TaskMode.ACTIVE,
        },
      }
    })
    setInitialData(newNodes, edges)
    updateNumberOfTasksModified(newNodes)
    app.notification.info({
      message: t('planning.n-tasks-activated', {
        ns: 'common',
        number: draftTasks.length,
      }),
    })
  }

  const removeChildrenNodesAndLayout = (
    tasksToDelete?: Node[],
    isDeletedFromTaskEditor: boolean = false,
  ) => {
    const {
      nodes: newNodes,
      edges: newEdges,
      parentTask,
    } = removeChildrenAndGrandchildren(
      tasksToDelete || tasksBeingDeleted,
      nodes,
      edges,
    )

    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      centerAt,
    } = getLayoutedElements(
      newNodes,
      newEdges,
      taskLevels,
      layoutDirection,
      parentTask,
    )
    setInitialData(layoutedNodes, layoutedEdges)
    setCenter(centerAt.x, centerAt.y, { zoom: getZoom(), duration: 1000 })
    if (!isDeletedFromTaskEditor) {
      setIsDeleteChildrenModalOpen(false)
      updateNumberOfTasksModified(layoutedNodes)
      setTasksBeingDeleted([])
    }
    if (layoutedNodes.filter((node) => !node.hidden).length === 0) {
      closeModal()
    }
  }

  const removeMiddleNodeAndLayout = () => {
    const { minLevel } = getMinMaxLevel(nodes)
    const newBaseLevel = minLevel - 1
    const {
      nodes: newNodes,
      edges: newEdges,
      parentTask,
    } = removeMiddleNode(tasksBeingDeleted, nodes, edges)
    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      centerAt,
    } = getLayoutedElements(
      newNodes,
      newEdges,
      taskLevels,
      layoutDirection,
      parentTask,
      newBaseLevel,
      true,
    )
    setInitialData(layoutedNodes, layoutedEdges)
    setCenter(centerAt.x, centerAt.y, { zoom: getZoom(), duration: 1000 })
    setIsDeleteChildrenModalOpen(false)
    updateNumberOfTasksModified(layoutedNodes)
    setTasksBeingDeleted([])
  }

  useEffect(() => {
    if (isTaskFlowViewerVisible && lastModifiedTasks.length) {
      let newNodes = [...nodes]
      let newEdges = [...edges]
      let updatedTaskId = undefined
      const { minLevel } = getMinMaxLevel(nodes)
      const newBaseLevel = minLevel - 1

      if (
        lastModifiedTasks.length === 1 &&
        lastModifiedTasks[0] &&
        Object.keys(lastModifiedTasks[0]).length === 1
      ) {
        const deletedNode = nodes.find(
          (node) => node.id === lastModifiedTasks[0].id,
        )
        if (deletedNode) {
          removeChildrenNodesAndLayout([deletedNode], true)
        }
        return
      }
      for (const updatedTask of lastModifiedTasks) {
        if (!updatedTask) {
          continue
        }
        if (!nodes.find((item) => item.id === updatedTask.id)) {
          continue
        }
        updatedTaskId = updatedTask.id

        const { updatedNodes, updatedEdges } = updateNodeAndEdge(
          newNodes,
          newEdges,
          updatedTask,
          false,
        )
        newNodes = [...updatedNodes]
        newEdges = [...updatedEdges]

        for (const updatedSubTask of updatedTask.children || []) {
          const {
            updatedNodes: updatedNodesChildren,
            updatedEdges: updatedEdgesChildren,
          } = updateNodeAndEdge(newNodes, newEdges, updatedSubTask, true)
          newNodes = [...updatedNodesChildren]
          newEdges = [...updatedEdgesChildren]
        }
      }

      // TODO: Test when user change level in Task Editor Modal
      if (updatedTaskId) {
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(
            newNodes,
            newEdges,
            taskLevels,
            layoutDirection,
            updatedTaskId,
            newBaseLevel,
          )
        setInitialData(layoutedNodes, layoutedEdges)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskFlowViewerVisible, lastModifiedTasks])

  // TODO: Reduce complexity of this effect
  useEffect(() => {
    const addElement = (newNode?: Node<TaskNodeData>, newEdge?: Edge) => {
      if (!newNode) {
        return
      }
      if (!newEdge) {
        return
      }
      addNodes(newNode)
      addEdges(newEdge)
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(
          nodes.filter((item) => item.id !== newNode.id).concat(newNode),
          edges
            .filter(
              (ed) => ed.target !== newNode.id && ed.source !== newNode.id,
            )
            .concat(newEdge),
          taskLevels,
          layoutDirection,
          newNode.id,
        )
      setInitialData(layoutedNodes, layoutedEdges)
      const centerAt = layoutedNodes.find((node) => node.id === newNode.id)
      if (centerAt) {
        setCenter(centerAt.position.x, centerAt.position.y, {
          zoom: getZoom(),
          duration: 1000,
        })
      }
    }
    const item = childNodeEvent.node.data

    if (childNodeEvent.event === TaskEvents.ADD) {
      const { newNode, newEdge } = taskEventShowInputToAdd(
        nodes,
        childNodeEvent.node,
      )
      addElement(newNode, newEdge)
    } else if (childNodeEvent.event === TaskEvents.EDIT) {
      if (numberOfTasksModified > 0 || numberTasksCreated > 0) {
        app.notification.info({
          message: t('planning.changes-automatically-saved'),
        })
        setLoading(true)
        saveChanges()
          .then((updatedTasks) => {
            updateTaskState(updatedTasks, true)
            setShouldUpdateTasks(true)
            setNumberTasksCreated(0)
            setNumberOfTasksModified(0)
            open(
              {
                ...item,
                level: { id: item.level, title: '' },
              } as unknown as Task,
              fetchTasks,
            )
          })
          .catch((error) => {
            const message =
              error.response?.data?.fallback_message || error.message
            app.notification.error({
              message,
            })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        open(
          {
            ...item,
            level: { id: item.level, title: '' },
            isUpdate: true,
          } as unknown as Task,
          fetchTasks,
        )
      }
    } else if (
      childNodeEvent.event === TaskEvents.COLLAPSE ||
      childNodeEvent.event === TaskEvents.EXPAND
    ) {
      const collapseExpandResult = collapseExpand(
        childNodeEvent.node,
        childNodeEvent.event === TaskEvents.COLLAPSE,
        nodes,
        edges,
      )

      const { minLevel } = getMinMaxLevel(nodes)
      const newBaseLevel = minLevel - 1

      const {
        nodes: layoutedNodes,
        edges: layoutedEdges,
        centerAt,
      } = getLayoutedElements(
        collapseExpandResult.nodes,
        collapseExpandResult.edges,
        taskLevels,
        layoutDirection,
        childNodeEvent.node.id,
        newBaseLevel,
      )

      setInitialData(layoutedNodes, layoutedEdges)
      updateNumberOfTasksModified(layoutedNodes)
      setCenter(centerAt.x, centerAt.y, { zoom: getZoom(), duration: 1000 })
    } else if (childNodeEvent.event === TaskEvents.BEGIN_EDIT_NODE) {
      const newNodes = nodes.map((node) => {
        if (node.id === childNodeEvent.node.id) {
          node.type = NODE_TYPE_EDIT
          node.draggable = false
          setNodes([node])
        }
        return node
      })
      setEditingNode(childNodeEvent.node)
      setInitialData(newNodes, edges)
      updateNumberOfTasksModified(newNodes)
    } else if (childNodeEvent.event === TaskEvents.COMMIT_NODE) {
      const isNew = childNodeEvent.node.id === TMP_ID

      const { minLevel } = getMinMaxLevel(nodes)
      const newBaseLevel = minLevel - 1

      if (childNodeEvent.node.data.title) {
        if (isNew) {
          const status =
            statusList && statusList.length > 0 ? statusList[0].id : 0

          const mainTask = {
            mode: isTemplateMode ? TaskMode.TEMPLATE : TaskMode.DRAFT,
            title: childNodeEvent.node.data.title,
            level: childNodeEvent.node.data.level,
            status: status,
            priorityGroup: 0,
            group: childNodeEvent.node.data.group?.id,
            taskAssignees: [{ email: user?.email }],
            parent: childNodeEvent.node.data.parent,
          } as unknown as TaskType

          createTask({ data: mainTask }, user?.id!)
            .then((tasks: TaskItem[]) => {
              const task = tasks[0] as Task
              const parentId = childNodeEvent.node.data.parent
              let newNode = getNodeFromTask(task)
              newNode = {
                ...newNode,
                data: {
                  ...newNode.data,
                  parent: parentId,
                  isNew: true,
                },
              }
              const newEdge = getEdge(parentId!, task.id)
              addNodes(newNode)
              addEdges(newEdge)

              const { nodes: layoutedNodes, edges: layoutedEdges } =
                getLayoutedElements(
                  nodes.filter((item) => item.id !== TMP_ID).concat(newNode),
                  edges
                    .filter(
                      (ed) => ed.target !== TMP_ID && ed.source !== TMP_ID,
                    )
                    .concat(newEdge),
                  taskLevels,
                  layoutDirection,
                  newNode.id,
                  newBaseLevel,
                )
              setInitialData(layoutedNodes, layoutedEdges)
              setNumberTasksCreated(numberTasksCreated + 1)
              app.notification.info({
                message: t('planning.task-created'),
              })
            })
            .catch((error) => {
              const message =
                error.response.data.fallback_message || error.message
              app.notification.error({
                message,
              })
            })
        } else {
          const newNodes = nodes.map((node) => {
            if (node.id === childNodeEvent.node.id) {
              node.type = NODE_TYPE_TASK
              node.data.title = childNodeEvent.node.data.title
              node.draggable = true
              setNodes([node])
            }
            return node
          })
          setInitialData(newNodes, edges)
          updateNumberOfTasksModified(newNodes)
        }
      } else {
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(
            nodes.filter((item) => item.id !== TMP_ID),
            edges.filter((ed) => ed.target !== TMP_ID && ed.source !== TMP_ID),
            taskLevels,
            layoutDirection,
            isNew ? childNodeEvent.node.data.parent : childNodeEvent.node.id,
            newBaseLevel,
          )
        setInitialData(layoutedNodes, layoutedEdges)

        app.notification.info({
          message: t('planning.title-required', {
            ns: 'validation',
          }),
        })
      }

      setEditingNode(undefined)
    } else if (childNodeEvent.event === TaskEvents.DUE_DATE_CHANGED) {
      const newNodes = nodes.map((node) => {
        if (node.id === childNodeEvent.node.id) {
          return {
            ...node,
            data: {
              ...node.data,
              dueAt: childNodeEvent.node.data.dueAt,
            },
          }
        }
        return node
      })
      setInitialData(newNodes, edges)
      updateNumberOfTasksModified(newNodes)
    } else if (childNodeEvent.event === TaskEvents.SCHEDULE_CHANGED) {
      const newNodes = nodes.map((node) => {
        if (node.id === childNodeEvent.node.id) {
          node = {
            ...node,
            data: {
              ...node.data,
              scheduledTask: childNodeEvent.node.data.scheduledTask,
            },
          }
        }
        return node
      })
      setInitialData(newNodes, edges)
      updateNumberOfTasksModified(newNodes)
    } else if (childNodeEvent.event === TaskEvents.PRIORITY_CHANGED) {
      const newNodes = nodes.map((node) => {
        if (node.id === childNodeEvent.node.id) {
          node = {
            ...node,
            data: {
              ...node.data,
              taskDetails: {
                ...node.data?.taskDetails,
                flag: childNodeEvent.node.data.taskDetails?.flag,
              },
            },
          }
        }
        return node
      })
      setInitialData(newNodes, edges)
      updateNumberOfTasksModified(newNodes)
    } else if (childNodeEvent.event === TaskEvents.PRIORITY_GROUP_CHANGED) {
      const newNodes = nodes.map((node) => {
        if (node.id === childNodeEvent.node.id) {
          node = {
            ...node,
            data: {
              ...node.data,
              priorityGroup: childNodeEvent.node.data.priorityGroup,
            },
          }
        }
        return node
      })
      setInitialData(newNodes, edges)
      updateNumberOfTasksModified(newNodes)
    } else if (childNodeEvent.event === TaskEvents.TASK_ROLE_CHANGED) {
      const newNodes = nodes.map((node) => {
        if (node.id === childNodeEvent.node.id) {
          node = {
            ...node,
            data: {
              ...node.data,
              taskRole: childNodeEvent.node.data.taskRole,
            },
          }
        }
        return node
      })
      setInitialData(newNodes, edges)
      updateNumberOfTasksModified(newNodes)
    }

    if (childNodeEvent.event !== TaskEvents.NONE) {
      setChildNodeEvent({ event: TaskEvents.NONE, node: emptyNode })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childNodeEvent])

  useEffect(() => {
    setTaskLevels()
    getAllUserTemplateRole()
  }, [setTaskLevels, getAllUserTemplateRole])

  const fetchTasks = useCallback(() => {
    if (taskId) {
      setLoading(true)
      getDescendants(taskId, true)
        .then((data) => {
          getTemplateTaskByTaskId(taskId)
            .then((template) => {
              setTemplateId(template?.id || null)
            })
            .catch((error) => {
              if (isAxiosError(error)) {
                const message =
                  error.response?.data?.fallback_message || error.message
                app.notification.error({
                  message,
                })
                setShouldUpdateTasks(true)
              }
            })
          const { initialNodes, initialEdges } = getInitialNodesAndEdges(
            data,
            taskId,
            isCalledFromPlanning,
          )

          const { minLevel } = getMinMaxLevel(initialNodes)
          const newBaseLevel = minLevel - 1

          const {
            nodes: layoutedNodes,
            edges: layoutedEdges,
            centerAt,
          } = getLayoutedElements(
            initialNodes,
            initialEdges,
            taskLevels,
            layoutDirection,
            taskId,
            newBaseLevel,
          )

          const mainTask = layoutedNodes.find(
            (nd) => nd.data.parent === undefined,
          )
          setInitialData(layoutedNodes, layoutedEdges)
          if (mainTask) {
            setCenter(centerAt.x, centerAt.y, {
              zoom: getZoom(),
              duration: 1000,
            })
          }
        })
        .catch((error) => {
          const message =
            error?.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
          setInitialData([], [])
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId])

  useEffect(() => {
    fetchTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId])

  return (
    <>
      <FlowDrawer
        flowViewer={
          <CustomReactFlow
            isEditing={!!editingNode}
            deleteNode={deleteNode}
            setIsDeleteChildrenModalOpen={setIsDeleteChildrenModalOpen}
            setIsSidePanelOpen={setIsSidePanelOpen}
            setIsRoleMappingPanelOpen={
              templateId ? setIsRoleMappingPanelOpen : undefined
            }
            changeParent={changeParent}
            appendTask={appendTask}
          />
        }
        numberTasksCreated={numberTasksCreated}
        numberOfTasksModified={numberOfTasksModified}
        loading={loading}
        isDeleteChildrenModalOpen={isDeleteChildrenModalOpen}
        removeChildrenNodesAndLayout={removeChildrenNodesAndLayout}
        removeMiddleNodeAndLayout={removeMiddleNodeAndLayout}
        discard={discard}
        saveAndClose={saveAndClose}
        convertToDraft={convertToDraft}
        convertToTemplate={convertToTemplate}
        close={closeModal}
        markAllAsActive={markAllAsActive}
      ></FlowDrawer>
      <SidePanel
        isTaskFlowViewerVisible={isTaskFlowViewerVisible}
        isSidePanelOpen={isTaskFlowViewerVisible && isSidePanelOpen}
        closeSidePanel={() => setIsSidePanelOpen(false)}
        nodes={nodes}
      />
      {isRoleMappingPanelOpen && (
        <RoleMappingPanel
          isTaskFlowViewerVisible={isTaskFlowViewerVisible}
          isRoleMappingPanelOpen={
            isTaskFlowViewerVisible && isRoleMappingPanelOpen
          }
          closeRoleMappingPanel={() => setIsRoleMappingPanelOpen(false)}
          nodes={nodes}
          templateId={templateId || 0}
          roleMapping={roleMapping}
          setRoleMapping={setRoleMapping}
        />
      )}
    </>
  )
}

export default FlowViewer
