import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

type UpdatePluginProps = {
  initialContent: string | null
}

const isValidJson = (jsonString: string) => {
  try {
    JSON.parse(jsonString)
  } catch (e) {
    return false
  }
  return true
}

const UpdatePlugin = ({ initialContent }: UpdatePluginProps) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (initialContent && isValidJson(initialContent)) {
      let editorState = editor.parseEditorState(initialContent)
      editor.setEditorState(editorState)
    }
  }, [initialContent, editor])

  return null
}

export default UpdatePlugin
