import { useState } from 'react'
import { Button, Typography, App, DatePicker } from 'antd'
import type { DatePickerProps } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styles from './DateSelectorModal.module.scss'

type DateSelectorModalProps = {
  isOpen: boolean
  onClose: () => void
  onResolve: ((date: string) => void) | null
  min: string
}

const { Title } = Typography

const DateSelectorModal = ({
  isOpen,
  onClose,
  onResolve,
  min,
}: DateSelectorModalProps) => {
  const [dateCompleted, setDateCompleted] = useState(
    dayjs().format('YYYY-MM-DD'),
  )

  const app = App.useApp()
  const { t } = useTranslation()

  const setToEndOfDayInUTC = (dateCompleted: string) => {
    const localDate = new Date(dateCompleted)
    localDate.setHours(23, 59, 59, 999)
    const utcDateString = new Date(
      localDate.getTime() + localDate.getTimezoneOffset() * 60000,
    ).toISOString()
    return utcDateString
  }

  const handleSubmit = () => {
    if (!dateCompleted) {
      app.message.error(t('curriculum.lesson-alert'))
    } else {
      if (onResolve) {
        onResolve(setToEndOfDayInUTC(dateCompleted))
      }
      onClose()
    }
  }

  const handleDateSelection: DatePickerProps['onChange'] = (
    date,
    dateString,
  ) => {
    setDateCompleted(dateString as string)
  }

  if (!isOpen) return null

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <Title level={5}>{t('curriculum.lesson-date-prompt')}</Title>
        <div>
          <DatePicker
            defaultValue={dayjs(dateCompleted)}
            minDate={dayjs(min)}
            maxDate={dayjs(new Date())}
            onChange={handleDateSelection}
          />
          <div className={styles.modalButtons}>
            <Button type="primary" onClick={handleSubmit}>
              {t('invite.submit')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateSelectorModal
