import { App, Col, Row, Space, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RoleMapping,
  RoleMappingBase,
  RoleMappingShared,
} from 'types/Templates'
import AccountRoles from 'views/Templates/RoleMapping/AccountRoles'
import RowComponent from 'views/Templates/RoleMapping/RowComponent'
import RowTitleComponent from 'views/Templates/RoleMapping/RowTitleComponent'
import { uniqueRoleMappingArray } from 'views/Templates/TemplatesUtils'

const { Title } = Typography

type MappingDetailsProps = {
  templateId: number
  roleMapping: RoleMappingBase[]
  setRoleMapping: Dispatch<SetStateAction<RoleMappingBase[]>>
}

const MappingDetails = ({
  templateId,
  roleMapping,
  setRoleMapping,
}: MappingDetailsProps) => {
  const { t } = useTranslation()
  const app = App.useApp()
  const {
    createRoleMapping,
    getAllRoleMapping,
    updateRoleMapping,
    userTemplateRole,
  } = useStore((state) => state)
  const [editingRole, setEditingRole] =
    useState<Partial<RoleMappingShared> | null>(null)

  const uniqueRoles = uniqueRoleMappingArray(roleMapping, true).map(
    (role) =>
      ({
        id: role.id,
        role: role.assigningRole,
        email: role.email,
        roleMatches: !!userTemplateRole?.find(
          (ur) => ur.title === role.assigningRole,
        ),
      } as RoleMappingShared),
  )

  const handleEdit = (role: Partial<RoleMappingShared> | null) => {
    setEditingRole(role)
  }

  const handleSave = () => {
    const roleId = editingRole?.id || 0
    if (roleId) {
      updateRole(roleId, {
        assigningRole: editingRole?.role,
        email: editingRole?.email,
      })
    } else {
      createRole({
        assigningRole: editingRole?.role,
        email: editingRole?.email,
      })
    }
    setEditingRole(null)
  }

  const updateRole = (id: number, data: Partial<RoleMapping>) => {
    updateRoleMapping(id, { ...data, updateSimilar: true })
      .then((updated) => {
        const newRoleMapping = roleMapping.map((role) =>
          role.assigningRole === editingRole?.role
            ? { ...role, email: editingRole?.email || '' }
            : role,
        )
        setRoleMapping(newRoleMapping)
        app.notification.info({
          message: t('templates.project-role.update-success'),
        })
      })
      .catch((error) => {
        const message = error.response.data.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const createRole = (data: Partial<RoleMapping>) => {
    createRoleMapping(data)
      .then((created) => {
        app.notification.info({
          message: t('templates.project-role.create-success'),
        })
      })
      .catch((error) => {
        const message = error.response.data.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  useEffect(() => {
    getAllRoleMapping({ templateId }).then((roles) => {
      setRoleMapping(roles)
    })
  }, [getAllRoleMapping, setRoleMapping, templateId])

  return (
    <>
      <Row gutter={16} style={{ padding: 16, marginLeft: 8 }} justify="start">
        <Col>
          <Space style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Title level={4}>{t('templates.project-roles')}</Title>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <RowTitleComponent
            column1={t('templates.source-role')}
            column2={t('templates.target-role')}
          />
        </Col>
        {uniqueRoles.map((role) => (
          <Col span={24} key={role.id}>
            <RowComponent
              field={role}
              // TODO: Set correct logic
              showButtons={true}
              editingRole={editingRole}
              setEditingRole={setEditingRole}
              onEdit={handleEdit}
              onSave={handleSave}
              isForRoleMappingTree={true}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={24}>
          <AccountRoles />
        </Col>
      </Row>
    </>
  )
}

export default MappingDetails
