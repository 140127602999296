import {
  AppointmentModel,
  ChangeSet,
  Changes,
  RecurrenceEditType,
} from '@devexpress/dx-react-scheduler'
import dayjs from 'dayjs'
import { rrulestr } from 'rrule'
import { CalendarScheduledTask, EventPeriod } from 'types/Calendar'
import { FlexibleTime } from 'types/FlexibleTime'
import { ScheduledTask, TaskItem } from 'types/Tasks'
import { isRecurring } from 'utils/taskUtils'

const dateFormatForICal = 'YYYYMMDDTHHmmssZ'

export const filterIsRecurring = (
  scheduledTasks: TaskItem[],
  includeRecurring: boolean,
) => {
  if (includeRecurring) {
    return scheduledTasks
  }
  return scheduledTasks?.filter((scheduleTask) => {
    return !isRecurring(scheduleTask)
  })
}

export const filterScheduledRoutines = (
  scheduledTasks: TaskItem[],
  includeRecurring: boolean,
) => {
  if (includeRecurring) {
    return scheduledTasks
  }
  return scheduledTasks?.filter((scheduleTask) => {
    return !(
      isRecurring(scheduleTask) &&
      scheduleTask.scheduledTask?.flexibleTime === FlexibleTime.SCHEDULED
    )
  })
}

export const filterFlexibleRoutines = (
  scheduledTasks: TaskItem[],
  includeRecurring: boolean,
) => {
  if (includeRecurring) {
    return scheduledTasks
  }
  return scheduledTasks?.filter((scheduleTask) => {
    return !(
      isRecurring(scheduleTask) &&
      scheduleTask.scheduledTask?.flexibleTime !== FlexibleTime.SCHEDULED
    )
  })
}

export const filterMyTasks = (scheduledTasks: TaskItem[], userId?: string) => {
  return scheduledTasks?.filter((scheduleTask) => {
    return (
      scheduleTask.taskAssignees?.[0]?.user === userId ||
      scheduleTask.scheduledTask?.scheduledAttendees?.find(
        (attendee) => attendee.user?.id === userId,
      )
    )
  })
}

export const filterDelegatedTasks = (
  scheduledTasks: TaskItem[],
  userId?: string,
) => {
  return scheduledTasks?.filter((scheduleTask) => {
    return (
      scheduleTask.user?.id === userId &&
      scheduleTask.taskAssignees?.[0]?.user !== userId
    )
  })
}

export const filterGroupSchedules = (
  scheduledTasks: TaskItem[],
  groupList: number[],
) => {
  return scheduledTasks?.filter((scheduleTask) => {
    return groupList.find((group) => group === scheduleTask.group?.id)
  })
}

export const preDeleteValidation = (task: CalendarScheduledTask) => {
  const changeSet: ChangeSet = {}
  if (!task?.id) {
    return changeSet
  }

  if (task.originalRecurRule) {
    const processedValues: { [key: string]: string | undefined } = {}
    const originalDate = dayjs(task.startDate).toISOString()
    const { exDate, rDate } = processExDateRDate({
      exDate: task.originalExDate!,
      rDate: task.originalRDate!,
      oldEvent: { startDate: originalDate, endDate: originalDate },
    })
    processedValues['exDate'] = exDate
    processedValues['rDate'] = rDate
    changeSet.changed = {}
    changeSet.changed[task.id] = processedValues

    const originalStartDate = dayjs(task.startDate).toISOString()
    const originalEndDate = dayjs(task.endDate).toISOString()
    const format = dateFormatForICal.slice(0, -1)
    processedValues['removeFutureInstance'] =
      dayjs(originalStartDate).utc().format(format) +
      'Z/' +
      dayjs(originalEndDate).utc().format(format) +
      'Z'
    processedValues['addFutureInstance'] = ''
  } else {
    changeSet.deleted = task.id
  }

  return changeSet
}

export const preCommitChanges = (
  changes: Changes | null,
  modifiedTask: Partial<AppointmentModel>,
  type: RecurrenceEditType,
) => {
  const changeSet: ChangeSet = {}
  if (!changes?.startDate || !changes?.endDate) {
    return changeSet
  }

  // If moving the task in Month View
  const inheritOriginalHours =
    dayjs(changes.startDate).hour() === 0 &&
    dayjs(changes.startDate).minute() === 0 &&
    dayjs(changes.endDate).hour() === 0 &&
    dayjs(changes.endDate).minute() === 0 &&
    !(
      dayjs(modifiedTask.startDate).hour() === 0 &&
      dayjs(modifiedTask.startDate).minute() === 0 &&
      dayjs(modifiedTask.endDate).hour() === 0 &&
      dayjs(modifiedTask.endDate).minute() === 0
    ) &&
    dayjs(changes.endDate).diff(dayjs(changes.startDate), 'day') === 1

  const processedValues: { [key: string]: string | undefined } = {}
  const startDate = inheritOriginalHours
    ? dayjs(changes.startDate)
        .hour(dayjs(modifiedTask.startDate).hour())
        .minute(dayjs(modifiedTask.startDate).minute())
        .toISOString()
    : dayjs(changes.startDate).toISOString()
  const endDate = inheritOriginalHours
    ? dayjs(changes.endDate)
        .hour(dayjs(modifiedTask.endDate).hour())
        .minute(dayjs(modifiedTask.endDate).minute())
        .add(-1, 'day')
        .toISOString()
    : dayjs(changes.endDate).toISOString()

  const newEvent = { startDate, endDate } as EventPeriod
  const originalStartDate = dayjs(modifiedTask.startDate).toISOString()
  const originalEndDate = dayjs(modifiedTask.endDate).toISOString()
  const oldEvent = { startDate: originalStartDate, endDate: originalEndDate }

  if (type === 'current') {
    const { exDate, rDate } = processExDateRDate({
      exDate: modifiedTask.originalExDate,
      rDate: modifiedTask.originalRDate,
      newEvent,
      oldEvent,
    })
    processedValues['rDate'] = rDate
    processedValues['exDate'] = exDate

    const format = dateFormatForICal.slice(0, -1)
    processedValues['removeFutureInstance'] =
      dayjs(originalStartDate).utc().format(format) +
      'Z/' +
      dayjs(originalEndDate).utc().format(format) +
      'Z'
    processedValues['addFutureInstance'] =
      dayjs(startDate).utc().format(format) +
      'Z/' +
      dayjs(endDate).utc().format(format) +
      'Z'

    const newDate = dayjs(startDate)
    const today = dayjs()
    if (!newDate.isAfter(today, 'day')) {
      processedValues['isInvalid'] = 'true'
    }
  }

  changeSet.changed = {}
  changeSet.changed[`${modifiedTask.id}`] = processedValues
  return changeSet
}

export const validateCalendarChanged = (
  changed: { [key: string]: any },
  calendarTasks: CalendarScheduledTask[],
  scheduledTasks?: ScheduledTask[],
): {
  isValid: boolean
  validationErrorCode?: string
  values?: {
    calendarTaskId: string
    params: Partial<CalendarScheduledTask>
  }
} => {
  const calendarTaskId = Object.keys(changed)[0]
  const changedValues: CalendarScheduledTask = Object.values(changed)[0]
  // No values provided
  if (Object.keys(changedValues).length === 0) {
    return { isValid: false }
  }
  // Future instances can only be moved on days after today
  if ('isInvalid' in changedValues) {
    return { isValid: false, validationErrorCode: 'calendar.cannot-reschedule' }
  }
  // Validate record exists
  let calendarTask = calendarTasks.find((ct) => ct.id === calendarTaskId)
  if (!calendarTask) {
    return { isValid: false }
  }
  // Routine Instances can not be moved to a different day.
  if (calendarTask?.isRoutineInstance) {
    const originalDate = dayjs(calendarTask.startDate)
    const newDate = dayjs(changedValues.startDate)
    if (!originalDate.isSame(newDate, 'day')) {
      return {
        isValid: false,
        validationErrorCode: 'calendar.change-date-instance',
      }
    }
  }
  const params = {
    ...changedValues,
    task: calendarTask.taskId,
  }
  return { isValid: true, values: { calendarTaskId, params } }
}

export function decodeExDate(exDate?: string) {
  const results: EventPeriod[] = []
  if (!exDate) {
    return results
  }
  const parts = exDate.split(':')
  const dates = parts[1]
  const datesArray = dates.split(',')
  for (const date of datesArray) {
    const result: EventPeriod = { startDate: null, endDate: null }
    const startDateObj = dayjs(date, 'YYYYMMDDTHHmmssZ')
    result.startDate = startDateObj.toISOString()
    results.push(result)
  }
  return results
}

const getEventPeriod = (date: string): EventPeriod => {
  const hasSlash = date.includes('/')
  const startDate = hasSlash ? date.split('/')[0] : date
  const endDateOrDuration = hasSlash ? date.split('/')[1] : null
  const startDateObj = dayjs(startDate, 'YYYYMMDDTHHmmssZ')
  const endDateObj = dayjs(endDateOrDuration, 'YYYYMMDDTHHmmssZ')
  return {
    startDate: startDateObj.toISOString(),
    endDate: endDateObj.toISOString(),
  }
}

export function decodeRDate(rdate?: string) {
  const results: EventPeriod[] = []
  if (!rdate) {
    return results
  }
  const parts = rdate.split(':')
  const hasParam = parts[0].includes(';')
  const param = hasParam ? parts[0].split(';')[1] : null
  const dates = parts[1]
  const datesArray = dates.split(',')

  for (const date of datesArray) {
    const result: EventPeriod = { startDate: null, endDate: null }

    const hasSlash = date.includes('/')
    const startDate = hasSlash ? date.split('/')[0] : date
    const endDateOrDuration = hasSlash ? date.split('/')[1] : null
    if (!startDate) {
      continue
    }
    const startDateObj = dayjs(startDate, 'YYYYMMDDTHHmmssZ')
    result.startDate = startDateObj.toISOString()

    if (param === 'VALUE=PERIOD') {
      const isDuration = endDateOrDuration?.startsWith('P')

      if (isDuration) {
        const durationStr = endDateOrDuration || ''
        const regex = /(\d+)([WDHMS])/g
        let days = 0
        let hours = 0
        let minutes = 0
        let seconds = 0
        let match
        while ((match = regex.exec(durationStr)) !== null) {
          const value = parseInt(match[1])
          const unit = match[2]

          switch (unit) {
            case 'W':
              days += value * 7
              break
            case 'D':
              days += value
              break
            case 'H':
              hours = value
              break
            case 'M':
              minutes = value
              break
            case 'S':
              seconds = value
              break
          }
        }

        const endDateObj = startDateObj
          .add(days, 'day')
          .add(hours, 'hour')
          .add(minutes, 'minute')
          .add(seconds, 'second')

        result.endDate = endDateObj.toISOString()
      } else {
        const endDateObj = dayjs(endDateOrDuration, 'YYYYMMDDTHHmmssZ')
        result.endDate = endDateObj.toISOString()
      }
    } else {
      result.endDate = null
    }
    results.push(result)
  }
  return results
}

export const dateToShorICalFormat = (date: string | Date) => {
  const dateObj = dayjs(date).second(0).millisecond(0)
  return dateObj
    .toISOString()
    .replaceAll('00.000Z', '00Z')
    .replaceAll(':', '')
    .replaceAll('-', '')
}

export const processFutureInstances = (
  futureInstances: string,
  oldPeriodString: string,
  newPeriodString: string,
) => {
  const futureInstancesList = decodeRDate(futureInstances)
  const oldPeriod = getEventPeriod(oldPeriodString)
  const newPeriod = newPeriodString ? getEventPeriod(newPeriodString) : null
  const returnValue = futureInstancesList.filter(
    (fi) =>
      fi.startDate !== oldPeriod.startDate &&
      (!newPeriod || fi.startDate !== newPeriod.startDate),
  )
  if (newPeriod) {
    returnValue.push(newPeriod)
  }
  return encodeRDate(returnValue)
}

export function encodeRDate(array: EventPeriod[]) {
  if (array.length === 0) {
    return ''
  }
  let result = ''
  result += 'RDATE;VALUE=PERIOD:'
  for (const obj of array) {
    const startDate = obj.startDate
    const endDate = obj.endDate
    if (!startDate) {
      continue
    }
    if (!endDate) {
      continue
    }
    const startDateObj = dayjs(startDate).second(0).millisecond(0)
    const endDateObj = dayjs(endDate).second(0).millisecond(0)
    result += dateToShorICalFormat(startDate)

    result += '/'
    let diff = endDateObj.diff(startDateObj, 'minute')
    let duration = ''
    duration += 'P'
    const minInDay = 24 * 60
    const minInHour = 60
    const days = Math.floor(diff / minInDay)
    diff -= days * minInDay
    const hours = Math.floor(diff / minInHour)
    diff -= hours * minInHour
    const minutes = Math.floor(diff)

    if (days > 0) {
      duration += days + 'D'
    }
    if (hours > 0 || minutes > 0) {
      duration += 'T'
    }
    if (hours > 0) {
      duration += hours + 'H'
    }
    if (minutes > 0) {
      duration += minutes + 'M'
    }
    result += duration
    if (obj !== array[array.length - 1]) {
      result += ','
    }
  }

  return result
}

export function encodeExDate(array: EventPeriod[]) {
  if (array.length === 0) {
    return ''
  }
  let result = 'EXDATE:'
  for (const obj of array) {
    const startDate = obj.startDate
    if (!startDate) {
      continue
    }
    result += dateToShorICalFormat(startDate) + ','
  }
  result = result.slice(0, -1)
  return result
}

export const addRDateList = (rdate?: string, list?: EventPeriod[]) => {
  if (!rdate || !list) {
    return ''
  }
  const rDateList = decodeRDate(rdate)
  return encodeRDate([...rDateList, ...list])
}

export const removeRDate = (rdate?: string, oldRDate?: string) => {
  const rDateList = decodeRDate(rdate).filter(
    (rDate) => !dayjs(rDate.startDate).isSame(dayjs(oldRDate)),
  )
  return encodeRDate(rDateList)
}

export const processExDateRDate = ({
  exDate,
  rDate,
  newEvent,
  oldEvent,
}: {
  exDate: string
  rDate: string
  newEvent?: EventPeriod
  oldEvent: EventPeriod
}) => {
  const originalExDateList = decodeExDate(exDate)
  const originalRDateList = decodeRDate(rDate)
  // Remove previous values
  const exDateList = originalExDateList.filter(
    (xd) =>
      !dayjs(xd.startDate).isSame(dayjs(oldEvent.startDate)) &&
      (!newEvent?.startDate ||
        !dayjs(xd.startDate).isSame(dayjs(newEvent?.startDate))),
  )
  // When user is moving again an RDate virtual task, do not add exDate
  if (!originalRDateList.find((rd) => rd.startDate === oldEvent.startDate)) {
    exDateList.push(oldEvent)
  }
  // Remove previous values
  const rDateList = originalRDateList.filter(
    (xd) =>
      !dayjs(xd.startDate).isSame(dayjs(oldEvent.startDate)) &&
      (!newEvent?.startDate ||
        !dayjs(xd.startDate).isSame(dayjs(newEvent?.startDate))),
  )
  // When user is moving a task back to the original position, do not add rDate
  if (
    newEvent &&
    !originalExDateList.find((xd) => xd.startDate === newEvent.startDate)
  ) {
    rDateList.push(newEvent)
  }
  // Remove duplicated dates in both exDate and rDate lists
  const sanitizedArrays = removeDuplicateStartDates(exDateList, rDateList)
  return {
    rDate: encodeRDate(sanitizedArrays.rDate),
    exDate: encodeExDate(sanitizedArrays.exDate),
  }
}

const removeDuplicateStartDates = (
  exDateList: EventPeriod[],
  rDateList: EventPeriod[],
) => {
  const duplicated = new Set()
  // Find duplicated ones
  for (const exDate of exDateList) {
    if (rDateList.find((rDate) => rDate.startDate === exDate.startDate)) {
      if (!duplicated.has(exDate.startDate)) {
        duplicated.add(exDate.startDate)
      }
    }
  }
  return {
    exDate: exDateList.filter((exDate) => !duplicated.has(exDate.startDate)),
    rDate: rDateList.filter((rDate) => !duplicated.has(rDate.startDate)),
  }
}

export const keepRDateBetween = (
  rDate?: string,
  startDate?: string,
  endDate?: string,
  excludeDate?: string,
) => {
  if (!rDate || !startDate || !endDate) {
    return ''
  }

  const rDateList = decodeRDate(rDate).filter(
    (rDate) =>
      dayjs(new Date(rDate.startDate!)).isAfter(dayjs(new Date(startDate))) &&
      dayjs(new Date(rDate.startDate!)).isBefore(dayjs(new Date(endDate))) &&
      (!excludeDate ||
        !dayjs(new Date(rDate.startDate!)).isSame(
          dayjs(new Date(excludeDate)),
        )),
  )
  return encodeRDate(rDateList)
}

export const keepExDateBetween = (
  exDate?: string,
  startDate?: string,
  endDate?: string,
) => {
  if (!exDate || !startDate || !endDate || exDate === 'EXDATE:') {
    return ''
  }
  const exDateString = exDate.replace('EXDATE:', '')
  const exDateList = exDateString
    .split(',')
    .filter(
      (date) =>
        dayjs(date, 'YYYYMMDDTHHmmssZ').isAfter(dayjs(new Date(startDate))) &&
        dayjs(date, 'YYYYMMDDTHHmmssZ').isBefore(dayjs(new Date(endDate))),
    )
  if (exDateList.length === 0) {
    return ''
  }
  const exDateNew = exDateList.join(',')
  return 'EXDATE:' + exDateNew
}

export const instancesBetween = (
  rRuleStr?: string,
  exDate?: string,
  startDate?: string,
  endDate?: string,
  startTime?: Date | string,
  endTime?: Date | string,
) => {
  if (!rRuleStr || !startDate || !endDate || !startTime || !endTime) {
    return []
  }
  if (dayjs(new Date(endDate)).isBefore(dayjs(new Date(startDate)))) {
    return []
  }

  const diff = dayjs(endTime).diff(dayjs(startTime), 'minute')

  const rRuleSetStr = rRuleStr.concat(exDate ? '\n'.concat(exDate) : '')
  const rRuleSet = rrulestr(rRuleSetStr, { forceset: true })
  return rRuleSet
    .between(new Date(startDate), new Date(endDate), false)
    .map((date) => {
      return {
        startDate: dayjs(date).toISOString(),
        endDate: dayjs(date).add(diff, 'minute').toISOString(),
      } as EventPeriod
    })
}
