import { SortAscendingOutlined, SwapOutlined } from '@ant-design/icons'
import useStore from 'hooks/useStore'
import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import ReactFlow, {
  Background,
  ConnectionLineType,
  ControlButton,
  Controls,
  Edge,
  MiniMap,
  Node,
  NodeTypes,
  useReactFlow,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { Task, TaskNodeData } from 'types/Tasks'
import { shallow } from 'zustand/shallow'
import {
  emptyNode,
  getFitViewOptions,
  getHighlightedNodeOnHover,
  getTargetOnNodeDrag,
  nodeColor,
  selector,
} from '../../FlowUtils'
import {
  LayoutDirection,
  getLayoutedElements,
  getMinMaxLevel,
  getNodeFromTask,
  getTargetNode,
} from '../../TaskFlowHelper'
import { useTaskFlowViewer } from '../../useTaskFlowViewer'
import BottomRightPanel from '../BottomRightPanel'
import styles from '../../TaskFlowViewer.module.scss'
import { nodeTypes } from './CustomReactFlowUtils'

export type CustomReactFlowProps = {
  appendTask: (
    taskDroppedNode?: Node<TaskNodeData>,
    target?: Node<TaskNodeData> | null,
  ) => void
  changeParent: (
    source?: Node<TaskNodeData>,
    target?: Node<TaskNodeData> | null,
  ) => void
  deleteNode: (deletedNode: Node<TaskNodeData>) => void
  isEditing: boolean
  setIsDeleteChildrenModalOpen: (nextValue: boolean) => void
  setIsSidePanelOpen: (nextValues: boolean) => void
  setIsRoleMappingPanelOpen?: (nextValues: boolean) => void
}

const CustomReactFlow = (props: CustomReactFlowProps) => {
  const dragRef = useRef<Node<TaskNodeData> | undefined | null>(null)
  const reactFlowWrapper = useRef<HTMLInputElement>(null)
  const taskLevels = useStore((state) => state.taskLevels)
  const { getZoom, project, setCenter } = useReactFlow()
  const { initialTaskItemFlow = emptyNode, isTemplateMode } =
    useTaskFlowViewer()
  const taskId = initialTaskItemFlow.id
  const fitViewOptions = getFitViewOptions(taskId)
  const [target, setTarget] = useState<Node<TaskNodeData> | undefined | null>(
    null,
  )
  const {
    appendTask,
    changeParent,
    deleteNode,
    isEditing,
    setIsSidePanelOpen,
    setIsRoleMappingPanelOpen,
  } = props
  const {
    edges,
    nodes,
    onEdgesChange,
    onNodesChange,
    setInitialData,
    setLayoutDirection,
  } = useStore(selector, shallow)

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
    if (reactFlowWrapper?.current) {
      const { top, left } = reactFlowWrapper.current.getBoundingClientRect()
      const position = project({
        x: event.clientX - left,
        y: event.clientY - top,
      })

      const centerX = position.x
      const centerY = position.y
      const targetNode = getTargetNode(nodes, centerX, centerY)
      setTarget(targetNode)
    }
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (target) {
      const taskDropped: Task = JSON.parse(
        event.dataTransfer.getData('application/reactflow'),
      )
      const taskDroppedNode = getNodeFromTask(taskDropped)
      appendTask(taskDroppedNode, target)
    }
    setTarget(null)
  }

  const onNodeDrag = (evt: MouseEvent, node: Node<TaskNodeData>) => {
    setTarget(getTargetOnNodeDrag(nodes, node))
  }

  const onNodeDragStart = (evt: MouseEvent, node: Node<TaskNodeData>) => {
    dragRef.current = node
  }

  const onNodeDragStop = (evt: MouseEvent, draggedNode: Node<TaskNodeData>) => {
    changeParent(draggedNode, target)
    setTarget(null)
    dragRef.current = null
  }

  const onLocalNodesDelete = (deleted: Node[]) => {
    if (deleted.length === 0) {
      return
    }
    const deletedNode = deleted[0]
    const isMainNode =
      edges.filter((edge) => edge.target === deletedNode.id).length === 0
    if (isMainNode) {
      return
    }
    if (!deletedNode.data.parent) {
      return
    }
    deleteNode(deletedNode)
  }

  const onLocalEdgesDelete = (deleted: Edge[]) => {
    if (deleted.length === 0) {
      return
    }
  }

  const onLocalLayout = (
    direction: LayoutDirection,
    selectedTask: string = '',
  ) => {
    const { minLevel } = getMinMaxLevel(nodes)
    const newBaseLevel = minLevel - 1
    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
      centerAt,
    } = getLayoutedElements(
      nodes,
      edges,
      taskLevels,
      direction,
      selectedTask,
      newBaseLevel,
    )
    setLayoutDirection(direction)
    setInitialData(layoutedNodes, layoutedEdges)
    setCenter(centerAt.x, centerAt.y, { zoom: getZoom(), duration: 1000 })
  }

  useEffect(() => {
    const newNodes = getHighlightedNodeOnHover(
      nodes,
      dragRef.current?.id,
      target?.id,
    )
    setInitialData(newNodes, edges)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target])

  return (
    <div style={{ height: '100%' }} ref={reactFlowWrapper}>
      <ReactFlow
        attributionPosition="bottom-right"
        className={styles.reactFlowStyle}
        connectionLineType={ConnectionLineType.SmoothStep}
        edges={edges}
        fitView={true}
        fitViewOptions={fitViewOptions}
        nodes={nodes}
        nodesConnectable={false}
        nodeTypes={nodeTypes as unknown as NodeTypes}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onLocalEdgesDelete}
        onNodesDelete={onLocalNodesDelete}
        onNodeDrag={onNodeDrag}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        onNodesChange={onNodesChange}
        panOnDrag={!isEditing}
      >
        <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
        <Controls>
          <ControlButton
            onClick={() => onLocalLayout(LayoutDirection.TOP_TO_BOTTOM)}
            title="sort"
          >
            <SortAscendingOutlined />
          </ControlButton>
          <ControlButton
            onClick={() => onLocalLayout(LayoutDirection.LEFT_TO_RIGHT)}
            title="sort"
          >
            <SwapOutlined />
          </ControlButton>
        </Controls>
        <Background gap={12} size={1} />
        {!isTemplateMode && (
          <BottomRightPanel
            setIsSidePanelOpen={setIsSidePanelOpen}
            setIsRoleMappingPanelOpen={setIsRoleMappingPanelOpen}
          />
        )}
      </ReactFlow>
    </div>
  )
}

export default CustomReactFlow
