import { useEffect } from 'react'
import { Button, Col, Layout, Row, Typography, theme } from 'antd'
import { ReactComponent as Logo } from 'assets/logo.svg'
import AccountGroupFilter from 'components/AccountGroupFilter/AccountGroupFilter'
import ThemeToggle from 'components/ThemeToggle'
import useStore from 'hooks/useStore'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Userpilot } from 'userpilot'
import { useLocation } from 'react-router'
import { AnalyticsBrowser } from '@segment/analytics-next'
import UserAvatar from 'components/UserAvatar'
import { IconButton } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help';

const { Header } = Layout
const { Title } = Typography

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY || '',
})

const AppHeader = () => {
  const { token } = theme.useToken()
  const location = useLocation()
  const {
    logout,
    user,
    resetSubscriptionStatus,
    thumbnailAvatar,
    clearSettingsSlice,
  } = useStore((store) => store)
  const isAuthenticated = useStore((state) => !!state.user)
  const { t } = useTranslation()

  useEffect(() => {
    Userpilot.reload()
  }, [location])

  let fullName = ''
  if (isAuthenticated) {
    fullName = `${user?.data?.firstName} ${user?.data?.lastName}`
  }

  useEffect(() => {
    if (isAuthenticated) {
      const userEmail = user?.data?.email
      if (fullName.trim() && userEmail?.trim()) {
        analytics.identify(user?.data.id, {
          name: fullName.trim() || '',
          email: user?.data.email,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLogout = () => {
    clearSettingsSlice()
    resetSubscriptionStatus()
    logout()
  }

  return (
    <Header
      style={{
        display: 'flex',
        minHeight: '64px',
        lineHeight: '32px',
        height: 'auto',
        paddingLeft: isAuthenticated ? '32px' : undefined,
        paddingRight: isAuthenticated ? '16px' : undefined,
        justifyContent: 'space-between',
        backgroundColor: isAuthenticated
          ? token.colorBgLayout
          : token.colorBgElevated,
      }}
    >
      {isAuthenticated ? (
        <Row
          gutter={16}
          wrap={false}
          justify="space-between"
          style={{
            width: '100%',
          }}
        >
          <Col flex="auto">
            <AccountGroupFilter label={''} />
          </Col>
          <IconButton
            style={{height: '40px', margin: 'auto'}}
            onClick={() => Userpilot.trigger('buDNw-V67f')}
          >
            <HelpIcon />
          </IconButton>
          <Col flex="none">
            <Row align="middle" style={{ gap: '8px', height: '64px' }}>
              <Button onClick={onLogout} style={{ marginRight: '8px' }}>
                {t('login.sign-out')}
              </Button>
              <Link to="/settings">
                <UserAvatar
                  image={thumbnailAvatar}
                  size="default"
                  label={fullName}
                />
              </Link>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <Logo height="32px" width="32px" />
            <Title level={3} style={{ margin: '0' }}>
              TepinTasks
            </Title>
          </Link>
          <Row align="middle" style={{ gap: '8px', height: '48px' }}>
            <ThemeToggle />
          </Row>
        </>
      )}
    </Header>
  )
}

export default AppHeader
