import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { useState } from 'react'
import DraggableTaskItem from 'sections/TaskFlowViewer/SidePanel/DraggableTaskItem/DraggableTaskItem'
import { Task } from 'types/Tasks'

type CategoryContainerProps = {
  groupedTasks: { [k: string]: any }
}

const collapseHeader = (title: string, isExpanded: boolean) => {
  return (
    <>
      {title} {isExpanded ? <UpOutlined /> : <DownOutlined />}
    </>
  )
}

const CategoryContainer = ({ groupedTasks }: CategoryContainerProps) => {
  const [activeKey, setActiveKey] = useState<string | string[]>('')

  const expandGroup = (key: string | string[]) => {
    setActiveKey(key)
  }

  const items = Object.keys(groupedTasks).map((category) => {
    return {
      key: category,
      label: collapseHeader(category, activeKey.includes(category)),
      showArrow: false,
      children: groupedTasks[category].map((task: Task) => (
        <DraggableTaskItem key={task.id} task={task} />
      )),
    }
  })

  return (
    <Collapse
      accordion
      activeKey={activeKey}
      expandIconPosition="end"
      onChange={expandGroup}
      bordered={false}
      items={items}
      ghost
    />
  )
}

export default CategoryContainer
