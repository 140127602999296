import { DatePicker, InputNumber, Radio, Space } from 'antd'
import Row from 'components/Row'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Endings } from 'utils/routines'
import useStore from 'hooks/useStore'

type RecurrenceProps = {
  ocurrences: number
  setOcurrences: (nextValue: number) => void
  ending: Endings
  setEnding: (nextValue: Endings) => void
  endRangeDate: Dayjs
  setEndRangeDate: (nextValue: Dayjs) => void
  disabled?: boolean
}

const RangeOfRecurrence = ({
  ocurrences,
  setOcurrences,
  ending,
  setEnding,
  endRangeDate,
  setEndRangeDate,
  disabled,
}: RecurrenceProps) => {
  const { t } = useTranslation()
  const { dateFormat } = useStore((state) => state)

  return (
    <>
      <Row style={{ padding: '8px 16px 0px 16px' }}>
        <h4>{t('new-task-form.recurrence.range-of-recurrence')}</h4>
      </Row>
      <Radio.Group
        value={ending}
        onChange={(evt) => setEnding(evt.target.value as Endings)}
        style={{ padding: '8px 16px' }}
        disabled={disabled}
      >
        <Space direction="vertical">
          <Radio value={Endings.END_BY}>
            {t('new-task-form.recurrence.ending.by-date')}:
            <DatePicker
              size="small"
              value={endRangeDate}
              onChange={(nextValue) => {
                setEndRangeDate(
                  nextValue?.hour(23)?.minute(59)?.second(0)?.millisecond(0),
                )
              }}
              disabled={disabled || ending !== Endings.END_BY}
              style={{ marginLeft: '8px', width: '160px' }}
              format={dateFormat}
            />
          </Radio>
          <Radio value={Endings.END_AFTER}>
            {t('new-task-form.recurrence.ending.by-ocurrence')}:
            <InputNumber
              min={1}
              size="small"
              value={ocurrences}
              disabled={disabled || ending !== Endings.END_AFTER}
              style={{ marginLeft: '8px', width: '160px' }}
              addonAfter={t('new-task-form.recurrence.ocurrences')}
              onChange={(nextValue) => setOcurrences(nextValue as number)}
            />
          </Radio>
          <Radio value={Endings.NO_ENDING}>
            {t('new-task-form.recurrence.ending.no-end')}
          </Radio>
        </Space>
      </Radio.Group>
    </>
  )
}

export default RangeOfRecurrence
