import { Typography } from 'antd'

type AccountFilterProps = {
  label: string
}

const AccountFilter = ({ label }: AccountFilterProps) => {
  const { Title } = Typography

  return (
    <div onClick={(e) => e.stopPropagation()} style={{ marginRight: 8 }}>
      <Title level={5} style={{wordBreak: "normal"}}>{label}</Title>
    </div>
  )
}

export default AccountFilter
