import { InfoCircleOutlined } from '@ant-design/icons'
import { Switch as SwitchAntd, Tooltip } from 'antd'
import { CSSProperties, useState } from 'react'

type SwitchProps = {
  initialValue: boolean
  onChange: (value: boolean) => void
  label?: string
  className?: string
  size?: 'default' | 'small'
  position?: 'right' | 'left'
  tooltip?: string
  style?: CSSProperties
  fontSize?: string | number | undefined
}

const Switch = ({
  initialValue,
  onChange,
  label,
  className,
  size = 'default',
  position = 'right',
  tooltip,
  style,
  fontSize,
  ...props
}: SwitchProps) => {
  const [checked, setChecked] = useState(initialValue)

  const handleChange = (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setChecked(checked)
    onChange(checked)
  }

  const labelSpan = (
    <span
      style={{
        fontSize: fontSize ? fontSize : size === 'default' ? '14px' : '10px',
        ...(position === 'right'
          ? { marginRight: '8px' }
          : { marginLeft: '4px' }),
      }}
    >
      {label}
    </span>
  )

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px',
        marginRight: '8px',
        marginBottom: '4px',
        ...(style || {}),
      }}
    >
      {position === 'right' && labelSpan}
      <SwitchAntd
        checked={checked}
        onChange={handleChange}
        size={size}
        {...props}
      />
      {position === 'left' && labelSpan}
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoCircleOutlined style={{ marginLeft: '4px' }} />
        </Tooltip>
      )}
    </div>
  )
}

export default Switch
