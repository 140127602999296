import { Select, SelectProps, Space } from 'antd'
import useStore from 'hooks/useStore'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PendingAssignee } from 'types/Tasks'
import { isValidEmail } from 'utils/taskUtils'
import { EmailOmmitedValuesRender } from './EmailOmmitedValuesRender'
import { EmailOptionRender } from './EmailOptionRender'
import { encodeOptionLabel, filterOption } from './EmailSelectorHelper'
import { EmailTagRender } from './EmailTagRender'

type EmailSelectorProps = {
  initialValues: PendingAssignee[]
  userList: PendingAssignee[]
  disabled: boolean
}

const EmailSelector = ({
  initialValues,
  userList,
  disabled,
}: EmailSelectorProps) => {
  const { t } = useTranslation()
  const firstRenderRef = useRef(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pendingInvites, addCollaboratorInvite } = useStore((state) => state)
  const [optionsAssignedList, setOptionsAssignedList] = useState<
    SelectProps['options']
  >([])
  const { selectedEmails, updateSelectedEmails } = useTaskEditorStore(
    (state) => state,
  )

  const handleChange = (values: string[]) => {
    if (disabled) {
      return
    }
    const trimmedValues = values.map((value) => value.trim())
    updateSelectedEmails(trimmedValues)

    trimmedValues.forEach((email) => {
      if (!userList.find((user) => user.email === email)) {
        if (!pendingInvites.find((invite) => invite.email === email)) {
          if (isValidEmail(email)) {
            addCollaboratorInvite(email.toLowerCase(), { id: '' }, '')
          }
        }
      }
    })
  }

  const handleDropdownVisibleChange = (isVisible: boolean) => {
    if (disabled) {
      return
    }
    setIsOpen(isVisible)
  }

  const initializeOptions = useCallback(() => {
    const options = userList.map((contact) => {
      const selected = initialValues.find((iv) => iv.email === contact.email)
      const contactWithStatus = {
        ...contact,
        status: selected?.status || '',
        createdAt: selected?.createdAt || '',
        image: contact.image || selected?.image,
      }
      return {
        key: contact.email,
        value: contact.email,
        label: encodeOptionLabel(contactWithStatus),
      }
    })
    updateSelectedEmails(initialValues.map((value) => value.email))
    setOptionsAssignedList(options)
  }, [initialValues, updateSelectedEmails, userList])

  useEffect(() => {
    initializeOptions()
  }, [initializeOptions])

  useEffect(() => {
    if (firstRenderRef.current) {
      initializeOptions()
      firstRenderRef.current = false
      return
    }
  }, [initializeOptions])

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Select
        allowClear
        value={selectedEmails}
        filterOption={filterOption}
        maxTagCount="responsive"
        maxTagPlaceholder={EmailOmmitedValuesRender}
        mode="tags"
        onChange={handleChange}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        open={isOpen}
        optionRender={EmailOptionRender}
        options={optionsAssignedList}
        placeholder={t('email-selector.placeholder')}
        style={{ width: '100%', height: '40px' }}
        tagRender={EmailTagRender}
        tokenSeparators={[',', ' ', ';']}
        disabled={disabled}
      />
    </Space>
  )
}

export default EmailSelector
