import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Cell, Pie, PieChart } from 'recharts'
import { TaskItem } from 'types/Tasks'
import {
  isTaskActive,
  isTaskDone,
  isTaskNotStarted,
  isTaskPaused,
} from 'utils/taskUtils'

const COLORS = [
  'var(--not-started-color)',
  'var(--in-progress-active-color)',
  'var(--in-progress-paused-color)',
  'var(--completed-color)',
]

type DelegatedTaskHeatmapProps = {
  tasks?: TaskItem[]
}

const DelegatedTaskHeatmap = ({ tasks }: DelegatedTaskHeatmapProps) => {
  const { t } = useTranslation()
  const not_started_tasks = tasks?.filter(isTaskNotStarted)?.length || 0
  const active_tasks = tasks?.filter(isTaskActive)?.length || 0
  const paused_tasks = tasks?.filter(isTaskPaused)?.length || 0
  const done_tasks = tasks?.filter(isTaskDone)?.length || 0
  const data = [
    { name: 'not-started', value: not_started_tasks },
    { name: 'active', value: active_tasks },
    { name: 'paused', value: paused_tasks },
    { name: 'done', value: done_tasks },
  ]

  return (
    <Tooltip title={t('my-today-page.task-status')}>
      <PieChart width={20} height={20}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={10}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </Tooltip>
  )
}

export default DelegatedTaskHeatmap
