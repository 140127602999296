import { Layout } from 'antd'
import type { FCProps } from 'types/FC'
import style from './Container.module.css'

const { Content } = Layout

const Container = (props: FCProps) => (
  <Content className={style.container} {...props} />
)

export default Container
