import React, { useCallback, useEffect, useState } from 'react'
import { App } from 'antd'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RippleBadge from './RippleBadge'
import MicIcon from '@mui/icons-material/Mic'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { UploadedFile } from 'services/Tasks.slice'
import Slider from '@mui/material/Slider'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { useForm, Controller } from 'react-hook-form'
import { TaskItem } from 'types/Tasks'
import PlayList from './PlayList'
import useStore from 'hooks/useStore'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { decodeHtmlEntities } from 'utils/richText'
import { getAudioTaskAttachments } from 'utils/taskUtils'
import Skeleton from '@mui/material/Skeleton'
import MusicOffIcon from '@mui/icons-material/MusicOff'
import Avatar from '@mui/material/Avatar'
import { useTranslation } from 'react-i18next'

type IFormInput = {
  title: string
  description: string
}

type PlayerProps = {
  tracks?: UploadedFile[]
  task?: Partial<TaskItem>
}

function formatDuration(duration: number) {
  const min = Math.floor(duration / 60)
  const sec = Math.floor(duration - min * 60)

  const formatted = [min, sec].map((n) => (n < 10 ? '0' + n : n)).join(':')

  return formatted
}

const Player = ({ tracks = [], task }: PlayerProps) => {
  const { notification } = App.useApp()
  const [open, setOpen] = useState(false)
  const { setInitialValues } = useTaskEditor()
  const { t } = useTranslation()
  // State
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const [isReady, setIsReady] = React.useState(false)
  const [trackIndex, setTrackIndex] = useState<number>(0)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [position, setPosition] = useState<number>(0)
  const [trackDuration, setTrackDuration] = useState<number>(0)
  const [audioFiles, setAudioFiles] = useState<UploadedFile[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const updateTask = useStore((state) => state.updateTask)
  const defaultValues = {
    title: task?.title,
    description: decodeHtmlEntities(
      task?.taskDetails?.description || '',
    ).replace(/(<([^>]+)>)/gi, ' '),
  }
  const getTaskFiles = useStore((state) => state.getTaskFiles)

  const { handleSubmit, reset, control, setValue } = useForm<IFormInput>({
    defaultValues,
  })

  const onSubmit = (data: IFormInput) => {
    if (task?.id) {
      updateTask(
        {
          taskId: task.id,
          data: {
            title: data.title,
            details: {
              description: data.description,
            },
          },
        },
        '',
      )
        .then(() => {
          notification.success({
            message: 'Task updated successfully',
          })

          const details = {
            ...task?.taskDetails,
            description: data.description,
          }
          setInitialValues({
            ...task,
            title: data.title,
            taskDetails: details,
          })
          handleCloseDialog()
        })
        .catch((error) => {
          notification.error({
            message: error.message,
          })
        })
    }
  }

  const audioRef = React.useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    if (isReady && audioRef.current) {
      setTrackDuration(audioRef.current?.duration)
    }
  }, [isReady])

  useEffect(() => {
    setValue('title', task?.title || '')
    setValue(
      'description',
      decodeHtmlEntities(task?.taskDetails?.description || '').replace(
        /(<([^>]+)>)/gi,
        ' ',
      ),
    )
  }, [task, setValue])

  const handleCloseDialog = () => {
    setOpen(false)
    setIsPlaying(false)
    setPosition(0)
    setTrackDuration(0)
    audioRef.current?.pause()
    if (audioRef.current) {
      audioRef.current.currentTime = 0
    }
    reset(defaultValues)
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    event.preventDefault()
    setSelectedIndex(index)
    setTrackIndex(index)
    togglePlayTrack()
  }

  const togglePlayTrack = () => {
    if (isPlaying) {
      audioRef.current?.pause()
      setSelectedIndex(trackIndex)
      setIsPlaying(false)
    } else {
      audioRef.current?.play()
      setIsPlaying(true)
    }
  }

  const onScrub = (event: Event, value: number | number[]) => {
    if (!audioRef.current) {
      return
    }
    audioRef.current.currentTime = value as number
    setPosition(value as number)
  }

  const fetchTaskFiles = useCallback(async () => {
    setOpen(true)
    if (task?.id) {
      try {
        const files = await getTaskFiles(task.id)
        const audio = getAudioTaskAttachments(files)
        if (files.length > 0 && audio.length > 0) {
          setAudioFiles(files)
          setIsLoading(false)
        } else {
          setAudioFiles([])
          setIsLoading(false)
        }
      } catch (error) {
        const errorMessage = error as Error
        notification.error({
          message: errorMessage.message,
        })
        setIsLoading(false)
      }
    }
  }, [getTaskFiles, task?.id, notification])

  return (
    <>
      <IconButton onClick={fetchTaskFiles}>
        <RippleBadge invisible={false}>
          <MicIcon sx={{ color: 'var(--audio-task-color)' }} />
        </RippleBadge>
      </IconButton>
      <Dialog open={open}>
        <DialogTitle sx={{ pl: 5 }}>{t('audio-task-form.title')}</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          {isLoading ? (
            <Stack spacing={1}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
              <Skeleton variant="rounded" width={'100%'} height={60} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              <Skeleton variant="rounded" width={'100%'} height={40} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
              <Skeleton variant="rounded" width={'100%'} height={120} />
            </Stack>
          ) : (
            <>
              {audioFiles.length === 0 && (
                <Stack
                  sx={{ my: 2, mx: 2 }}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Avatar sx={{ bgcolor: 'var(--warning-color)' }}>
                    <MusicOffIcon />
                  </Avatar>
                  <Typography variant="subtitle1" gutterBottom>
                    {t('audio-task-form.no-files')}
                  </Typography>
                </Stack>
              )}
              {audioFiles.length > 0 && (
                <>
                  <PlayList
                    tracks={tracks}
                    handleListItemClick={handleListItemClick}
                    selectedIndex={selectedIndex || -1}
                    isActive={isPlaying}
                  />

                  {document && (
                    <audio
                      ref={audioRef}
                      preload="metadata"
                      onDurationChange={(e) =>
                        setTrackDuration(e.currentTarget.duration)
                      }
                      onPlaying={() => setIsPlaying(true)}
                      onPause={() => setIsPlaying(false)}
                      onCanPlay={(e) => {
                        setIsReady(true)
                      }}
                      onTimeUpdate={(e) => {
                        setPosition(e.currentTarget.currentTime)
                      }}
                    >
                      <source
                        type="audio/mpeg"
                        src={audioFiles[trackIndex].document}
                      />
                    </audio>
                  )}
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ my: 2, mr: 2 }}
                  >
                    <IconButton onClick={togglePlayTrack}>
                      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <Slider
                      aria-label="Volume"
                      value={position}
                      onChange={onScrub}
                      min={0}
                      max={trackDuration}
                      step={0.1}
                      sx={{
                        color: 'var(--audio-task-color)',
                        '& .MuiSlider-rail': {
                          opacity: 0.5,
                          boxShadow: 'inset 0px 0px 4px -2px #000',
                          backgroundColor: '#d0d0d0',
                        },
                      }}
                    />

                    <Typography variant="subtitle1" sx={{ width: 160 }}>
                      {formatDuration(position)}/
                      {`-${formatDuration(trackDuration)}`}
                    </Typography>
                  </Stack>

                  <FormControl component="fieldset" sx={{ width: '100%' }}>
                    <FormGroup aria-label="task edit form">
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <TextField
                                id="title-field"
                                fullWidth
                                margin="dense"
                                size="small"
                                {...field}
                              />
                            }
                            label="Title"
                            labelPlacement="top"
                            sx={{ alignItems: 'flex-start', mb: 2 }}
                          />
                        )}
                      />
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <TextField
                                id="description-field"
                                fullWidth
                                margin="dense"
                                size="small"
                                multiline
                                minRows={4}
                                {...field}
                              />
                            }
                            label="Description"
                            labelPlacement="top"
                            sx={{ alignItems: 'flex-start' }}
                          />
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {t('audio-task-form.close')}
          </Button>
          <Button onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            {t('audio-task-form.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Player
