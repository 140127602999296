import { DeploymentUnitOutlined } from '@ant-design/icons'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Button, MenuProps, Space, Typography } from 'antd'
import { DataNode } from 'antd/es/tree'
import { colorList } from 'components/AccountGroupFilter/GroupForm'
import { getIconByGroup } from 'components/CheckboxIconTag'
import dayjs from 'dayjs'
import { Dispatch, JSX, SetStateAction } from 'react'
import { TaskGroup } from 'types/Tasks'
import {
  AllowedTemplateTypes,
  RoleMappingBase,
  RoleMappingShared,
  TemplateTask,
  TemplateTypes,
  UserFolder,
  UserTemplateRole,
} from 'types/Templates'
import CustomTemplateInput from './CustomTemplateInput'
import RowComponent from './RoleMapping/RowComponent'
import RowTitleComponent from './RoleMapping/RowTitleComponent'
import TemplateTaskToolbar from './TemplateTaskToolbar'
import AddButtons from './TemplatesList/AddButtons'
import { isGroupOwner } from 'utils/taskUtils'

const { Text } = Typography

export enum ElementEnum {
  NEW_FOLDER,
  NEW_TEMPLATE,
  EDIT_FOLDER,
  EDIT_TEMPLATE,
  CLONE_TEMPLATE,
  DELETE_TEMPLATE,
  DELETE_FOLDER,
  EDIT_ROLE,
  DELETE_ROLE,
}

export type EditingElementType = {
  type: ElementEnum
  templateType: AllowedTemplateTypes
  folder?: string
  title?: string
  key?: string
  taskId?: string
  target?: number | string
}

export type TreeTemplateData = {
  id: number
  type: 'group' | 'template' | 'folder' | 'role'
  taskId?: string
  parent?: string
  title: string
  key?: string
  templateType?: AllowedTemplateTypes
  icon: JSX.Element
  children?: TreeTemplateData[]
  isOwner: boolean
  isArchived?: boolean
}

export const composeTreeTemplateData = (
  groups: TaskGroup[],
  folders: UserFolder[],
  templates: TemplateTask[],
  userId: string,
) => {
  const isGroupArchived: { [key: number]: boolean } = {}
  for (const group of groups) {
    const { id, status } = group
    isGroupArchived[id] = status === 'archived'
  }
  const groupTree = groups.map(
    (group) =>
      ({
        id: group.id,
        type: 'group',
        title: group.alternativeTitle || group.title,
        icon: (
          <Button
            icon={getIconByGroup(group?.metadata?.icon)}
            size="small"
            style={{
              borderRadius: '16px',
              backgroundColor: isGroupArchived[group.id]
                ? '#aaaaaa'
                : group?.metadata.color || colorList[0],
              color: 'white',
              paddingLeft: '3px',
              paddingTop: '2px',
              opacity: isGroupArchived[group.id] ? 0.5 : 1,
            }}
            type="text"
          />
        ),
        isOwner: isGroupOwner(group, userId),
        isArchived: isGroupArchived[group.id],
      } as TreeTemplateData),
  )
  const folderTree = folders.map(
    (folder) =>
      ({
        id: folder.id,
        type: 'folder',
        parent: folder.parent?.id
          ? 'f' + folder.parent.id
          : 'g' + folder.group.id,
        title: folder.title,
        icon: (
          <FolderOpenOutlinedIcon
            style={{
              opacity: isGroupArchived[folder.group.id] ? 0.5 : 1,
            }}
          />
        ),
        isArchived: isGroupArchived[folder.group.id],
      } as TreeTemplateData),
  )
  const templateTree = templates.map(
    (template) =>
      ({
        id: template.id,
        type: 'template',
        parent: template.folder?.id
          ? 'f' + template.folder.id
          : 'g' + template.task?.group?.id,
        title: template.title,
        icon:
          template.type === TemplateTypes.CUSTOM_FIELD ? (
            <PlaylistAddIcon
              style={{
                opacity: isGroupArchived[template.task?.group?.id] ? 0.5 : 1,
              }}
            />
          ) : (
            <DeploymentUnitOutlined
              style={{
                opacity: isGroupArchived[template.task?.group?.id] ? 0.5 : 1,
              }}
            />
          ),
        templateType: template.type,
        taskId: template.task.id,
        isArchived: isGroupArchived[template.task?.group?.id],
      } as TreeTemplateData),
  )
  const treeData = [...groupTree, ...folderTree, ...templateTree]
  return createTree(treeData)
}

const getIconForEditingTemplate = (editingElement?: EditingElementType) => {
  if (
    !(
      editingElement?.type === ElementEnum.NEW_TEMPLATE ||
      editingElement?.type === ElementEnum.EDIT_TEMPLATE
    )
  ) {
    return <FolderOpenOutlinedIcon />
  }
  if (editingElement?.templateType === TemplateTypes.CUSTOM_FIELD) {
    return <PlaylistAddIcon />
  } else {
    return <DeploymentUnitOutlined />
  }
}

export const getEditingTemplate = (
  onSave: Function,
  isValidTitle: Function,
  editingElement?: EditingElementType,
): DataNode => {
  return {
    title: (
      <Space
        style={{
          width: '90%',
          justifyContent: 'space-between',
        }}
      >
        <CustomTemplateInput
          onSave={(title: string) => {
            onSave({ ...editingElement, title })
          }}
          isValidTitle={isValidTitle}
          style={{ width: '100%' }}
          editingElement={editingElement}
        />
      </Space>
    ),
    key: editingElement?.key + '-0',
    icon: getIconForEditingTemplate(editingElement),
  }
}

const hiddenButton = (
  <Space style={{ visibility: 'hidden' }}>
    <Button type="text" size="small" />
  </Space>
)
export const getSingleTreeElement = (
  node: TreeTemplateData,
  onNewElement: Function,
  onView: Function,
  onEdit: Function,
  onClone: Function,
  onDelete: Function,
  onSave: Function,
  onMove: Function,
  isMyTemplates: boolean,
  defaultGroupKey: number,
  groupListOptions: MenuProps['items'],
  isRoleMapping: boolean,
  editingElement?: EditingElementType,
  editingTemplate?: DataNode,
): DataNode => {
  const isGroup = node.type === 'group'
  const isFolder = node.type === 'folder'
  const isArchived = node.isArchived
  const isRoleElement = node.type === 'role'
  const isOwner = node.isOwner
  const isNewElement =
    (editingElement?.type === ElementEnum.NEW_FOLDER ||
      editingElement?.type === ElementEnum.NEW_TEMPLATE) &&
    editingElement?.key === node.key
  const isEditingElement =
    (editingElement?.type === ElementEnum.EDIT_FOLDER ||
      editingElement?.type === ElementEnum.EDIT_TEMPLATE) &&
    editingElement?.key === node.key

  const children = [
    ...(node.children || []).map((child) => {
      return getSingleTreeElement(
        child,
        onNewElement,
        onView,
        onEdit,
        onClone,
        onDelete,
        onSave,
        onMove,
        isMyTemplates,
        defaultGroupKey,
        groupListOptions,
        isRoleMapping,
        editingElement,
        editingTemplate,
      )
    }),
    ...(isNewElement ? [editingTemplate!] : []),
  ]

  if (isEditingElement && editingTemplate) {
    return {
      ...editingTemplate,
      children: [...children],
    }
  }

  const addButtonsGroup =
    isOwner && !isRoleMapping ? (
      <AddButtons
        parentKey={node.key!}
        onNewElement={onNewElement}
        onDelete={isFolder ? onDelete : undefined}
      />
    ) : (
      hiddenButton
    )
  const toolbarTemplates =
    !isRoleMapping || isRoleElement ? (
      <TemplateTaskToolbar
        templateKey={node.key!}
        title={node.title!}
        taskId={node.taskId!}
        isOwner={node.isOwner}
        onView={onView}
        onEdit={onEdit}
        onClone={onClone}
        onDelete={onDelete}
        onMove={onMove}
        isMyTemplates={isMyTemplates}
        defaultGroupKey={defaultGroupKey}
        groupListOptions={groupListOptions}
        templateType={node.templateType}
      />
    ) : (
      hiddenButton
    )

  return {
    title: (
      <Space style={{ width: '90%', justifyContent: 'space-between' }}>
        <Text
          style={{ paddingLeft: 8, ...(!isGroup && { paddingTop: '2px' }) }}
          className={
            (isGroup ? '' : ' template-title ') +
            (isArchived && ' archived-group ')
          }
        >
          {node.title}
        </Text>
        {isGroup || isFolder ? addButtonsGroup : toolbarTemplates}
      </Space>
    ),
    key: node.key!,
    icon: node.icon,
    children: [...children],
  }
}

export const getRoleMappingDataNode = (
  role: RoleMappingBase,
  parentKey: string,
  onSave: Function,
  onEdit: Function,
  onDelete: Function,
  editingRole?: Partial<RoleMappingShared> | null,
  setEditingRole?: Dispatch<SetStateAction<Partial<RoleMappingShared> | null>>,
  userTemplateRole?: Partial<UserTemplateRole>[],
): DataNode => {
  const field = {
    id: role.id,
    role: role.assigningRole,
    email: role.email,
    roleMatches: !!userTemplateRole?.find(
      (ur) => ur.title === role.assigningRole,
    ),
  } as RoleMappingShared
  // TODO: Implement logic
  const isOwner = true
  return {
    title: (
      <RowComponent
        field={field}
        showButtons={isOwner}
        editingRole={editingRole}
        setEditingRole={setEditingRole}
        onEdit={(role) => onEdit?.(role)}
        onSave={() => onSave?.()}
        isForRoleMappingTree={true}
        showHorizontalBorders={true}
      />
    ),
    key: `${parentKey}-r${role.id}`,
    isLeaf: true,
  }
}

export const getRoleMappingHeaderNode = (parentKey: string): DataNode => {
  return {
    title: (
      <RowTitleComponent
        column1={'Source Role'}
        column2={'Target Role/Email'}
        isForRoleMappingTree={true}
      />
    ),
    key: `${parentKey}-x0`,
    isLeaf: true,
  }
}

export const treeData2 = (
  nodes: TreeTemplateData[],
  onNewElement: Function,
  onView: Function,
  onEdit: Function,
  onClone: Function,
  onDelete: Function,
  onSave: Function,
  onMove: Function,
  defaultGroupId: number,
  groupListOptions: MenuProps['items'],
  isRoleMapping: boolean,
  editingElement?: EditingElementType,
  editingTemplate?: DataNode,
  defaultParentKey?: string,
): DataNode[] => {
  const editingNode =
    defaultParentKey && editingElement?.key === defaultParentKey
      ? [editingTemplate!]
      : []
  const nodeList = nodes.map((node) => {
    return getSingleTreeElement(
      node,
      onNewElement,
      onView,
      onEdit,
      onClone,
      onDelete,
      onSave,
      onMove,
      !!defaultParentKey,
      defaultGroupId,
      groupListOptions,
      isRoleMapping,
      editingElement,
      editingTemplate,
    )
  })
  return [...nodeList, ...editingNode]
}

export const getTabs = () => {}

const createTree = (array: TreeTemplateData[]) => {
  const tree: { [key: string]: TreeTemplateData[] } = {}
  for (const obj of array) {
    const parentKey = obj.parent || 'root'
    if (!tree[parentKey]) {
      tree[parentKey] = []
    }
    tree[parentKey].push(obj)
  }

  function attachChildren(
    node: TreeTemplateData,
    parentKey: string,
    isOwner: boolean,
  ) {
    node.isOwner = isOwner
    node.key =
      parentKey === 'root'
        ? 'g' + node.id
        : parentKey + '-' + node.type.charAt(0) + node.id
    const children = tree[node.type.charAt(0) + node.id]
    if (children && children.length > 0) {
      node.children = children
      for (const child of children) {
        attachChildren(child, node.key, isOwner)
      }
    }
  }

  const root = tree['root']
  if (!root) {
    return []
  }
  for (const node of root) {
    attachChildren(node, 'root', node.isOwner)
  }
  return root
}

export const getParentFolderAndGroup = (key?: string | number) => {
  const parents = (key as string)?.split('-')
  const groupId = parseInt(parents?.shift()?.substring(1)!)
  let lastElement = parents?.length === 0 ? undefined : parents?.pop()
  let templateId = undefined
  if (lastElement) {
    if (lastElement.charAt(0) === 't') {
      templateId = parseInt(lastElement.substring(1))
      lastElement = parents?.length === 0 ? undefined : parents?.pop()
    }
  }
  const folderId = lastElement ? parseInt(lastElement.substring(1)) : undefined
  if (lastElement) {
    lastElement = parents?.length === 0 ? undefined : parents?.pop()
  }
  const parentFolderId = lastElement
    ? parseInt(lastElement.substring(1))
    : undefined
  return { groupId, folderId, parentFolderId, templateId }
}

export const filterTreeData = (
  treeDataArray: TreeTemplateData[],
  searchString: string,
): TreeTemplateData[] => {
  if (!treeDataArray || !searchString) {
    return treeDataArray
  }
  searchString = searchString.toLowerCase()
  function filterHelper(node: TreeTemplateData) {
    if (!node) {
      return undefined
    }
    const newNode = { ...node }
    if (newNode.children) {
      newNode.children = newNode.children
        .map(filterHelper)
        .filter((child) => child) as TreeTemplateData[]
    }
    if (
      newNode.title.toLowerCase().includes(searchString) ||
      (newNode?.children?.length || 0) > 0
    ) {
      return newNode
    }
    return undefined
  }
  return treeDataArray
    .map(filterHelper)
    .filter((treeData) => treeData) as TreeTemplateData[]
}

export function sortByDate<T extends TemplateTask>(list: T[]): T[] {
  return [...list].sort((a, b) => {
    const a0 = dayjs(new Date(a.createdAt))
    const b0 = dayjs(new Date(b.createdAt))
    return b0.diff(a0)
  })
}

export const isDuplicatedTitle = (
  groupId: number,
  folderId: number | undefined,
  templateId: number,
  title: string,
  templates: TemplateTask[],
) => {
  return !!templates.find(
    (template) =>
      template.task?.group?.id === groupId &&
      (template.folder?.id || 0) === (folderId || 0) &&
      template.id !== templateId &&
      template.title?.toLowerCase()?.trim() === title.toLowerCase().trim(),
  )
}

export const mergeTreeData = (
  treeData: DataNode[],
  additionalData: {
    [key: string]: DataNode[]
  },
): DataNode[] => {
  const mergedTree = [...treeData]
  const mergeChildren = (nodes: DataNode[]): DataNode[] => {
    return (nodes || []).map((node) => {
      if (additionalData[node.key as string]) {
        return {
          ...node,
          children: additionalData[node.key as string],
        }
      }
      if (node.children?.length) {
        return {
          ...node,
          children: mergeChildren(node.children),
        }
      }
      return node
    })
  }
  return mergeChildren(mergedTree)
}

export const uniqueRoleMappingArray = (
  objects: RoleMappingBase[],
  keepFirst = true,
) => {
  return Array.from(
    objects
      .reduce((map, e) => {
        if (keepFirst && map.has(e.assigningRole)) return map
        return map.set(e.assigningRole, e)
      }, new Map())
      .values(),
  )
}
