import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TaskMode } from 'services/Tasks.slice'
import { TaskNodeData, TaskPriority, TaskStatusId } from 'types/Tasks'
import { hasPermission } from 'utils/permissions'
import {
  getAssigneeInvitationStatus,
  getGroupNameWithOwnerIfNeeded,
  isInstanceOfSubtaskOfRoutine,
  isRoutineDefinitionSchedule,
  isRoutineInstanceSchedule,
} from 'utils/taskUtils'
import {
  borderColors,
  completedColor,
  draftColor,
  getLevelForColor,
} from '../../FlowUtils'

export const useTaskNodeData = (data: TaskNodeData) => {
  const { t } = useTranslation()
  const mode = data.mode
  const isDraft = mode === TaskMode.DRAFT
  const isCompleted = mode === TaskMode.COMPLETED
  const isActive = !(isDraft || isCompleted)
  const isRunning =
    isActive &&
    !!data.status &&
    data.status?.id !== TaskStatusId.NOT_STARTED &&
    data.status?.id !== TaskStatusId.DONE
  const isAssigned = data.taskAssignees && data.taskAssignees.length > 0
  const schedule = data.scheduledTask
  const isScheduled =
    schedule &&
    (!schedule.recurRule || Object.keys(schedule.recurRule).length === 0)
  const isRoutineDefinition = isRoutineDefinitionSchedule(data.scheduledTask)
  const isRoutineInstance = isRoutineInstanceSchedule(data)
  const isSubtaskOfRoutine = isInstanceOfSubtaskOfRoutine(data)
  const isUrgent = data.taskDetails?.flag?.id === TaskPriority.URGENT
  const assigneesStatus = getAssigneeInvitationStatus(data).toLowerCase()
  const isTemplate = data.mode === TaskMode.TEMPLATE
  const hasGroup = !!data.group
  const groups = useStore((state) => state.tasks?.groups)
  const userTemplateRole = useStore((state) => state.userTemplateRole)
  const defaultRoleMapping = { assigningRole: '', email: '', managingRole: 0 }
  const taskRoleMapping = data.taskRole || defaultRoleMapping
  const groupNameWithOwner = getGroupNameWithOwnerIfNeeded(data, groups)
  const groupName = (hasGroup && groupNameWithOwner) || ''
  const taskRolePermissions = useStore((state) => state.taskRolePermissions)
  const dateFormat = useStore((state) => state.dateFormat)
  const hasPermissionToUpdateField = useCallback(
    (field: string, roles?: number[]) => {
      return hasPermission(field, roles, taskRolePermissions)
    },
    [taskRolePermissions],
  )
  const pendingAssignee = data.pendingAssignees?.find(
    (pa) => pa.status !== 'Refused',
  )
  const assigneesName = data.taskAssignees?.[0]?.assigneeName || ''
  const assigneesAvatar = data.taskAssignees?.[0]?.avatar
  let assigneeTooltip = t('my-today-page.delegate-task', { ns: 'common' })

  if (isAssigned && data.taskAssignees?.[0]?.assigneeName && assigneesStatus) {
    assigneeTooltip = t('delegation-status.' + assigneesStatus)
  } else if (pendingAssignee) {
    assigneeTooltip = t('my-today-page.delegation-confirmation-pending', {
      ns: 'common',
      date: dayjs(pendingAssignee.createdAt).format(dateFormat),
    })
  }

  const isIssue = useMemo(() => data.priorityGroup === 8, [data.priorityGroup])

  const groupIconName =
    groups?.find((group) => group.id === data.group?.id)?.metadata?.icon || ''
  const dueDateTooltip = data.dueAt
    ? dayjs(data.dueAt).format('dddd, MMMM D YYYY')
    : t('new-task-form.due-date.title').toString()

  const levelForColor = getLevelForColor(data.level)

  let iconColor = borderColors[levelForColor - 1]
  if (isDraft) iconColor = draftColor
  else if (isCompleted) iconColor = completedColor

  return {
    assigneesName,
    assigneesAvatar,
    assigneesStatus,
    assigneeTooltip,
    dueDateTooltip,
    groupIconName,
    groupName,
    hasGroup,
    hasPermissionToUpdateField,
    iconColor,
    isActive,
    isAssigned,
    isCompleted,
    isDraft,
    pendingAssignee,
    isRoutineDefinition,
    isRoutineInstance,
    isRunning,
    isScheduled,
    isSubtaskOfRoutine,
    isTemplate,
    isUrgent,
    isIssue,
    levelForColor,
    schedule,
    taskRoleMapping,
    userTemplateRole,
  }
}
