import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactFlowProvider } from 'reactflow'
import 'reactflow/dist/style.css'
import { TaskEditorProvider, useTaskEditor } from 'sections/TaskEditorModal'
import type { TaskItem } from 'types/Tasks'
import { TemplateTask } from 'types/Templates'

interface TaskFlowViewer {
  isTaskFlowViewerVisible: boolean
  isCalledFromPlanning: boolean
  isTemplateMode: boolean
  taskFlowTemplateId: number
  initialTaskItemFlow?: Partial<TaskItem>
  showTaskFlowViewer: () => void
  switchToTaskMode: (values?: Partial<TaskItem>) => void
  switchToTemplateMode: (value: TemplateTask) => void
  openTaskFlowViewer: (
    values?: Partial<TaskItem>,
    calledFromPlanning?: boolean,
    templateId?: number,
  ) => void
  closeTaskFlowViewer: () => void
  editTaskOnModal: (values?: Partial<TaskItem>) => void
}

const TaskFlowViewerContext = createContext<TaskFlowViewer>({
  isTaskFlowViewerVisible: false,
  isCalledFromPlanning: false,
  isTemplateMode: false,
  taskFlowTemplateId: 0,
  initialTaskItemFlow: {},
  showTaskFlowViewer: () => {},
  switchToTaskMode: (values?: Partial<TaskItem>) => {},
  switchToTemplateMode: (value: TemplateTask) => {},
  openTaskFlowViewer: (
    values?: Partial<TaskItem>,
    calledFromPlanning?: boolean,
    templateId?: number,
  ) => {},
  closeTaskFlowViewer: () => {},
  editTaskOnModal: (values?: Partial<TaskItem>) => {},
})

type ProviderProps = {
  children: React.JSX.Element | React.JSX.Element[]
}

export const useTaskFlowViewer = (): TaskFlowViewer => {
  const context = useContext(TaskFlowViewerContext)
  const { t } = useTranslation()

  if (context === undefined) {
    throw new Error(
      `${t('usecontext-within-provider', {
        ns: 'validation',
        context: 'useTaskFlowViewer',
        provider: 'TaskFlowViewerProvider',
      })}`,
    )
  }
  return context
}

export const TaskFlowViewerProvider = ({ children }: ProviderProps) => {
  const [isTaskFlowViewerVisible, setIsTaskFlowViewerVisible] = useState(false)
  const [isCalledFromPlanning, setIsCalledFromPlanning] = useState(false)
  const [isTemplateMode, setIsTemplateMode] = useState(false)
  const [taskFlowTemplateId, setTaskFlowTemplateId] = useState(0)
  const [initialTaskItemFlow, setInitialTaskItemFlow] =
    useState<Partial<TaskItem>>()

  const { open } = useTaskEditor()

  return (
    <TaskFlowViewerContext.Provider
      value={{
        isTaskFlowViewerVisible,
        isCalledFromPlanning,
        isTemplateMode,
        taskFlowTemplateId,
        initialTaskItemFlow,
        showTaskFlowViewer: () => {
          setIsTaskFlowViewerVisible(true)
        },
        switchToTaskMode: (values?: Partial<TaskItem>) => {
          setIsTemplateMode(false)
          setTaskFlowTemplateId(0)
          if (values) {
            setInitialTaskItemFlow(values)
          } else {
            setInitialTaskItemFlow({ id: '' })
          }
        },
        switchToTemplateMode: (value: TemplateTask) => {
          setIsTemplateMode(true)
          setTaskFlowTemplateId(value.id)
          setInitialTaskItemFlow({ id: value.task?.id })
        },
        openTaskFlowViewer: (
          values,
          calledFromPlanning = false,
          templateId = 0,
        ) => {
          setIsCalledFromPlanning(calledFromPlanning)
          setIsTemplateMode(!!templateId)
          setTaskFlowTemplateId(templateId)
          if (values) {
            setInitialTaskItemFlow(values)
          } else {
            setInitialTaskItemFlow({ id: '' })
          }
          setIsTaskFlowViewerVisible(true)
        },
        closeTaskFlowViewer: () => {
          setIsTaskFlowViewerVisible(false)
          setInitialTaskItemFlow(undefined)
          setIsTemplateMode(false)
          setTaskFlowTemplateId(0)
        },
        editTaskOnModal: (values) => {
          open({ ...values })
        },
      }}
    >
      <TaskEditorProvider>
        <ReactFlowProvider>{children}</ReactFlowProvider>
      </TaskEditorProvider>
    </TaskFlowViewerContext.Provider>
  )
}
