import {
  $createListItemNode,
  $createListNode,
  $isListItemNode,
  $isListNode,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $copyNode,
  $getNodeByKey,
  $getRoot,
  $isElementNode,
  $isLeafNode,
  $isRootOrShadowRoot,
  $isTextNode,
  TextNode,
} from 'lexical'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import {
  $isSelectingEmptyListItem,
  append,
  createListOrMerge,
} from '../utils/formatListUtils'
import './index.css'

export type ContentPastePluginProps = {
  removeCompletedOnes: () => void
}

const SUPPORTED_NODE_TYPES = ['text']

const ContentPastePlugin = forwardRef((_, ref) => {
  useImperativeHandle(ref, () => ({
    removeCompletedOnes() {
      editor.update(
        () => {
          const root = $getRoot()
          const childrenKeys = root.getChildrenKeys()
          for (let i = childrenKeys.length - 1; i >= 0; i--) {
            const childNode = $getNodeByKey(childrenKeys[i])
            if ($isListNode(childNode)) {
              const grandChildren = childNode.getChildren()
              if (childNode.getListType() !== 'check') {
                continue
              }
              for (let y = grandChildren.length - 1; y >= 0; y--) {
                const grandChild = $getNodeByKey(grandChildren[y]?.getKey())
                if ($isListItemNode(grandChild)) {
                  if (!grandChild.getChecked()) {
                    grandChild.remove()
                  }
                }
              }
            }
          }
        },
        { discrete: true },
      )
    },
  }))

  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerUpdateListener((listener) => {
      if (!listener.tags.has('paste')) return

      const listType = 'bullet'

      editor.update(() => {
        const insertList = (nodeToConvert: TextNode, indent: number) => {
          // This is a modified version of the insertList function in
          // https://github.com/facebook/lexical/blob/main/packages/lexical-list/src/formatList.ts
          const nodes = [nodeToConvert]
          const anchorNode = nodeToConvert
          const anchorNodeParent = anchorNode.getParent()

          if ($isSelectingEmptyListItem(anchorNode, nodes)) {
            const list = $createListNode(listType)

            if ($isRootOrShadowRoot(anchorNodeParent)) {
              anchorNode.replace(list)
              const listItem = $createListItemNode()
              if ($isElementNode(anchorNode)) {
                listItem.setFormat(anchorNode.getFormatType())
                listItem.setIndent(anchorNode.getIndent())
              }
              list.append(listItem)
            } else if ($isListItemNode(anchorNode)) {
              const parent = anchorNode.getParentOrThrow()
              append(list, parent.getChildren())
              parent.replace(list)
            }

            return
          } else {
            const handled = new Set()
            for (let i = 0; i < nodes.length; i++) {
              const node = nodes[i]

              if (
                $isElementNode(node) &&
                node.isEmpty() &&
                !$isListItemNode(node) &&
                !handled.has(node.getKey())
              ) {
                createListOrMerge(node, listType)
                continue
              }

              if ($isLeafNode(node)) {
                let parent = node.getParent()
                while (parent != null) {
                  const parentKey = parent.getKey()

                  if ($isListNode(parent)) {
                    if (!handled.has(parentKey)) {
                      const newListNode = $createListNode(listType)
                      append(newListNode, parent.getChildren())
                      parent.replace(newListNode)
                      handled.add(parentKey)
                    }

                    break
                  } else {
                    const nextParent = parent.getParent()

                    if (
                      $isRootOrShadowRoot(nextParent) &&
                      !handled.has(parentKey)
                    ) {
                      handled.add(parentKey)
                      createListOrMerge(parent, listType, indent)
                      break
                    }

                    parent = nextParent
                  }
                }
              }
            }
          }
        }

        listener.dirtyLeaves.forEach((key) => {
          const node = $getNodeByKey(key)
          if (!node || !SUPPORTED_NODE_TYPES.includes(node?.getType() ?? ''))
            return

          const copy = $copyNode(node)
          if ($isTextNode(copy)) {
            const content = node?.getTextContent()
            let indent = -1
            if (content.startsWith('·')) {
              indent = 0
            }
            if (content.startsWith('o')) {
              indent = 1
            }
            if (content.startsWith('§')) {
              indent = 2
            }
            if (indent !== -1) {
              copy.setTextContent(content.substring(1).trimStart())
              node.replace(copy)
              insertList(copy, indent)
            }
          }
        })
      })
    })
  }, [editor])

  return null
})

export default ContentPastePlugin
