import { TaskGroup, TaskItem, TaskUser } from './Tasks'

export const TemplateTypes = Object.freeze({
  TASK: 'task',
  CUSTOM_FIELD: 'custom-field',
})

export type AllowedTemplateTypes =
  (typeof TemplateTypes)[keyof typeof TemplateTypes]

export type UserFolderBase = {
  id: number
  title: string
  group: number
  parent?: number | null | undefined
}

export type UserFolder = Omit<UserFolderBase, 'parent' | 'group'> & {
  user: TaskUser
  parent?: UserFolder
  group: TaskGroup
}

export type TemplateTaskBase = {
  id: number
  title: string
  task: string
  folder?: number | null | undefined
  type: AllowedTemplateTypes
  updatedAt: string
  createdAt: string
}

export type TemplateTask = Omit<TemplateTaskBase, 'task' | 'folder'> & {
  user: TaskUser
  task: TaskItem
  folder: UserFolder
  status: string
}

export type UserTemplateRoleBase = {
  id: number
  user: string
  title: string
  email: string
  updatedAt: string
  createdAt: string
}

export type UserTemplateRole = UserTemplateRoleBase

export type RoleMappingBase = {
  id: number
  user: string
  assigningRole: string
  email: string
  managingRole: number
  task: string
  template: number
  updatedAt: string
  createdAt: string
}

export type RoleMapping = RoleMappingBase

export type RoleMappingShared = {
  id: number
  role: string
  email: string
  roleMatches?: boolean
}
