import { Typography, List, Row, Col, Space, Button } from 'antd'
import dayjs from 'dayjs'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import UserAvatar from 'components/UserAvatar'
import { Size } from 'components/UserAvatar/UserAvatar'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

type ChatMessageProps = {
  message: string
  userName?: string
  userAvatar?: string
  createdAt: string
  dateFormatOption?: string
  timeFormat?: string
  updatedAt?: string
  deletedAt?: string
  onEditClick: () => void
  onDeleteClick: () => void
}

const ChatMessage = ({
  message,
  userName,
  userAvatar,
  createdAt,
  dateFormatOption,
  timeFormat,
  updatedAt,
  deletedAt,
  onEditClick,
  onDeleteClick,
}: ChatMessageProps) => {
  const [showActions, setShowActions] = useState<boolean>(false)
  const urlRegex = /https?:\/\/(.+?\.)+\w{2,}(\/.+?)?/
  const parts = message.split(' ')
  const { t } = useTranslation()

  const dateFormat = useMemo(
    () => (dateFormatOption === 'month_first' ? 'MM/DD' : 'DD/MM'),
    [dateFormatOption],
  )

  return (
    <List.Item
      style={{
        textAlign: 'left',
        borderBlockEnd: 'none',
        padding: 0,
        justifyContent: userName ? 'left' : 'right',
      }}
    >
      <Row
        style={{
          maxWidth: '75%',
          marginBottom: '1em',
          alignItems: userName ? 'start' : 'end',
        }}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        {userName && (
          <UserAvatar
            label={userName}
            image={userAvatar}
            size={Size.LARGE}
            avatarStyles={{ marginTop: '16px', marginRight: '8px' }}
          />
        )}
        <Row
          style={{
            flexDirection: 'column',
            alignItems: userName ? 'start' : 'end',
          }}
        >
          {showActions && !deletedAt && !userName && (
            <Row
              style={{
                position: 'absolute',
                zIndex: 3,
                backgroundColor: 'var(--secondary-text-color)',
                borderRadius: 4,
                padding: '6px',
              }}
            >
              <Button
                onClick={onEditClick}
                title={t('task-grid-buttons.edit-task') || 'Edit chat'}
                size="small"
                type="text"
                icon={
                  <EditOutlined
                    style={{ color: 'var(--offset-background-color)' }}
                  />
                }
              />
              <Button
                onClick={onDeleteClick}
                title={t('task-grid-buttons.delete-task') || 'Delete chat'}
                size="small"
                type="text"
                icon={
                  <DeleteOutlined
                    style={{ color: 'var(--offset-background-color)' }}
                  />
                }
              />
            </Row>
          )}
          <Row>
            <Text
              style={{
                marginRight: '8px',
                color: 'var(--secondary-text-color)',
              }}
            >
              {userName && `${userName} - `}
              {`${dayjs(createdAt).format(
                `${dateFormat} ${timeFormat}`,
              )}${updatedAt ? ' - edited' : ''}`}
            </Text>
          </Row>
          <Col
            style={{
              backgroundColor: userName
                ? 'var(--background-color)'
                : 'var(--blue-highlight)',
              padding: 8,
              borderRadius: 4,
              maxWidth: 'fit-content',
            }}
          >
            <Space direction="vertical">
              <Text
                style={{
                  whiteSpace: 'pre-line',
                  fontStyle: deletedAt ? 'italic' : 'inherit',
                }}
              >
                {deletedAt
                  ? 'Message has been deleted'
                  : parts.map((part, index) => {
                      if (urlRegex.test(part)) {
                        return (
                          <a
                            key={index}
                            href={part}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {part + ' '}
                          </a>
                        )
                      }
                      return part + ' '
                    })}
              </Text>
            </Space>
          </Col>
        </Row>
      </Row>
    </List.Item>
  )
}

export default ChatMessage
