import { InfoCircleOutlined } from '@ant-design/icons'
import {
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import { CustomFieldDataFormat, CustomFieldDataType } from 'types/CustomFields'
import styles from './CustomFields.module.scss'
import CustomFieldsCellComponent from './CustomFieldsCellComponent'

const { Text } = Typography
const listSeparator = '|'

type CustomFieldsValuesRowProps = {
  field: CustomFieldDataFormat
  value?: string | number
  disabled?: boolean
  onChange?: (value: string | number) => void
}

const CustomFieldsValuesRow = ({
  field,
  value,
  onChange,
  disabled,
}: CustomFieldsValuesRowProps) => {
  const { dateFormat, timeFormat } = useStore((state) => state)
  const dateFormatCustomValue = 'YYYY/MM/DD'
  const timeFormatCustomValue = 'HH:mm'
  const date =
    field.type === 'date' && value
      ? dayjs(value, dateFormatCustomValue)
      : undefined
  const time =
    field.type === 'time' && value
      ? dayjs(value, timeFormatCustomValue)
      : undefined

  return (
    <Row className={styles.rowGrid} gutter={[24, 24]}>
      <CustomFieldsCellComponent span={6} justify="left">
        <Text className={field.isRequired ? styles.isRequired : undefined}>
          {field.label}
        </Text>
        {field.description && (
          <Tooltip title={field.description}>
            <InfoCircleOutlined style={{ marginLeft: '4px' }} />
          </Tooltip>
        )}
      </CustomFieldsCellComponent>
      <CustomFieldsCellComponent span={18} justify="left">
        {field.type === CustomFieldDataType.NUMBER && (
          <InputNumber
            placeholder={field.description}
            value={value}
            onChange={(value) => {
              if (onChange && typeof value === 'number') {
                onChange(value)
              }
            }}
            disabled={disabled}
            style={{ width: '100%' }}
          />
        )}
        {field.type === CustomFieldDataType.TEXT && (
          <Input
            placeholder={field.description}
            maxLength={field.maxChar}
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={disabled}
          />
        )}
        {field.type === CustomFieldDataType.DATE && (
          <DatePicker
            value={date}
            disabled={disabled}
            format={dateFormat}
            onChange={(e) => onChange?.(e?.toISOString() || '')}
          />
        )}
        {field.type === CustomFieldDataType.TIME && (
          <TimePicker
            value={time}
            disabled={disabled}
            format={timeFormat}
            onChange={(e) => onChange?.(e?.toISOString() || '')}
          />
        )}
        {field.type === CustomFieldDataType.LIST && (
          <Select
            allowClear
            style={{ width: '100%' }}
            value={value as string}
            disabled={disabled}
            onChange={(value: string) => onChange?.(value)}
            options={field.options
              ?.split(listSeparator)
              .map((option) => ({ label: option, value: option }))}
          />
        )}
      </CustomFieldsCellComponent>
    </Row>
  )
}

export default CustomFieldsValuesRow
