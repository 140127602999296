import { Row, Typography } from 'antd'
import clsx from 'clsx'
import CellComponent from './CellComponent'
import styles from './RoleMapping.module.scss'

const { Title } = Typography

type RowTitleComponentProps = {
  column1: string
  column2: string
  isForRoleMappingTree?: boolean
}

const RowTitleComponent = ({
  column1,
  column2,
  isForRoleMappingTree,
}: RowTitleComponentProps) => {
  return (
    <Row
      className={clsx(
        isForRoleMappingTree ? styles.rowRoleMappingTree : styles.rowGrid,
      )}
      gutter={[24, 24]}
    >
      <CellComponent span={12}>
        <Title level={5}>{column1}</Title>
      </CellComponent>
      <CellComponent span={12}>
        <Title level={5}>{column2}</Title>
      </CellComponent>
    </Row>
  )
}

export default RowTitleComponent
