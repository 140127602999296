import {
  DeleteOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { Button, Dropdown, MenuProps, Popconfirm, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { AllowedTemplateTypes } from 'types/Templates'
import { EditingElementType, ElementEnum } from '../TemplatesUtils'

type TemplateTaskToolbarProps = {
  templateKey: string
  title: string
  taskId: string
  isOwner: boolean
  onView: Function
  onEdit: Function
  onClone: Function
  onDelete: Function
  onMove: Function
  isMyTemplates: boolean
  defaultGroupKey: number
  groupListOptions: MenuProps['items']
  templateType?: AllowedTemplateTypes
}

const TemplateTaskToolbar = ({
  templateKey,
  title,
  taskId,
  isOwner,
  onView,
  onEdit,
  onClone,
  onDelete,
  onMove,
  isMyTemplates,
  defaultGroupKey,
  groupListOptions,
  templateType,
}: TemplateTaskToolbarProps) => {
  const { t } = useTranslation()
  return (
    <Space>
      <Button
        icon={<RemoveRedEyeOutlinedIcon />}
        type="text"
        size="small"
        onClick={() =>
          onView({
            type: ElementEnum.NEW_TEMPLATE,
            taskId: taskId,
            key: templateKey,
            templateType,
          } as EditingElementType)
        }
      />

      {isOwner && (
        <>
          <Button
            icon={<BorderColorOutlinedIcon />}
            type="text"
            size="small"
            onClick={() =>
              onEdit({
                type: ElementEnum.EDIT_TEMPLATE,
                title: title,
                taskId: taskId,
                key: templateKey,
              } as EditingElementType)
            }
          />
          <Button
            icon={<ContentCopyOutlinedIcon />}
            type="text"
            size="small"
            onClick={() =>
              onClone({
                type: ElementEnum.CLONE_TEMPLATE,
                title: title,
                taskId: taskId,
                key: templateKey,
              } as EditingElementType)
            }
          />
          {isMyTemplates ? (
            <Dropdown
              menu={{
                items: groupListOptions,
                onClick: (event) => {
                  onMove({
                    type: ElementEnum.NEW_TEMPLATE,
                    title: title,
                    taskId: taskId,
                    key: templateKey,
                    target: event.key,
                  } as EditingElementType)
                },
              }}
              trigger={['hover']}
            >
              <Button icon={<DoubleRightOutlined />} type="text" size="small" />
            </Dropdown>
          ) : (
            <Button
              icon={<DoubleLeftOutlined />}
              type="text"
              size="small"
              onClick={(event) => {
                onMove({
                  type: ElementEnum.NEW_TEMPLATE,
                  title: title,
                  taskId: taskId,
                  key: templateKey,
                  target: defaultGroupKey,
                } as EditingElementType)
              }}
            />
          )}

          <Popconfirm
            title={t('templates.delete-modal.title', {
              ns: 'common',
            })}
            description={t('templates.delete-modal.description', {
              ns: 'common',
            })}
            okText={t('templates.delete-modal.ok-text', {
              ns: 'common',
            })}
            okButtonProps={{
              danger: true,
              type: 'default',
              icon: <DeleteOutlined />,
            }}
            cancelText={t('templates.delete-modal.cancel-text', {
              ns: 'common',
            })}
            icon={null}
            onConfirm={() =>
              onDelete({
                type: ElementEnum.DELETE_TEMPLATE,
                taskId: taskId,
                key: templateKey,
              } as EditingElementType)
            }
          >
            <Button
              icon={<DeleteOutlinedIcon />}
              type="text"
              size="small"
              style={{ color: 'var(--warning-color)' }}
            />
          </Popconfirm>
        </>
      )}
    </Space>
  )
}

export default TemplateTaskToolbar
