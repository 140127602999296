import Home from 'views/Home'
import { lazy, Suspense } from 'react'
import PageLayout from 'components/PageLayout'
import PageLoader from 'components/PageLoader'
import { Routes, Route } from 'react-router-dom'
import { TaskEditorProvider } from 'sections/TaskEditorModal'
import { TaskFlowViewerProvider } from 'sections/TaskFlowViewer'
import ProtectedRoute, { RouteProtectionRule } from 'components/ProtectedRoute'

const Login = lazy(() => import('views/Login'))
const Signup = lazy(() => import('views/Signup'))
const Settings = lazy(() => import('views/Settings'))
const VerifyEmail = lazy(() => import('views/Signup/Verify'))
const LoginCallback = lazy(() => import('views/Login/Callback'))
const PasswordReset = lazy(() => import('views/PasswordReset'))
const ForgotPassword = lazy(() => import('views/ForgotPassword'))
const GroupInvite = lazy(() => import('views/Invites/GroupInvite'))
const DelegationInvite = lazy(() => import('views/Invites/DelegationInvite'))
const AttendeeInvite = lazy(() => import('views/Invites/AttendeeInvite'))
const Tasks = lazy(() => import('views/Tasks'))
const SingleTask = lazy(() => import('views/SingleTask'))
const Chat = lazy(() => import('views/Chat'))
const Calendar = lazy(() => import('views/Calendar'))
const Notifications = lazy(() => import('views/Notifications'))
const Planning = lazy(() => import('views/Planning'))
const Routines = lazy(() => import('views/Routines'))
const Templates = lazy(() => import('views/Templates'))
const Timesheets = lazy(() => import('views/Timesheets'))
const Integrations = lazy(() => import('views/Integrations'))
const Contacts = lazy(() => import('views/Contacts'))
const DonwloadToday = lazy(() => import('views/DownloadToday'))
const IntegrationCallback = lazy(() => import('views/IntegrationCallback'))
const TaskAgenda = lazy(() => import('views/Agenda/TaskAgenda'))
const Curriculum = lazy(() => import('views/Curriculum'))

const Router = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route
          element={
            <ProtectedRoute
              redirectURI="/login"
              rule={RouteProtectionRule.AUTHENTICATED}
            >
              <TaskEditorProvider>
                <TaskFlowViewerProvider>
                  <PageLayout />
                </TaskFlowViewerProvider>
              </TaskEditorProvider>
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/planning" element={<Planning />} />
          <Route path="/routines" element={<Routines />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/timesheets" element={<Timesheets />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/task/:taskId" element={<SingleTask />} />
          <Route path="/callback/:platform" element={<IntegrationCallback />} />
          <Route path="/task-agenda" element={<TaskAgenda />} />
          <Route path="/curriculum" element={<Curriculum />} />
        </Route>
        <Route
          element={
            <ProtectedRoute
              redirectURI="/"
              rule={RouteProtectionRule.NOT_AUTHENTICATED}
            >
              <PageLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/login" element={<Login />}>
            <Route path="callback/:provider" element={<LoginCallback />} />
          </Route>
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/password-reset/:uid/:token"
            element={<PasswordReset />}
          />
        </Route>
        <Route path="/accept-invite" element={<GroupInvite />} />
        <Route path="/accept-delegate-invite" element={<DelegationInvite />} />
        <Route path="/accept-attendee-invite" element={<AttendeeInvite />} />
        <Route path="/download-today" element={<DonwloadToday />} />
      </Routes>
    </Suspense>
  )
}

export default Router
