import { SerializedElementNode, SerializedTextNode } from 'lexical'
import { SerializedEditorState } from 'lexical/LexicalEditorState'
import { SerializedLexicalNode } from 'lexical/LexicalNode'
import { TaskMode, TaskType } from 'services/Tasks.slice'
import { TaskPriorityGroup, TaskStatusId } from 'types/Tasks'

export const getTaskTreeFromLexical = (
  lexicalJson: SerializedEditorState<SerializedLexicalNode>,
) => {
  const result: Partial<TaskType> = {}
  const root = lexicalJson.root
  if (root.type === 'root') {
    result.title = root.type
    if (Array.isArray(root.children)) {
      let children: SerializedElementNode<SerializedLexicalNode>[] =
        root.children as SerializedElementNode<SerializedLexicalNode>[]
      let lastChildNode:
        | SerializedElementNode<SerializedLexicalNode>
        | undefined = undefined
      for (let child of children) {
        processLexicalChild(child, result, lastChildNode)
        lastChildNode = { ...child }
      }
    }
  }
  return result.children
}

const processLexicalChild = (
  child: SerializedElementNode<SerializedLexicalNode>,
  parentTask: Partial<TaskType>,
  lastNode?: SerializedElementNode<SerializedLexicalNode>,
) => {
  if (isTaskNode(child, lastNode)) {
    const { title, description, ...additionalProps } =
      getTitleAndDescription(child)
    if (title) {
      if (
        lastNode &&
        belongsToPreviousNode(child, lastNode) &&
        parentTask.children?.length
      ) {
        const previousIndex = parentTask.children.length - 1
        if (Array.isArray(parentTask.children[previousIndex]?.children)) {
          parentTask.children[previousIndex]!.children!.push({
            title,
            ...additionalProps,
          })
        } else {
          parentTask.children[previousIndex].children = [
            { title, ...additionalProps },
          ]
        }
      } else {
        if (Array.isArray(parentTask.children)) {
          parentTask.children.push({
            title,
            details: { description },
            ...additionalProps,
          })
        } else {
          parentTask.children = [
            { title, details: { description }, ...additionalProps },
          ]
        }
      }
    }
  } else if (
    lastNode &&
    isNestedListItem(child, lastNode) &&
    parentTask.children?.length
  ) {
    // When it is a list inside another list (listitem - list - listitem)
    const grandChildren: SerializedElementNode<SerializedLexicalNode>[] =
      child.children as SerializedElementNode<SerializedLexicalNode>[]
    let lastGrandchildNode:
      | SerializedElementNode<SerializedLexicalNode>
      | undefined = undefined
    for (const grandChild of grandChildren) {
      processLexicalChild(
        grandChild,
        parentTask.children[parentTask.children.length - 1],
        lastGrandchildNode,
        // lastTask,
      )
      lastGrandchildNode = { ...grandChild }
    }
  }
  if (isList(child)) {
    const grandChildren: SerializedElementNode<SerializedLexicalNode>[] =
      child.children as SerializedElementNode<SerializedLexicalNode>[]
    let lastGrandchildNode:
      | SerializedElementNode<SerializedLexicalNode>
      | undefined = undefined
    if (lastNode) {
      parentTask = getLastChild(parentTask)
    }
    for (const grandChild of grandChildren) {
      processLexicalChild(grandChild, parentTask, lastGrandchildNode)
      lastGrandchildNode = { ...grandChild }
    }
  }
}

const isTaskNode = (
  node: SerializedElementNode<SerializedLexicalNode>,
  lastNode?: SerializedElementNode<SerializedLexicalNode>,
) => {
  if (node.type === 'paragraph' || node.type === 'heading') {
    return true
  }
  if (node.type === 'listitem') {
    if (!lastNode) {
      return true
    }
    if (!isNestedListItem(node, lastNode)) {
      return true
    }
  }
  return false
}

const isList = (node: SerializedElementNode<SerializedLexicalNode>) => {
  return node.type === 'list'
}

const getLastChild = (task: Partial<TaskType>): Partial<TaskType> => {
  if (!task.children || task.children.length === 0) {
    return task
  } else {
    return getLastChild(task.children[task.children.length - 1])
  }
}

const isNestedListItem = (
  node: SerializedElementNode<SerializedLexicalNode> & { value?: number },
  lastNode: SerializedElementNode<SerializedLexicalNode> & { value?: number },
) => {
  if (lastNode.type !== node.type) {
    return false
  }
  return (
    node.type === 'listitem' &&
    node.children?.[0]?.type === 'list' &&
    (node.value || 0) > (lastNode.value || 0)
  )
}

const belongsToPreviousNode = (
  node: SerializedElementNode<SerializedLexicalNode>,
  lastNode: SerializedElementNode<SerializedLexicalNode>,
) => {
  return lastNode.type === 'heading'
}

const getTitleAndDescription = (
  node: SerializedElementNode<SerializedLexicalNode>,
  prefix?: string,
) => {
  const titleArray: string[] = []
  const descriptionArray: string[] = []
  let isStartingWords = true
  let trimTitle = true
  let index = 0
  for (const child of node.children || []) {
    if (child.type === 'text') {
      const text = (child as SerializedTextNode).text
      if (node.type === 'heading') {
        // When the whole node is a heading
        titleArray.push(text)
      } else if (isStartingWords) {
        if (text?.indexOf(':') !== -1) {
          // When paragraph starts with a subtitle before :
          const splitted = text.split(':')
          const title = splitted.shift()!
          titleArray.push(title)
          descriptionArray.push(splitted?.join(':'))
          isStartingWords = false
          trimTitle = false
        } else if (
          titleArray.length === 1 &&
          index > 0 &&
          (node.children[index - 1] as SerializedTextNode).format >
            (node.children[index] as SerializedTextNode).format
        ) {
          // When paragraph starts with a subtitle in bold
          isStartingWords = false
          trimTitle = false
          descriptionArray.push(text)
        } else {
          titleArray.push(text)
        }
      } else {
        descriptionArray.push(text)
      }
    } else if (child.type === 'link') {
      const text = (
        (child as SerializedElementNode<SerializedLexicalNode>)
          .children?.[0] as SerializedTextNode
      )?.text
      if (text) {
        if (isStartingWords) {
          titleArray.push(text)
        } else {
          descriptionArray.push(text)
        }
      }
    } else if (child.type === 'linebreak') {
      if (titleArray.length) {
        isStartingWords = false
      }
      if (descriptionArray.length) {
        descriptionArray.push('\n')
      }
    }
    index++
  }

  const specialChars = ['*', '-', '·', 'o', '§']
  for (let i: number = 0; i <= titleArray.length - 1; i++) {
    let startsWithSpecialChar = false
    for (const char of specialChars) {
      if (titleArray[i].startsWith(char)) {
        titleArray[i] = titleArray[i].slice(1).trim()
        startsWithSpecialChar = true
      }
    }
    if (!startsWithSpecialChar) {
      break
    }
  }

  if (prefix && titleArray.length) {
    titleArray.unshift(prefix)
  }

  const joinedTitle = titleArray.length
    ? titleArray.join(' ').trim()
    : descriptionArray.join(' ').trim()
  const joinedDescription = descriptionArray.length
    ? descriptionArray.join(' ').trim()
    : joinedTitle
  let trimmedTitle =
    trimTitle && joinedTitle.length > 80
      ? clip(joinedTitle.trim(), 50) + '…'
      : joinedTitle
  const concatToDescription =
    trimmedTitle === joinedTitle ? '' : joinedTitle + '\n'
  const description =
    concatToDescription + joinedTitle === joinedDescription
      ? ''
      : joinedDescription

  const isCompleted =
    node.type === 'listitem' && 'checked' in node && node.checked

  return {
    title: trimmedTitle,
    description,
    ...(isCompleted ? completedTaskProperties() : {}),
  }
}

const clip = (phrase: string, limit: number) => {
  if (phrase.length <= limit) return phrase
  let sliceIndex = limit
  while (sliceIndex < phrase.length && /\b/.test(phrase[sliceIndex]))
    sliceIndex++
  return phrase.slice(0, sliceIndex)
}

const completedTaskProperties = () => {
  return {
    completionAt: new Date().toISOString(),
    mode: TaskMode.COMPLETED,
    priorityGroup: TaskPriorityGroup.Now,
    status: TaskStatusId.DONE,
    taskPosition: 'bottom',
  } as Partial<TaskType>
}
