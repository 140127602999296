import { Flex, Typography } from 'antd'
import { PropsWithChildren } from 'react'

type CardFooterSelectItemProps = {
  label: string
}

const CardFooterSelectItem = ({
  children,
  label,
}: PropsWithChildren<CardFooterSelectItemProps>) => {
  return (
    <Flex justify="space-between" style={{ marginLeft: 8, marginBottom: 4 }}>
      <Typography.Text>{label}</Typography.Text>
      <div
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        style={{ marginLeft: 8 }}
      >
        {children}
      </div>
    </Flex>
  )
}
export default CardFooterSelectItem
