import useStore from 'hooks/useStore'
import React, { createContext } from 'react'
import { FCProps } from 'types/FC'
import useWebSocket from 'react-use-websocket'

export type WebSocketContextType = {
  lastMessage: MessageEvent | null
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
)

type WebSocketProviderProps = FCProps & {
  url: string
}

export const WebSocketProvider = ({
  url,
  children,
}: WebSocketProviderProps) => {
  const token = useStore((state) => state.token)
  const { lastMessage } = useWebSocket(
    url,
    {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      queryParams: { token: token?.access || '' },
    },
    token !== undefined,
  )

  const value: WebSocketContextType = {
    lastMessage,
  }

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  )
}
