import { PlusOutlined } from '@ant-design/icons'
import { Button, Space, Switch as SwitchAntd, Tooltip, Typography } from 'antd'
import useTaskEditorStore from 'hooks/useTaskEditorStore'
import { useTranslation } from 'react-i18next'
import { Userpilot } from 'userpilot'

const { Text } = Typography

type ActionButtonsProps = {
  onCancel: () => void
  onInvite: () => void
}

const ActionButtons = ({ onCancel, onInvite }: ActionButtonsProps) => {
  const { t } = useTranslation()
  const { isIndividualModeSelected, updateIsIndividualModeSelected } =
    useTaskEditorStore((state) => state)

  return (
    <Space wrap style={{ width: '100%', justifyContent: 'flex-start' }}>
      <Button onClick={onCancel}>{t('delegation-drawer.cancel-text')}</Button>
      <Tooltip
        title={
          isIndividualModeSelected
            ? t('new-task-form.individual-delegation-tooltip')
            : t('new-task-form.shared-delegation-tooltip')
        }
      >
        <SwitchAntd
          checked={!isIndividualModeSelected}
          checkedChildren={
            <Space align="center" style={{ height: 29 }}>
              <Text style={{ color: '#FFF' }}>
                {t('delegation-drawer.delegation-mode.shared')}
              </Text>
            </Space>
          }
          unCheckedChildren={
            <Space align="center">
              <Text style={{ color: 'var(--text-color)' }}>
                {t('delegation-drawer.delegation-mode.individual')}
              </Text>
            </Space>
          }
          disabled={
            true
            // !hasFieldPermission(TaskFields.TASK_ASSIGNEES) || isTemplate
          }
          style={{
            backgroundColor: isIndividualModeSelected
              ? 'var(--not-started-color)'
              : 'var(--completed-color)',
            height: 30,
          }}
          onClick={(e) => {
            updateIsIndividualModeSelected(!e)
          }}
        />
      </Tooltip>
      <Button
        onClick={() => {
          Userpilot.track('Clicked Invite Other Users button')
          onInvite()
        }}
        type="primary"
        style={{ backgroundColor: 'var(--completed-color)' }}
      >
        {t('delegation-drawer.invite-other-user')}
        <PlusOutlined />
      </Button>
    </Space>
  )
}

export default ActionButtons
