import {
  RoleMappingBase,
  RoleMapping,
  TemplateTask,
  TemplateTaskBase,
  UserFolder,
  UserFolderBase,
  UserTemplateRole,
  UserTemplateRoleBase,
  AllowedTemplateTypes,
} from 'types/Templates'
import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import { TaskItem } from 'types/Tasks'
import { buildFilterQuery } from 'utils/taskUtils'
import { TemplateCustomField } from 'types/CustomFields'

type AllRoleMappingFilter = {
  taskId?: string
  templateId?: number
}

export interface TemplateTaskState extends ApiState {
  userTemplateRole: Partial<UserTemplateRole>[]
  createTemplateTask: (
    templateTask: Partial<TemplateTaskBase>,
  ) => Promise<TemplateTask>
  updateTemplateTask: (
    id: number,
    templateTask: Partial<TemplateTaskBase> & { group?: number },
  ) => Promise<TemplateTask>
  convertTemplateToDraft: (id: number) => Promise<TaskItem>
  convertTaskToTemplate: (
    task_id: string,
    type: AllowedTemplateTypes,
  ) => Promise<TemplateTask>
  cloneTemplateTask: (id: number) => Promise<TemplateTask>
  getAllTemplateTask: () => Promise<TemplateTask[]>
  getTemplateTaskById: (id: number) => Promise<TemplateTask>
  getTemplateTaskByTaskId: (task_id: string) => Promise<TemplateTask | null>
  deleteTemplateTask: (id: number) => Promise<void>
  createUserFolder: (userFolder: Partial<UserFolderBase>) => Promise<UserFolder>
  updateUserFolder: (
    id: number,
    userFolder: Partial<UserFolderBase>,
  ) => Promise<UserFolder>
  getAllUserFolder: () => Promise<UserFolder[]>
  getUserFolderById: (id: number) => Promise<UserFolder>
  deleteUserFolder: (id: number) => Promise<void>
  createUserTemplateRole: (
    userTemplateRole: Partial<UserTemplateRoleBase>,
  ) => Promise<UserTemplateRole>
  updateUserTemplateRole: (
    id: number,
    userTemplateRole: Partial<UserTemplateRoleBase>,
  ) => Promise<UserTemplateRole>
  getAllUserTemplateRole: () => Promise<UserTemplateRole[]>
  getUserTemplateRole: (id: number) => Promise<UserTemplateRole>
  deleteUserTemplateRole: (id: number) => Promise<void>

  createRoleMapping: (
    roleMapping: Partial<RoleMappingBase>,
  ) => Promise<RoleMapping>
  updateRoleMapping: (
    id: number,
    roleMapping: Partial<RoleMappingBase> & { updateSimilar?: boolean },
  ) => Promise<RoleMapping>
  getAllRoleMapping: (params: AllRoleMappingFilter) => Promise<RoleMapping[]>
  getRoleMapping: (id: number) => Promise<RoleMapping>
  deleteRoleMapping: (id: number) => Promise<void>

  getTemplateCustomField: () => Promise<TemplateCustomField[]>
}

export const createTemplateTaskSlice: StateCreator<TemplateTaskState> = (
  set,
  get,
) => ({
  userTemplateRole: [],
  createTemplateTask: async (templateTask) => {
    const response = await get().api!('POST', '/task/template', templateTask)
    return response.data
  },
  updateTemplateTask: async (id, templateTask) => {
    const response = await get().api!(
      'PATCH',
      `/task/template/${id}`,
      templateTask,
    )
    return response.data
  },
  convertTemplateToDraft: async (id) => {
    const response = await get().api!('POST', `/task/template/conversion/${id}`)
    return response.data
  },
  convertTaskToTemplate: async (task_id, type) => {
    const data = { type }
    const response = await get().api!(
      'POST',
      `/task/template/convert-from-task/${task_id}`,
      data,
    )
    return response.data
  },
  cloneTemplateTask: async (id) => {
    const response = await get().api!('POST', `/task/template/clone/${id}`)
    return response.data
  },
  getAllTemplateTask: async () => {
    const response = await get().api!('GET', `/task/template`)
    return response.data
  },
  getTemplateTaskById: async (id) => {
    const response = await get().api!('GET', `/task/template/${id}`)
    return response.data
  },
  getTemplateTaskByTaskId: async (task_id) => {
    const response = await get().api!('GET', `/task/template/search/${task_id}`)
    if (Array.isArray(response.data)) {
      if (response.data.length === 0) {
        return null
      }
    }
    return response.data
  },
  deleteTemplateTask: async (id) => {
    await get().api!('DELETE', `/task/template/${id}`)
    return
  },

  createUserFolder: async (userFolder) => {
    const response = await get().api!('POST', '/task/folder', userFolder)
    return response.data
  },
  updateUserFolder: async (id, userFolder) => {
    const response = await get().api!('PATCH', `/task/folder/${id}`, userFolder)
    return response.data
  },
  getAllUserFolder: async () => {
    const response = await get().api!('GET', `/task/folder`)
    return response.data
  },
  getUserFolderById: async (id) => {
    const response = await get().api!('GET', `/task/folder/${id}`)
    return response.data
  },
  deleteUserFolder: async (id) => {
    await get().api!('DELETE', `/task/folder/${id}`)
  },

  createUserTemplateRole: async (userTemplateRole) => {
    const response = await get().api!(
      'POST',
      '/task/template/role',
      userTemplateRole,
    )
    const newValues = [
      ...get().userTemplateRole,
      {
        id: response.data.id,
        title: response.data.title,
      },
    ]
    set({ userTemplateRole: newValues })
    return response.data
  },
  updateUserTemplateRole: async (id, userTemplateRole) => {
    const response = await get().api!(
      'PATCH',
      `/task/template/role/${id}`,
      userTemplateRole,
    )
    const newValues = get().userTemplateRole.map((role) =>
      role.id === response.data.id
        ? {
            id: response.data.id,
            title: response.data.title,
          }
        : role,
    )
    set({ userTemplateRole: newValues })
    return response.data
  },
  getAllUserTemplateRole: async () => {
    const response = await get().api!('GET', `/task/template/role`)
    const result: UserTemplateRole[] = response.data
    set({
      userTemplateRole: result.map((item) => ({
        id: item.id,
        title: item.title,
      })),
    })
    return result
  },
  getUserTemplateRole: async (id) => {
    const response = await get().api!('GET', `/task/template/role/${id}`)
    return response.data
  },
  deleteUserTemplateRole: async (id) => {
    await get().api!('DELETE', `/task/template/role/${id}`)

    const newValues = get().userTemplateRole.filter((role) => role.id !== id)
    set({ userTemplateRole: newValues })
  },

  createRoleMapping: async (roleMapping) => {
    const response = await get().api!('POST', '/task/role-mapping', roleMapping)
    return response.data
  },
  updateRoleMapping: async (id, roleMapping) => {
    const response = await get().api!(
      'PATCH',
      `/task/role-mapping/${id}`,
      roleMapping,
    )
    return response.data
  },
  getAllRoleMapping: async ({ taskId, templateId }) => {
    const filters = new Map<string, string | string[] | number[]>()
    if (taskId) {
      filters.set('task_id', taskId)
    }
    if (templateId) {
      filters.set('template_id', templateId.toString())
    }
    const filterQuery = buildFilterQuery(filters)
    const response = await get().api!(
      'GET',
      `/task/role-mapping?${filterQuery}`,
    )
    return response.data
  },
  getRoleMapping: async (id) => {
    const response = await get().api!('GET', `/task/role-mapping/${id}`)
    return response.data
  },
  deleteRoleMapping: async (id) => {
    await get().api!('DELETE', `/task/role-mapping/${id}`)
  },

  getTemplateCustomField: async () => {
    const response = await get().api!('GET', `/task/template/custom-field`)
    return response.data
  },
})
