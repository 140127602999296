import './styles.css'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { EditorState, LexicalEditor } from 'lexical'
import { HeadingNode } from '@lexical/rich-text'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { ListItemNode, ListNode } from '@lexical/list'
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import UpdatePlugin from './plugins/UpdatePlugin'
import UpdateHtmlPlugin from './plugins/UpdateHtmlPlugin'
import ToolbarPlugin from './plugins/ToolbarPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import { isJson } from 'utils/taskUtils'
import ContentPastePlugin, {
  ContentPastePluginProps,
} from './plugins/ContentPastePlugin'
import { RefObject } from 'react'
import { InlineImageNode } from './nodes/InlineImageNode'
import InlineImagePlugin from './plugins/InlineImagePlugin'
import { ImageNode } from './nodes/ImageNode'
import ImagesPlugin from './plugins/ImagesPlugin'

type WYSIWYGProps = {
  onChange: (editorState: EditorState, editor: LexicalEditor) => void
  initialContent: string | null
  editorRef?: { current: null | LexicalEditor }
  contentPasteRef?: RefObject<ContentPastePluginProps>
}

const theme = {
  // Theme styling goes here
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
  },
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'editor-text-underline',
    strikethrough: 'editor-text-strikethrough',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
    code: 'editor-text-code',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
    listitemChecked: 'editor-listitem-checked',
    listitemUnchecked: 'editor-listitem-unchecked',
  },
  image: 'editor-image',
  inlineImage: 'inline-editor-image',
  link: 'editor-link',
}

function Placeholder() {
  return <div className="editor-placeholder">Start typing...</div>
}

function onError(error: Error) {
  console.error(error)
}

export default function WYSIWYG({
  onChange,
  initialContent,
  editorRef,
  contentPasteRef,
}: WYSIWYGProps) {
  const editorConfig = {
    namespace: 'WYSIWYG',
    theme,
    onError,
    nodes: [
      HeadingNode,
      ImageNode,
      InlineImageNode,
      ListNode,
      ListItemNode,
      AutoLinkNode,
      LinkNode,
    ],
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-shell">
        <ToolbarPlugin />
        <div className="editor-container">
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor">
                  <ContentEditable className="editor-input" />
                </div>
              </div>
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={onChange} />
          {isJson(initialContent || '') ? (
            <UpdatePlugin initialContent={initialContent} />
          ) : (
            <UpdateHtmlPlugin initialContent={initialContent} />
          )}
          <ImagesPlugin />
          <InlineImagePlugin />
          <HistoryPlugin />
          <LexicalClickableLinkPlugin />
          <ListPlugin />
          <AutoLinkPlugin />
          <LinkPlugin />
          <CheckListPlugin />
          <ContentPastePlugin ref={contentPasteRef} />
          {editorRef !== undefined && <EditorRefPlugin editorRef={editorRef} />}
        </div>
      </div>
    </LexicalComposer>
  )
}
