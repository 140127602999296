import { App, Button, Col, Divider, Row, Space, Spin, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TaskRolePermission } from 'types/Permission'
import { TaskItem } from 'types/Tasks'
import SelectTag from '../DelegationDrawer/SelectTag'
import AddTaskMember from './AddTaskMember'
import TaskProjectManager from './TaskProjectManager'
import { useTaskEditor } from '../useTaskEditor'
import { TaskFields } from 'utils/permissions'

const { Text, Title } = Typography

type TaskMembersProps = {
  task: Partial<TaskItem>
}

const groupByUserId = (arr: TaskRolePermission[]) => {
  const grouped: { [key: string]: TaskRolePermission[] } = {}
  for (const item of arr) {
    const userId = item.user.id
    if (!grouped[userId]) {
      grouped[userId] = []
    }
    grouped[userId].push(item)
  }
  return grouped
}

const TaskMembers = ({ task }: TaskMembersProps) => {
  const { t } = useTranslation()
  const app = App.useApp()
  const [isEditing, setIsEditing] = useState(false)
  const [userTaskRoles, setUserTaskRoles] = useState<TaskRolePermission[]>([])
  const [loading, setLoading] = useState(false)

  const { createUserTaskRole, deleteUserTaskRole, getUserTaskRoleByTaskId } =
    useStore((state) => state)

  const { hasUpdatePermissionOnTask } = useTaskEditor()

  const taskRolesByUser = useMemo(
    () => groupByUserId(userTaskRoles),
    [userTaskRoles],
  )
  const hasPermissionToAddMembers = useMemo(
    () => hasUpdatePermissionOnTask(TaskFields.MANAGE_ROLE),
    [hasUpdatePermissionOnTask],
  )

  const handleAddTaskMember = (userId: string, roleId: number) => {
    createUserTaskRole({ user: userId, role: roleId, task: task.id })
      .then((role) => {
        fetchUserTaskRole()
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const handleRemoveTaskMember = (userTaskRoleId: number) => {
    deleteUserTaskRole(userTaskRoleId)
      .then(() => {
        fetchUserTaskRole()
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const fetchUserTaskRole = useCallback(() => {
    if (task.id) {
      setLoading(true)
      getUserTaskRoleByTaskId(task.id)
        .then((roles) => {
          setUserTaskRoles(roles)
        })
        .catch((error) => {
          const message =
            error.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [app.notification, getUserTaskRoleByTaskId, task.id])

  useEffect(() => {
    fetchUserTaskRole()
  }, [fetchUserTaskRole])

  return (
    <div
      id="scrollableDiv"
      style={{
        maxHeight: 700,
        overflowX: 'auto',
        paddingRight: 16,
      }}
    >
      <Row gutter={8} style={{ padding: 16 }} justify="start">
        <Col span={24}>
          <TaskProjectManager
            taskId={task?.id!}
            refreshMembersList={fetchUserTaskRole}
          />
        </Col>
        <Divider />
      </Row>
      <Row
        gutter={8}
        style={{ paddingLeft: 16, paddingRight: 16 }}
        justify="start"
      >
        <Col span={24}>
          <Space
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '8px',
            }}
          >
            <Title level={4}>{t('task-members.title')}</Title>
          </Space>
        </Col>
        {isEditing && (
          <Col span={24}>
            <AddTaskMember addTaskMember={handleAddTaskMember} cancelEditing={() => setIsEditing(false)} />
            <Divider />
          </Col>
        )}
        <Col span={24}>
          <Spin spinning={loading} size="large">
            {isEditing && (
              <Row>
                <Col span={8}>
                  <Title level={4}>{t('task-members.remove-roles')}</Title>
                </Col>
              </Row>
            )}
            {Object.keys(taskRolesByUser).map((userId) => (
              <Row key={userId}>
                <Col span={8}>
                  <SelectTag
                    label={taskRolesByUser[userId][0].user.name}
                    image={taskRolesByUser[userId][0].user.avatar}
                  />
                </Col>
                <Col span={8}>
                  {taskRolesByUser[userId].map((assignedRole, index) => (
                    <Row key={assignedRole.id}>
                      <Col span={8} style={{ marginBottom: 4 }}>
                        <Text>{assignedRole.role.title}</Text>
                      </Col>
                      {isEditing && (
                        <Col span={16} style={{ marginBottom: 4 }}>
                          <Button
                            onClick={() =>
                              handleRemoveTaskMember(assignedRole.id)
                            }
                          >
                            {t('actions.delete')}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  ))}
                </Col>
                {!isEditing && (
                  <Col span={8}>
                  <Button
                    type="primary"
                    onClick={() => setIsEditing(!isEditing)}
                    disabled={!hasPermissionToAddMembers}
                  >
                       {t('settings.user-form.edit')}
                  </Button>
                </Col>
                )}
                <Divider />
              </Row>
            ))}
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default TaskMembers
