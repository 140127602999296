import { Col, Row, Table, Typography } from 'antd'
import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomField, CustomFieldValueDataFormat } from 'types/CustomFields'
import { fixedColumns } from './CompletionReportUtils'

const { Text, Title } = Typography

type SentDataReportProps = {
  taskId: string
  customFields: CustomField
}

const SentDataReport = ({ taskId, customFields }: SentDataReportProps) => {
  const { t } = useTranslation()
  const [completedPercentage, setCompletedPercentage] = useState<number>(0)
  const [tableData, setTableData] = useState<CustomFieldValueDataFormat[]>([])
  const [tableColumns, setTableColumns] = useState<
    CustomFieldValueDataFormat[]
  >([])
  const { dateFormat, getTaskReportData } = useStore((state) => state)

  useEffect(() => {
    getTaskReportData(taskId).then((result) => {
      const numberFilledRecords = result.filter(
        (item) => !!item.customValue,
      ).length
      if (result.length) {
        const percentage = (numberFilledRecords / result.length) * 100
        setCompletedPercentage(percentage)
      }
      const data = result.map((item) => ({
        key: item.id,
        startDate: dayjs(item.startDate).format(dateFormat),
        status: item.customValue
          ? t('reports.data.sent')
          : t('reports.data.pending'),
        ...(item.customValue ? item.customValue : {}),
      }))
      setTableData(data)
    })
  }, [getTaskReportData, t, taskId, dateFormat])

  useEffect(() => {
    const customColumns = customFields.customField.map((field) => ({
      title: field.label,
      dataIndex: field.id,
      key: field.id,
    }))
    setTableColumns([...fixedColumns, ...customColumns])
  }, [customFields.customField])

  return (
    <Row gutter={16} style={{ padding: 16 }} justify="start">
      <Col span={24}>
        <Row gutter={16}>
          <Col span={24}>
            <Title level={2}>{t('reports.data.title')}</Title>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={14}>
                <Title level={5}>
                  {t('reports.data.completion-percentage')}
                </Title>
              </Col>
              <Col span={10}>
                <Text strong>
                  {Math.round(completedPercentage * 100) / 100}%
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col>
                <Table
                  dataSource={tableData}
                  columns={tableColumns}
                  scroll={{ x: 'max-content', y: 400 }}
                  sticky
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SentDataReport
