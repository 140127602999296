import { Options, RRule, rrulestr } from 'rrule'
import { FlexibleTime } from 'types/FlexibleTime'

const FLEXIBLE_RULE = 'FLEXIBLE'
interface RecurRuleOptions extends Options {
  flexible: FlexibleTime | undefined
}

export const recurRuleFromString = (rRule: string | undefined) => {
  rRule = rRule
    ?.replaceAll('\\n', '\n')
    ?.replaceAll('"', '')
    ?.replaceAll('{}', '')
    ?.replaceAll('[]', '')
  if (!rRule) {
    return new RecurRule(undefined)
  }
  const lines = rRule.split(';')
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].includes(FLEXIBLE_RULE)) {
      lines.splice(i, 1)
      break
    }
  }
  try {
    const rule = rrulestr(lines.join(';'))
    return new RecurRule({
      ...rule.origOptions,
    })
  } catch (error) {
    console.error(rRule, error)
  }
  return new RecurRule(undefined)
}

class RecurRule extends RRule {
  flexible: FlexibleTime | undefined

  constructor(props: Partial<RecurRuleOptions> | undefined) {
    const { flexible, ...superProps } = props || {}
    super(superProps)
    this.flexible = flexible || FlexibleTime.SCHEDULED
  }

  public toCompatibleString = () => {
    return super.toString()
  }

  isFlexibleTime(): boolean {
    return this.flexible !== FlexibleTime.SCHEDULED
  }

  isRecurring(): boolean {
    return this.origOptions.interval ? this.origOptions.interval > 0 : false
  }

  isRecurringToShowOnCalendar(): boolean {
    return this.isRecurring() && !this.isFlexibleTime()
  }
}

export default RecurRule
