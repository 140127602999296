import { SendOutlined } from '@ant-design/icons'
import {
  App,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import clsx from 'clsx'
import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { TaskFields } from 'utils/permissions'
import { EmailOptionRender } from './EmailOptionRender'
import styles from './EmailSelector.module.scss'
import {
  decodeOptionLabel,
  shouldShowResendButton,
} from './EmailSelectorHelper'

const { Text } = Typography
const transparentStyle = { color: 'rgba(0, 0, 0, 0.6)' }

type EmailPopoverProps = {
  data: DefaultOptionType
}

export const EmailPopover = ({ data }: EmailPopoverProps) => {
  const { t } = useTranslation()
  const { message } = App.useApp()
  const { initialValues } = useTaskEditor()
  const listItem = EmailOptionRender(data, { index: 0 })
  const { status, createdAt } = decodeOptionLabel(data.label as string)
  const { dateFormat, resendDelegationInvitation } = useStore((state) => state)
  const { hasUpdatePermissionOnTask } = useTaskEditor()
  const isReadonly = !hasUpdatePermissionOnTask(TaskFields.TASK_ASSIGNEES)
  const statusClassName = clsx(
    styles[status ? status.replaceAll(' ', '').toLowerCase() : 'onhold'],
  )
  const dateString = status
    ? dayjs(createdAt).isValid()
      ? dayjs(createdAt).format(dateFormat)
      : t('email-selector.no-date')
    : t('email-selector.not-sent')

  const handleClickResend: MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!initialValues?.id) {
      return
    }
    resendDelegationInvitation(data.value as string, initialValues.id)
      .then(() => {
        message.success(t('invite.resent'))
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.fallback_message)
        } else {
          message.error(error.message)
        }
      })
  }

  return (
    <Space
      direction="vertical"
      onMouseDown={(evt) => {
        evt.preventDefault()
        evt.stopPropagation()
      }}
    >
      <Row>
        <Col span={24}>{listItem}</Col>
      </Row>
      <Row gutter={8}>
        <Col>
          <Space style={{ paddingLeft: 20 }}>
            <Text strong style={transparentStyle}>
              {t('email-selector.status')}:
            </Text>
            <Text className={statusClassName}>{status || 'Not Sent'}</Text>
            <Text strong style={transparentStyle}>
              {t('email-selector.date')}:
            </Text>
            <Text>{dateString}</Text>
          </Space>
        </Col>
      </Row>
      <Divider style={{ marginTop: 0, marginBottom: 0 }} />
      <Row gutter={8}>
        <Col style={{ paddingLeft: 20 }}>
          <Tooltip title={t('group.resend')}>
            <Button
              icon={<SendOutlined />}
              disabled={!shouldShowResendButton(status) || isReadonly}
              onClick={handleClickResend}
            />
          </Tooltip>
        </Col>
      </Row>
    </Space>
  )
}
