enum customEvents {
  SaveNewTask = 'SaveNewTask',
  UpdateTask = 'UpdateTask',
  DeleteTask = 'DeleteTask',
}

function subscribe(eventName: customEvents, listener: EventListener) {
  document.addEventListener(eventName, listener)
}

function unsubscribe(eventName: customEvents, listener: EventListener) {
  document.removeEventListener(eventName, listener)
}

function publish(eventName: customEvents, data: any) {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}

export { publish, subscribe, unsubscribe, customEvents }
