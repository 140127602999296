import { Col, Input, List, Row, Typography } from 'antd'
import { SearchProps } from 'antd/es/input'
import Fuse from 'fuse.js'
import useStore from 'hooks/useStore'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectTag from '../DelegationDrawer/SelectTag'

const { Text } = Typography
const { Search } = Input

export type UserSearch = {
  id: string
  name: string
  email: string
}

const NoResultsItem: UserSearch = {
  id: '',
  name: '',
  email: 'NoRecordsFound',
}

type SearchUserProps = {
  selectedUserChanged?: (user: UserSearch) => void
}

const options = {
  shouldSort: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
}

const searchContacts = (query: string, list: UserSearch[], keys: string[]) => {
  const fuse = new Fuse(list, { ...options, keys })
  return fuse.search(query)
}

const SearchUser = ({ selectedUserChanged }: SearchUserProps) => {
  const { t } = useTranslation()
  const { allGroups } = useStore((state) => state)
  const [isSearching, setIsSearching] = useState(false)
  const [searchList, setSearchList] = useState<UserSearch[]>([])
  const [selectedUser, setSelectedUser] = useState<string | undefined>(
    undefined,
  )

  const contactList = useMemo(() => {
    const memberList = allGroups
      .flatMap((group) => group.groupMembers)
      .map(
        (groupMember) =>
          ({
            id: groupMember?.user || '',
            name: groupMember?.name || '',
            email: groupMember?.email || '',
            label: (
              <SelectTag
                label={groupMember?.name}
                secondaryLabel={groupMember?.email}
              />
            ),
          } as UserSearch),
      )
    return memberList.filter((item, index) => {
      return index === memberList.findIndex((obj) => item.id === obj.id)
    })
  }, [allGroups])

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    if (info?.source !== 'input' || value?.trim() === '') {
      setIsSearching(false)
      return
    }
    const usersList: UserSearch[] = [...contactList]
    const result = searchContacts(value, usersList, ['email'])
    const itemChildList = result.map((result) => result?.item)
    const uniqueResultList = itemChildList.filter((item, index) => {
      return index === itemChildList.findIndex((obj) => item.id === obj.id)
    })
    setIsSearching(true)
    if (!itemChildList.length) {
      setSearchList([NoResultsItem])
    } else {
      setSearchList(uniqueResultList)
    }
  }

  return (
    <>
      <Search
        placeholder="Search"
        style={{ width: '100%' }}
        enterButton
        allowClear
        onSearch={onSearch}
      />
      <Row>
        <Col span={24}>
          <div
            id="scrollableDiv"
            style={{
              height: 150,
              overflow: 'auto',
              padding: '0 16px',
              border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            <List
              dataSource={isSearching ? searchList : contactList}
              bordered={false}
              renderItem={(item) => {
                if (item?.email === NoResultsItem.email) {
                  return (
                    <List.Item style={{ paddingInline: 16 }}>
                      <Text
                        ellipsis
                        style={{
                          backgroundColor: 'transparent',
                          verticalAlign: 'middle',
                          paddingTop: 6,
                          border: 'none',
                        }}
                      >
                        {t('task-members.n-results-found', {
                          ns: 'common',
                          number: '0',
                        })}
                      </Text>
                    </List.Item>
                  )
                }
                return (
                  <List.Item
                    style={{
                      paddingInline: 0,
                      border: 'none',
                      backgroundColor:
                        selectedUser === item.id
                          ? 'var(--selected-icon)'
                          : 'var(--offset-background-color)',
                      marginBottom: 4,
                    }}
                    onClick={() => {
                      selectedUserChanged?.(item)
                      setSelectedUser(item.id)
                    }}
                  >
                    <SelectTag
                      label={item?.name}
                      secondaryLabel={item?.email}
                      border={false}
                    />
                  </List.Item>
                )
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SearchUser
