import { Layout, Divider, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PropsWithChildren } from 'react'

const { Content } = Layout

type SelectDropdownProps = {
  menu: JSX.Element
  onAdd: () => void
  buttonText: string
}

const SelectDropdown = ({
  menu,
  onAdd,
  buttonText,
}: PropsWithChildren<SelectDropdownProps>) => {
  return (
    <Content
      style={{
        textAlign: 'left',
        color: 'var(--completed-color)',
      }}
    >
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Button
        type="text"
        style={{ color: 'var(--completed-color)' }}
        onClick={onAdd}
      >
        {buttonText}
        <PlusOutlined />
      </Button>
    </Content>
  )
}

export default SelectDropdown
