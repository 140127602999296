import { App, Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import useStore from 'hooks/useStore'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Company } from 'types/Contacts'
import { getCountries } from 'utils/IntlUtlis'

type AddCompanyModalProps = {
  isOpen: boolean
  onCancel: () => void
}

enum ValidateStatus {
  DEFAULT = '',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  VALIDATING = 'validating',
}

const AddCompanyModal = ({ isOpen, onCancel }: AddCompanyModalProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const app = App.useApp()
  const [status, setStatus] = useState<ValidateStatus>()
  const createCompany = useStore((state) => state.createCompany)
  const setCompanies = useStore((state) => state.setCompanies)
  const companies = useStore((state) => state.companies)
  const user = useStore((state) => state.user?.data)
  const countries = getCountries()
  const userId = user?.id

  const onFinish = (values: Company) => {
    const { email = '' } = values
    setStatus(ValidateStatus.VALIDATING)

    createCompany({ ...values, email: email.toLowerCase() })
      .then((data) => {
        setStatus(ValidateStatus.SUCCESS)
        form.resetFields()

        setCompanies([...companies, ...[data]])
        app.notification.success({
          message: t('add-company-modal.add-company-success'),
        })
      })
      .catch((error) => {
        setStatus(ValidateStatus.ERROR)
        const message = error?.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
  }

  const onFinishFailed = () => {
    setStatus(ValidateStatus.ERROR)
  }

  return (
    <Modal
      zIndex={1010}
      title={t('add-company-modal.add-company')}
      open={isOpen}
      onCancel={onCancel}
      centered
      width={isTabletOrMobile ? '60%' : '40%'}
      footer={null}
    >
      <Form
        name="add-company"
        layout="vertical"
        form={form}
        initialValues={{
          remember: true,
          user: userId,
          name: '',
          address1: '',
          address2: '',
          phone: '',
          cell: '',
          city: '',
          zip: '',
          state: '',
          country: 'United States of America',
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="user"
              hidden
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input value={userId} />
            </Form.Item>
            <Form.Item
              label={t('add-company-modal.name')}
              name="name"
              hasFeedback
              validateStatus={status}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t('add-company-modal.address-1')}
              name="address1"
              validateStatus={status}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('add-company-modal.address-2')}
              name="address2"
              validateStatus={status}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('add-company-modal.phone')}
              name="phone"
              validateStatus={status}
            >
              <Input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="XXX-XXX-XXXX"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('add-company-modal.email')}
              name="email"
              validateStatus={status}
            >
              <Input type="email" style={{ textTransform: 'lowercase' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('add-company-modal.city')}
              name="city"
              validateStatus={status}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('add-company-modal.state')}
              name="state"
              validateStatus={status}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('add-company-modal.country')}
              name="country"
              validateStatus={status}
            >
              <Select options={countries} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('add-contact-modal.zip')}
              name="zip"
              validateStatus={status}
              rules={[
                {
                  max: 12,
                  message: 'Value should be less than 12 character',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={12}>
            <Form.Item
              style={{
                alignItems: 'flex-end',
                paddingTop: 16,
                textAlign: 'right',
              }}
            >
              <Space align="baseline">
                <Button onClick={onCancel}>
                  {t('add-company-modal.close')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: 'var(--completed-color)' }}
                >
                  {t('add-company-modal.save')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default AddCompanyModal
